import React from "react";
import labels from "../../../Static/Dictionary.json";
import { Block } from "../../Atoms/Block";
import { Row } from "react-bootstrap";

export const PracticeInformation = ({ practiceInfoData }) => {
  return (
    <>
      <div className="Approvalbox">
        <div className="mainblockbox mb-2">
          <Row>
            <Block
              labelText={labels.Practice_Name}
              details={practiceInfoData?.practiceName}
            />
            <Block
              labelText={labels.Practice_Manager}
              details={practiceInfoData?.practiceManagerFirstName ? `${practiceInfoData?.practiceManagerFirstName}${" "}
              ${practiceInfoData?.practiceManagerMiddleName ? practiceInfoData?.practiceManagerMiddleName : ""}${" "}
              ${practiceInfoData?.practiceManagerLastName}` : "--"}
            />
            <Block
              labelText={labels.Practice_Location}
              details={practiceInfoData?.practiceLocation}
            />
          </Row>
        </div>
        <p className="info-text">Primary SQCN Contact Information </p>
        <div className="mainblockbox mb-2">
          <Row>
            <Block labelText={labels.Name}
              details={practiceInfoData?.primaryContactFirstName ? `${practiceInfoData?.primaryContactFirstName}${" "}
               ${practiceInfoData?.primaryContactMiddleName ? practiceInfoData?.primaryContactMiddleName : ""}${" "}
               ${practiceInfoData?.primaryContactLastName}` : "--"} />
            <Block
              labelText={labels.MDOFFICE_ID}
              details={practiceInfoData?.primaryContactMdOfficeId}
            />
            <Block labelText={labels.Email_ID} details={practiceInfoData?.primaryContactEmailAddress} />
          </Row>
        </div>
        <div className="mainblockbox">
          <Row>
            <Block
              labelText={labels.Direct_Phone_number}
              details={practiceInfoData?.primaryContactDirectPhoneNumber}
            />
          </Row>
        </div>
      </div>
    </>
  );
};
