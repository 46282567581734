export const formatDate = (date) => {
  return (
    date.toLocaleDateString("en-CA") +
    "T" +
    date.toLocaleTimeString("it-IT") +
    "." +
    String(date.getMilliseconds()).padStart(3, "0") +
    "Z"
  );
};

export const formatDateByTimeZone = (date) => {
  // Adjust for the timezone offset to ensure the correct date is captured
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  // Convert to ISO string
  return utcDate.toISOString();
}