import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import addIcon from "../../../../../../../images/plus_icon.svg";
import AdminAddLocationForm from "./AdminAddLocationForm";
import AlertWarning from "Components/UI/Atoms/AlertWarning";

const AdminAddLocation = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const selectedPracticeIdByAdmin = localStorage.getItem(
    "selectedPracticeIdByAdmin"
  );
  const [addNewLocation, setAddNewLocation] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isAddView, setAddView] = useState(false);
  const [isEditView, setEditView] = useState(false);
  return (
    <div className="add-location-button">
      <Row className="mb-3 mx-0">
        <Col md={9}></Col>
        <Col md={3} className="text-right p-0">
          <Button
            variant="primary"
            onClick={() => {
              setModalShow(true);
              setShowForm(true);
              setAddView(true);
              setEditView(false);
            }}
            className="add-location rounded-pill add_new_practice"
            data-testid="AddLocationbutton"
          >
            <span>
              <img src={addIcon} alt="add icon" />
            </span>
            Add Location
          </Button>
        </Col>
      </Row>
      {modalShow && (
        <AdminAddLocationForm
          data={[]}
          showForm={showForm}
          setShowForm={setShowForm}
          clickHandler={() => {}}
          data-testid="modal"
          practiceManagerId={props?.practiceManagerId}
          selectedPracticeId={selectedPracticeIdByAdmin}
          AddNewLocationInfo={() => setAddNewLocation(true)}
          setLocationPayload={props.setLocationPayload}
          addPractice={props.addPractice}
          isAddView={isAddView}
          setAddView={setAddView}
          isEditView={isEditView}
          setEditView={setEditView}
        />
      )}
      {addNewLocation && (
        <AlertWarning
          ApprovalIcon={addIcon}
          //   ApprovalcloseIcon={ApprovalcloseIcon}
          //   AlertText="New location to practice sent for SQCN Admin review"
          //   ALertHandleClick={handleClick}
        />
      )}
    </div>
  );
};

export default AdminAddLocation;
