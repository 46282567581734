import React from "react";
import SqcnSideMenu from "./SqcnSideMenu";
import { PracticeList } from "./ManageSqcnPractice/PracticeList";

export const PracticeAccess = (props) => {
  return (
    <SqcnSideMenu pmName={props.pmName} headerText={"Manage SQCN Practice"}>
      <PracticeList />
    </SqcnSideMenu>
  );
};
