import React from "react";
import { Col } from "react-bootstrap";
import("./_style.scss");

export const Block = (props) => {
  return (
    <>
      <Col md={4}>
        <p className="details-label">{props?.labelText}</p>
        {props?.details ?
          <p className="practice-details">{props?.details}</p> :
          <p className="practice-details"> -- </p>
        }
      </Col>
    </>
  );
};
