import React, { useEffect, useState } from "react";
import labels from "Static/Dictionary.json";
import datePickerIcon from "../../../../../../../images/datepicker_icon.svg";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Button, Row, Col } from "react-bootstrap";
import cookies from "js-cookie";
import { formatDate } from "../../../../../../../Static/format.js";
import StateData from "Components/UI/Organisms/StateData";
import stateFieldData from "Static/state.json";
import { phoneNumberAutoFormat } from "utils";
import LocationValidationModal from "../LocationValidation/LocationValidationModal";

const EditLocation = (props) => {
  const {
    showEditData,
    handleAddClick,
    selectedPracticeIdByAdmin,
    setSuccess,
    setLocAdded,
    setLocDeleted,
    setEditView,
    passArrayToParent,
    updatedLocData,
  } = props;
  const [stateName, setStateName] = useState(null);
  const [newState, setNewState] = useState();
  const [isSavedData, setIsSavedData] = useState(false);
  const [locationCount, setLocationCount] = useState(0);
  useEffect(() => {
    const largestLocationNumber = updatedLocData?.reduce((max, item) => Math.max(max, item.locationNumber), 0);
    setLocationCount(largestLocationNumber + 1);
  }, [updatedLocData]);
  const selectedStateIdfn = (id, name, data = []) => {
    const fName = data?.filter(
      (item) => item?.stateName?.toLowerCase() === name?.toLowerCase()
    );
    if (fName?.length > 0) {
      setNewState(false);
    } else {
      setNewState(true);
    }
    setStateName(name);
  };

  const stateCodeName = stateFieldData?.find(
    (item) => item?.stateName?.toLowerCase() === stateName?.toLowerCase()
  );
  const validationSchema = Yup.object().shape({
    locationNumber: Yup.string().notRequired(),
    locationName: Yup.string().required("Location Name cannot be blank"),
    street1: Yup.string().required("Street1 cannot be blank"),
    city: Yup.string().required("City Name cannot be blank"),
    // state: Yup.string().required("State Name cannot be empty"),
    zipCode: Yup.string().required("Zip Code cannot be blank"),
    phoneNumber: Yup.string().required("Phone Number cannot be blank"),
    faxNumber: Yup.string().required("Fax Number cannot be blank"),
    startDate: Yup.string().notRequired(),
    termDate: Yup.string().notRequired(),
  });
  // const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const onSubmit = (values, { setSubmitting }) => {
    console.log("res-- Location Edit Form submitted:", values);
    // Set submitting to true to indicate form submission is in progress
    setSubmitting(true);
    const formatStartDate = new Date(values?.startDate);
    const formattedStartDate = formatDate(formatStartDate);
    const formatTermDate = new Date(values?.termDate);
    const formattedTermDate = formatDate(formatTermDate);
    const token = cookies.get("access");
    const base_url = process.env.REACT_APP_SERVER_URL;
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    const editLocPayLoadData = {
      id: showEditData.id,
      locationName: values.locationName,
      locationNumber: values.locationNumber,
      street1: values.street1,
      street2: values.street2,
      street3: values.street3,
      city: values.city,
      state: stateCodeName?.stateCode ? stateCodeName?.stateCode : values.state,
      zipCode: values.zipCode,
      startDate: values?.startDate && formattedStartDate,
      termDate: values?.termDate && formattedTermDate,


      notesRegardingTermdatesforLocation: values.notesRegardingTermdatesforLocation,
      phoneNumber: [
        {
          number: values?.phoneNumber,
          phoneTypeId: 1,
          practiceId: selectedPracticeIdByAdmin,
          id: showEditData?.phoneNumber?.find(
            (phone) => phone.phoneTypeId === 1
          )?.id,
        },
        {
          number: values?.faxNumber,
          phoneTypeId: 4,
          practiceId: selectedPracticeIdByAdmin,
          id: showEditData?.phoneNumber?.find(
            (phone) => phone.phoneTypeId === 4
          )?.id,
        },
        {
          number: values?.schedulingNumber,
          phoneTypeId: 5,
          practiceId: selectedPracticeIdByAdmin,
          id: showEditData?.phoneNumber?.find(
            (phone) => phone.phoneTypeId === 5
          )?.id,
        },
      ],
      status: "Approved",
      sourceSystem: "SQCN",
      updateUser: "SYSTEM",
      lastConfirmed: "2023-05-05T00:00:00",
      isDeleted: false,
    };
    const addLocPayLoadData = {
      locationReplicaId: 0,
      locationNumber: locationCount,
      locationName: values.locationName,
      street1: values.street1,
      street2: values.street2,
      street3: values.street3,
      city: values.city,
      state: stateCodeName?.stateCode ? stateCodeName?.stateCode : values.state,
      zipCode: values.zipCode,
      startDate: values?.startDate && formattedStartDate,
      termDate: values?.termDate && formattedTermDate,

      notesRegardingTermdatesforLocation: values.notesRegardingTermdatesforLocation,
      main_ContactNumber: values?.phoneNumber,
      fax_ContactNumber: values?.faxNumber,
      directScheduling_ContactNumber: values?.schedulingNumber,
      status: "Approved",
      sourceSystem: "SQCN",
      updateUser: "SYSTEM",
      lastConfirmed: "2023-05-05T00:00:00",
      isDeleted: false,

    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(editLocPayLoadData),
    };
    const requestOptionsForAdd = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(addLocPayLoadData),
    };
    if(isEdit){
      fetch(
        base_url +
          `/api/v1/practices/${selectedPracticeIdByAdmin}/location/EditLocation`,
        requestOptions
      ).then((response) => {
        console.log(
          "Saved locationInfo editLocationInfo",
          response,
          response.status
        );
        if (response.status == 200) {
          setSuccess(true);
          setLocAdded(false);
          setLocDeleted(false);
          setEditView(false);
          setSubmitting(false);
          passArrayToParent(editLocPayLoadData);
          handleAddClick();
        }
      });
    } else {
      fetch(
        base_url +
        `/api/v1/practices/${selectedPracticeIdByAdmin}/location?isFromSqcn=true`,
        requestOptionsForAdd
      ).then((response) => {
        console.log(
          "Saved locationInfo AddLocationInfo",
          response,
          response.status
        );
        if (response.status == 200) {
          setSuccess(true);
          setLocAdded(true);
          setLocDeleted(false);
          setEditView(false);
          setSubmitting(false);
          passArrayToParent(addLocPayLoadData);
          handleAddClick();
        }
      });
    }
  };
  const [locationModalShow, setLocationModalShow] = useState(false);
  const [locationdetails, setLocationDetails] = useState("");
  const [locationDetails2, setLocationDetails2] = useState("");
  const [locationDetailsObj, setLocationDetailsObj] = useState({
    street_number: "",
    route: "",
    sublocality_level_2: "",
    sublocality_level_1: "",
    administrative_area_level_1: "",
    administrative_area_level_3: "",
    postalCode: ""
  });
  const [locDetails, setLocDetails] = useState(false);
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [stateA, setStateA] = useState("");
  const [zipcode, setZipcode] = useState("");
  useEffect(() => {
    if (Object.keys(showEditData).length === 0) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
    setStreet1(showEditData?.street1 || "");
    setStreet2(showEditData?.street2 || "");
    setCity(showEditData?.city || "");
    setStateName(showEditData?.state || "");
    setZipcode(showEditData?.zipCode || "");
  }, [showEditData]);
  const handleChangeGoogleApi = async () => {
    const street1Address = street1 ? `${street1.replace(/\s/g, "+")}` : "";
    const street2Address = street2 ? `${street2.replace(/\s/g, "+")}` : "";
    const cityAddress = city ?`${city.replace(/\s/g, "+")}` : "";
    const stateAAddress = stateA ? `${stateA.replace(/\s/g, "+")}` : "";
    const zipcodeAddress = zipcode ? `${zipcode.replace(/\s/g, "+")}` : "";
    const fullAddress = `${street1Address}+${street2Address}+${cityAddress}+${stateAAddress}+${zipcodeAddress}`;
    let status = "";
    const googleToken = process.env.REACT_APP_GOOGLE_TOKEN;
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=${googleToken}`
    )
      .then((response) => {
        status = response?.status;
        return response.json();
      }).then((res) => {
        if (status == 200) {
          if(res?.results.length > 0 && res && res.results && res.results[0] && res.results[0].address_components) {
            const street_number = res.results[0].address_components.find(component => 
              component.types.includes("street_number")
            )?.long_name;
            const route = res.results[0].address_components.find(component => 
              component.types.includes("route")
            )?.long_name;
            const sublocality_level_2 = res.results[0].address_components.find(component => 
              component.types.includes("sublocality_level_2")
            )?.long_name;
            const sublocality_level_1 = res.results[0].address_components.find(component => 
              component.types.includes("sublocality_level_1")
            )?.long_name;
            const locality = res.results[0].address_components.find(component => 
              component.types.includes("locality")
            )?.long_name;
            const administrative_area_level_1 = res.results[0].address_components.find(component => 
              component.types.includes("administrative_area_level_1")
            )?.long_name;
            const administrative_area_level_3 = res.results[0].address_components.find(component => 
              component.types.includes("administrative_area_level_3")
            )?.long_name;
            const postal_code = res.results[0].address_components.find(component => 
              component.types.includes("postal_code")
            )?.long_name;
            setLocationDetailsObj({
              street_number: street_number,
              route: route,
              locality: locality,
              sublocality_level_2: sublocality_level_2,
              sublocality_level_1: sublocality_level_1,
              administrative_area_level_3: administrative_area_level_3,
              administrative_area_level_1: administrative_area_level_1,
              postalCode: postal_code,
            });
            setLocationDetails(`${street_number || ""} ${route || ""} ${sublocality_level_2 || ""} ${sublocality_level_1 || ""} ${locality || ""}`);
            setLocationDetails2(`${administrative_area_level_1 || ""} ${postal_code || ""}`);
          }
        } else {
          setLocationDetails(showEditData?.street1);
          setLocationDetails2(`${showEditData.state} ${showEditData.zipCode}`);
        }
      });
  }
  useEffect(() => {
    const fetch = async () => {
      handleChangeGoogleApi();
    };
    fetch();
  }, [street1, street2, city, stateName, stateA, zipcode, locationModalShow]);
  useEffect(() => {
    if(locDetails === true) {
      setStreet1(`${locationDetailsObj?.street_number || ""} ${locationDetailsObj?.route || ""}`);
      setStreet2(`${locationDetailsObj?.sublocality_level_2 || ""} ${locationDetailsObj?.sublocality_level_1 || ""}`);
      setCity(locationDetailsObj?.locality ? locationDetailsObj?.locality : locationDetailsObj?.administrative_area_level_3 || "");
      setStateA(locationDetailsObj?.administrative_area_level_1 || "");
      setStateName(locationDetailsObj?.administrative_area_level_1 || "");
      setZipcode(locationDetailsObj?.postalCode || "");
    }
  }, [locDetails]);

  return (
    <Formik
      // key={showEditData.locationNumber}
      enableReinitialize
      initialValues={{
        locationNumber: isEdit ? showEditData.locationNumber : locationCount || "",
        locationName: showEditData.locationName || "",
        street1: showEditData.street1 || "",
        street2: showEditData.street2 || "",
        street3: showEditData.street3 || "",
        city: showEditData.city || "",
        state: showEditData.state || "",
        zipCode: showEditData.zipCode || "",
        phoneNumber:
          showEditData?.phoneNumber?.find((phone) => phone?.phoneTypeId === 1)
            ?.number || "",
        faxNumber:
          showEditData?.phoneNumber?.find((phone) => phone?.phoneTypeId === 4)
            ?.number || "",
        schedulingNumber:
          showEditData?.phoneNumber?.find((phone) => phone?.phoneTypeId === 5)
            ?.number || "",
        status: "",
        updateUser: "",
        startDate:
          (showEditData?.startDate && new Date(showEditData?.startDate)) || "",
        termDate:
          (showEditData?.termDate && new Date(showEditData?.termDate)) || "",

        notesRegardingTermdatesforLocation: showEditData?.notesRegardingTermdatesforLocation || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, handleChange, values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="contact-section">
            <Row>
              <Col md={2}>
                <p className="details-label">{labels.LOCATION_NUMBER}</p>
                <Form.Group controlId="locationNumber">
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    className="nb-input readonly-input"
                    value={values.locationNumber}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <p className="details-label">{labels.LOCATION_NAME}</p>
                <Form.Group controlId="locationName">
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    className="nb-input"
                    value={values.locationName}
                    onChange={handleChange}
                    isInvalid={!!errors.locationName}
                    placeholder={!values.locationName && isEdit && "--"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.locationName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <p className="details-label">{labels.ADDRESS}</p>
                <Form.Group controlId="street1">
                  <Form.Control
                    type="text"
                    value={street1}
                    name="street1"
                    autoComplete="off"
                    className="nb-input"
                    onChange={(e) => {
                      setStreet1(e.target.value);
                      setFieldValue("street1", e.target.value);
                    }}
                    isInvalid={!!errors.street1}
                    placeholder={!values.street1 && isEdit && "--"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.street1}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4 d-flex flex-row-reverse">
              <Col md={6}>
                <Form.Group controlId="street2">
                  <Form.Control
                    type="text"
                    value={street2}
                    name="street2"
                    autoComplete="off"
                    className="nb-input"
                    onChange={(e) => {
                      setStreet2(e.target.value);
                      setFieldValue("street2", e.target.value);
                    }}
                    placeholder={!values.street2 && isEdit && "--"}
                    id="street2"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4 d-flex flex-row-reverse">
              <Col md={6}>
                <Form.Group controlId="street3">
                  <Form.Control
                    type="text"
                    value={values.street3}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                    placeholder={!values.street3 && isEdit && "--"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                <p className="details-label">{labels.CITY}</p>
                <Form.Group controlId="city">
                  <Form.Control
                    type="text"
                    value={city}
                    name="city"
                    autoComplete="off"
                    className="nb-input"
                    onChange={(e) => {
                      setCity(e.target.value);
                      setFieldValue("city", e.target.value);
                    }}
                    isInvalid={!!errors.city}
                    placeholder={!values.city && isEdit && "--"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.STATE}</p>
                <StateData
                  placeholder={!values.state && isEdit && "--"}
                  id="practiceManagerMdId"
                  autoComplete="off"
                  className="nb-input"
                  stateFieldData={stateFieldData}
                  selectedStateIdfn={selectedStateIdfn}
                  validationError={
                    (values?.locationName?.length > 0 &&
                      !stateName?.length > 0) ||
                    (errors.locationName && !stateName?.length > 0)
                  }
                  validationError1={newState && stateName?.length > 1 && values.state === ""}
                  isSavedData={isSavedData}
                  setIsSavedData={setIsSavedData}
                  formState={values.state}
                  stateA={stateA}
                  setStateName={setStateName}
                  setStateA={setStateA}
                />
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.ZIP_CODE}</p>
                <Form.Group controlId="zipCode">
                  <Form.Control
                    type="text"
                    value={zipcode}
                    name="zipCode"
                    autoComplete="off"
                    className="nb-input"
                    onChange={(e) => {
                      setZipcode(e.target.value);
                      setFieldValue("zipCode", e.target.value);
                    }}
                    isInvalid={!!errors.zipCode}
                    placeholder={!values.zipCode && isEdit && "--"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.PHONE_NUMBER_MAIN}</p>
                <Form.Group controlId="phoneNumber">
                  <Form.Control
                    type="text"
                    value={values.phoneNumber}
                    autoComplete="off"
                    className="nb-input"
                    onChange={(e)=>{
                      let formattedNumber = phoneNumberAutoFormat(e.target.value);
                      const formattedObject = {
                        target: {id:e.target.id, name: e.target.name, value: formattedNumber }
                      };
                      handleChange(formattedObject);
                    }}
                    isInvalid={!!errors.phoneNumber}
                    placeholder={!values.phoneNumber && isEdit && "--"}
                    maxLength={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                <p className="details-label">{labels.FAX_NUMBER}</p>
                <Form.Group controlId="faxNumber">
                  <Form.Control
                    type="text"
                    value={values.faxNumber}
                    autoComplete="off"
                    className="nb-input"
                    onChange={(e)=>{
                      let formattedNumber = phoneNumberAutoFormat(e.target.value);
                      const formattedObject = {
                        target: {id:e.target.id, name: e.target.name, value: formattedNumber }
                      };
                      handleChange(formattedObject);
                    }}
                    isInvalid={!!errors.faxNumber}
                    placeholder={!values.faxNumber && isEdit && "--"}
                    maxLength={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.faxNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.DIRECT_SCHEDULING_NUMBER}</p>
                <Form.Group controlId="schedulingNumber">
                  <Form.Control
                    type="text"
                    value={values.schedulingNumber}
                    autoComplete="off"
                    className="nb-input"
                    onChange={(e)=>{
                      let formattedNumber = phoneNumberAutoFormat(e.target.value);
                      const formattedObject = {
                        target: {id:e.target.id, name: e.target.name, value: formattedNumber }
                      };
                      handleChange(formattedObject);
                    }}
                    placeholder={!values.schedulingNumber && isEdit && "--"}
                    maxLength={12}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.START_DATE}</p>
                <div className="input-group search-container-date">
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={values.startDate}
                    onChange={(date) =>
                      handleChange({
                        target: { name: "startDate", value: date },
                      })
                    }
                    placeholderText={!values.startDate && isEdit && "--"}
                    data-testid="startDate"
                    className="nb-input width-full"
                    defaultValue={new Date(values.startDate)}
                    isInvalid={!!errors.startDate}
                  />
                  <button
                    type="button"
                    className="search-button right cursor-auto"
                  >
                    <img src={datePickerIcon} alt="DatePicker Icon" />
                  </button>
                </div>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.TERM_DATE}</p>
                <div className="input-group search-container-date">
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={values.termDate}
                    minDate={values.startDate}
                    onChange={(date) =>
                      handleChange({
                        target: { name: "termDate", value: date },
                      })
                    }
                    placeholderText={!values.termDate && isEdit && "--"}
                    data-testid="editproviderStartDate"
                    className="nb-input width-full"
                    value={new Date(values.termDate)}
                    isInvalid={!!errors.endDate}
                  />
                  <button
                    type="button"
                    className="search-button right cursor-auto"
                  >
                    <img src={datePickerIcon} alt="DatePicker Icon" />
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                <p className="details-label">  Notes Regarding Term Dates
                </p>
                <Form.Control
                  type="text"
                  placeholder="--"
                  id="notesRegardingTermdatesforLocation"
                  autoComplete="off"
                  className="nb-input"
                  value={values.notesRegardingTermdatesforLocation}
                  onChange={handleChange}

                  disabled={!values.termDate}
                  // isInvalid={!!errors.street1}
                />
              </Col>
            </Row>
          </div>
          <LocationValidationModal
            data-testid="locationValidationModal"
            show={locationModalShow}
            onHide={() => setLocationModalShow(false)}
            locationdetails={locationdetails}
            locationDetails2={locationDetails2}
            setLocDetails={setLocDetails}
          />
          <Row className="mt-3 d-flex justify-content-end">
            <Col md={2} className="text-right">
              <Button
                type="submit"
                className="rounded-pill save_btn blue-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(true);
                  setLocationModalShow(true);
                }}
                disabled
              >
                Validate
              </Button>
            </Col>
            <Col md={2} className="text-right validate_btn">
              <Button
                type="submit"
                className="rounded-pill save_btn blue-btn"
                onSubmit={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default EditLocation;
