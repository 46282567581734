import Modal from "react-bootstrap/Modal";
import valid_icon from "../images/valid_icon.svg";
import invalid_icon from "../images/invalid_icon.svg";
import { useEffect, useState } from "react";
import cookies from "js-cookie";

function NpiValidationModal(props) {
  const [status, setStatus] = useState("");
  const [content, setContent] = useState("");
  const handleNpiValidation = async () => {
    const token = cookies.get("access");
    const base_url = process.env.REACT_APP_SERVER_URL;
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    await fetch(
      base_url + `/api/v1/practices/NpiValidationApi?npi=${props.npiValue}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results.length > 0) {
          if (data.results && data.results.length > 0) {
            const basicInfo = data.results[0].basic;
            const firstName = basicInfo.first_name;
            const lastName = basicInfo.last_name;
            setStatus("Valid NPI");
            setContent(`${firstName} ${lastName}`);
          } else {
            setStatus("Invalid NPI");
            setContent("");
          }
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setStatus("Invalid NPI");
        setContent("");
      });
  };
  useEffect(() => {
    const fetch = async () => {
      handleNpiValidation();
    };
    fetch();
  }, [props.npiValue]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="add-provider-modal"
      data-testid="addprovidermodal"
    >
      <Modal.Header closeButton className="btn_close_padding">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className={
            status === "Valid NPI" ? "margin_bottom1" : "margin_bottom3"
          }
        >
          {status}
          {status === "Valid NPI" ? (
            <img
              src={valid_icon}
              alt="Valid Icon"
              className="npi_validation_icon"
            />
          ) : (
            <img
              src={invalid_icon}
              alt="Invalid Icon"
              className="npi_validation_icon"
            />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Modal.Body>
    </Modal>
  );
}

export default NpiValidationModal;