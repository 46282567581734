import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import { ChangeRequest } from "../../UI/Organisms/ChangeRequest";
import { PracticeInformation } from "../../UI/Organisms/PracticeInformation/PracticeInformation";
import { Heading } from "../../UI/Atoms/Heading";
import { LocationReview } from "./LocationReview";
import { ChangePracticeInformation } from "./ChangePracticeInformation";
import ReviewAlert from "../../UI/Atoms/ReviewAlert";
import AddIcon from "./images/updatedIcon.svg";
import ApprovalcloseIcon from "./images/closeIcon.svg";
import RejectAddIcon from "./images/reject-Icon.svg";
import RejectcloseIcon from "./images/close-icon.svg";
import { Button } from "../../UI/Atoms/Button";

export const ReviewPracticeApproval = ({ practiceReviewId, setReviewMode }) => {
  const [practiceReviewList, setPracticeReviewList] = useState([]);
  const [reviewApprove, setReviewApprove] = useState(false);
  const [reviewReject, setReviewReject] = useState(false);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;

  const fetchReviewPracticeList = () => {
    fetch(
      base_url +
      `/api/v1/providers/GetPracticeReviewDetails?practiceId=${practiceReviewId[0]}&requestTypeId=${practiceReviewId[1]}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setPracticeReviewList(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchReviewPracticeList();
    };
    fetch();
  }, []);
  console.log("rpd", practiceReviewList);
  const handleClick = () => {
    setReviewApprove(false);
    setReviewReject(false);
  };
  return (
    <>
      {reviewApprove && practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 23 &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="Changes to practice information successfully approved"
          ALertHandleClick={handleClick}
        />
      }
      {reviewReject && practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 23 &&
        <ReviewAlert
          ApprovalIcon={RejectAddIcon}
          variant="warning"
          ApprovalcloseIcon={RejectcloseIcon}
          AlertText="Changes to practice information rejected"
          ALertHandleClick={handleClick}
        />
      }
      {reviewApprove && practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 22 &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="Removal of new location successfully approved"
          ALertHandleClick={handleClick}
        />
      }
      {reviewReject && practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 22 &&
        <ReviewAlert
          ApprovalIcon={RejectAddIcon}
          variant="warning"
          ApprovalcloseIcon={RejectcloseIcon}
          AlertText="Removal of new location rejected"
          ALertHandleClick={handleClick}
        />
      }
      {reviewApprove && practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 21 &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="Addition of new location successfully approved"
          ALertHandleClick={handleClick}
        />
      }
      {reviewReject && practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 21 &&
        <ReviewAlert
          ApprovalIcon={RejectAddIcon}
          variant="warning"
          ApprovalcloseIcon={RejectcloseIcon}
          AlertText="Addition of new location rejected"
          ALertHandleClick={handleClick}
        />
      }
      <Button
        text="Cancel"
        className="Cancel-button"
        onClick={() => setReviewMode(false)}
        type="submit"
      />
      <ChangeRequest ChangeRequestData={practiceReviewList?.practiceReviewChangeDTO} />
      <PracticeInformation practiceInfoData={practiceReviewList?.practiceDetails} />
      {practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 23 &&
        <ChangePracticeInformation
          changePracticeReviewData={practiceReviewList?.changeFieldDetails}
          RequestTypeData={practiceReviewList?.practiceReviewChangeDTO}
          practiceReviewApproval={() => setReviewApprove(true)}
          practiceReviewReject={() => setReviewReject(true)}
          fetchReviewPracticeList={fetchReviewPracticeList}
          setReviewMode={setReviewMode}
        />
      }
      {practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 22 && practiceReviewList?.reviewLocationModel?.length > 0 &&
        <div>
          <Heading text="Remove Location" />
          <LocationReview
            locationReviewData={practiceReviewList?.reviewLocationModel}
            practiceInfoId={practiceReviewList?.practiceDetails?.practiceId}
            RequestTypeData={practiceReviewList?.practiceReviewChangeDTO}
            practiceReviewApproval={() => setReviewApprove(true)}
            practiceReviewReject={() => setReviewReject(true)}
            fetchReviewPracticeList={fetchReviewPracticeList}
            setReviewMode={setReviewMode}
          />
        </div>
      }
      {practiceReviewList?.practiceReviewChangeDTO?.requestTypeId === 21 && practiceReviewList?.reviewLocationModel?.length > 0 &&
        <div>
          <Heading text="Add New Location" />
          <LocationReview
            locationReviewData={practiceReviewList?.reviewLocationModel}
            practiceInfoId={practiceReviewList?.practiceDetails?.practiceId}
            RequestTypeData={practiceReviewList?.practiceReviewChangeDTO}
            practiceReviewApproval={() => setReviewApprove(true)}
            practiceReviewReject={() => setReviewReject(true)}
            fetchReviewPracticeList={fetchReviewPracticeList}
            setReviewMode={setReviewMode}
          />
        </div>
      }
    </>
  );
};
