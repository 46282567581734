/* eslint-disable indent */
import React from "react";
import { Modal } from "react-bootstrap";

function ConfirmModal(props) {
    return (
        <React.Fragment>
            <Modal
                show={props?.updateModal}
                onHide={props?.updateModalClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="update-provider-modal"
                data-testid="bulkupdateprovidermodal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props?.headerText}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{props?.bodyText}</p>
                </Modal.Body>
                {props?.serverError &&
                    <p className="error_ppd mb-1 text-center">Some thing went wrong</p>
                }
                <Modal.Footer className="justify-content-center">
                    <button
                        onClick={props?.updateModalClose}
                        className="white-btn"
                        data-testid="close-button"
                        disabled={props?.isLoadingWaiting}
                    >
                        Cancel
                    </button>

                    <button
                        className="blue-btn"
                        disabled={props?.isLoadingWaiting}
                        value="submit"
                        type="submit"
                        onClick={props?.handleSubmit}
                        data-testid="createnewproviderbutton"
                    >
                        {props?.isLoadingWaiting ? "Saving..." : "Yes, Confirm"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default ConfirmModal;
