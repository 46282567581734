/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button, Form, Breadcrumb, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";
import addIcon from "./add-icon.svg";
import practiceIcon from "./images/Icon-b-practice.svg";
import providerListIcon from "./images/Icon-b-provider-list.svg";
import userIcon from "./images/Icon-b-user.svg";
import editIcon from "./images/Icon-b-edit.svg";
import dropIcon from "./images/dropdownIcon.svg";
import DatePicker from "react-datepicker";
import sortIcon from "./images/Sort_arrow.svg";
import labels from "Static/Dictionary.json";
import "react-datepicker/dist/react-datepicker.css";
import AddLocationModalPage from "../DataPages/AddLocationModalPage";
import { useFetch } from "Components/Hooks/Fetch";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import SpecialtyPage from "../DataPages/SpecialtyPage";
import cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { formatDateByTimeZone } from "Static/format";
import { EMAIL_REGEX,ALPHABETS_AND_SPECIAL_CHARS_REGEX } from "utils";

function convertLocalToUTCDate(date) {
  return date ? new Date(date) : null;
}

const CreateNewProviderForm = ({
  practiceTitle,
  practiceManagerId,
  selectedPracticeId,
}) => {
  const { id } = useParams();
  const { data = [] } = useFetch("/api/v1/providers/GetAllTitleTypes");
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState("");
  const [providerEdit, setProviderEdit] = useState({
    titleType: "",
  });
  const [sltdSpltyId, setSltdSpltyId] = useState(null);
  const [spltyName, setSpltyName] = useState(null);
  const [newSpecialty, setNewSpecialty] = useState();
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    prefixType: "1",
  });
  const [searchInput, setSearchInput] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [locationListData, setLocationListData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [addLocationList, setAddLocationListData] = useState([]);
  const [primaryPracticeId, setPrimaryPracticeId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const selectedPracticeId1 = localStorage.getItem("selectedPracticeId") || selectedPracticeId;
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const decodeToken = jwt_decode(token);
  const location = useLocation();
  console.log(
    "ld",
    location?.state?.data,
    spltyName,
    newSpecialty,
    sltdSpltyId,
    title,
    providerEdit,
    providerEdit?.titleType[0]?.id,
  );
  const providerLocationGetUpdate = () => {
    fetch(
      base_url +
        `/api/v1/providers/GetLocationByPracticeManagerId?practiceManagerId=${
          decodeToken?.id || practiceManagerId
        }&personid=${id}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.practiceAssignment?.length > 0) {
          const primaryLocations = data?.practiceAssignment?.filter(
            (assignment) => assignment?.practiceId === parseInt(selectedPracticeId1)
          )
            .map((item) => ({
              ...item,
              isProviderTrue: item?.termDate === null ? true : false,
              isProviderFalse: item?.termDate === null ? false : true,
              assignmentTypeId: 1,
              isDefaultId: item?.assignmentTypeId === 1 ? true : false,
              isdefaultPrimary: true,
            }));
          setLocationListData(primaryLocations);
          const primaryAddLocations = data?.practiceAssignment?.filter(
            (assignment) => assignment?.practiceId !== parseInt(selectedPracticeId1)
          )
          setAddLocationListData(primaryAddLocations);
          console.log("primaryLocations", primaryLocations, selectedPracticeId1);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      providerLocationGetUpdate();
    };
    fetchData();
  }, [selectedPracticeId1]);

  const clickHandler = (locations) => {
    const fData = locations?.map((item) => ({
      ...item,
      isProviderTrue: item?.termDate === null ? true : false,
      isProviderFalse: item?.termDate === null ? false : true,
      isDefaultId: item?.assignmentTypeId === 1 ? true : false,
      assignmentTypeId: 2,
    }));
    const value = [...locationListData, ...fData]
    setLocationListData(value);
    if (locations?.length > 0) {
      const updatedData = addLocationList?.filter((assignment) =>
        !locations.some((selectedAssignment) =>
          assignment?.practice && selectedAssignment?.practice
            ? assignment?.practice?.id === selectedAssignment?.practice?.id
            : false
        )
      );
      setAddLocationListData(updatedData);
    }
  };
  const navigation = useNavigate();
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .max(25, "Too Long!")
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Name cannot contain numbers ")
      .required("First Name is required"),
    middleName: yup
      .string()
      .max(25, "Too Long!")
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Name cannot contain numbers"),
    lastName: yup
      .string()
      .max(25, "Too Long!")
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Name cannot contain numbers")
      .required("Last Name is required"),
    mdofficeId: yup.string().max(25, "Too Long!"),
    npi: yup
      .string()
      .required("NPI is required")
      .min(10, "NPI should be 10 digits")
      .max(10, "NPI should be 10 digits")
      .matches(/^[0-9]+$/, "Must be only digits"),
      preferredEmailAddress: yup
      .string()
         .required("Email required.")
         .matches(EMAIL_REGEX, "Invalid email format"),
   
       sentaraEmailAddress: yup.string().matches(EMAIL_REGEX, "Invalid email format"),
  });
  const formik = useFormik({
    initialValues: {
      npi: location.state.data || "",
      mdofficeId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      personStatusId: 1,
      preferredEmailAddress: "",
      sentaraEmailAddress: "",
      practiceAssignment: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const payLoad = {
        updateUser: practiceManagerId,
        prefixId: formData?.prefixType,
        firstName: values?.firstName,
        middleName: values?.middleName,
        lastName: values?.lastName,
        personStatusId: 1,
        npi: values?.npi,
        mdofficeId: values?.mdofficeId,
        email: [
          {
            emailTypeId: 1,
            emailAddress: values?.preferredEmailAddress,
          },
          {
            emailTypeId: 2,
            emailAddress: values?.sentaraEmailAddress,
          },
        ],
        titleType: [
          {
            id: providerEdit?.titleType[0]?.id,
            title: title,
          },
        ],
        specialtySpecialtyType: [
          {
            specialtyId: sltdSpltyId,
            specialtyName: spltyName,
            specialtyTypeId: 2,
          },
        ],
        practiceAssignment:
          locationListData?.map((item) => (
            {
              startDate: item?.startDate ? moment(item?.startDate).format("YYYY-MM-DD") : "",
              termDate: item?.termDate ? moment(item?.termDate).format("YYYY-MM-DD") : undefined,
              practiceId: item.practiceId,
              roleTypeId: 2,
              assignmentTypeId: item?.assignmentTypeId,
            }
          ))
      };
      let status = "";
      fetch(base_url + "/api/v1/providers?primaryLocation=false", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "access-control-allow-credentials": "true",
          "ocp-apim-subscription-key": subscriptionKey,
          "api-supported-versions": "1.0",
        },
        body: JSON.stringify(payLoad),
      })
        .then((response) => {
          status = response?.status;
          return response.json();
        })
        .then((res) => {
          console.log("ress", res, status);
          if (Number(status) === 200) {
            setError("");
            setIsLoading(false);
            navigation("/", {
              state: { isCreate: true },
            });
          } else {
            setError(res?.message || res?.title || res?.error);
            setIsLoading(false);
          }
        });
    },
  });
  const selectedSpecialtyIdfn = (id, name, data = []) => {
    console.log("spltyId", id, name, data);
    const fName = data.filter(
      (item) => item.specialtyName.toLowerCase() === name.toLowerCase()
    );
    console.log("fff", fName);
    if (fName.length > 0) {
      setNewSpecialty(false);
    } else {
      setNewSpecialty(true);
    }
    setSltdSpltyId(id);
    setSpltyName(name);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleCheckYes = (e, itemId) => {
    const updatedData = locationListData?.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          isProviderTrue: e.target.checked,
          isProviderFalse: !e.target.checked,
          termDate: null,
          assignmentTypeId: (item?.isPrimarySelect === true) || (item?.isdefaultPrimary === true) ? 1 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: (item?.isPrimarySelect === true) || (item?.isdefaultPrimary === true) ? 1 : 2,
          }
        };
      }
      return item;
    });
    setLocationListData(updatedData);
  };
 console.log("ld", locationListData)
  const handleCheckNo = (e, itemId) => {
    const updatedData = locationListData?.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          isProviderTrue: !e.target.checked,
          isProviderFalse: e.target.checked,
          assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            id: 2,
          }
        };
      }
      return item;
    });
    setLocationListData(updatedData);
  };
  const handleRadioSelection = (pid) => {

    setPrimaryPracticeId(pid);
    const updatedData = locationListData?.map((item) => {
      if (item?.practiceId === pid) {
        return {
          ...item,
          isProviderTrue: true,
          isProviderFalse: false,
          termDate: null,
          assignmentTypeId: item?.practiceId === pid ? 1 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.practiceId === pid ? 1 : 2
          },
          isPrimarySelect: true,
          isdefaultPrimary: false,
        };
      } else {
        return {
          ...item,
          assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            id: 2
          },
          isPrimarySelect: false,
          isdefaultPrimary: false,
        };
      }
      // } else if (!item?.isDefaultId) {
      //   return {
      //     ...item,
      //     isProviderTrue: item?.practiceId === pid ? false : true,
      //     isProviderFalse: item?.practiceId === pid ? true : false,
      //     termDate: null,
      //     assignmentTypeId: item?.practiceId === pid ? 1 : 2,
      //     assignmentType: {
      //       ...item.assignmentType,
      //       id: item?.practiceId === pid ? 1 : 2
      //     }
      //   };
      // } else if (item.isDefaultId) {
      //   return {
      //     ...item,
      //     isProviderTrue: item?.practiceId === pid ? true : false,
      //     isProviderFalse: item?.practiceId === pid ? false : true,
      //     termDate: null,
      //     assignmentTypeId: item?.practiceId === pid ? 1 : 2,
      //     assignmentType: {
      //       ...item.assignmentType,
      //       id: item?.practiceId === pid ? 1 : 2
      //     }
      //   };
      // }
      // return {
      //   ...item,
      //   assignmentTypeId: item?.practiceId === pid ? 1 : 2,
      //   ["assignmentType.id"]: item?.practiceId === pid ? 1 : 2
      // };
    });
    setLocationListData(updatedData);
    console.log("upd", updatedData, primaryPracticeId);
  };
  const startDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    const updatedData = locationListData?.map((item) => {
      if (item.id === itemId) {
        console.log("item", item.id);
        return { ...item, startDate: isoDateString };
      }
      return item;
    });
    setLocationListData(updatedData);
  };
  const endDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    const updatedData = locationListData?.map((item) => {
      if (item.id === itemId) {
        return { ...item, termDate: isoDateString };
      }
      return item;
    });
    setLocationListData(updatedData);
  };
  const handleClickStorage = () => {
    localStorage.removeItem("tab");
  };
  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };

  const handleSearchAddressChange = (e) => {
    const input = e.target.value;
    setSearchAddress(input);
  };

  const handleSearchStartDateChange = (e) => {
    const input = e.target.value;
    setSearchStartDate(input);
  };

  const handleSearchEndDateChange = (e) => {
    const input = e.target.value;
    setSearchEndDate(input);
  };

  const createSearchPattern = (input) => {
    // Remove spaces from the input and make it case-insensitive
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };

  const filterData = (item) => {
    const searchLocationNamePattern = createSearchPattern(searchInput);
    const searchAddressPattern = createSearchPattern(searchAddress);
    const searchStartDatePattern = createSearchPattern(searchStartDate);
    const searchEndDatePattern = createSearchPattern(searchEndDate);

    const locationMatches = item.practice.location.some((location) => {
      const locationName = location.locationName.toLowerCase().replace(/\s/g, "");
      const address = `${location.street1 || ""} ${location.street2 || ""} ${location.city || ""} ${location.state || ""} ${location.zipCode || ""}`.toLowerCase().replace(/\s/g, "");
      const startDate = item.startDate ? moment(item.startDate).format("MM/DD/YYYY").toLowerCase().replace(/\s/g, "") : "";
      const endDate = item.termDate ? moment(item.termDate).format("MM/DD/YYYY").toLowerCase().replace(/\s/g, "") : "";

      return (
        searchLocationNamePattern.test(locationName) &&
        searchAddressPattern.test(address) &&
        searchStartDatePattern.test(startDate) &&
        searchEndDatePattern.test(endDate)
      );
    });

    return locationMatches;
  };

  useEffect(() => {
    const newFilteredData = locationListData?.filter(filterData);
    setFilteredAllData(newFilteredData);
    console.log("newFilteredData")
  }, [searchInput, searchAddress, searchStartDate, searchEndDate, locationListData]);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortDataByStartDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.startDate).diff(moment(b.startDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };

  const sortDataByEndDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.termDate).diff(moment(b.termDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };
  return (
    <div>
      <Container className="mt-40">
        <Breadcrumb>
          <Breadcrumb.Item data-testid="practiceName">
            <Link
              to={"/"}
              data-testid="practicetitlelink"
              onClick={handleClickStorage}
            >
              <img src={practiceIcon} alt="Practice Icon" /> {locationListData[0]?.practice?.practiceNameLegal || practiceTitle}{" "}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" data-testid="providerlistpage">
            <Link to={"/"} data-testid="providerlist">
              <img src={providerListIcon} alt="Practice Icon" /> Provider List
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item data-testid="providerNameDisplay">
            <img src={userIcon} alt="Practice Icon" />
            {formik?.values?.firstName} {formik?.values?.lastName}
          </Breadcrumb.Item>
          <Breadcrumb.Item active data-testid="createNewProvider">
            <img src={editIcon} alt="Edit Icon" /> Create
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="edit-provider">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <Row>
              <Col md={2} xs={12}>
                <div className="provider-profile">
                  <div
                    className="firstletter justify-content-center  d-flex align-items-center"
                    data-testid="providerNamefirstletters"
                  >
                    {formik?.values?.firstName?.charAt(0)?.toUpperCase()}
                    {formik?.values?.lastName?.charAt(0)?.toUpperCase()}
                  </div>
                </div>
              </Col>
              <Col md={10} xs={12}>
                <div className="main-block">
                  <div className="block">
                    <div className="title mt-2">{labels.PREFIX}</div>
                    <div className="title-dropdown">
                      <div className="title-change" data-testid="locationType">
                        {formData.prefixType == 1 && <div>--</div>}
                        {formData.prefixType == 2 && <div>Mr</div>}
                        {formData.prefixType == 3 && <div>Ms</div>}
                        {formData.prefixType == 4 && <div>Dr</div>}
                        <span className="locationtypeicon">
                          <img src={dropIcon} alt="dropdown icon" />
                        </span>
                      </div>
                      <div className="title-list-block">
                        <ul className="title-list">
                          <li className="titles" data-testid="Type">
                            <button
                              type="button"
                              name="prefixType"
                              className={
                                formData.prefixType == 1
                                  ? "selected-primary"
                                  : ""
                              }
                              onClick={handleChange}
                              value="1"
                              data-testid="TypeMr"
                            >
                              --
                            </button>
                          </li>
                          <li className="titles" data-testid="Type">
                            <button
                              type="button"
                              name="prefixType"
                              className={
                                formData.prefixType == 2
                                  ? "selected-primary"
                                  : ""
                              }
                              onClick={handleChange}
                              value="2"
                              data-testid="TypeMr"
                            >
                              Mr
                            </button>
                          </li>
                          <li className="titles" data-testid="Type">
                            <button
                              type="button"
                              name="prefixType"
                              className={
                                formData.prefixType == 3
                                  ? "selected-primary"
                                  : ""
                              }
                              onClick={handleChange}
                              value="3"
                              data-testid="TypeMs"
                            >
                              Ms
                            </button>
                          </li>
                          <li className="titles" data-testid="Type">
                            <button
                              type="button"
                              name="prefixType"
                              className={
                                formData.prefixType == 4
                                  ? "selected-primary"
                                  : ""
                              }
                              onClick={handleChange}
                              value="4"
                              data-testid="TypeDr"
                            >
                              Dr
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <Form.Label>{labels.FIRST_NAME}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      className={
                        formik.touched.firstName && formik.errors.firstName
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                      name="firstName"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerFirstName"
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div
                        className="form-error"
                        data-testid="providerFirstNameError"
                      >
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.MIDDLE_INTITIAL}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      name="middleName"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.middleName}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      className="nb-input"
                      data-testid="providerMiddleName"
                    />
                    {formik.touched.middleName && formik.errors.middleName ? (
                      <div
                        className="form-error"
                        data-testid="providerMiddleNameError"
                      >
                        {formik.errors.middleName}
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.LAST_NAME}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      autoComplete="off"
                      name="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerLastName"
                      className={
                        formik.touched.lastName && formik.errors.lastName
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div
                        className="form-error"
                        data-testid="providerLastNameError"
                      >
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <Form.Label>{labels.TITLE}</Form.Label>
                    <div className="title-dropdown mt-1">
                      <div className="title-change" data-testid="titleName">
                        {title.length == 0 ? "Select" : title}
                        <span>
                          <img src={dropIcon} alt="dropdown icon" />
                        </span>
                      </div>
                      <div className="title-list-block">
                        <ul className="title-list">
                          {data.map((title) => (
                            <li
                              className="titles"
                              key={title.id}
                              value={title.title}
                            >
                              <button
                                type="button"
                                data-testid={`title-button-${title.id}`}
                                onClick={() => {
                                  setProviderEdit((prev) => {
                                    return {
                                      ...prev,
                                      titleType: [{ ...title }],
                                    };
                                  });
                                  setTitle(title.title);
                                }}
                              >
                                {title.title}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {!title && (
                      <p className="form-error">Title is required</p>
                    )}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.BOARD_SPECIALITY}</Form.Label>
                    <SpecialtyPage
                      selectedSpecialtyIdfn={selectedSpecialtyIdfn}
                    />
                    {!spltyName && (
                      <p className="form-error">Board Specialty is required</p>
                    )}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.NPI}</Form.Label>
                    <Form.Control
                      type="tel"
                      maxLength={10}
                      placeholder="---"
                      name="npi"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.npi}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerNpi"
                      className={
                        formik.touched.npi && formik.errors.npi
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.npi && formik.errors.npi ? (
                      <div
                        className="form-error"
                        data-testid="providerNpiError"
                      >
                        {formik.errors.npi}
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.MDOFFICE_ID}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      name="mdofficeId"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mdofficeId}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerMDofficeId"
                      className={
                        formik.touched.mdofficeId && formik.errors.mdofficeId
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.mdofficeId && formik.errors.mdofficeId ? (
                      <div
                        className="form-error"
                        data-testid="providerMDofficeIdError"
                      >
                        {formik.errors.mdofficeId}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <Form.Label>{labels.PREFERED_EMAIL_ADDRESS}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      name="preferredEmailAddress"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.preferredEmailAddress}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerPreferredEmailAddress"
                      className={
                        formik.touched.preferredEmailAddress &&
                          formik.errors.preferredEmailAddress
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.preferredEmailAddress &&
                      formik.errors.preferredEmailAddress ? (
                      <div
                        className="form-error"
                        data-testid="providerPreferredEmailAddressError"
                      >
                        {formik.errors.preferredEmailAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.SENTARA_EMAIL_ADDRESS}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      name="sentaraEmailAddress"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sentaraEmailAddress}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providersentaraEmailAddress"
                      className="nb-input"
                    />
                    {formik.touched.sentaraEmailAddress &&
                      formik.errors.sentaraEmailAddress ? (
                      <div
                        className="form-error"
                        data-testid="providersentaraEmailAddressError"
                      >
                        {formik.errors.sentaraEmailAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className="block"></div>
                  <div className="block"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="location-banner" data-testid="locationBanner">
                  <Row>
                    <Col
                      md={8}
                      xs={12}
                      className="practice-loc-title"
                      data-testid="practicelocationTitle"
                    >
                      <h4>Provider Locations</h4>
                    </Col>
                    <Col md={4} xs={12} className="ta-right">
                      {addLocationList?.length > 0 &&
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="add-location"
                          data-testid="AddLocationbutton"
                        >
                          <span>
                            <img src={addIcon} alt="add icon" />
                          </span>
                          Add Location
                        </Button>
                      }
                      {modalShow && (
                        <AddLocationModalPage
                          data-testid="LocationModel"
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          clickHandler={clickHandler}
                          practiceManagerId={practiceManagerId}
                          addLocationList={addLocationList}
                          id="0"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
                <div>
                  <div className="provider-location-table">
                    <Table data-testid="providerLocationtable">
                      <thead>
                        <tr>
                          <th>Location Number - Name</th>
                          <th>Address</th>
                          <th className="phone-head5">Provider at Location</th>
                          <th className="phone-head4">Is Primary?</th>
                          <th className="phone-head6"><span className="date_text">Start Date</span>
                            <button className="sort_icon_arrow" type="button" onClick={sortDataByStartDate}>
                              <img src={sortIcon} alt="Sort Icon" />
                            </button>
                          </th>
                          <th className="phone-head6"><span className="date_text">End Date </span>
                            <button className="sort_icon_arrow" type="button" onClick={sortDataByEndDate}>
                              <img src={sortIcon} alt="Sort Icon" />
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody data-testid="ProviderData">
                        <tr>
                          <td className="search_column">
                            <Form.Group className="search-npi" controlId="formGroupEmail">
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchInput}
                                onChange={handleSearchChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column">
                            <Form.Group className="search-npi" controlId="formGroupEmail">
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchAddress}
                                onChange={handleSearchAddressChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column"></td>
                          <td className="search_column"></td>
                          <td className="search_column">
                            <Form.Group className="search-npi" controlId="formGroupEmail">
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchStartDate}
                                onChange={handleSearchStartDateChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column1">
                            <Form.Group className="search-npi" controlId="formGroupEmail">
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchEndDate}
                                onChange={handleSearchEndDateChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        {filteredAllData?.map((item) => {
                          const formattedStartDate = item?.startDate ? new Date(item?.startDate) : null;
                          const formattedTermDate = item?.termDate ? new Date(item?.termDate) : null;
                          return (
                            <React.Fragment key={item?.id}>
                              {item.practice.location.map((locationItem) => (
                                <tr key={locationItem.id}>
                                  <td className={locationItem.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                    {locationItem.locationNumber}&nbsp;-&nbsp;{locationItem.locationName}
                                  </td>
                                  <td className={locationItem.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                    {`${locationItem.street1} ${locationItem.street2 ? locationItem.street2 : ""}, ${locationItem.city}, ${locationItem.state} ${locationItem.zipCode}`}
                                  </td>
                                  <td className={locationItem.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                                    <div className="info">
                                      <Form.Check
                                        inline
                                        type="radio"
                                        disabled={locationItem?.locationNumber !== 1}
                                        label="Yes"
                                        data-testid="providerAtLocationYes"
                                        value={item.isProviderTrue}
                                        checked={item.isProviderTrue}
                                        onChange={(e) => handleCheckYes(e, item?.id)}
                                      />
                                      <Form.Check
                                        inline
                                        type="radio"
                                        disabled={locationItem?.locationNumber !== 1}
                                        label="No"
                                        data-testid="providerAtLocationNo"
                                        value={item.isProviderFalse}
                                        checked={item.isProviderFalse}
                                        onChange={(e) => handleCheckNo(e, item?.id)}
                                      />
                                    </div>
                                  </td>
                                  {locationItem?.locationNumber === 1 ? (
                                    <td className={locationItem.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                                      <div className="info">
                                        <Form.Check
                                          inline
                                          type="radio"
                                          data-testid="providerPrimary"
                                          value="yes"
                                          checked={item?.assignmentTypeId === 1}
                                          onChange={() => handleRadioSelection(item?.practiceId)}
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td className={locationItem.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}></td>
                                  )}
                                  {locationItem.locationNumber === 1 ? (
                                    <td className={locationItem.locationNumber === 1 ? "Primary1" : "Secondary1 text-center"}>
                                      <div className="info" data-testid="editstartDateInput">
                                        <DatePicker
                                          dateFormat="MM/dd/yyyy"
                                          selected={formattedStartDate}
                                          onChange={(date) => startDateFn(date, item.id)}
                                          placeholderText="MM/DD/YYYY"
                                          data-testid="editproviderStartDate"
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td className={locationItem.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                      {item.startDate ? moment(item.startDate).format("MM/DD/YYYY") : "--"}
                                    </td>
                                  )}
                                  {locationItem.locationNumber === 1 ? (
                                    <td className={locationItem.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                      <div className="info" data-testid="editendDateInput">
                                        <DatePicker
                                          disabled={item.isProviderTrue}
                                          dateFormat="MM/dd/yyyy"
                                          data-testid="editproviderEndDate"
                                          selected={formattedTermDate}
                                          placeholderText="MM/DD/YYYY"
                                          onChange={(date) => endDateFn(date, item.id)}
                                          required
                                          minDate={formattedStartDate}
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td className={locationItem.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                      {item.termDate ? moment(item.termDate).format("MM/DD/YYYY") : "--"}
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="add-location-footer">
                  {error && (
                    <p className="Error-message" data-testid="Errormessage">
                      {error}
                    </p>
                  )}
                  <p data-testid="providerStartDateconfirmText">
                    Please confirm that all information is correct
                  </p>
                  <div className="add-location-buttons">
                    <Link to={"/"}>
                      <button className="white-btn" data-testid="CancelButton">
                        Cancel
                      </button>
                    </Link>
                    <button
                      type="submit"
                      className="blue-btn"
                      disabled={!title || !spltyName || isLoading}
                      data-testid="ConfirmButton"
                    >
                      {isLoading ? "Saving..." : "Confirm and Save"}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default CreateNewProviderForm;
