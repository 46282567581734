import React from "react";
import { useFetch } from "Components/Hooks/Fetch";
import Spinner from "Components/Hooks/Spinner";
import PracticeBanner from "Components/UI/Organisms/PracticeBanner/PracticeBanner";

const PracticeBannerPage = ({
  practiceManagerId,
  selectHandler,
  titleHandler,
  isAdmin
}) => {
  const { data = [], loading } = useFetch(
    `/api/v1/practices/PracticeSummary?practiceManagerId=${practiceManagerId}&isSQCNAdmin=${isAdmin}`
  );
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <PracticeBanner
        practiceListData={data}
        selectHandler={selectHandler}
        titleHandler={titleHandler}
      />
    </>
  );
};

export default PracticeBannerPage;
