import React from "react";
import("./_style.scss");

export const Button = (props) => {
  return (
    <>
      <button className={props.className} disabled={props.disabled} onClick={props.onClick} type={props.type}>
        {props.text}
      </button>
    </>
  );
};
