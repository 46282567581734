import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "Components/Hooks/Fetch";
import Spinner from "Components/Hooks/Spinner";
import ProviderSummary from "../ProviderList/ProviderSummary";
import jwt_decode from "jwt-decode";
import cookies from "js-cookie";

const ProviderSummaryPage = ({
  practiceTitle,
  selectedPracticeId,
  practiceManagerId,
  PersonRole
}) => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigation = useNavigate();
  const token = cookies.get("access");
  const decodeToken = jwt_decode(token);
  const {
    data = [],
    loading,
    error = false,
  } = useFetch(
    `/api/v1/providers/${id}?practicemanagerid=${decodeToken?.id || practiceManagerId}`
  );
  if (loading) {
    return <Spinner />;
  }
  const handleClose = () => {
    navigation(
      `/provider-list/${id}`,
      { state: { isEdit: false } },
      { state: { isEdit: false } },
      { state: { isNEdit: false } },
      { state: { isOtherProviderCreate: false } },
    );
  };
  return (
    <>
      <ProviderSummary
        providerSummary={data}
        isproviderServerError={error}
        practiceTitle={practiceTitle}
        id={id}
        selectedPracticeId={selectedPracticeId}
        state={state}
        handleClose={handleClose}
        PersonRole={PersonRole}
      />
    </>
  );
};

export default ProviderSummaryPage;
