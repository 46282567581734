import { useFetch } from "Components/Hooks/Fetch";
import Spinner from "Components/Hooks/Spinner";
import EditProviderAdmin from "../ProviderList/EditProviderAdmin";
import jwt_decode from "jwt-decode";
import cookies from "js-cookie";
const EditProviderPageAdmin = ({
  practiceTitle,
  selectedPracticeId,
  practiceManagerId,
  personRoleName,
  setRefreshData,
  setViewProviderId,
  viewProviderId,
  setSuccessMessage,
  refreshData,
  updatedProviderList,
  setUpdatedProviderList,
  addFlow,
  localSelectedProvider,
  providerListGetUpdate,
  titleData,
  prefixData
}) => {
  //const { id } = useParams();
  const token = cookies.get("access");
  const decodeToken = jwt_decode(token);
  const providerIdByAdmin = localStorage.getItem("providerIdByAdmin")

  const { data, loading } = useFetch(
    `/api/v1/providers/${providerIdByAdmin}?practicemanagerid=${decodeToken?.id || practiceManagerId}&&isSqcnAdmin=true`
  );
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <EditProviderAdmin
        practiceManagerId={decodeToken?.id || practiceManagerId}
        providerSummary={localSelectedProvider || data}
        id={providerIdByAdmin}
        selectedPracticeId={selectedPracticeId}
        setViewProviderId={setViewProviderId}
        viewProviderId={viewProviderId}
        practiceTitle={practiceTitle}
        personRoleName={personRoleName}
        setRefreshData={setRefreshData}
        setSuccessMessage={setSuccessMessage}
        refreshData={refreshData}
        updatedProviderList={updatedProviderList}
        setUpdatedProviderList={setUpdatedProviderList}
        addFlow={addFlow}
        providerListGetUpdate={providerListGetUpdate}
        titleData={titleData}
        prefixData={prefixData}
      />
    </>
  );
};

export default EditProviderPageAdmin;
