import React from "react";
import IconPrevious from "./images/Icon-previous.svg";
import IconNext from "./images/Icon-next.svg";

const Pagination = ({
  nPages,
  currentPage,
  setCurrentPage
}) => {
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <div className="pagination-wrapper">
      <ul className="provider-pagination">
        <li className="page-item">
          <button onClick={prevPage} data-testid="prevpage">
            <img src={IconPrevious} alt="Previous Icon" />
          </button>
        </li>
        <li data-testid="currentpage">{currentPage + " / " + nPages} pages</li>
        <li className="page-item">
          <button onClick={nextPage} data-testid="nextpage">
            <img src={IconNext} alt="Next Icon" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
