import React from "react";

const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
    
  const handleClick = () => {
    setActiveTab(id);
  };
    
  return (
    <li>
      <div role="button" 
        onClick={ handleClick } 
        onKeyDown={ handleClick } 
        tabIndex={ id } 
        id={ id } 
        className={ activeTab === id ? "active" : "" }>
        { title }
      </div>
    </li>
  );
};
export default TabNavItem;
