import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import { Table } from "react-bootstrap";
import { Button } from "../../UI/Atoms/Button";
import { Notification } from "../../UI/Atoms/Notification";
import PendingIcon from "../PracticeApprovalBoard/images/pending_icon.svg";
import { ReviewProviderApproval } from "./ReviewProviderApproval";
import Pagination from "../PracticeApprovalBoard/Pagination";
import("./_style.scss");

function ProviderApprovalboard() {
  const [changeProviderMode, setChangeProviderMode] = useState(false);
  const [providerApprovalList, setProviderApprovalList] = useState([]);
  const [providerReviewId, setProviderReviewId] = useState();
  const [approvedStatusChange, setApprovedStatusChange] = useState(false);
  const [rejectStatusChange, setRejectedStatusChange] = useState(false);
  const [approvedLocStatusChange, setApprovedLocStatusChange] = useState(false);
  const [rejectLocStatusChange, setRejectedLocStatusChange] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;

  const fetchProviderRecordsList = () => {
    fetch(
      base_url +
      "/api/v1/providers/GetProviderApprovalBoardList",
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setProviderApprovalList(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchProviderRecordsList();
    };
    fetch();
  }, []);

  const handleProviderChangeClick = (e, id, id1, id2, id3, name, userId) => {
    const reviewId = [id, id1, id2, id3, name, userId];
    console.log("on", reviewId, e);
    setProviderReviewId(reviewId);
    setChangeProviderMode(true);
  };
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = providerApprovalList?.length > 0 && providerApprovalList?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(providerApprovalList?.length / recordsPerPage);
  return (
    <React.Fragment>
      {changeProviderMode ? (
        <div>
          <ReviewProviderApproval
            providerReviewId={providerReviewId}
            statusChange={() => setApprovedStatusChange(true)}
            locStatusChange={() => setApprovedLocStatusChange(true)}
            locRejectStatusChange={() => setRejectedLocStatusChange(true)}
            approvedStatusChange={approvedStatusChange}
            statusChangeReject={() => setRejectedStatusChange(true)}
            rejectStatusChange={rejectStatusChange}
            approvedLocStatusChange={approvedLocStatusChange}
            rejectLocStatusChange={rejectLocStatusChange}
            setChangeProviderMode={setChangeProviderMode}
          />
        </div>
      ) : (
        <div className="manage-location-table1">
          <Notification
            text={`${providerApprovalList?.length || 0} Pending Approvals`}
            iconImage={PendingIcon}
          />
          <Table data-testid="providerlisttable">
            <thead>
              <tr>
                <th>Provider Name</th>
                <th>Practice Name</th>
                <th>Practice Manager</th>
                <th>Request Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody data-testid="ProviderData">
              {currentRecords?.length > 0 && currentRecords?.map((item) => (
                <tr key={item?.changerecordsId}>
                  <td>{item?.providerFirstName}&nbsp;{item?.providerMiddleName}&nbsp;{item?.providerLastName}</td>
                  <td>{item?.practiceName}</td>
                  <td>{item?.practiceManagerFistName}&nbsp;{item?.practiceManagerMiddleName}&nbsp;{item?.practiceManagerLastName} </td>
                  <td>{item?.requestType}</td>
                  <td>
                    <Button
                      text="Review"
                      className="Review-button"
                      onClick={(e) => {
                        handleProviderChangeClick(
                          e,
                          item?.requestTypeId,
                          item?.practiceId,
                          item?.changeFieldId || item?.changerecordsId,
                          item?.personId,
                          item?.existingProvider,
                          item?.updateUser ?? ""
                        );
                        setApprovedStatusChange(false);
                        setRejectedStatusChange(false);
                        setApprovedLocStatusChange(false);
                        setRejectedLocStatusChange(false);
                      }}
                    />
                  </td>
                </tr>
              ))}
              {!(currentRecords.length > 0) && (
                <div className="error-message" data-testid="noProviderData">
                  <p>
                    <img src={PendingIcon} alt="error-icon" className="error-icon"></img>
                    Currently there are no pending approvals.
                  </p>
                </div>
              )}
            </tbody>
          </Table>
          {currentRecords?.length > 0 && (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              data-testid="PaginationPage"
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default ProviderApprovalboard;
