/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import labels from "../../../../Static/Dictionary.json";
// import ErrorServer from "Components/Hooks/Error404";
import { Formik } from "formik";
import * as Yup from "yup";

import DatePickerIcon from "../images/datePickerIcon.svg";
import { Form } from "react-bootstrap";
import { useFetch, useFetchPost } from "Components/Hooks/Fetch";
import DatePicker from "react-datepicker";
import { Container, Alert, Row, Col } from "react-bootstrap";
import icon from "../images/Icon.svg";
import cookies from "js-cookie";
import CreatableSelect from "react-select/creatable";
import { formatDate } from "Static/format";
import { ALPHABETS_AND_SPECIAL_CHARS_REGEX } from "utils";
import { formatDateByTimeZone } from "Static/format";


import EditCareManager from "./EditCareManager";
export const EditPractice = ({
  selectedPracticeIdByAdmin,
  practiceInformationData,
  initialValues,
  setEditMode,
  setSuccess,
  careManagers,
  setPracticeInformationData,
  updateUser
}) => {
  const [practiceMasterData, setPracticeMasterData] = useState(null);  
  const [teleHealthOptions, setTeleHealthOptions] = useState([]);
  const [emrOptions, setEmrOptions] = useState([]);
  const [inputList, setInputList] = useState([]);
  // const [filterTin, setFilterTin] = useState(null);

  const [tinOptions, setTinOptions] = useState([]);

  const [marketsData, setMarketsData] = useState([]);

  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tinInput, setTinInput] = useState("000000000");
  const [lowerAgeLimitDisable, setLowerAgeLimitDisable] = useState(false);
  const [lowerAgeLimitInvalid, setLowerAgeLimitInvalid] = useState(false);

  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");


  const convertBoolean=(v)=>{ return v==="false" || v==="null" || v==="NaN" || v==="undefined" || v==="0" ? false : !!v;  }


  const errorStyle = {
    color: "#000",
  };
  const { data: masterData } = useFetch(
    "/api/v1/practices/GetPracticeMasterData"
  );

  const { data: teleHealth } = useFetch("/api/v1/contact/GetTeleHealthData");

  const { data: markets } = useFetch("/api/v1/contact/GetRSOData");

  const { data: GetAllSuperGroups } = useFetch(
    "/api/v1/practices/GetAllSuperGroups"
  );

  const { data: GetsAllPracticeGroups } = useFetch(
    "/api/v1/practices/GetsAllPracticeGroups"
  );



  const {
    data: editData,
    error,
    callPost,
  } = useFetchPost("/api/v1/practices/EditPracticeSQCN");
  useEffect(() => {
    if (masterData) {
      setPracticeMasterData(masterData);
        let localOptions = [];
        masterData?.emRs.map((item) => {
          localOptions.push({ value: item.emr, label: item.emr });
        });
        setEmrOptions(localOptions);
  
    }
  }, [masterData]);

  useEffect(() => {
    if (markets) {
      setMarketsData(markets);
    }
  }, [markets]);

  useEffect(() => {
    if (teleHealth) {
     // setTeleHealthData(teleHealth);

      let localOptions = [];
      teleHealth.map((item) => {
        localOptions.push({ value: item, label: item });
      });

      setTeleHealthOptions(localOptions);
    }
  }, [teleHealth]);

  useEffect(() => {
    if (GetsAllPracticeGroups) {
      let tins = [];
      GetsAllPracticeGroups.map((item) => {
        // tins.push({ value: item.tin, label: item.tin });
        tins.push({
          value: item.tin,
          label: item.tin,
          practiceGroupId: item.practiceGroupId,
          practiceGroupName: item.practiceGroupName,
          tin: item.tin,
          innovaccerTinname: item.innovaccerTinname,
          supergroupId: item.supergroupId,
        });
      });

      setTinOptions(tins);
    }
  }, [GetsAllPracticeGroups]);

  useEffect(() => {
    if (error) {
      setShowError(true);
      setIsLoading(false);
      return false;
    } else if (editData) {
     GetPracticeInfo();
    }
  }, [editData]);

  const [practiceSpclData, setPracticeSpclData] = useState(null);
  const [practiceSubSpcl1Data, setPracticeSubSpcl1Data] = useState(null);
  const [practiceSubSpcl2Data, setPracticeSubSpcl2Data] = useState(null);
  const [practiceSubSpcl3Data, setPracticeSubSpcl3Data] = useState(null);

  const { data: GetPracticeSpclData } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=3"
  );

  const { data: GetPracticeSubSpcl1Data } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=3"
  );

  const { data: GetPracticeSubSpcl2Data } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=3"
  );

  const { data: GetPracticeSubSpcl3Data } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=3"
  );

  useEffect(() => {
    if (GetPracticeSpclData) {
      const data = GetPracticeSpclData?.sort((a, b) => a.name.localeCompare(b.name));
      setPracticeSpclData(data);
    }
  }, [GetPracticeSpclData]);
  useEffect(() => {
    if (GetPracticeSubSpcl1Data) {
      const data = GetPracticeSubSpcl1Data?.sort((a, b) => a.name.localeCompare(b.name));
      setPracticeSubSpcl1Data(data);
    }
  }, [GetPracticeSubSpcl1Data]);
  useEffect(() => {
    if (GetPracticeSubSpcl2Data) {
      const data = GetPracticeSubSpcl2Data?.sort((a, b) => a.name.localeCompare(b.name));
      setPracticeSubSpcl2Data(data);
    }
  }, [GetPracticeSubSpcl2Data]);
  useEffect(() => {
    if (GetPracticeSubSpcl3Data) {
      const data = GetPracticeSubSpcl3Data?.sort((a, b) => a.name.localeCompare(b.name));
      setPracticeSubSpcl3Data(data);
    }
  }, [GetPracticeSubSpcl3Data]);
  const lowerAgeLimitData = [
    {id:1, name: "Newborn"},
    {id:2, name: "No Age Limit"},
    {id:3, name: "Days"},
    {id:4, name: "Weeks"},
    {id:5, name: "Months"},
    {id:6, name: "Years"},
    {id:7, name: "N/A"},
  ];

  const GetPracticeInfo = () => {
    fetch(base_url + `/api/v1/practices/${selectedPracticeIdByAdmin}`, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
setPracticeInformationData(data);

setIsLoading(false);
setSuccess(true);
setEditMode(false);
      });
  };
  const getSpecialtyByText = (specialtyName, id) => {
    if (
      practiceSpclData &&
      practiceSpclData.length > 0
    ) {
        const specialty = practiceSpclData?.filter((item) => item.name === specialtyName);
      if (specialty.length > 0) {
        return {
          ...{ specialtyTypeId: id, specialtyId: specialty[0]?.specialityId },
        };
      }
      return {};
    }
    return {};
  };
  const getSpecialty1ByText = (specialtyName, id) => {
    if (
      practiceSubSpcl1Data &&
      practiceSubSpcl1Data.length > 0
    ) {
      const specialty = practiceSubSpcl1Data.filter(
        (item) => item.name === specialtyName
      );
      if (specialty) {
        return {
          specialtyId: specialty[0]?.specialityId,
          specialtyTypeId: id,
        };
      }
    }
    return {};
  };
  const getSpecialty2ByText = (specialtyName, id) => {
    if (
      practiceSubSpcl2Data &&
      practiceSubSpcl2Data.length > 0
    ) {
      const specialty = practiceSubSpcl2Data.filter(
        (item) => item.name === specialtyName
      );
      if (specialty) {
        return {
          specialtyId: specialty[0]?.specialityId,
          specialtyTypeId: id,
        };
      }
    }
    return {};
  };
  const getSpecialty3ByText = (specialtyName, id) => {
    if (
      practiceSubSpcl3Data &&
      practiceSubSpcl3Data.length > 0
    ) {
      const specialty = practiceSubSpcl3Data.filter(
        (item) => item.name === specialtyName
      );
      if (specialty) {
        return {
          specialtyId: specialty[0]?.specialityId,
          specialtyTypeId: id,
        };
      }
    }
    return {};
  };
  const getSuffixByName = (id) => {
    if(allSuffixData){
      const suffixId = allSuffixData?.filter((suffix) => suffix.id === id);
      if(suffixId){
        return suffixId[0]?.suffixName || null;
      }
    }
  }
  const getTitleById = (id) => {
    if(titleData){
      const titleId = titleData?.filter((title) => title.id === id);
      if(titleId){
        return titleId[0]?.title || null;
      }
    }
  }
  const getPrefixById = (id) => {
    if (prefixData.length > 0) {
      const prefix = prefixData.filter((item) => item.id === id);
      if (prefix.length > 0) {
        return prefix[0].prefixName;
      }
    }
    return "";
  };
  const getPrefixByName = (prefixName) => {
    if (prefixData?.length > 0) {
      const prefix = prefixData?.filter(
        (item) => item.prefixName === prefixName
      );
      if (prefix.length > 0) {
        return prefix[0].id;
      }
    }
    return "";
  };
  const [contractYesFields, setContractYesFields] = useState(false);
  const [titleData, setTitleData] = useState([]);
  const [prefixData, setPrefixData] = useState([]);
  const [allSuffixData, setAllSuffixData] = useState([]);
  const [contractDesc, setContractDesc] = useState([]);
  const GetContractDetails = () => {
    fetch(base_url + "/api/v1/practices/GetContractTypeDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setContractDesc(data);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      GetContractDetails();
    };
    fetch();
  }, []);

  const GetTitleDetails = () => {
    fetch(base_url + "/api/v1/contact/GetTitleDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTitleData(data?.titleTypeList || []);
        setPrefixData(data?.prefixList || []);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      GetTitleDetails();
    };
    fetch();
  }, []);
  
  const { data: GetSuffixData } = useFetch("/api/v1/contact/GetSuffixData");
  useEffect(() => {
    if (GetSuffixData) {
      setAllSuffixData(GetSuffixData);
    }
  }, [GetSuffixData]);
  const prefix_mapTitles = [
    "16",
    "41",
    "24",
    "61",
    "31",
    "62",
    "63",
    "5",
    "11",
    "36",
    "1",
    "66",
    "67",
    "32",
    "68",
    "22",
    "84",
    "23",
    "45",
  ];

  const practiceInformationSchema = Yup.object().shape({
    practiceNameLegal: Yup.string().required("Name cannot be blank"),
    //   .max(10, "Practice NPI should be 10 digits")
    //   .matches(/^[0-9]+$/, "Must be only digits"),
    //  emr: Yup.string().required("EMR cannot be blank"),
    // lowerAgeLimit: Yup.string().required(
    //   "Lower Age Limit for New Patients cannot be blank"
    // ),


    reportingGroupId: Yup.string().required("Reporting Group ID cannot be blank"),
    supergroup: Yup.string().required("Super Group cannot be blank"),
    // tinLocal: Yup.string().min(9, "Maximum length is 9 characters").max(9, "Maximum length is 9 characters").required("Tax Identification Number cannot be blank"),
   //primaryNpi: Yup.string().required("NPI cannot be blank"),
 //   .matches(/^[0-9]+$/, "Must be only digits"),
 emrLocal: Yup.string().required("EMR cannot be blank"),
 specialty: Yup.string().required("Specialty cannot be blank"),
   telehealth: Yup.string().required("TeleHealth cannot be blank"),
   location1: Yup.string().required("Location cannot be blank"),
   rsoName: Yup.string().required("Market Region cannot be blank"),
   InnovaccerDesignatedName: Yup.string().required("Innovaccer name cannot be blank"),
   contractFirstName: Yup.string()
   .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid First Name"),
   contractMiddleName: Yup.string()
   .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Middle Name"),
   contractLastName: Yup.string()
   .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Last Name"),
   lowerAgeNumber: Yup.string().required("Age Limit cannot be blank"),
  });


  const getEmrId = (emr) => {

      let emrId = practiceMasterData?.emRs.filter((item) => item.emr === emr);
        
if(emrId?.length > 0 ){
return emrId[0].id;
  }else{
    return 0;
  }
  }
  const onSubmitFun = (values) => {
    let ageLimit = (lowerAgeNumber === "" && (lowerAgeName === "Newborn" || lowerAgeName === "No Age Limit" || lowerAgeName === "N/A"));
    let ageLimitNos = (lowerAgeNumber !== "" && (lowerAgeName === "Days" || lowerAgeName === "Weeks" || lowerAgeName === "Months" || lowerAgeName === "Years"));
    if(values.practiceNameLegal !== "" && values.reportingGroupId &&
      values.supergroup !== "" && values.emrLocal !== "" &&
      values.specialty !== "" && values.telehealth !== "" &&
      values.location1 !== "" && values.rsoName !== "" &&
      values.InnovaccerDesignatedName !== "" && values.tinLocal?.length === 9 &&
      (ageLimit || ageLimitNos || (lowerAgeNumber === "" && lowerAgeName == ""))
    ) {
    setShowError(false);

    setIsLoading(true);
    let specialties = [
      getSpecialtyByText(values.specialty, 3),
      getSpecialty1ByText(values.specialty1, 4),
      getSpecialty2ByText(values.specialty2, 5),
      getSpecialty3ByText(values.specialty3, 6),
    ];
    specialties =
      specialties.length > 0
        ? specialties.filter((value) => Object.keys(value).length !== 0)
        : [];

    let careList = [];
    if (inputList.length > 0) {
      inputList.map((data) => {
        let list = {};
        list["mdofficeId"] = data?.mdofficeId || "";
        list["firstName"] = data?.firstName || "";
        list["lastName"] = data?.lastName || "";
        list["middleName"] = data?.middleName || "";
        list["suffixId"] = parseInt(data?.suffixId) || "";
        list["titleId"] = parseInt(data?.titleId) || "";
        list["prefixId"] = parseInt(data?.prefixId) || 0;
        list["personId"] = data?.personId;
        careList.push(list);
      });
    }

    let payload = {
      ...practiceInformationData,
      ...values,
      ...{ specialties: specialties },

      ...{
        isSqcn: convertBoolean(values?.isSqcn),
        isPrimaryCare: convertBoolean(values?.isPrimaryCare),
        isAco: convertBoolean(values?.isAco),
        isAffiliated: convertBoolean(values?.isAffiliated),
        takingNewPatients: convertBoolean(values?.takingNewPatients),
        takingMedicaidPatients:
        convertBoolean(values?.takingMedicaidPatients),

        hasEpicCareLinkAccess:
        convertBoolean(values?.hasEpicCareLinkAccess),
        uses837: convertBoolean(values?.uses837),
        adt: convertBoolean(values?.adt),
        ccda: convertBoolean(values?.ccda),
        oru: convertBoolean(values?.oru),
        aetnaBROnly: convertBoolean(values?.aetnaBROnly),
        johnHopkinsUSFamilyHealthPlan:
        convertBoolean(values?.johnHopkinsUSFamilyHealthPlan),
        optimaCommercial: convertBoolean(values?.optimaCommercial),
        optimaMedicaid: convertBoolean(values?.optimaMedicaid),
        humanaMA: convertBoolean(values?.humanaMA),
        optimaMedicareAdvantage:
        convertBoolean(values?.optimaMedicareAdvantage),
        id: parseInt(selectedPracticeIdByAdmin),
        // superGroup:parseInt(values.supergroupId),
        emRid:getEmrId(values.emrLocal),
        emr: values.emrLocal,
        // superGroupId: parseInt(values.supergroupId),
        supergroupId: parseInt(values.supergroupId),
        // supergroupName: getSuperGroupCode(parseInt(values.supergroupId)),
        supergroupName: values.supergroup,
        careManagers: careList,
        rsoId: parseInt(values.rsoId),
        updateUser: updateUser,
        practiceGroupId:parseInt(values?.practiceGroupId),
        practiceGroup: createSuperGroup(parseInt(values.supergroupId),values.innovaccerTinname,values.tin),
        tin: values?.tinLocal ? parseInt(values?.tinLocal) : "",
        startDate: values?.startDate ? formatDateByTimeZone(values?.startDate) : null,
        termDate: values?.termDate ? formatDateByTimeZone(values?.termDate) : null,
        notesRegardingTermDates: values?.notesRegardingTermDates,
      },
    };
    const contractPayload = {
      id: parseInt(selectedPracticeIdByAdmin),
      startDate: values?.contractDate && formatDate(values?.contractDate),
      termDate: values?.contractTermDate && formatDate(values?.contractTermDate),
      practiceId: parseInt(selectedPracticeIdByAdmin),
      tin: values?.tinLocal || "",
      contractAssigneeTypeId: values?.contractAssigneeTypeId,
      personContract: {
        sourceSystem: "SQCN",
        updateUser: "SYSTEM",
        lastConfirmed: new Date(),
        isDeleted: false,
        personId: values?.personId || 0,
        npi: values?.primaryNpi,
        prefixId: values?.contractPrefixId,
        firstName: values?.contractFirstName,
        middleName: values?.contractMiddleName,
        lastName: values?.contractLastName,
        suffixId: values?.contractSuffixId,
        titleId: values?.contractTitleId,
      },
    };
  
    setPracticeInformationData(payload);
    callPost(payload);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(contractPayload),
    };
    fetch(
      base_url +
        "/api/v1/practices/InsertorUpdateContractFields",
      requestOptions
    ).then((response) => {
      console.log(
        "Saved locationInfo updatedLocationNumbers",
        response,
        response.status
      );
      if (response.status == 200) {
        setSuccess(true);
      }
    });
  }
  };



  const createSuperGroup = (supergroupId,innovaccerTinname,tin) => {


    let practiceGroup = {};
        
        // let groupData = practiceMasterData.practiceGroups.filter((item) => {
        //   return item.supergroupId == filterTin;
        // });
        // if(groupData?.length > 0){
        //   practiceGroup = groupData[0];
        // }
        let supergroup = GetAllSuperGroups.filter((item) => {
          return item.id == supergroupId;
        });
    
        if(supergroup?.length > 0){
          return { ...{supergroup:supergroup[0]}, ...practiceGroup, innovaccerTinname,tin };
        }
        
    
      }

      const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/[^0-9]/g, ""),
      });
      const [lowerAgeNumber, setLowerAgeNumber] = useState("");
      const [lowerAgeName, setLowerAgeName] = useState("");
      useEffect(() => {
        if(initialValues.contractAssigneeTypeId === 1) {
          setContractYesFields(true);
        }
        if (initialValues?.lowerAgeLimit) {
          if (!isNaN(initialValues?.lowerAgeLimit)) {
            // If it's a number, set it to the primary input and set the secondary input to a default value
            setLowerAgeNumber(initialValues?.lowerAgeLimit);
            setLowerAgeName(""); // Default unit, can be adjusted
          } else {
            const match = initialValues?.lowerAgeLimit.match(/^(\d+)\s*(Days|Weeks|Months|Years)$/);
            if (match !== null) {
              setLowerAgeNumber(match[1]);
              setLowerAgeName(match[2]);
            } else {
              setLowerAgeNumber("");
              setLowerAgeName(initialValues?.lowerAgeLimit);
            }
            if(initialValues?.lowerAgeLimit === "Newborn" || initialValues?.lowerAgeLimit === "No Age Limit" || initialValues?.lowerAgeLimit === "N/A") {
              setLowerAgeLimitDisable(true);
              setLowerAgeLimitInvalid(false);
            } else {
              setLowerAgeLimitDisable(false);
              if(lowerAgeNumber === "") {
                setLowerAgeLimitInvalid(true);
              } else {
                setLowerAgeLimitInvalid(false);
              }
            }
          }
        }
      }, [initialValues]);

  return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={practiceInformationSchema}
          //onSubmit={onSubmitFun}
          onSubmit={(values) => {
            onSubmitFun(values);
          }}
          enableReinitialize={true}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>

              <div>
                <div className="main-block">
                  <div className="block">
                    <div className="title" data-testid="title">
                      {labels.Practice_ID}{" "}
                      {/* {practiceNameL?.status === "Pending" && (
                    <span>
                      <ApproveIcon />
                    </span>
                  )} */}
                    </div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="id"
                        name={"id"}
                        autoComplete="off"
                        value={values.id}
                        defaultValue={values.id}
                        className="nb-input"
                        disabled
                        onChange={handleChange}
                        // isInvalid={!!errors.practiceGroupId}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                      {errors.practiceGroupId}
                    </Form.Control.Feedback> */}
                    </div>
                    {/* )} */}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Practice_Reporting_ID}</div>
                    <div className="info">
                      <Form.Control
                        type="number"
                        maxLength={10}
                        placeholder="---"
                        id="reportingGroupId"
                        name={"reportingGroupId"}
                        autoComplete="off"
                        value={values.reportingGroupId}
                        defaultValue={values.reportingGroupId}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={!values.reportingGroupId}
                      />
                    <Form.Control.Feedback type="invalid">
                      {!values.reportingGroupId ?errors.reportingGroupId:null}
                    </Form.Control.Feedback>
                    </div>
                    {/* )} */}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Start_Date}</div>
                    <div className="input-group search-container-date">
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={values.startDate}
                        name={"startDate"}
                        onChange={(date) =>
                          handleChange({
                            target: { name: "startDate", value: date },
                          })
                        }
                        placeholderText="--"
                        data-testid="editproviderStartDate"
                        className="nb-input width-full"
                        defaultValue={new Date(values.startDate)}
                      />
                      <button
                        type="button"
                        className="search-button right cursor-auto"
                      >
                        <img src={DatePickerIcon} alt="DatePicker Icon" />
                      </button>
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Term_Date}</div>

                    <div className="input-group search-container-date">
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={values.termDate}
                        minDate={new Date(values.startDate)}
                        onChange={(date) =>
                          handleChange({
                            target: { name: "termDate", value: date },
                          })
                        }
                        placeholderText="--"
                        data-testid="editproviderStartDate"
                        className="nb-input width-full"
                        defaultValue={new Date(values.termDate)}
                      />
                      <button
                        type="button"
                        className="search-button right cursor-auto"
                      >
                        <img src={DatePickerIcon} alt="DatePicker Icon" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <p className="details-label mb-radio"> {labels.SQCN}</p>
                    <div className="info" data-testid="AcceptingNewPatients">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="isSqcn"
                        name={"isSqcn"}
                        className="contactradio"
                        data-testid="isSqcn"
                        value={true}
                        checked={convertBoolean(values?.isSqcn)}
                        defaultChecked={convertBoolean(values?.isSqcn)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="isSqcn"
                        name={"isSqcn"}
                        data-testid="isSqcn"
                        checked={!convertBoolean(values?.isSqcn)}
                        defaultChecked={!convertBoolean(values?.isSqcn)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <p className="details-label mb-radio"> {labels.ACO}</p>
                    <div className="info" data-testid="isAco">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="isAco"
                        className="contactradio"
                        data-testid="isAco"
                        value={true}
                        name={"isAco"}
                        checked={convertBoolean(values?.isAco)}
                        defaultChecked={convertBoolean(values?.isAco)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="isAco"
                        name={"isAco"}
                        data-testid="isAcoNo"
                        checked={!convertBoolean(values?.isAco)}
                        defaultChecked={!convertBoolean(values?.isAco)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <p className="details-label mb-radio">
                      {" "}
                      {labels.Affiliated}
                    </p>
                    <div className="info" data-testid="isAffiliateds">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="isAffiliated"
                        name={"isAffiliated"}
                        className="contactradio"
                        data-testid="isAffiliated"
                        value={true}
                        checked={convertBoolean(values?.isAffiliated)}
                        defaultChecked={convertBoolean(values?.isAffiliated)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="isAffiliated"
                        name={"isAffiliated"}
                        data-testid="isAffiliated"
                        checked={!convertBoolean(values?.isAffiliated)}
                        defaultChecked={!convertBoolean(values?.isAffiliated)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Market_Region}</div>
                    <div className="info">
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="marketRegion"
                        name={"marketRegion"}
                        autoComplete="off"
                        value={values.rsoName}
                        defaultValue={values.rsoName}
                        className="nb-select"
                        // onChange={handleChange}
                         isInvalid={!values.rsoName}
                        //   />

                        onChange={(e) => {
                          handleChange({
                            target: { name: "rsoName", value: e.target.value },
                          });
                          let rsoId =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-market"
                            );
                          handleChange({
                            target: { name: "rsoId", value: rsoId },
                          });
                          // setsupergroupId(getSuperGroupId)
                        }}
                      >
                        <option value={values.rsoName} key={values.rsoName}>
                          {values.rsoName}
                        </option>
                        {marketsData?.length > 0 &&
                          marketsData.sort((a, b) => a.rso.localeCompare(b.rso)).map((market) => (
                            <option
                              key={market.id}
                              data-market={market.id}
                              value={market.rso}
                            >
                              {market.rso}
                            </option>
                          ))}
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">
                      {!values.rsoName ?errors.rsoName:null}
                    </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
                <div className="main-block">

                <Col md={3}>
                  <div className="block">
                
                    <div className="title">{labels.Supergroup}</div>
                    <div className="info">
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="superGroup"
                        autoComplete="off"
                        name={"supergroup"}
                        value={values.supergroup}
                       // defaultValue={values.superGroup}
                        className="nb-select"
                        isInvalid={!values.supergroup}
                        onChange={(e) => {
                          handleChange({
                            target: { name: "supergroup", value: e.target.value },
                          });
                       

                          let superId =
                          e.target[e.target.selectedIndex].getAttribute(
                            "data-inv"
                          );

                          // setFilterTin(superId);
                          // handleChange({
                          //   target: { name: "tin", value: "" },
                          // });
                          handleChange({
                            target: { name: "supergroupId", value: superId },
                          });
                        
                        }}
                      >
                        <option
                          value={values.superGroup}
                          key={values.supergroupId}
                          data-inv={values.supergroupId}
                        >
                          {values.superGroup}
                        </option>
                        {GetAllSuperGroups?.length > 0 &&
                          GetAllSuperGroups.sort((a, b) => a.supergroupCode.localeCompare(b.supergroupCode)).map((superGroup) => (
                            <option
                              key={superGroup.id}
                              data-inv={superGroup.id}
                              value={superGroup.supergroupCode}
                            >
                              {superGroup.supergroupCode}
                            </option>
                          ))}
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">
                      {!values.supergroup ?errors.supergroup:null}
                    </Form.Control.Feedback>
                    </div>
                   
                  </div>
                  </Col>
 
                  <Col md={3}>
                  <div className="block">
                    <div className="title">{labels.TIN}</div>
                    <div className="info">
                      {/* <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="tin"
                        name="tin"
                        autoComplete="off"
                        value={values.tin}
                        defaultValue={values.tin}
                        className="nb-select"
                        isInvalid={!values.tin}
                        // onChange={handleChange}

                        onChange={(e) => {

                          handleChange({
                            target: { name: "tin", value: e.target.value },
                          });
                          let InnovaccerDesignatedName =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-inv"
                            );


                            let superGroupId =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-practice"
                            );

                          handleChange({
                            target: {
                              name: "supergroupId",
                              value: superGroupId,
                            },
                          });

                          handleChange({
                            target: {
                              name: "InnovaccerDesignatedName",
                              value: InnovaccerDesignatedName,
                            },
                          });
                        }}
                      >
                        <option value={values.tin}>{values.tin ? values.tin : "--Select--"}</option>
                        {GetsAllPracticeGroups?.length > 0 &&
                          GetsAllPracticeGroups.sort((a, b) => a.tin.localeCompare(b.tin)).map((tinObj) => (
                            <option
                              key={tinObj.tin}
                              data-inv={tinObj.innovaccerTinname}
                              data-practice={tinObj.practiceGroupId}
                              value={tinObj.tin}
                            >
                              {tinObj.tin}
                            </option>
                          ))}
                      </Form.Select> */}
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="tinInput"
                        autoComplete="off"
                        name="tinInput"
                        value={tinInput}
                        defaultValue={tinInput}
                        className="nb-input"
                        isInvalid={!tinInput}
                        hidden
                      />
                      <CreatableSelect
                        placeholder="---"
                        type="text"
                        id="tin"
                        name="tinLocal"
                        autoComplete="off"
                        className="nb-select nb-select-custom"
                        options={tinOptions}
                        maxLength={9}
                        onInputChange={inputValue =>
                          (inputValue.length === 9 ? inputValue : inputValue.substring(0, 9))
                        }
                        onCreateOption={(selectedOption) => {
                          setTinInput(selectedOption);
                          setFieldValue("tinLocal", selectedOption.replace(/[^0-9]/g, ""));
                          setFieldValue("InnovaccerDesignatedName", "");
                          const newOption = createOption(selectedOption);
                          setFieldValue("tinOption", newOption);
                        }}
                        onChange={(selectedOption) => {
                          setTinInput(selectedOption.value);
                          handleChange({
                            target: {
                              name: "tinLocal",
                              value: selectedOption.value.replace(/[^0-9]/g, ""),
                            },
                          });
                          handleChange({
                            target: {
                              name: "InnovaccerDesignatedName",
                              value: selectedOption?.innovaccerTinname,
                            },
                          });
                          //   setsupergroupId(0);
                          // }

                          handleChange({
                            target: {
                              name: "tinOption",
                              value: selectedOption,
                            },
                          });
                        }}
                        isInvalid={!values.tinLocal}
                        value={values.tinOption}
                        defaultValue={values.tinOption}
                      />
                      {tinInput?.length === 0 ?
                        <div className="invalid-feedback-1">
                          Tax Identification Number cannot be blank
                        </div> : tinInput?.length !== 9 ? 
                        <div className="invalid-feedback-1">
                          Tax Identification Number should be 9 digits
                        </div> : null
                      }
                    </div>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {!values.tinLocal ? errors.tinLocal : null}
                  </Form.Control.Feedback>
                  </Col>
                  <div className="block">
                    <div className="title">
                      {labels.Innovaccer_Designated_Name}
                    </div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="InnovaccerDesignatedName"
                        autoComplete="off"
                        name="InnovaccerDesignatedName"
                        value={values.InnovaccerDesignatedName}
                        defaultValue={values.InnovaccerDesignatedName}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={!values.InnovaccerDesignatedName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {!values.InnovaccerDesignatedName ? errors.InnovaccerDesignatedName : null}
                      </Form.Control.Feedback>
                    </div>
                    {/* )} */}
                  </div>
         
                </div>
                <div className="main-block">
                <div className="block">
                    <div className="title">{labels.Practice_Name_Legal}</div>
                    <div className="info">
                      <Form.Control
                        type="textarea"
                        placeholder="---"
                        id="practiceNameLegal"
                        name="practiceNameLegal"
                        autoComplete="off"
                        value={values.practiceNameLegal}
                        defaultValue={values.practiceNameLegal}
                        className="nb-input"
                        onChange={handleChange}
                         isInvalid={!values.practiceNameLegal}
                      />
              <Form.Control.Feedback type="invalid">
                      {!values.practiceNameLegal ?errors.practiceNameLegal:null}
                    </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Name_Location1}
                    </div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="location1"
                        name="location1"
                        autoComplete="off"
                        value={values.location1}
                        defaultValue={values.location1}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={!values.location1}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}

<Form.Control.Feedback type="invalid">
                      {!values.location1 ?errors.location1:null}
                    </Form.Control.Feedback>
                    </div>
                  </div>

                  <Col md={2}>
                  <div className="block">
                    <div className="title">{labels.Primary_NPI}</div>
                    <div className="info">
                      <Form.Control
                        type="number"
                        placeholder="---"
                        id="primaryNpi"
                        name="primaryNpi"
                        autoComplete="off"
                        maxLength={10}
                        value={values.primaryNpi}
                        defaultValue={values.primaryNpi}
                        className="nb-input"
                        onChange={handleChange}
                        //isInvalid={!values.primaryNpi}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                      {!values.primaryNpi ?errors.primaryNpi:null}
                    </Form.Control.Feedback> */}
                    </div>
                    {/* )} */}
                  </div>
                  </Col>
                  <Col md={2}>
                  <div className="block">
                    <div className="title">{labels.Secondary_NPI}</div>
                    <div className="info">
                      <Form.Control
                        type="number"
                        placeholder="---"
                        id="secondaryNpi"
                        name="secondaryNpi"
                        autoComplete="off"
                        maxLength={10}
                        value={values.secondaryNpi}
                        defaultValue={values.secondaryNpi}
                        className="nb-input"
                        onChange={handleChange}
                        // isInvalid={!!errors.secondaryNpi}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.secondaryNpi}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
 </Col>
                </div>

                <div className="main-block">
                <div className="block">
                    <div className="title">{labels.EMR_Vendor}</div>
                    <div className="info">
                    <CreatableSelect
                    placeholder="---"
                    type="text"
                    id="emr"
                    name="emrLocal"
                    options={emrOptions}
                    className="nb-select nb-select-custom"

                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "emrLocal",
                          value: selectedOption.value,
                        },
                      });
                      handleChange({
                        target: {
                          name: "emrOption",
                          value: selectedOption,
                        },
                      });

                    }}
                    isInvalid={!values.emrLocal}
                    value={values.emrOption}
                  />
                   <Form.Control.Feedback type="invalid">
                    {!values.emrLocal ? errors.emrLocal : null}
                  </Form.Control.Feedback>

                      {/* <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="emr"
                        name="emr"
                        autoComplete="off"
                        value={values.localEmr}
                        defaultValue={values.localEmr}
                        className="nb-select"
                        //onChange={handleChange}
                        isInvalid={!values.localEmr }

                        onChange={(e) => {
                          handleChange({
                            target: { name: "localEmr", value: e.target.value },
                          });
                          let emRid =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-emrId"
                            );
                          handleChange({
                            target: { name: "emRid", value: emRid },
                          });
                        }}
                      >
                        <option value={values.localEmr}>{values.localEmr}</option>
                        {practiceMasterData?.emRs.length > 0 &&
                          practiceMasterData?.emRs.map((emrItem, index) => (
                            <option
                              key={emrItem.emr + index}
                              data-emrId={emrItem.id}
                              value={emrItem.emr}
                              selected={emrItem.id === values.emRid}
                            >
                              {emrItem.emr}
                            </option>
                          ))}
                      </Form.Select> */}
                      <Form.Control.Feedback type="invalid">
                      {!values.localEmr ?errors.localEmr:null}
                    </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.TeleHealth}</div>
                    {/* <div className="info">
                      <Form.Select
                        size="sm"
                        placeholder="---"
                        id="telehealth"
                        name="telehealth"
                        autoComplete="off"
                        value={values.telehealth}
                        defaultValue={values.telehealth}
                        className="nb-select"
                        onChange={handleChange}
                        isInvalid={!values.telehealth }
                      >
                        <option value={values.telehealth}>
                          {values.telehealth}
                        </option>
                        {teleHealthData.length > 0 &&
                          teleHealthData.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">
                      {!values.telehealth ? errors.telehealth:null}
                    </Form.Control.Feedback>
                    </div> */}


                    <div className="info">
                  <CreatableSelect
                    placeholder="---"
                    id="telehealth"
                    name="telehealth"
                    options={teleHealthOptions}
                    className="nb-select"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "telehealth",
                          value: selectedOption.value,
                        },
                      });
                      handleChange({
                        target: {
                          name: "telehealthOption",
                          value: selectedOption,
                        },
                      });
                    }}
                    isInvalid={!values.telehealth}
                    value={values.telehealthOption}
                  />
                  <Form.Control.Feedback type="invalid">
                    {!values.telehealth ? errors.telehealth : null}
                  </Form.Control.Feedback>
                </div>
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Management_System}
                    </div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="practiceManagementSystem"
                        name="practiceManagementSystem"
                        autoComplete="off"
                        value={values.practiceManagementSystem}
                        defaultValue={values.practiceManagementSystem}
                        className="nb-input"
                        onChange={handleChange}
                        // isInvalid={!!errors.practiceManagementSystem}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                      {errors.practiceManagementSystem}
                    </Form.Control.Feedback> */}
                    </div>
                    {/* )} */}
                  </div>
           
        
                </div>

                <div className="main-block">
                <div className="block">
                    <div className="title">{labels.Practice_Specialty}</div>

                    <div className="info">
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="specialty"
                        autoComplete="off"
                        name={"specialty"}
                        value={values.specialty}
                        defaultValue={values.specialty}
                        className="nb-select"
                        onChange={handleChange}
                        isInvalid={!values.specialty }
                      >
                        <option value={values.specialty}>
                          {values.specialty}
                        </option>
                        {practiceSpclData?.length > 0 &&
                          practiceSpclData?.map(
                            (specialitiesItem, index) => (
                              <option
                                key={specialitiesItem.name + index}
                                value={specialitiesItem.name}
                              >
                                {specialitiesItem.name}
                              </option>
                            )
                          )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                      {!values.specialty ? errors.specialty:null}
                    </Form.Control.Feedback>
                    </div>

                    {/* <div className="info">
                          <PracticeSpecialty
                            specialtydata={specialtydata}
                            specialtyDetailsByspecialtype={
                              specialtyDetailsByspecialtype
                            }
                            selectedSpecialtyIdfn={selectedSpecialtyIdfn}
                            validationError={!spltyName?.length > 0}
                            validationError1={
                              newSpecialty && spltyName?.length > 1
                            }
                          />
                        </div> */}
                  </div>
                <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty1}
                    </div>
                    <div className="info">
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="specialty1"
                        autoComplete="off"
                        name={"specialty1"}
                        value={values.specialty1}
                        defaultValue={values.specialty1}
                        className="nb-select"
                        onChange={handleChange}
                      >
                        <option value={""}>{""}</option>
                        {practiceSubSpcl1Data?.length > 0 &&
                          practiceSubSpcl1Data?.map(
                            (specialitiesItem, index) => (
                              <option
                                key={specialitiesItem.name + index}
                                value={specialitiesItem.name}
                              >
                                {specialitiesItem.name}
                              </option>
                            )
                          )}
                      </Form.Select>
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty2}
                    </div>
                    <div className="info">
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="specialty2"
                        name={"specialty2"}
                        autoComplete="off"
                        value={values.specialty2}
                        defaultValue={values.specialty2}
                        className="nb-select"
                        onChange={handleChange}
                      >
                        <option value={""}>{""}</option>
                        {practiceSubSpcl2Data?.length > 0 &&
                          practiceSubSpcl2Data?.map(
                            (specialitiesItem, index) => (
                              <option
                                key={specialitiesItem.name + index}
                                value={specialitiesItem.name}
                              >
                                {specialitiesItem.name}
                              </option>
                            )
                          )}
                      </Form.Select>
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
           
            
                </div>

                <div className="main-block">
                <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty3}
                    </div>
                    <div className="info">
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="specialty3"
                        name={"specialty3"}
                        autoComplete="off"
                        value={values.specialty3}
                        defaultValue={values.specialty3}
                        className="nb-select"
                        onChange={handleChange}
                      >
                        <option value={""}>{""}</option>
                        {practiceSubSpcl3Data?.length > 0 &&
                          practiceSubSpcl3Data?.map(
                            (specialitiesItem, index) => (
                              <option
                                key={specialitiesItem.name + index}
                                value={specialitiesItem.name}
                              >
                                {specialitiesItem.name}
                              </option>
                            )
                          )}
                      </Form.Select>
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
                <div className="block">
                    <p className="details-label mb-radio">
                      {" "}
                      {labels.Primary_Care}
                    </p>
                    <div className="info" data-testid="isPrimaryCare">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="isPrimaryCare"
                        name="isPrimaryCare"
                        className="contactradio"
                        data-testid="isPrimaryCare"
                        value={true}
                        checked={convertBoolean(values?.isPrimaryCare)}
                        defaultChecked={convertBoolean(values?.isPrimaryCare)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="isPrimaryCare"
                        name="isPrimaryCare"
                        data-testid="isPrimaryCare"
                        checked={!convertBoolean(values?.isPrimaryCare)}
                        defaultChecked={!convertBoolean(values?.isPrimaryCare)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Previous_Practice_Name}</div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="previousPracticeName"
                        name="previousPracticeName"
                        autoComplete="off"
                        value={values.previousPracticeName}
                        defaultValue={values.previousPracticeName}
                        className="nb-input"
                        onChange={handleChange}
                        //  isInvalid={!!errors.previousPracticeName}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.previousPracticeName}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
                
                  
                </div>

                <div className="main-block">
                <div className="block">
                    <p className="details-label mb-radio">
                      {labels.Accepting_New_Patients}
                    </p>
                    <div className="info" data-testid="AcceptingNewPatients">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="takingNewPatients"
                        name="takingNewPatients"
                        className="contactradio"
                        data-testid="takingNewPatients"
                        value={true}
                        checked={convertBoolean(values?.takingNewPatients)}
                        defaultChecked={convertBoolean(values?.takingNewPatients)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="takingNewPatients"
                        name="takingNewPatients"
                        data-testid="takingNewPatients"
                        checked={!convertBoolean(values?.takingNewPatients)}
                        defaultChecked={!convertBoolean(values?.takingNewPatients)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="block">
                    <p className="details-label mb-radio">
                      {labels.Accepting_New_Medicaid_Patients}
                    </p>
                    <div className="info" data-testid="takingMedicaidPatients">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="takingMedicaidPatients"
                        name="takingMedicaidPatients"
                        className="contactradio"
                        data-testid="takingMedicaidPatients"
                        value={true}
                        checked={convertBoolean(values?.takingMedicaidPatients)}
                        defaultChecked={convertBoolean(values?.takingMedicaidPatients)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="takingMedicaidPatients"
                        name="takingMedicaidPatients"
                        data-testid="takingMedicaidPatients"
                        checked={!convertBoolean(values?.takingMedicaidPatients)}
                        defaultChecked={!convertBoolean(values?.takingMedicaidPatients)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Lower_Age_Limit}</div>
                    <div className="info">
                    <Row>
                    <Col md={2}>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="lowerAgeNumber"
                        autoComplete="off"
                        name="lowerAgeNumber"
                        value={lowerAgeNumber}
                        defaultValue={lowerAgeNumber}
                        className="nb-input"
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("lowerAgeLimit", value ? `${value+lowerAgeName}` : lowerAgeName);
                          setLowerAgeNumber(value);
                          if(value === ""){
                            setLowerAgeLimitInvalid(true);
                          } else {
                            setLowerAgeLimitInvalid(false);
                          }
                        }}
                        maxLength={4}
                        disabled={lowerAgeLimitDisable}
                        isInvalid={lowerAgeLimitInvalid}
                      />
                      <Form.Control.Feedback type="invalid">
                        {lowerAgeLimitInvalid && errors.lowerAgeNumber}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6}>
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder={lowerAgeLimitDisable ? "---" : ""}
                        id="lowerAgeName"
                        name={"lowerAgeName"}
                        autoComplete="off"
                        value={lowerAgeName}
                        defaultValue={lowerAgeName}
                        className="nb-select"
                        onChange={(e) => {
                          const { value } = e.target;
                          setLowerAgeName(value);
                          if(value === "Newborn" || value === "No Age Limit" || value === "N/A") {
                            setFieldValue("lowerAgeLimit", value);
                            setLowerAgeLimitDisable(true);
                            setLowerAgeLimitInvalid(false);
                            setLowerAgeNumber("");
                          } else {
                            setFieldValue("lowerAgeLimit", lowerAgeNumber ? `${lowerAgeNumber+value}` : value);
                            setLowerAgeLimitDisable(false);
                            if(lowerAgeNumber === "") {
                              setLowerAgeLimitInvalid(true);
                            } else {
                              setLowerAgeLimitInvalid(false);
                            }
                          }
                        }}
                      >
                        {(lowerAgeName === "" || lowerAgeName === null) && <option
                          key={values.lowerAgeName}
                          data-lowerAgeName={values.lowerAgeName}
                          value={values.lowerAgeName}
                        >
                          {values.lowerAgeName}
                        </option>}
                        {lowerAgeLimitData?.length > 0 &&
                          lowerAgeLimitData?.map(
                            (lowerAgeLimitItem, index) => (
                              <option
                                key={lowerAgeLimitItem.name + index}
                                data-lowerAgeName={lowerAgeLimitItem.name}
                                value={lowerAgeLimitItem.name}
                              >
                                {lowerAgeLimitItem.name}
                              </option>
                            )
                          )}
                      </Form.Select>
                    </Col>
                    </Row>
                    </div>
                  </div>
                  </div>
                <EditCareManager
                  careManagers={careManagers}
                  inputList={inputList}
                  setInputList={setInputList}
                  selectedPracticeIdByAdmin={selectedPracticeIdByAdmin}
                />
                <div className="main-block not-flex">
                  <Row>
                    <Col md={3}>
                      <div className="block">
                        <div className="title">
                          {labels.Contract}
                        </div>
                        <div className="info">
                          <Form.Select
                            size="sm"
                            type="text"
                            placeholder="---"
                            id="contractAssigneeTypeDesc"
                            name={"contractAssigneeTypeDesc"}
                            autoComplete="off"
                            value={(values.contractAssigneeTypeDesc !== "" || values.contractAssigneeTypeDesc !== undefined) && values.contractAssigneeTypeDesc}
                            defaultValue={(values.contractAssigneeTypeDesc !== "" || values.contractAssigneeTypeDesc !== undefined) && values.contractAssigneeTypeDesc}
                            className="nb-select"
                            onChange={(e) => {
                              handleChange({
                                target: { name: "contractAssigneeTypeDesc", value: e.target.value },
                              });
                              let id =
                                e.target[e.target.selectedIndex].getAttribute(
                                  "data-contractid"
                                );
                              setFieldValue("contractAssigneeTypeId", parseInt(id));
                              if(e.target.value === "Yes") {
                                setContractYesFields(true);
                              } else {
                                setContractYesFields(false);
                              }
                            }}
                          >
                             {values.contractAssigneeTypeDesc === "" || values.contractAssigneeTypeDesc === undefined && <option
                                key={values.contractAssigneeTypeId}
                                data-contractid={values.contractAssigneeTypeId}
                                value={values.contractAssigneeTypeDesc}
                              >
                                {values.contractAssigneeTypeDesc}
                              </option>}
                            {contractDesc?.length > 0 &&
                              contractDesc?.map((item) => (
                                <option
                                  key={item.contractAssigneeTypeId}
                                  data-contractid={item.contractAssigneeTypeId}
                                  value={item.contractAssigneeTypeDesc}
                                >
                                  {item.contractAssigneeTypeDesc}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      </div>
                    </Col>
                    {contractYesFields && (
                      <>
                        <Col md={3}></Col>
                        <Col md={3}>
                          <div className="block">
                            <div className="title">
                              {labels.Contract_Date}
                            </div>
                            <div className="input-group search-container-date padding-top4">
                              <DatePicker
                                dateFormat="MM/dd/yyyy"
                                selected={values?.contractDate}
                                name={"contractDate"}
                                onChange={(date) =>
                                  handleChange({
                                    target: { name: "contractDate", value: date },
                                  })
                                }
                                placeholderText="--"
                                data-testid="contractDate"
                                className="nb-input width-full"
                                defaultValue={new Date(values?.contractDate)}
                              />
                              <button
                                type="button"
                                className="search-button right cursor-auto"
                              >
                                <img src={DatePickerIcon} alt="DatePicker Icon" />
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="block">
                            <div className="title">{labels.Contract_Term_Date}</div>
                            <div className="input-group search-container-date padding-top4">
                              <DatePicker
                                dateFormat="MM/dd/yyyy"
                                selected={values?.contractTermDate}
                                name={"contractTermDate"}
                                onChange={(date) =>
                                  handleChange({
                                    target: { name: "contractTermDate", value: date },
                                  })
                                }
                                placeholderText="--"
                                data-testid="contractTermDate"
                                className="nb-input width-full"
                                defaultValue={new Date(values?.contractTermDate)}
                                minDate={values?.contractDate}
                              />
                              <button
                                type="button"
                                className="search-button right cursor-auto"
                              >
                                <img src={DatePickerIcon} alt="DatePicker Icon" />
                              </button>
                            </div>
                          </div>
                        </Col>
                      </>)}
                  </Row>
                  {contractYesFields && (
                    <>
                    <Row>
                      <div className="block upper-space">
                        <div className="title">
                          {labels.Contract_Signee}
                        </div>
                      </div>
                      <Col md={3}></Col>
                    </Row>
                    <Row>
                      <div className="block upper-space flex">
                        <Col md={1}>
                          <div className="block">
                            <div className="title">
                              {labels.Prefix}
                            </div>
                            <div className="info">
                              <Form.Select
                                size="md"
                                placeholder="---"
                                id="prefix"
                                autoComplete="off"
                                value={(values?.contractPrefixId !== "" || values?.contractPrefixId !== null) && getPrefixById(values?.contractPrefixId)}
                                defaultValue={(values?.contractPrefixId !== "" || values?.contractPrefixId !== null) && getPrefixById(values?.contractPrefixId)}
                                className="nb-select"
                                name="prefix"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  handleChange(value);
                                  let id =
                                    e.target[e.target.selectedIndex].getAttribute(
                                      "data-prefixid"
                                    );
                                  setFieldValue("contractPrefixId", parseInt(id));
                                }}
                              >
                              {values?.contractPrefixId === "" || values?.contractPrefixId === null && <option
                                 key={values.contractPrefixId}
                                 data-prefixid={values.contractPrefixId}
                                 value={getPrefixById(values?.contractPrefixId)}
                               >
                                 {getPrefixById(values?.contractPrefixId)}
                               </option>}
                                {prefixData.map((prefixItem, index) => (
                                  <option
                                    key={prefixItem.prefixName + index}
                                    value={prefixItem.prefixName}
                                    data-prefixid={prefixItem.id}
                                  >
                                    {prefixItem.prefixName}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="block">
                            <div className="title">
                              {labels.First_Name}
                            </div>
                            <div className="info">
                              <Form.Control
                                type="text"
                                placeholder="---"
                                maxLength={25}
                                id="contractFirstName"
                                autoComplete="off"
                                name="contractFirstName"
                                value={values?.contractFirstName}
                                defaultValue={values?.contractFirstName}
                                className="nb-input padding-top8"
                                isInvalid={!!errors.contractFirstName}
                                onChange={handleChange}
                              />
                         
                            <Form.Control.Feedback type="invalid">
                              {errors.contractFirstName}
                            </Form.Control.Feedback>  
                  </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="block">
                            <div className="title">
                              {labels.MIDDLE_NAME}
                            </div>
                            <div className="info">
                              <Form.Control
                                type="text"
                                placeholder="---"
                                id="contractMiddleName"
                                autoComplete="off"
                                name="contractMiddleName"
                                value={values?.contractMiddleName}
                                defaultValue={values?.contractMiddleName}
                                className="nb-input padding-top8"
                                onChange={handleChange}

                                isInvalid={errors.contractMiddleName && values?.contractMiddleName}
                              />
                       
                            <Form.Control.Feedback type="invalid">
                    {errors.contractMiddleName}
                  </Form.Control.Feedback>  
                  </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="block">
                            <div className="title">
                              {labels.Last_Name}
                            </div>
                            <div className="info">
                              <Form.Control
                                type="text"
                                placeholder="---"
                                id="contractLastName"
                                autoComplete="off"
                                name="contractLastName"
                                value={values?.contractLastName}
                                defaultValue={values?.contractLastName}
                                className="nb-input padding-top8"
                                
                                isInvalid={errors.contractLastName && values?.contractLastName}
                                onChange={handleChange}

                              />
                           
                            <Form.Control.Feedback type="invalid">
                    {errors.contractLastName}
                  </Form.Control.Feedback>  
                  </div>
                          </div>
                        </Col>
                        <Col md={1}>
                          <div className="block">
                            <div className="title">
                              {labels.SUFFIX}
                            </div>
                            <div className="info">
                              <Form.Select
                                size="md"
                                id="suffix"
                                autoComplete="off"
                                name="suffix"
                                value={(values?.contractSuffixId !== "" || values?.contractSuffixId !== null) && getSuffixByName(values?.contractSuffixId)}
                                defaultValue={(values?.contractSuffixId !== "" || values?.contractSuffixId !== null) && getSuffixByName(values?.contractSuffixId)}
                                className="nb-select"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  handleChange(value);
                                  let id =
                                    e.target[e.target.selectedIndex].getAttribute(
                                      "data-suffixid"
                                    );
                                  setFieldValue("contractSuffixId", parseInt(id));
                                }}
                              >
                              {values?.contractSuffixId === "" || values?.contractSuffixId === null && <option
                                 key={values.contractSuffixId}
                                 data-suffixid={values.contractSuffixId}
                                 value={getSuffixByName(values?.contractSuffixId)}
                               >
                                 {getSuffixByName(values?.contractSuffixId)}
                               </option>}
                                {allSuffixData?.length > 0 &&
                                  allSuffixData?.map((suffix) => (
                                    <option
                                      key={suffix.id}
                                      data-suffixid={suffix.id}
                                      value={suffix.suffixName}
                                    >
                                      {suffix.suffixName}
                                    </option>
                                  ))}
                              </Form.Select>
                            </div>
                          </div>
                        </Col>
                        <Col md={1}>
                          <div className="block">
                            <div className="title">
                              {labels.Title}
                            </div>
                            <div className="info">
                              <Form.Select
                                size="sm"
                                id="contractTitleName"
                                autoComplete="off"
                                name="contractTitleName"
                                value={(values?.contractTitleId !== "" || values?.contractTitleId !== null) && getTitleById(values?.contractTitleId)}
                                defaultValue={(values?.contractTitleId !== "" || values?.contractTitleId !== null) && getTitleById(values?.contractTitleId)}
                                className="nb-select"
                                onChange={(e) => {
                                  handleChange(e);
                                  let id =
                                    e.target[e.target.selectedIndex].getAttribute(
                                      "data-title"
                                    );
                                  setFieldValue("contractTitleId", parseInt(id));
                                  if (prefix_mapTitles.includes(id)) {
                                    // handlePrefixChange(id);
                                    setFieldValue("prefix", "Dr");
                                    const DrId = getPrefixByName("Dr");
                                    setFieldValue("contractPrefixId", parseInt(DrId));
                                  }
                                }}
                              >
                              {values?.contractTitleId === "" || values?.contractTitleId === null && <option
                                 key={values.contractTitleId}
                                 data-title={values.contractTitleId}
                                 value={getTitleById(values?.contractTitleId)}
                               >
                                 {getTitleById(values?.contractTitleId)}
                               </option>}
                                {titleData.map((titleItem, index) => (
                                  <option
                                    key={titleItem.title + index}
                                    value={titleItem.title}
                                    data-title={titleItem.id}
                                  >
                                    {titleItem.title}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Row>
                    </>
                  )}
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Name_Before_Editing_Innovaccer}
                    </div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="practiceNamebeforeEditing"
                        name="practiceNamebeforeEditing"
                        autoComplete="off"
                        value={values.practiceNamebeforeEditing}
                        defaultValue={values.practiceNamebeforeEditing}
                        className="nb-input"
                        onChange={handleChange}
                        // isInvalid={!!errors.telemedicine}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Notes_Regarding_Payments}
                    </div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="payments"
                        autoComplete="off"
                        name="payments"
                        value={values.payments}
                        defaultValue={values.payments}
                        className="nb-input"
                        onChange={handleChange}
                        //isInvalid={!!errors.telemedicine}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">
                      {labels.Additional_Comments_For_Audits}
                    </div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="additionalCommentsForAudits"
                        name="additionalCommentsForAudits"
                        autoComplete="off"
                        value={values.additionalCommentsForAudits}
                        defaultValue={values.additionalCommentsForAudits}
                        className="nb-input"
                        onChange={handleChange}
                        // isInvalid={!!errors.emr}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                      {errors.emr}
                    </Form.Control.Feedback> */}
                    </div>
                    {/* )} */}
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Notes_Regarding_Term_Dates}
                    </div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="notesRegardingTermDates"
                        name="notesRegardingTermDates"
                        autoComplete="off"
                        value={values.notesRegardingTermDates}
                        defaultValue={values.notesRegardingTermDates}
                        className="nb-input"
                        disabled={!values?.termDate}
                        onChange={handleChange}
                        // isInvalid={!!errors.notesRegardingTermDates}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Claims_Clearing_House}</div>
                    <div className="info">
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="claimsClearingHouse"
                        name="claimsClearingHouse"
                        autoComplete="off"
                        value={values.claimsClearingHouse}
                        defaultValue={values.claimsClearingHouse}
                        className="nb-input"
                        onChange={handleChange}
                        // isInvalid={!!errors.claimsClearingHouse}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                    </div>
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <p className="details-label mb-radio">{labels._837}</p>
                    <div className="info" data-testid="uses837">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="uses837"
                        className="contactradio"
                        name="uses837"
                        data-testid="uses837"
                        value={true}
                        checked={convertBoolean(values?.uses837)}
                        defaultChecked={convertBoolean(values?.uses837)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="uses837"
                        name="uses837"
                        data-testid="uses837"
                        checked={!convertBoolean(values?.uses837)}
                        defaultChecked={!convertBoolean(values?.uses837)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <p className="details-label mb-radio"> {labels.CCDA}</p>
                    <div className="info" data-testid="AcceptingNewPatients">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="ccda"
                        name="ccda"
                        className="contactradio"
                        data-testid="ccda"
                        value={true}
                        checked={convertBoolean(values?.ccda)}
                        defaultChecked={convertBoolean(values?.ccda)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="ccda"
                        name="ccda"
                        data-testid="ccda"
                        checked={!convertBoolean(values?.ccda)}
                        defaultChecked={!convertBoolean(values?.ccda)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block" />
                </div>

                <div className="main-block">
                  <div className="block">
                    <p className="details-label mb-radio">
                      {" "}
                      {labels.Aetna_BR_Only}
                    </p>
                    <div className="info" data-testid="aetnaBROnly">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="aetnaBROnly"
                        name="aetnaBROnly"
                        className="contactradio"
                        data-testid="aetnaBROnly"
                        value={true}
                        checked={convertBoolean(values?.aetnaBROnly)}
                        defaultChecked={convertBoolean(values?.aetnaBROnly)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        name="aetnaBROnly"
                        id="aetnaBROnly"
                        data-testid="aetnaBROnly"
                        checked={!convertBoolean(values?.aetnaBROnly)}
                        defaultChecked={!convertBoolean(values?.aetnaBROnly)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <p className="details-label mb-radio">
                      {" "}
                      {labels.Humana_MA}
                    </p>
                    <div className="info" data-testid="humanaMA">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="humanaMA"
                        name="humanaMA"
                        className="contactradio"
                        data-testid="humanaMA"
                        value={true}
                        checked={convertBoolean(values?.humanaMA)}
                        defaultChecked={convertBoolean(values?.humanaMA)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="humanaMA"
                        name="humanaMA"
                        data-testid="humanaMA"
                        checked={!convertBoolean(values?.humanaMA)}
                        defaultChecked={!convertBoolean(values?.humanaMA)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <p className="details-label mb-radio">
                      {" "}
                      {labels.John_Hopkins_US}
                    </p>
                    <div className="info" data-testid="AcceptingNewPatients">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name="johnHopkinsUSFamilyHealthPlan"
                        id="johnHopkinsUSFamilyHealthPlan"
                        className="contactradio"
                        data-testid="johnHopkinsUSFamilyHealthPlan"
                        value={true}
                        checked={
                          convertBoolean(values?.johnHopkinsUSFamilyHealthPlan)
                        }
                        defaultChecked={
                          convertBoolean(values?.johnHopkinsUSFamilyHealthPlan)
                        }
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        name="johnHopkinsUSFamilyHealthPlan"
                        id="johnHopkinsUSFamilyHealthPlan"
                        data-testid="johnHopkinsUSFamilyHealthPlan"
                        checked={!convertBoolean(values?.johnHopkinsUSFamilyHealthPlan)}
                        defaultChecked={
                          !convertBoolean(values?.johnHopkinsUSFamilyHealthPlan)
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <p className="details-label mb-radio">
                      {labels.SHP_Commercial}
                    </p>
                    <div className="info" data-testid="optimaCommercial">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="optimaCommercial"
                        name="optimaCommercial"
                        className="contactradio"
                        data-testid="optimaCommercial"
                        value={true}
                        checked={convertBoolean(values?.optimaCommercial)}
                        defaultChecked={convertBoolean(values?.optimaCommercial)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="optimaCommercial"
                        name="optimaCommercial"
                        data-testid="optimaCommercial"
                        checked={!convertBoolean(values?.optimaCommercial)}
                        defaultChecked={!convertBoolean(values?.optimaCommercial)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <p className="details-label mb-radio">
                      {" "}
                      {labels.SHP_Medicaid}
                    </p>
                    <div className="info" data-testid="optimaMedicaid">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="optimaMedicaid"
                        name="optimaMedicaid"
                        className="contactradio"
                        data-testid="optimaMedicaid"
                        value={true}
                        checked={convertBoolean(values?.optimaMedicaid)}
                        defaultChecked={convertBoolean(values?.optimaMedicaid)}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="optimaMedicaid"
                        name="optimaMedicaid"
                        data-testid="optimaMedicaid"
                        checked={!convertBoolean(values?.optimaMedicaid)}
                        defaultChecked={!convertBoolean(values?.optimaMedicaid)}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <p className="details-label mb-radio">
                      {" "}
                      {labels.SHP_MA}
                    </p>
                    <div className="info" data-testid="humanaMA">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="optimaMedicareAdvantage"
                        name="optimaMedicareAdvantage"
                        className="contactradio"
                        data-testid="optimaMedicareAdvantage"
                        value={true}
                        checked={convertBoolean(values?.optimaMedicareAdvantage)}
                        defaultChecked={
                          convertBoolean(values?.optimaMedicareAdvantage)
                        }
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value={false}
                        id="optimaMedicareAdvantage"
                        name="optimaMedicareAdvantage"
                        data-testid="optimaMedicareAdvantage"
                        checked={!convertBoolean(values?.optimaMedicareAdvantage)}
                        defaultChecked={
                          !convertBoolean(values?.optimaMedicareAdvantage)
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="add-location-footer">
          <p>Please confirm that all information is correct</p>
          <div className="add-location-buttons">
            <button
              className="white-btn"
              data-testid="EditCancelButton"
              onClick={() => {}}
            >
              Cancel
            </button>
            <button
              className="blue-btn"
              type="submit"
              data-testid="EditConfirmButton"
              value="submit"
              disabled={false}
            >
              {isLoading ? "Saving..." : "Confirm and Save"}
            </button>
          </div>
        </div> */}
              {showError && (
                <Alert variant="danger" data-testid="error-server">
                  <Container>
                    <p className="mb-0" style={errorStyle}>
                      <img
                        src={icon}
                        alt="error-icon"
                        className="error-icon"
                      ></img>{" "}
                      {editData?.message || "Something went wrong"}
                    </p>
                  </Container>
                </Alert>
              )}
              <Row className="mt-3 d-flex flex-row-reverse">
                <Col md={3} className="text-right">
                  <button
                    className="blue-btn"
                    type="submit"
                    onClick={() => {
                      tinInput === "000000000" && setTinInput("");
                      onSubmitFun(values);
                      // handleSubmit();
                    }}
                    disabled={isLoading}
                    value="submit"
                  >
                   {isLoading ? "Saving..." : "Save"}
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
  );
};
