import React from "react";
import Sidebar from "../Pages/Sidebar";
import Header from "../Pages/Header";
import Spinner from "../../../Hooks/Spinner";

function SqcnSideMenu({ pmName, headerText, loading, children }) {
  return (
    <div className="dashboard d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flow-root",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Header headerText={headerText} SQCNName={pmName} />
        <div style={{ height: "100%", background: "#f5f5f5" }}>
          <div
            className="dashboard-body"
            style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}
          >
            {loading ? <Spinner /> : children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SqcnSideMenu;
