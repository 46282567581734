/* eslint-disable react/jsx-key, indent */
import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import labels from "../../../../Static/Dictionary.json";
import cookies from "js-cookie";
import deleteIconB from "../../../../../../../images/delete_icon_black.svg";
import { useFetch } from "Components/Hooks/Fetch";

const EditCareManager = ({
  inputList,
  setInputList,
  careManagers,
  selectedPracticeIdByAdmin,
}) => {
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");

  const [titleData, setTitleData] = useState([]);
  const [prefixData, setPrefixData] = useState([]);
  const [allSuffixData, setAllSuffixData] = useState([]);

  const prefix_mapTitles = [
    "16",
    "41",
    "24",
    "61",
    "31",
    "62",
    "63",
    "5",
    "11",
    "36",
    "1",
    "66",
    "67",
    "32",
    "68",
    "22",
    "84",
    "23",
    "45",
  ];

  const handleMdOfficeIdChange = (e, role, roleId, index) => {
    const { value } = e.target;
    if (value.trim() !== "") {
      fetchContactRecords(value, role, roleId, index);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const getPrefixByName = (prefixName) => {
    if (prefixData.length > 0) {
      const prefix = prefixData.filter(
        (item) => item.prefixName === prefixName
      );
      if (prefix.length > 0) {
        return prefix[0].id;
      }
    }
    return "";
  };
  const handlePrefixChange = (e, index, id) => {
    console.log("id", id);
    const list = [...inputList];
    list[index]["prefix"] = "Dr";
    list[index]["prefixId"] = getPrefixByName("Dr");
    setInputList(list);
  };

  const handleIdChange = (name, index, value) => {
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  useEffect(() => {
    if (careManagers?.length > 0 && prefixData?.length > 0) {
      let list = [...inputList];
      careManagers.map((careManager) => {
        let updatedObject = setDynamicInput(careManager);
        if (list.length !== careManagers?.length) {
          list.push(updatedObject);
        }
      });

      setInputList(list);
    }
  }, [careManagers, prefixData]);

  const getPrefixById = (id) => {
    if (prefixData.length > 0) {
      const prefix = prefixData.filter((item) => item.id === id);
      if (prefix.length > 0) {
        return prefix[0].prefixName;
      }
    }
    return "";
  };

  const GetTitleDetails = () => {
    fetch(base_url + "/api/v1/contact/GetTitleDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTitleData(data?.titleTypeList || []);
        setPrefixData(data?.prefixList || []);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      GetTitleDetails();
    };
    fetch();
  }, []);

  const handleRemove = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const { data: GetSuffixData } = useFetch("/api/v1/contact/GetSuffixData");
  useEffect(() => {
    if (GetSuffixData) {
      setAllSuffixData(GetSuffixData);
    }
  }, [GetSuffixData]);

  const fetchContactRecords = (mdOfficeId, role, roleId, index) => {
    if (mdOfficeId?.length > 4) {
      fetch(
        base_url +
          `/api/v1/practices/ContactInfoByMdofficeIdAndPracticeId?practiceid=${selectedPracticeIdByAdmin}&mdOfficeId=${mdOfficeId}&roleTypId=${roleId}`,

        {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            "ocp-apim-subscription-key": subscriptionKey,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("API Request Failed");
          }
        })
        .then((data) => {
          updateDynamicInput(index, data[0]);
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    }
  };

  const updateDynamicInput = (index, data) => {
    const list = [...inputList];
    list[index]["mdofficeId"] = data?.mdofficeId || "";
    list[index]["firstName"] = data?.firstName || "";
    list[index]["lastName"] = data?.lastName || "";
    list[index]["middleName"] = data?.middleName || "";
    list[index]["suffix"] = data?.suffix || "";
    list[index]["suffixId"] = data?.suffixId || "";
    list[index]["title"] = data?.title || "";
    list[index]["prefix"] = getPrefixById(data?.prefixId);
    list[index]["personId"] = data?.id;
    list[index]["prefixId"] = data?.prefixId || 0;
    list[index]["titleId"] = data?.titleId || 0;

    setInputList(list);
  };
  const addMoreCareManager = (e) => {
    e.preventDefault();

    if (inputList.length < 4) {
      setInputList([
        ...inputList,
        {
          mdofficeId: "",
          firstName: "",
          lastName: "",
          middleName: "",
          suffix: "",
          suffixId: "",
          title: "",
          prefix: "",
          personId: "",
          prefixId: "",
          titleId: "",
        },
      ]);
    }
  };

  const setDynamicInput = (data) => {
    return {
      mdofficeId: data?.mdofficeId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      middleName: data?.middleName,
      suffix: data?.suffix,
      suffixId: data?.suffixId,
      title: data?.titleType[0]?.title,
      prefix: getPrefixById(data?.prefixId),
      personId: data?.id,
      prefixId: data?.prefixId,
      titleId: data?.titleType[0]?.id,
    };
  };

  const getSuffixByName = (id) => {
    if(allSuffixData){
      const suffixId = allSuffixData?.filter((suffix) => suffix.id === id);
      if(suffixId){
        return suffixId[0]?.suffixName || null;
      }
    }
  }

  return (
    <>
      <div className="main-block">
        <div className="block">
          <Row className="center-align-flex">
            <Col md={3}>
              <div className="title">{labels.Care_Manager_Name} </div>
            </Col>
            <Col md={9} className="text-right">
              <button className="blue-btn" onClick={addMoreCareManager}>
                Add
              </button>
            </Col>
          </Row>
        </div>
      </div>

      <div className="dynamic_height">
        {inputList.map((x, i) => {
          return (
            <>
              <div className="display-inline">
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID} </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="practiceManagerMdId"
                        autoComplete="off"
                        name="mdofficeId"
                        value={x.mdofficeId}
                        defaultValue={x.mdofficeId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "manager", 1, i);
                          handleInputChange(e, i);
                        }}
                      />
                    </Col>
                    <Col md={8} className="text-right">
                      <button
                        type="button"
                        className="icon_style"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemove(i);
                        }}
                      >
                        <img src={deleteIconB} alt="Sort Icon" />
                      </button>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={2}>
                      <p className="details-label">{labels.Prefix}</p>

                      <Form.Select
                        size="md"
                        placeholder="---"
                        id="prefix"
                        autoComplete="off"
                        value={x.prefix}
                        defaultValue={x.prefix}
                        className="nb-select"
                        name="prefix"
                        // onChange={handleChange}

                        onChange={(e) => {
                          handleInputChange(e, i);

                          let id =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-prefixId"
                            );

                          handleIdChange("prefixId", i, id);
                        }}
                      >
                        <option value={x.prefix}>{x.prefix}</option>
                        {prefixData.map((prefixItem, index) => (
                          <option
                            key={prefixItem.prefixName + index}
                            value={prefixItem.prefixName}
                            data-prefixId={prefixItem.id}
                          >
                            {prefixItem.prefixName}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={4}>
                          <p className="details-label">{labels.First_Name}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="firstName"
                            autoComplete="off"
                            name="firstName"
                            value={x.firstName}
                            className="nb-input"
                            onChange={(e) => {
                              handleInputChange(e, i);
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <p className="details-label">{labels.MIDDLE_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="middleName"
                            name="middleName"
                            autoComplete="off"
                            value={x.middleName}
                            defaultValue={x.middleName}
                            className="nb-input"
                            onChange={(e) => {
                              handleInputChange(e, i);
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <p className="details-label">{labels.Last_Name}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="practiceManagerLName"
                            autoComplete="off"
                            name="lastName"
                            value={x.lastName}
                            className="nb-input"
                            onChange={(e) => {
                              handleInputChange(e, i);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col md={2}>
                      <p className="details-label">{labels.SUFFIX}</p>
                      <Form.Select
                        size="md"
                        id="suffix"
                        autoComplete="off"
                        name="suffix"
                        value={getSuffixByName(x.suffixId)}
                        defaultValue={getSuffixByName(x.suffixId)}
                        className="nb-select"
                        onChange={(e) => {
                          handleInputChange(e, i);

                          let id =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-suffixId"
                            );

                          handleIdChange("suffixId", i, id);
                        }}
                      >
                        <option value={getSuffixByName(x.suffixId)}>{getSuffixByName(x.suffixId)}</option>
                        {allSuffixData?.length > 0 &&
                          allSuffixData?.map((suffix) => (
                            <option
                              key={suffix.id}
                              data-suffixId={suffix.id}
                              value={suffix.suffixName}
                            >
                              {suffix.suffixName}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                    <Col md={2}>
                      <p className="details-label">{labels.Title}</p>
                      <Form.Select
                        size="sm"
                        id="title"
                        autoComplete="off"
                        name="title"
                        value={x.title}
                        defaultValue={x.title}
                        className="nb-select"
                        onChange={(e) => {
                          handleInputChange(e, i);
                          let id =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-title"
                            );
                          if (prefix_mapTitles.includes(id)) {
                            handlePrefixChange(e, i, id);
                          }

                          handleIdChange("titleId", i, id);
                        }}
                      >
                        <option value={x.title}>{x.title}</option>
                        {titleData.map((titleItem, index) => (
                          <option
                            key={titleItem.title + index}
                            value={titleItem.title}
                            data-title={titleItem.id}
                          >
                            {titleItem.title}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default EditCareManager;
