import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import SqcnSideMenu from "../../../SqcnSideMenu";
import Title from "Components/UI/Atoms/Title/Title";
import Accordions from "../Accordion";
import { Container, Row, Col, Popover } from "react-bootstrap";
import tooltipData from "../../../../../../../Components/Templates/ContactInformation/ContactTooltip.json";
import EditPracticeContactInformation from "../../../../../../Templates/ContactInformation/EditPracticeContactInformation";
import AdminAddLocation from "../AddLocation/AdminAddLocation";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
//import AddProvider from "../ProviderAccordion/AddProvider";

import ProviderListAdminAddFlow from "Components/Templates/ProviderList/AddProvider/ProviderListAdminAddFlow";
import AddPracticeInfo from "./AddPracticeInfo";
// import { useFetchPost } from "Components/Hooks/Fetch";
import closeIcon from "../images/close-icon.svg";
import { Alert } from "react-bootstrap";
import updatedIcon from "../images/updatedIcon.svg";
import icon from "../images/Icon.svg";
const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{tooltipData.mdOfficeID}</p>
    </Popover.Body>
  </Popover>
);
const popover1 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{tooltipData.additional}</p>
    </Popover.Body>
  </Popover>
);
const popover2 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{tooltipData.director}</p>
    </Popover.Body>
  </Popover>
);

const popover3 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">
        To change this please email{" "}
        <a href="mailto:SQCNPPD@Sentara.com">SQCNPPD@Sentara.com</a> This is the
        executive leader who has signing priviledges for your practice(s)
      </p>
    </Popover.Body>
  </Popover>
);

const popover4 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">
        The change to this field can only be initiated by SQCN admin. Please
        email changes to
        <a href="mailto:SQCNPPD@Sentara.com">SQCNPPD@Sentara.com</a>
      </p>
    </Popover.Body>
  </Popover>
);

const AddPracticeList = (props) => {
  const [titleData, setTitleData] = useState([]);
  const [suffixData, setSuffixData] = useState([]);
  const [prefixData, setPrefixData] = useState([]);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const [contactInformationData, setContactInformationData] = useState([]);
  const [practiceInformationData, setPracticeInformationData] = useState({});
  const [locationPayload, setLocationPayload] = useState([]);

  const [providerLocationPayload, setProviderLocationPayload] = useState([]);
  const [providerContactPayload, setProviderContactPayload] = useState([]);
  const [practiceContractData, setPracticeContractData] = useState({});
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [contactInfoError, setContactInfoError] = useState(false);
  //console.log(contactInformationData);

  const [success, setSuccess] = useState(false);

  const token1 = cookies.get("access");
  const decodeToken1 = jwt_decode(token1);
  const errorStyle = {
    color: "#000",
  };

  // const {
  //   data: response,
  //   error,
  //   callPost,
  // } = useFetchPost(`/api/v1/practices/Add?updateUser=${decodeToken1?.id}`);

  const GetTitleDetails = () => {
    fetch(base_url + "/api/v1/contact/GetTitleDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTitleData(data?.titleTypeList || []);
        setPrefixData(data?.prefixList || []);
      });
  };

  const getSuffixDetails = () => {
    fetch(base_url + "/api/v1/contact/GetSuffixData", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSuffixData(data);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      GetTitleDetails();
      getSuffixDetails();
    };
    fetch();
  }, []);

  // useEffect(() => {
  //   if (response && !error) {
  //     console.log("response && !error", response, error);
  //     setSuccess(true);
  //   }
  // }, [response, error]);

  const addPracticeClick = async (e) => {
    e.preventDefault();
    let payload = {
      ...practiceInformationData,
      ...{ supergroup: practiceInformationData?.supergroup },
      practiceAssignment: [
        ...contactInformationData,
        ...providerContactPayload.flat() || [],
      ],
      location: [...locationPayload, ...providerLocationPayload],
    };
    // callPost(payload);
    console.log("payload---", payload);
    // if(practiceInformationData?.length !== undefined && practiceInformationData?.supergroup?.length !== undefined && contactInformationData?.length !== 0 && providerContactPayload?.length !== 0 && locationPayload.length !== 0 && providerLocationPayload?.length !== 0){
    const firstResponse = await fetch(base_url + `/api/v1/practices/Add?updateUser=${decodeToken1?.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(payload),
    }
    );
    if (firstResponse.ok) {
      const firstData = await firstResponse.json();
      const resultId = firstData?.id;
      if (resultId) {
        const careManagers = practiceInformationData?.careManagers || [];
        const requestOptionsCareManager = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "access-control-allow-credentials": "true",
            "ocp-apim-subscription-key": subscriptionKey,
            "api-supported-versions": "1.0",
          },
          body: JSON.stringify(careManagers),
        };
        const secondResponse = await fetch(
          base_url +
            `/api/v1/practices/InsertCareManagerDetails?practiceid=${resultId}`,
          requestOptionsCareManager
        );

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "access-control-allow-credentials": "true",
            "ocp-apim-subscription-key": subscriptionKey,
            "api-supported-versions": "1.0",
          },
          body: JSON.stringify(practiceContractData),
        };
        fetch(
          base_url +
            "/api/v1/practices/InsertorUpdateContractFields",
          requestOptions
        ).then((response) => {
          console.log(
            "Saved locationInfo updatedLocationNumbers",
            response,
            response.status
          );
          if (response.status == 200) {
            setSuccess(true);
          }
        });
        if (secondResponse.ok) {
          const secondData = await secondResponse.json();
          console.log(secondData);
          setSuccess(true);
        } else {
          setResponse({ message: "Something went wrong" });
          setError(true);
        }
      } else {
        setResponse({ message: "Something went wrong" });
        setError(true);
      }
    } else {
      setResponse({ message: "Something went wrong" });
      setError(true);
    }
    // } else {
    //   setResponse({message: "Something went wrong"});
    //   setError(true);
    // }
  };

  return (
    <SqcnSideMenu pmName={props?.pmName} headerText={""}>
      <div className="manage-practice-banner">
        <Row>
          <Col md={10} xs={12}>
            <Title title={"Add new practice to SQCN"} />
          </Col>
        </Row>
      </div>
      <div className="manage-outlet">
        <Accordions
          content={
            <AddPracticeInfo
              practiceInformationData={practiceInformationData}
              setPracticeInformationData={setPracticeInformationData}
              setPracticeContractData={setPracticeContractData}
            />
          }
          title={"Practice Information"}
        />
        <Accordions
          content={
            <AdminAddLocation
              practiceManagerId={decodeToken1?.id}
              setLocationPayload={setLocationPayload}
              addPractice={true}
            />
          }
          title={"Add Location"}
        />
        <Accordions
          content={
            <EditPracticeContactInformation
              data={[]}
              isContactServerError={false}
              practiceManagerId={decodeToken1?.id}
              // contactInfoUpdate={contactInfoUpdate}
              // contactInfoGetUpdate={contactInfoGetUpdate}
              setContactInformationData={setContactInformationData}
              titleData={titleData}
              suffixData={suffixData}
              prefixData={prefixData}
              popover={popover}
              popover1={popover1}
              popover2={popover2}
              popover3={popover3}
              popover4={popover4}
              setEditModeBox={() => {}}
              type="add"
            />
          }
          title={"Add Contact Information"}
        />
        <Accordions
          content={
            <>
              <ProviderListAdminAddFlow
                practiceTitle={props.practiceTitle}
                practiceManagerId={props.practiceManagerId}
                selectedPracticeId={props.selectedId}
                setContactInformationData={setContactInformationData}
                contactInformationData={contactInformationData}
                locationPayload={locationPayload}
                setLocationPayload={setLocationPayload}
                addFlow={true}
                providerLocationPayload={providerLocationPayload}
                setProviderLocationPayload={setProviderLocationPayload}
                providerContactPayload={providerContactPayload}
                setProviderContactPayload={setProviderContactPayload}
                titleData={titleData}
                prefixData={prefixData}
              />
            </>
            // <AddProvider
            //   practiceTitle={props.practiceTitle}
            //   practiceManagerId={props.practiceManagerId}
            //   selectedPracticeId={props.selectedId}
            //   setContactInformationData={setContactInformationData}
            //   contactInformationData={contactInformationData}
            //   locationPayload={locationPayload}
            //   setLocationPayload={setLocationPayload}

            //   addFlow={true}

            // />
          }
          title={"Add Provider"}
        />

        <div className="add-location-footer " id="footer">
          {success && (
            <div>
              <Alert variant="success" data-testid="successProvider">
                <img
                  src={updatedIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
                Practice information added successfully
                <button
                  onClick={() => setSuccess(false)}
                  className="close-icon-button"
                  data-testid="providerProfileupdatedclose"
                >
                  <img
                    src={closeIcon}
                    alt="Updated Icon"
                    className="updated-icon"
                  />
                </button>
              </Alert>
            </div>
          )}

          {error && (
            <Alert variant="danger" data-testid="error-server">
              <Container>
                <p className="mb-0" style={errorStyle}>
                  <img src={icon} alt="error-icon" className="error-icon"></img>{" "}
                  {response?.message || "Something went wrong"}
                </p>
              </Container>
            </Alert>
          )}
          {contactInfoError && (
            <Alert variant="danger" data-testid="error-server">
              <Container>
                <p className="mb-0" style={errorStyle}>
                  <img src={icon} alt="error-icon" className="error-icon"></img>{" "}
                  Please complete the Contact Information Section
                </p>
              </Container>
            </Alert>
          )}
          <div className="add-location-buttons">
            <Link to={"/manage-sqcn-practice"}>
              <button className="white-btn" data-testid="CancelButton">
                Cancel
              </button>
            </Link>
            {!success && (
              <button
                className="blue-btn"
                type="submit"
                data-testid="ConfirmButton"
                onClick={(e)=>{
                  if(contactInformationData.length) {
                    addPracticeClick(e);
                    setContactInfoError(false);
                  } 
                  else {
                    setContactInfoError(true);
                  }
                }}
              >
                Confirm and Save
              </button>
            )}
          </div>
        </div>
      </div>
    </SqcnSideMenu>
  );
};

export default AddPracticeList;
