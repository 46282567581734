import React from "react";

const listItemStyle = (selected) => {
  return {
    color: selected ? "#0076A8" : "#000",
    backgroundColor: "#fff",
    fontWeight: selected ? "400" : "normal",
    cursor: "pointer",
    paddingLeft: "0.75em",
    paddingRight: "0.75em",
    paddingTop: "0.2em",
    paddingBottom: "0.2em",
    border: "unset",
    textAlign: "left",
  };
};

function ListItem({ id, name, selected, onItemClicked }) {
  return (
    <li key={id} className="practice">
      <button
        type="button"
        onClick={() => {
          onItemClicked(id);
        }}
        style={listItemStyle(selected)}
        data-testid={`practiceNameDropDown${id}`}
      >
        {name}
      </button>
    </li >
  );
}

export default ListItem;
