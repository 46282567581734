/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import("../images/_style.scss");
import cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useFetch } from "Components/Hooks/Fetch";
import EditPracticeBanner from "./EditPracticeBanner";
import Accordions from "../Accordion";
import AdminLocationList from "../EditLocation/AdminLocationList";
import SqcnSideMenu from "../../../SqcnSideMenu";
import PracticeContactInformation from "../PracticeContactInformation";
import EditPracticeInfo from "./EditPracticeInfo";
import ProviderListPageAdmin from "Components/Templates/DataPages/ProviderListPageAdmin";

export const EditPracticeList = (props) => {
  const selectedPracticeIdByAdmin = localStorage.getItem("selectedPracticeIdByAdmin");
  const [isEditPractice, setIsEditPractice] = useState(false);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL;
  const base_url1 = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const [locationData, setLocationData] = useState([]);
  const [contactInfoData, SetContactInfoData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [suffixData, setSuffixData] = useState([]);
  const [prefixData, setPrefixData] = useState([]);
  const [contactInformationData, setContactInformationData] = useState({});
  const [deletedLocationId, setDeletedLocationId] = useState();
  const [updatedLocationVal, setUpdatedLocationVal] = useState();
  const [updatedProviderVal, setUpdatedProviderVal] = useState();
  console.log(contactInformationData);
  useEffect(() => {
    const fetch = async () => {
      fetchLocationRecords();
    };
    fetch();
  }, [props.selectedPracticeId]);

  useEffect(() => {
    setIsEditPractice(true);
  }, [props.selectedPracticeId]);
  const fetchLocationRecords = () => {
    fetch(
      base_url +
      `/api/v1/practices/${selectedPracticeIdByAdmin}/location?includePending=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("locationData11", data);
        if (data && data.length > 0) {
          const locationWithoutTermDate = data?.filter(
            (location) => location?.isDeleted !== true
          );
          if (data && locationWithoutTermDate?.length > 0) {
            const sortedData = locationWithoutTermDate?.sort(
              (a, b) => a.locationNumber - b.locationNumber
            );
            setLocationData(sortedData);
            localStorage.setItem("locationCount", sortedData.length);
          } else {
            setLocationData(data);
            localStorage.setItem("locationCount", data.length);
          }
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchLocationRecords();
    };
    fetch();
  }, [props.selectedPracticeId, deletedLocationId, updatedLocationVal]);
  const fetchContactInformation = () => {
    fetch(base_url + `/api/v1/practices/${selectedPracticeIdByAdmin}/contact`, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        SetContactInfoData(data);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      fetchContactInformation();
    };
    fetch();
  }, [props.selectedPracticeId]);

  const GetTitleDetails = () => {
    fetch(base_url + "/api/v1/contact/GetTitleDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTitleData(data?.titleTypeList || []);
        setPrefixData(data?.prefixList || [])
      });
  };

  const getSuffixDetails = () => {
    fetch(base_url + "/api/v1/contact/GetSuffixData", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSuffixData(data);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      GetTitleDetails();
      getSuffixDetails();
    };
    fetch();
  }, []);
  const { data = [], loading } = useFetch(
    `/api/v1/practices/PracticeSummary?practiceManagerId=${props.pmId}&isSQCNAdmin=${props.isSqcnRole}`
  );
  const [providerData, setProviderData] = useState([]);
  const providerListDataDetailsForCount = () => {
    fetch(
      base_url1 +
      `/api/v1/providers/ProviderDataByPracticeId?practiceId=${selectedPracticeIdByAdmin}&practicemanagerid=${props.pmId}&isSQCNAdmin=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          console.log("data1", data);
          setProviderData(data);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      providerListDataDetailsForCount();
    };
    fetch();
  }, [selectedPracticeIdByAdmin, updatedProviderVal]);
  const { data: GetAllPracticeDataById } = useFetch(
    `/api/v1/practices/GetAllPracticeDataById?practiceid=${selectedPracticeIdByAdmin}`
  );
  return (
    <SqcnSideMenu pmName={props?.pmName} headerText={""} loading={loading}>
      <EditPracticeBanner
        practiceListData={data}
        practiceNameLegal={GetAllPracticeDataById?.practiceNameLegal}
        editPractice={isEditPractice}
        // practiceListData1={GetAllPracticeDataById?.practiceAssignment}
        providerData={providerData}
      />
      <div className="manage-outlet">
        <Accordions
          content={<EditPracticeInfo selectedPracticeIdByAdmin={selectedPracticeIdByAdmin} />}
          title={"Practice Information"}
        />
        <Accordions
          content={
            <AdminLocationList
              locationListData={locationData}
              selectedPracticeIdByAdmin={selectedPracticeIdByAdmin}
              practiceManagerId={props.pmId}
              setDeletedLocationId={setDeletedLocationId}
              setUpdatedLocationVal={setUpdatedLocationVal}
              setLocationData={setLocationData}
            />
          }
          title={"Location"}
        />
        <Accordions
          content={<PracticeContactInformation data={contactInfoData} selectedPracticeId={selectedPracticeIdByAdmin}
            titleData={titleData} suffixData={suffixData}
            prefixData={prefixData} setContactInformationData={setContactInformationData}
            fetchContactInformation={fetchContactInformation} />}
          title={"Contact Information"}
        />

        <Accordions content={<ProviderListPageAdmin
          providerList={GetAllPracticeDataById?.practiceAssignment}
          practiceTitle={props.practiceTitle}
          selectedPracticeId={selectedPracticeIdByAdmin}
          practiceManagerId={props.pmId}
          isAdmin={props.isSqcnRole}
          PersonRole={props.PersonRole}
          setUpdatedProviderVal={setUpdatedProviderVal}
          titleData={titleData}
          prefixData={prefixData}
        />} title={"Provider"} />

        <div className="add-location-footer " id="footer">
          <div className="add-location-buttons">
            <Link to={"/manage-sqcn-practice"}>
              <button className="white-btn" data-testid="CancelButton">
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </div>
    </SqcnSideMenu>
  );
};
