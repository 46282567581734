/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Table } from "react-bootstrap";
import { Button } from "../../UI/Atoms/Button";
import { uniqueObjectsFilterById } from "utils";
import Checkbox from "../../UI/Atoms/Checkbox";
import cookies from "js-cookie";
import useOutsideClick from "./useOutsideClick";
import TooltipsIcon from "./images/Vector-Icon.svg";
import leftToRightIcon from "./images/left-move-Icon.svg";
import rightToLeftIcon from "./images/right-move-Icon.svg";
import SortIcon from "./images/Sort_arrow.svg";
import AddIcon from "../PracticeApprovalBoard/images/updatedIcon.svg";
import ApprovalcloseIcon from "../PracticeApprovalBoard/images/closeIcon.svg";
// import RejectAddIcon from "../PracticeApprovalBoard/images/reject-Icon.svg";
// import RejectcloseIcon from "../PracticeApprovalBoard/images/close-icon.svg";
import "./_style.scss";
import ConfirmModal from "../../UI/Atoms/ConfirmModal";
import ReviewAlert from "../../UI/Atoms/ReviewAlert";
import SqcnAdminAccess from "./SqcnAdminAccess";
import RemovePpdAccess from "./RemovePpdAccess";
import RemoveSqcnAccess from "./RemoveSqcnAccess";

const ManagePpdAcess = ({
  mdofficeTooltip,
  superGroupTooltip,
  allLocationsTooltip,
  provisionLocationsTooltip
}) => {
  const ref = useRef();
  const sltRef = useRef(null);
  const sltPrevRef = useRef(null);
  const [isCheck, setIsCheck] = useState([]);
  const [superGroupData, setSuperGroupData] = useState([]);
  const [superGroupLocationData, setSuperGroupLocationData] = useState([]);
  const [provisionLocationData, setProvisionLocationData] = useState([]);
  const [mdOfficeListData, setMdofficeListData] = useState([]);
  const [personMdofficeId, setpersonMdofficeId] = useState([]);
  const [mdOfficevalue, setmdOfficeValue] = useState("");
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [filteredAllLocationData, setFilteredAllLocationData] = useState([]);
  const [filteredProvisionLocation, setFilteredProvisionLocation] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [updateModal, setUpdateModal] = useState(false);
  const [providePpdAccess, setProvidePpdAccess] = useState(false);
  const [removePpdAccess, setRemovedPpdAccess] = useState(false);
  const [provideSqcnAccess, setProvideSqcnAccess] = useState(false);
  const [sqcnReject, setSqcnReject] = useState(false);
  const [sqcnAdminRemove, setSqcnAdminRemove] = useState(false);
  const [ppdUserRemove, setPpdUserRemove] = useState(false);
  const [ppdReject, setPpdReject] = useState(false);
  const [alreadyPpd, setAlreadyPpd] = useState(false);
  const [providePpdAccessError, setProvidePpdAccessError] = useState(false);
  const [ppdRemoved, setPpdRemoved] = useState(true);
  const [searchLocationName, setSearchLocationName] = useState("");
  const [searchProvisionLocation, setSearchProvisionLocation] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedProviionOption, setSelectedPovisionOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationSelectAll, setIsLocationSelectAll] = useState(true);
  const [isProvisionSelectAll, setIsProvisionSelectAll] = useState(true);
  // const [lastClicked, setLastClicked] = useState(-1);

  useOutsideClick(ref, () => {
    if (filterDropdown) {
      setFilterDropdown(false);
    }
  });

  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  console.log("locv", superGroupLocationData, provisionLocationData, mdOfficeListData);

  const fetchMdofficeData = () => {
    fetch(
      base_url +
      "/api/v1/practices/GetsMdofficeIdList",
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          data?.sort((a, b) => a?.mdofficeId?.localeCompare(b?.mdofficeId));
          setMdofficeListData(data);
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchMdofficeData();
    };
    fetch();
  }, []);


  const fetchSuperGroups = () => {
    fetch(
      base_url +
      "/api/v1/practices/GetAllSuperGroups",
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          data?.sort((a, b) => a?.supergroupCode?.localeCompare(b?.supergroupCode));
          setSuperGroupData(data);
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchSuperGroups();
    };
    fetch();
  }, []);

  const fetchAllLocations = () => {
    fetch(
      base_url +
      "/api/v1/practices/GetAllLocations",
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          data?.sort((a, b) => a?.locationName?.localeCompare(b?.locationName));
          const provisionLocationIds = provisionLocationData?.map(locationItem => locationItem?.id);
          const remainingLocations = data?.filter(location =>
            !provisionLocationIds.includes(location?.id)
          );
          setSuperGroupLocationData(remainingLocations);
        }
      });
  };

  useEffect(() => {
    const fetch = async () => {
      if (isCheck?.length > 0) {
        await fetchSuperGroupLocation();
      } else {
        await fetchAllLocations();
      }
    };
    fetch();
  }, [isCheck]);

  const fetchSuperGroupLocation = () => {
    fetch(
      base_url +
      `/api/v1/practices/GetAllLocations?supergroupid=${isCheck}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          data?.sort((a, b) => a?.locationName?.localeCompare(b?.locationName));
          const provisionLocationIds = provisionLocationData?.map(locationItem => locationItem?.id);
          const remainingLocations = data?.filter(location =>
            !provisionLocationIds.includes(location?.id)
          );
          setSuperGroupLocationData(remainingLocations);
        }
      });
  };

  const fetchProvisionLocation = () => {
    fetch(
      base_url +
      `/api/v1/practices/GetsProvisionedLocationsbyMdofficeId?mdofficeId=${personMdofficeId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          setAlreadyPpd(true);
          data?.sort((a, b) => a?.locationName?.localeCompare(b?.locationName));
          setProvisionLocationData(data);
          const provisionLocationIds = data?.map(locationItem => locationItem?.id);
          const remainingLocations = superGroupLocationData?.filter(location =>
            !provisionLocationIds.includes(location?.id)
          );
          setSuperGroupLocationData(remainingLocations);
        } else {
          setAlreadyPpd(false);
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchProvisionLocation();
    };
    fetch();
  }, [personMdofficeId]);

  const handleClickAlert = () => {
    setProvidePpdAccess(false);
    setSqcnReject(false);
    setProvideSqcnAccess(false);
    setPpdReject(false);
    setSqcnAdminRemove(false);
    setPpdUserRemove(false);
  };

  const handleClick = (e, id) => {
    console.log("idf", id)
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  console.log("isc", isCheck, providePpdAccessError);
  // const handleKeyDown = (e) => {
  //   console.log("bbb", e.shiftKey, e.key);
  //   if (e.shiftKey && e.key === "ArrowDown") {
  //     console.log("so", selectedOption, selectedProviionOption);
  //     if (selectedOption === 0 || selectedOption && superGroupLocationData?.length - 1 > selectedOption) {
  //       let currentIndex = selectedOption + 1;
  //       console.log("jjjs", currentIndex);
  //       const value = [...superGroupLocationData];
  //       value[currentIndex].selected = true;
  //       setSuperGroupLocationData(value);
  //       setSelectedOption(currentIndex);
  //       setSelectedPovisionOption(null);
  //       if (sltRef.current) {
  //         const item = sltRef.current.children[currentIndex];
  //         sltRef.current.scrollTop = item?.offsetTop - sltRef.current?.offsetTop
  //       }
  //       e.preventDefault();
  //     }
  //     console.log("splo", provisionLocationData?.length, selectedProviionOption);
  //     if (selectedProviionOption === 0 || selectedProviionOption && provisionLocationData?.length - 1 > selectedProviionOption) {
  //       let currentIndex = selectedProviionOption + 1;
  //       console.log("jjjp", currentIndex);
  //       const value = [...provisionLocationData];
  //       value[currentIndex].selected = true;
  //       setProvisionLocationData(value);
  //       setSelectedPovisionOption(currentIndex);
  //       setSelectedOption(null);
  //       if (sltPrevRef.current) {
  //         const item = sltPrevRef.current.children[currentIndex];
  //         sltPrevRef.current.scrollTop = item?.offsetTop - sltPrevRef.current?.offsetTop
  //         // scrollIntoView({behavior:"smooth", block:"center"})
  //       }
  //       e.preventDefault();
  //     }
  //   } else if (e.shiftKey && e.keyCode === 38 && selectedOption >-1) {
  //     if (selectedOption === 0 || selectedOption && superGroupLocationData?.length - 1 > selectedOption) {
  //       let currentIndex = selectedOption - 1;
  //       const value = [...superGroupLocationData];
  //       value[currentIndex].selected = false;
  //       value[selectedOption].selected = false;
  //       setSuperGroupLocationData(value);
  //       setSelectedOption(currentIndex);
  //       setSelectedPovisionOption(null);
  //       if (sltRef.current) {
  //         const item = sltRef.current.children[currentIndex];
  //         sltRef.current.scrollTop = item?.offsetTop - sltRef.current?.offsetTop
  //       }
  //       e.preventDefault();
  //     }
  //     if(selectedProviionOption === 0 || selectedProviionOption && provisionLocationData?.length - 1 > selectedProviionOption) {
  //       let currentIndex = selectedProviionOption - 1;
  //       console.log("jjjp", currentIndex);
  //       const value = [...provisionLocationData];
  //       value[currentIndex].selected = false;
  //       setProvisionLocationData(value);
  //       setSelectedPovisionOption(currentIndex);
  //       setSelectedOption(null);
  //       if (sltPrevRef.current) {
  //         const item = sltPrevRef.current.children[currentIndex];
  //         sltPrevRef.current.scrollTop = item?.offsetTop - sltPrevRef.current?.offsetTop
  //         // scrollIntoView({behavior:"smooth", block:"center"})
  //       }
  //       e.preventDefault();
  //     }
  //   }
  // }
  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown)
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown)
  //   }
  // }, [superGroupLocationData, provisionLocationData])

  const handleItemSelectLocation = (data, index) => {
    setFilteredAllLocationData((prevData) => prevData?.map((item) => item?.id === data?.id ?
      { ...item, selected: !item?.selected } : { ...item }));
    setSelectedOption(index);
    setSelectedPovisionOption(-1);
    let allSelectedData = filteredProvisionLocation.map((item)=>{
      return {
        ...item,
        selected: false
      }
    })
    setFilteredProvisionLocation(allSelectedData);
  }
  const handleKeyLocation = (e)=> {
    if((e.ctrlKey || e.shiftKey) && (e.keyCode===40|| e.key === "ArrowDown") && selectedOption >-1 && ( (selectedOption+1) <filteredAllLocationData.length)) {
      setFilteredAllLocationData((prevData) => prevData?.map((item,index) => index === selectedOption+1 ?
      { ...item, selected: true } : { ...item }));
      setSelectedOption((previousSelection)=>previousSelection+1);
      setSelectedPovisionOption(-1);
      let allSelectedData = filteredProvisionLocation.map((item)=>{
        return {
          ...item,
          selected: false
        }
      })
      setFilteredProvisionLocation(allSelectedData)
    }
 
    if((e.ctrlKey || e.shiftKey) && (e.keyCode===38 || e.key === "ArrowUp") && selectedOption >-1) {
      setFilteredAllLocationData((prevData) => prevData?.map((item,index) => index === selectedOption ?
      { ...item, selected: false } : { ...item }));
      setSelectedOption((previousSelection)=>previousSelection-1);
      setSelectedPovisionOption(-1);
      console.log("sI", selectedOption);
      let allSelectedData = filteredProvisionLocation.map((item)=>{
        return {
          ...item,
          selected: false
        }
      })
      setFilteredProvisionLocation(allSelectedData)
    }
  }
  const handleKeyProvison = (e)=> {
    if((e.ctrlKey || e.shiftKey) && (e.keyCode===40|| e.key === "ArrowDown") && selectedProviionOption >-1 && ( (selectedProviionOption+1) <filteredProvisionLocation.length)) {
      setFilteredProvisionLocation((prevData) => prevData?.map((item,index) => index === selectedProviionOption+1 ?
      { ...item, selected: true } : { ...item }));
      setSelectedPovisionOption((previousSelection)=>previousSelection+1);
      setSelectedOption(-1);
      let allSelectedData = filteredAllLocationData.map((item)=>{
        return {
          ...item,
          selected: false
        }
      })
      setFilteredAllLocationData(allSelectedData)
    }
 
    if((e.ctrlKey || e.shiftKey) && (e.keyCode===38 || e.key === "ArrowUp") && selectedProviionOption >-1) {
      setFilteredProvisionLocation((prevData) => prevData?.map((item,index) => index === selectedProviionOption ?
      { ...item, selected: false } : { ...item }));
      setSelectedPovisionOption((previousSelection)=>previousSelection-1);
      setSelectedOption(-1);
      let allSelectedData = filteredAllLocationData.map((item)=>{
        return {
          ...item,
          selected: false
        }
      })
      setFilteredAllLocationData(allSelectedData)
    }
  }
  const handleRightMove = () => {
    const selectedLocItems = filteredAllLocationData?.filter((item) =>
      item?.selected).map((item) => ({ ...item, selected: false }))
    console.log("sI", selectedLocItems);
    let selectedIds = selectedLocItems.map((item) => item?.id);
    setProvisionLocationData([...provisionLocationData, ...selectedLocItems]);
    setSuperGroupLocationData(superGroupLocationData?.filter((item) => !item?.selected && !selectedIds.includes(item.id)))
    setPpdRemoved(true);
  }
  const handleItemProvisionLoc = (data, index) => {
    setFilteredProvisionLocation((prevData) => prevData?.map((item) => item?.id === data?.id ?
      { ...item, selected: !item?.selected } : { ...item }));
    setSelectedPovisionOption(index);
    setSelectedOption(-1);
    let allSelectedData = filteredAllLocationData.map((item)=>{
      return {
        ...item,
        selected: false
      }
    })
    setFilteredAllLocationData(allSelectedData);
  }
  const handleLeftMove = () => {
    const selectedLocItems = filteredProvisionLocation?.filter((item) =>
      item?.selected).map((item) => ({ ...item, selected: false }))
    console.log("sI", selectedLocItems);
    let selectedIds = selectedLocItems.map((item) => item?.id);
    setSuperGroupLocationData([...superGroupLocationData, ...selectedLocItems]);
    setProvisionLocationData(provisionLocationData?.filter((item) => !item?.selected && !selectedIds.includes(item.id)));
    const provsionLocData = provisionLocationData?.filter((item) => !item?.selected && !selectedIds.includes(item.id))
    console.log("prl", provisionLocationData?.length - 1 > selectedProviionOption, selectedProviionOption, provisionLocationData?.length, alreadyPpd)
    if (provsionLocData.length === 0) {
      setPpdRemoved(false);
    } else {
      setPpdRemoved(true);
    }
  }
  const onChange = (event) => {
    setmdOfficeValue(event.target.value);
    if (event.target.value?.length < 3) {
      setpersonMdofficeId([]);
      setProvisionLocationData([]);
      setPpdReject(false);
      setProvidePpdAccess(false);
      setPpdRemoved(true);
      setProvideSqcnAccess(false);
      setSqcnAdminRemove(false);
      setSqcnReject(false);
      fetchAllLocations();
      setIsCheck([]);
      setPpdUserRemove(false);
    }
    setFilterDropdown(true);
  };
  const onSearch = (searchTerm, searchName) => {
    setpersonMdofficeId(searchTerm);
    const result = searchTerm + " - " + searchName;
    setmdOfficeValue(result);
    console.log("on", searchTerm, personMdofficeId);
  };
  const personData = mdOfficeListData?.filter((item) => item?.mdofficeId === personMdofficeId);
  console.log("peda", personData);

  const handleSubmit = async () => {
    setIsLoading(true);
    const payLoad = uniqueObjectsFilterById(provisionLocationData)?.map((item) => ({
      ...item
    }));
    console.log("pay", payLoad);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(payLoad),
    };
    fetch(
      base_url + `/api/v1/practices/ProvidePPDAccess?mdofficeid=${personData[0]?.mdofficeId}&personid=${personData[0]?.id}&roletypeid=1`,
      requestOptions
    ).then((response) => {
      console.log("rep", response, response.status);
      if (response.status == 200) {
        setUpdateModal(false);
        setPpdRemoved(true);
        setProvidePpdAccess(true);
        setPpdReject(false);
        // setPpdRemoved(false);
        fetchMdofficeData();
        fetchProvisionLocation()
        setIsLoading(false);
        if(ppdRemoved) {
          setRemovedPpdAccess(false);
        } else {
          setRemovedPpdAccess(true);
        }
      } else {
        setProvidePpdAccessError(true);
        setIsLoading(false);
      }
    });
  };

  const handleSearchLocationNameChange = (e) => {
    setSearchLocationName(e.target.value);
  };
  const handleSearchLocationChange = (e) => {
    setSearchProvisionLocation(e.target.value);
  };

  const filterData = (item) => {
    const removeSpacesAndSpecialChars = (str) => str.toLowerCase()?.replace(/\s/g, "")?.replace(/[^a-z0-9-]/g, "");
    const locationName = removeSpacesAndSpecialChars(item?.locationName);
    const address = removeSpacesAndSpecialChars(`${item?.street1} ${item?.street2 ? item?.street2 : ""} ${item?.street3 ? item?.street3 : ""}, ${item?.city}, ${item?.state} ${item?.zipCode}`);
    const searchTermLowerCase = removeSpacesAndSpecialChars(searchLocationName);
    const locationNameMatches = !searchLocationName || locationName.includes(searchTermLowerCase);
    const addressMatches = !searchLocationName || address.includes(searchTermLowerCase);
    return locationNameMatches || addressMatches;
  };
  useEffect(() => {
    const newFilteredData = superGroupLocationData?.filter(filterData);
    const  uniqueObjectsGroupLocationData = uniqueObjectsFilterById(newFilteredData);
    setFilteredAllLocationData(uniqueObjectsGroupLocationData);
  }, [searchLocationName, superGroupLocationData]);

  const handleLocationSelectAll = () => {
    let allSelectedData = filteredAllLocationData.map((item)=>{
      return {
        ...item,
        selected:isLocationSelectAll ? true : false
      }
    })
    setIsLocationSelectAll(!isLocationSelectAll);
    setFilteredAllLocationData(allSelectedData)
  };

  const handleProvisonSelectAll = () =>{
    let allSelectedData = filteredProvisionLocation.map((item)=>{
      return {
        ...item,
        selected:isProvisionSelectAll ? true : false
      }
    })
    setIsProvisionSelectAll(!isProvisionSelectAll);
    setFilteredProvisionLocation(allSelectedData)
  }

  const filteredProvisionData = (item) => {
    const removeSpacesAndSpecialChars = (str) => str.toLowerCase()?.replace(/\s/g, "")?.replace(/[^a-z0-9-]/g, "");
    const locationName = removeSpacesAndSpecialChars(item?.locationName);
    const address = removeSpacesAndSpecialChars(`${item?.street1} ${item?.street2 ? item?.street2 : ""} ${item?.street3 ? item?.street3 : ""}, ${item?.city}, ${item?.state} ${item?.zipCode}`);
    const searchTermLowerCase = removeSpacesAndSpecialChars(searchProvisionLocation);
    const locationNameMatches = !searchProvisionLocation || locationName.includes(searchTermLowerCase);
    const addressMatches = !searchProvisionLocation || address.includes(searchTermLowerCase);
    return locationNameMatches || addressMatches;
  };
  useEffect(() => {
    const newFilteredData = provisionLocationData?.filter(filteredProvisionData);
    const  uniqueObjectsProvisonLocationData = uniqueObjectsFilterById(newFilteredData);
    setFilteredProvisionLocation(uniqueObjectsProvisonLocationData);
  }, [searchProvisionLocation, provisionLocationData]);

  const sortAllLocationData = () => {
    const sortedData = [...filteredAllLocationData];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return b.locationName.localeCompare(a.locationName);
      } else {
        return a.locationName.localeCompare(b.locationName);
      }
    });
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setSuperGroupLocationData(sortedData);
  };

  const sorProvisionLocationData = () => {
    const sortedData = [...filteredProvisionLocation];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return b.locationName.localeCompare(a.locationName);
      } else {
        return a.locationName.localeCompare(b.locationName);
      }
    });
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setProvisionLocationData(sortedData);
  };

  return (
    <React.Fragment>
      {providePpdAccess &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText={`This person is successfully ${!removePpdAccess ? "provisioned" : "removed"} with PPD Access for the below locations`}
          ALertHandleClick={handleClickAlert}
        />
      }
      {/* {ppdReject &&
        <ReviewAlert
          ApprovalIcon={RejectAddIcon}
          variant="warning"
          ApprovalcloseIcon={RejectcloseIcon}
          AlertText="This person has SQCN Admin access. Please choose a different user for provisioning PPD access."
          ALertHandleClick={handleClickAlert}
        />
      } */}
      {provideSqcnAccess &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="This person is successfully added as SQCN admin"
          ALertHandleClick={handleClickAlert}
        />
      }
      {sqcnAdminRemove &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="This person is successfully removed as SQCN admin"
          ALertHandleClick={handleClickAlert}
        />
      }
      {ppdUserRemove && !provideSqcnAccess && !sqcnAdminRemove &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="This person is successfully added as SQCN admin"
          ALertHandleClick={handleClickAlert}
        />
      }
      <div className="manage-outlet">
        <Row>
          <Col md={6}>
            <Form autoComplete="off" data-testid="form">
              <Form.Group className="mb-2 search-npi">
                <Form.Label className="search-ppd-label">
                  MDOffice ID of the Person who needs access to the PPD&nbsp;
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="top"
                    overlay={mdofficeTooltip}
                    data-testid="stillAtPracticeTooltip"
                  >
                    <img
                      src={TooltipsIcon}
                      alt="Tooltip Icon"
                      className="tooltip-icon"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  className="search-ppd"
                  value={mdOfficevalue}
                  onChange={onChange}
                  data-testid="LocationName"
                />
              </Form.Group>
              {filterDropdown && mdOfficevalue && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  className="dropdown locations-result"
                  data-testid="dropdown-row-1"
                  onClick={() => setFilterDropdown(!filterDropdown)}
                  ref={ref}
                >
                  {mdOfficeListData
                    ?.filter((item) => {
                      const searchTerm = mdOfficevalue && mdOfficevalue?.length > 0 && mdOfficevalue?.toLowerCase();
                      const spltyName = item?.mdofficeId?.toLowerCase();
                      const spltyFirstName = item?.firstName?.toLowerCase();
                      const spltyLastName = item?.lastName?.toLowerCase();
                      return (
                        (searchTerm && spltyName?.startsWith(searchTerm)) ||
                        spltyName === searchTerm ||
                        (searchTerm && spltyFirstName?.startsWith(searchTerm)) || spltyFirstName === searchTerm ||
                        (searchTerm && spltyLastName?.startsWith(searchTerm)) || spltyLastName === searchTerm
                      );
                    })
                    .sort((a, b) => a.mdofficeId?.localeCompare(b.mdofficeId))
                    .map((item) => (
                      <button
                        onClick={() => onSearch(item?.mdofficeId, `${item?.firstName} ${item?.lastName}`)}
                        className="dropdown-row"
                        key={item?.id}
                        data-testid={`dropdown-row${item.id}`}
                      >
                        {item?.mdofficeId} - {`${item?.firstName} ${item?.lastName}`}
                      </button>
                    ))}
                </div>
              )}
            </Form>
          </Col>
          <Col md={6} className="mt-2">
            <SqcnAdminAccess
              mdOfficevalue={mdOfficevalue}
              personData={personData}
              sqcnReviewReject={() => setSqcnReject(true)}
              sqcnReviewRemove={() => setSqcnAdminRemove(true)}
              sqcnAccessApprove={() => setProvideSqcnAccess(true)}
              fetchMdofficeData={fetchMdofficeData}
              buttonText={personData &&
                personData?.length > 0 &&
                personData[0]?.accessType === 13 ? "Remove SQCN Admin Access" : "Provide SQCN Admin Access"}
              mdOfficeId={personData[0]?.mdofficeId}
              id={personData[0]?.id}
              setFilteredProvisionLocation={setFilteredProvisionLocation}
            />
            {personData && personData.length > 0 && personData[0]?.accessType === 13 ? (
              <Button
                text="Provide PPD Access"
                className="manage-ppd-button"
                disabled={mdOfficevalue?.length < 1 && personData[0]?.length > 0}
                type="submit"
                onClick={() => setPpdReject(true)}
              />
            ) : (
              <Button
                text={!ppdRemoved ?
                  "Remove PPD Access" : "Provide PPD Access"
                }
                className="manage-ppd-button"
                disabled={mdOfficevalue?.length < 1
                }
                type="submit"
                onClick={() => {
                  setUpdateModal(true);
                  setProvidePpdAccessError(false);
                }}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={3}>
            <div className="manage-ppd-table table-responsive">
              <Table data-testid="providerlisttable">
                <thead>
                  <tr>
                    <th>Supergroup&nbsp;
                      <OverlayTrigger
                        trigger={["hover", "hover"]}
                        placement="top"
                        overlay={superGroupTooltip}
                        data-testid="stillAtPracticeTooltip"
                      >
                        <img
                          src={TooltipsIcon}
                          alt="Tooltip Icon"
                          className="tooltip-icon"
                        />
                      </OverlayTrigger></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="location_data_list1" data-testid="ProviderData">
                  {superGroupData?.length > 0 && superGroupData?.map((item) => (
                    <tr key={item?.id}>
                      <td className="supergroup_check">
                        <Checkbox
                          key={item?.id}
                          type="checkbox"
                          id="1"
                          handleClick={(e) => handleClick(e, item?.id)}
                          isChecked={isCheck?.includes(item?.id)}
                        />{item?.supergroupCode}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={4}>
            <div className="manage-ppd-table table-responsive">
              <Table data-testid="providerlisttable">
                <thead>
                  <tr>
                    <th className="d-flex align-items-center justify-content-between">
                      <div>
                        All Locations&nbsp;
                        <OverlayTrigger
                          trigger={["hover", "hover"]}
                          placement="top"
                          overlay={allLocationsTooltip}
                          data-testid="stillAtPracticeTooltip"
                        >
                          <img
                            src={TooltipsIcon}
                            alt="Tooltip Icon"
                            className="tooltip-icon"
                          />
                        </OverlayTrigger>
                      </div>
                      
                      <button className="btn_select_all_location"
                        onClick={() => handleLocationSelectAll()}>
                        {isLocationSelectAll ? "Select All" : "Deselect All"}
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <td className="search_column ppd">
                      <Form.Group className="search-npi loc" controlId="formGroupLocationName">
                        <Form.Control
                          type="text"
                          name="locationName"
                          placeholder="Search"
                          autoComplete="off"
                          className="search-input_ppd"
                          value={searchLocationName}
                          onChange={handleSearchLocationNameChange}
                          data-testid="LocationName"
                        />
                      </Form.Group>
                      <button
                        className="sort-icon_class"
                        type="button"
                        onClick={sortAllLocationData}
                      >
                        <img
                          src={SortIcon}
                          alt="Sort"
                          className="sort_image"
                        />
                      </button>
                    </td>
                  </tr>
                </thead>
                <tbody className="location_data_list" ref={sltRef}>
                  {filteredAllLocationData.map((item, index) => (
                      <tr key={item?.id}>
                        <td>
                          <div className={item?.selected ? "selectedClass" : "unselectedClass"}>
                            <button
                              className="selected_location"
                              type="button" onClick={() => handleItemSelectLocation(item, index)} onKeyDown={(e)=>handleKeyLocation(e)}>
                              <span className="locationname_text">{item?.locationName}</span><br></br>
                              <span className="address_text">
                                {`${item?.street1}
                            ${item?.street2 ? item?.street2 : ""}
                            ${item?.street3 ? item?.street3 : ""},
                            ${item?.city}, ${item?.state} ${item?.zipCode}`}
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={1}>
            <button
              className="move_button left_right"
              type="button"
              onClick={() => handleRightMove()}>
              <img src={rightToLeftIcon} alt="Right to Left" />
            </button>
            <button
              className="move_button"
              type="button"
              onClick={() => handleLeftMove()}>
              <img src={leftToRightIcon} alt="Left to Right" />
            </button>
          </Col>
          <Col md={4}>
            <div className="manage-ppd-table table-responsive">
              <Table data-testid="providerlisttable">
                <thead>
                  <tr>
                    <th className="d-flex align-items-center justify-content-between">
                      <div>
                        Provisioned Locations&nbsp;
                        <OverlayTrigger
                          trigger={["hover", "hover"]}
                          placement="top"
                          overlay={provisionLocationsTooltip}
                          data-testid="stillAtPracticeTooltip"
                        >
                          <img
                            src={TooltipsIcon}
                            alt="Tooltip Icon"
                            className="tooltip-icon"
                          />
                        </OverlayTrigger>
                        <span className="provision_count">{filteredProvisionLocation.length}</span>
                      </div>
                      <button className="btn_select_all_provision"
                        onClick={() => handleProvisonSelectAll()}>
                        {isProvisionSelectAll ? "Select All" : "Deselect All"}
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <td className="search_column ppd">
                      <Form.Group className="search-npi loc" controlId="formGroupLocationName">
                        <Form.Control
                          type="text"
                          name="locationName"
                          placeholder="Search"
                          autoComplete="off"
                          className="search-input_ppd"
                          value={searchProvisionLocation}
                          onChange={handleSearchLocationChange}
                          data-testid="LocationName"
                        />
                      </Form.Group>
                      <button
                        className="sort-icon_class"
                        type="button"
                        onClick={sorProvisionLocationData}
                      >
                        <img
                          src={SortIcon}
                          alt="Sort"
                          className="sort_image"
                        />
                      </button>
                    </td>
                  </tr>
                </thead>
                <tbody className="location_data_list" ref={sltPrevRef}>
                  {filteredProvisionLocation?.map((item, index) => (
                    <tr key={item?.id}>
                      <td>
                        <div className={item?.selected ? "selectedClass" : "unselectedClass"}>
                          <button
                            className="selected_location"
                            type="button" onClick={() => handleItemProvisionLoc(item, index)} onKeyDown={(e)=>handleKeyProvison(e)}>
                            <span className="locationname_text">{item?.locationName}</span><br></br>
                            <span className="address_text">
                              {`${item?.street1}
                            ${item?.street2 ? item?.street2 : ""}
                            ${item?.street3 ? item?.street3 : ""},
                            ${item?.city}, ${item?.state} ${item?.zipCode}`}
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <ConfirmModal
          updateModal={updateModal}
          updateModalClose={() => setUpdateModal(false)}
          handleSubmit={handleSubmit}
          bodyText={`Are you sure we can ${ppdRemoved ? "provide" : "remove"} PPD access for the locations`}
          headerText={`Confirm ${ppdRemoved ? "Provide" : "Remove"} PPD Access`}
          serverError={providePpdAccessError}
          isLoadingWaiting={isLoading}
        />
        <RemovePpdAccess
          updateModal={sqcnReject}
          updateModalClose={() => setSqcnReject(false)}
          bodyText={"This person is already has a PPD access."}
          secondSentence={"Do you wish to provide SQCN admin access"}
          headerText=""
          personData={personData}
          fetchMdofficeData={fetchMdofficeData}
          ppdUserRemoved={() => setPpdUserRemove(true)}
          emptyProvisionLocation={() => setProvisionLocationData([])}
        />
        <RemoveSqcnAccess
          updateModal={ppdReject}
          updateModalClose={() => setPpdReject(false)}
          bodyText={"This person is already has a SQCN admin access."}
          secondSentence={"Do you wish to provide PPD access"}
          headerText=""
          personData={personData}
          fetchMdofficeData={fetchMdofficeData}
          handlePpdSubmit={handleSubmit}
          provisionAddLocationData={provisionLocationData}
          isLoadingWait={isLoading}
        />
      </div>
    </React.Fragment>
  );
};

export default ManagePpdAcess;
