import React from "react";
import("./_style.scss");

function Title({ title }) {
  return (
    <h4 className="practice-name" data-testid="practiceName">
      {title}
    </h4>
  );
}

export default Title;
