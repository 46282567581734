import React from "react";
import IconPrevious from "../../../../../../images/Icon-previous.svg";
import IconNext from "../../../../../../images/Icon-next.svg";

const Pagination2 = ({
  currentPage,
  totalPages,
  setCurrentPage,
  isCheckAll,
  setIsCheckAll
}) => {
  const nextPage = () => {
    if (currentPage !== totalPages) setCurrentPage(currentPage + 1);
    if (isCheckAll) {
      setIsCheckAll(true);
    }
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <>
      <div className="pagination-wrapper">
        <ul className="provider-pagination">
          <li className="page-item">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              data-testid="prevpage"
            >
              <img src={IconPrevious} alt="Previous Icon" />
            </button>
          </li>
          <li data-testid="currentpage">
            {currentPage + " / " + totalPages} pages
          </li>
          <li className="page-item">
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              data-testid="nextpage"
            >
              <img src={IconNext} alt="Next Icon" />
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination2;
