/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import labels from "../../../../Static/Dictionary.json";
// import ErrorServer from "Components/Hooks/Error404";
import { Formik } from "formik";
import * as Yup from "yup";
import { convertBoolean,ALPHABETS_AND_SPECIAL_CHARS_REGEX } from "utils";
import cookies from "js-cookie";

import DatePickerIcon from "../images/datePickerIcon.svg";
import { Form } from "react-bootstrap";
import { useFetch } from "Components/Hooks/Fetch";
import DatePicker from "react-datepicker";
import { Row, Col } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { formatDate } from "Static/format";

import AddCareManager from "./AddCareManager";
export const AddPractice = ({
  selectedPracticeIdByAdmin,
  practiceInformationData,
  initialValues,
  setEditMode,
  careManagers,
  setPracticeInformationData,
  updateUser,
  setPracticeContractData
}) => {
  const [practiceMasterData, setPracticeMasterData] = useState(null);

  const [inputList, setInputList] = useState([]);

  const [supergroupId, setsupergroupId] = useState(null);

  // const [filterTin, setFilterTin] = useState(null);

  const [tinOptions, setTinOptions] = useState([]);
  const [tinApiOptions, setTinApiOptions] = useState([]);
  //const [teleHealthData, setTeleHealthData] = useState([]);

  const [teleHealthOptions, setTeleHealthOptions] = useState([]);

  const [emrOptions, setEmrOptions] = useState([]);

  const [marketsData, setMarketsData] = useState([]);
  const [practiceSpclData, setPracticeSpclData] = useState([]);
  const [practiceSubSpcl1Data, setPracticeSubSpcl1Data] = useState([]);
  const [practiceSubSpcl2Data, setPracticeSubSpcl2Data] = useState([]);
  const [practiceSubSpcl3Data, setPracticeSubSpcl3Data] = useState([]);
  const [tinInput, setTinInput] = useState("000000000");
  const [lowerAgeLimitDisable, setLowerAgeLimitDisable] = useState(false);
  const [lowerAgeLimitInvalid, setLowerAgeLimitInvalid] = useState(false);
  const lowerAgeLimitData = [
    {id:1, name: "Newborn"},
    {id:2, name: "No Age Limit"},
    {id:3, name: "Days"},
    {id:4, name: "Weeks"},
    {id:5, name: "Months"},
    {id:6, name: "Years"},
    {id:7, name: "N/A"},
  ];

  const { data: masterData } = useFetch(
    "/api/v1/practices/GetPracticeMasterData"
  );

  const { data: GetPracticeSpclData } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=3"
  );

  const { data: GetPracticeSubSpcl1Data } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=3"
  );

  const { data: GetPracticeSubSpcl2Data } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=3"
  );

  const { data: GetPracticeSubSpcl3Data } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=3"
  );

  const { data: GetAllSuperGroups } = useFetch(
    "/api/v1/practices/GetAllSuperGroups"
  );

  const { data: GetsAllPracticeGroups } = useFetch(
    "/api/v1/practices/GetsAllPracticeGroups"
  );

  const { data: teleHealth } = useFetch("/api/v1/contact/GetTeleHealthData");

  const { data: markets } = useFetch("/api/v1/contact/GetRSOData");

  useEffect(() => {
    if (markets) {
      setMarketsData(markets);
    }
  }, [markets]);

  useEffect(() => {
    if (teleHealth) {
      //setTeleHealthData(teleHealth);

      let localOptions = [];
      teleHealth.map((item) => {
        localOptions.push({ value: item, label: item });
      });

      setTeleHealthOptions(localOptions);
    }
  }, [teleHealth]);

  useEffect(() => {
    if (masterData) {
      setPracticeMasterData(masterData);
      let localOptions = [];
      masterData?.emRs.map((item) => {
        localOptions.push({ value: item.emr, label: item.emr });
      });
      setEmrOptions(localOptions);
    }
  }, [masterData]);

  useEffect(() => {
    if (GetPracticeSpclData) {
      const data = GetPracticeSpclData?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setPracticeSpclData(data);
    }
  }, [GetPracticeSpclData]);
  useEffect(() => {
    if (GetPracticeSubSpcl1Data) {
      const data = GetPracticeSubSpcl1Data?.sort((a, b) => a.name.localeCompare(b.name));
      setPracticeSubSpcl1Data(data);
    }
  }, [GetPracticeSubSpcl1Data]);
  useEffect(() => {
    if (GetPracticeSubSpcl2Data) {
      const data = GetPracticeSubSpcl2Data?.sort((a, b) => a.name.localeCompare(b.name));
      setPracticeSubSpcl2Data(data);
    }
  }, [GetPracticeSubSpcl2Data]);
  useEffect(() => {
    if (GetPracticeSubSpcl3Data) {
      const data = GetPracticeSubSpcl3Data?.sort((a, b) => a.name.localeCompare(b.name));
      setPracticeSubSpcl3Data(data);
    }
  }, [GetPracticeSubSpcl3Data]);

  useEffect(() => {
    if (GetsAllPracticeGroups?.length > 0) {
      // let tins = GetsAllPracticeGroups.filter((item) => {
      //   return item.supergroupId == filterTin;
      // });
      if (GetsAllPracticeGroups?.length > 0) {
        setTinApiOptions(GetsAllPracticeGroups);


        let localOptions = [];
        GetsAllPracticeGroups.map((item) => {
          // localOptions.push({ value: item.tin, label:item.tin });
          localOptions.push({
            value: item?.tin,
            label: item?.tin,
            practiceGroupId: item?.practiceGroupId,
            practiceGroupName: item?.practiceGroupName,
            tin: item?.tin,
            innovaccerTinname: item?.innovaccerTinname,
            supergroupId: item?.supergroupId,
          });
        });
        setTinOptions(localOptions);
      }
    }
  }, [GetsAllPracticeGroups]);

  // const getSpecialtyObject = (specialtyName, id) => {
  //   if (specialtyName) {
  //     const specialty = practiceMasterData?.specialities.filter(
  //       (item) => item.specialtyName === specialtyName
  //     );

  //     if (specialty.length > 0) {
  //       return {
  //         ...{ specialty: specialty[0] },
  //         ...{ specialtyTypeId: id, specialtyId: specialty[0].id },
  //       };
  //     }
  //     return {};
  //   }
  //   return {};
  // };
  const getSpecialtyByText = (specialtyName, id) => {
    if (
      practiceSpclData &&
      practiceSpclData.length > 0
    ) {
        const specialty = practiceSpclData?.filter((item) => item.name === specialtyName);
      if (specialty.length > 0) {
        return {
          ...{ specialtyTypeId: id, specialtyId: specialty[0]?.specialityId },
        };
      }
      return {};
    }
    return {};
  };
  const getSpecialty1ByText = (specialtyName, id) => {
    if (
      practiceSubSpcl1Data &&
      practiceSubSpcl1Data.length > 0
    ) {
      const specialty = practiceSubSpcl1Data.filter(
        (item) => item.name === specialtyName
      );
      if (specialty) {
        return {
          specialtyId: specialty[0]?.specialityId,
          specialtyTypeId: id,
        };
      }
    }
    return {};
  };
  const getSpecialty2ByText = (specialtyName, id) => {
    if (
      practiceSubSpcl2Data &&
      practiceSubSpcl2Data.length > 0
    ) {
      const specialty = practiceSubSpcl2Data.filter(
        (item) => item.name === specialtyName
      );
      if (specialty) {
        return {
          specialtyId: specialty[0]?.specialityId,
          specialtyTypeId: id,
        };
      }
    }
    return {};
  };
  const getSpecialty3ByText = (specialtyName, id) => {
    if (
      practiceSubSpcl3Data &&
      practiceSubSpcl3Data.length > 0
    ) {
      const specialty = practiceSubSpcl3Data.filter(
        (item) => item.name === specialtyName
      );
      if (specialty) {
        return {
          specialtyId: specialty[0]?.specialityId,
          specialtyTypeId: id,
        };
      }
    }
    return {};
  };

  const getEmrObject = (emrName) => {
    let emr = {};

    // const getSpecialtyObject = (specialtyName, id) => {
    //   if (specialtyName) {
    //     const specialty = practiceMasterData?.specialities.filter(
    //       (item) => item.specialtyName === specialtyName
    //     );

    //     if (specialty.length > 0) {
    //       return { ...{specialty:specialty[0]}, ...{ specialtyTypeId: id,specialtyId:specialty[0].id } };
    //     }
    //     return {};
    //   }
    //   return {};
    // };
    let groupData = practiceMasterData?.emRs.filter((item) => {
      return item.emr == emrName;
    });
    if (groupData?.length > 0) {
      emr = groupData[0];
    } else {
      emr = { id: 0, emr: emrName };
    }
    return emr;
  };

  const createSuperGroup = (supergroupId, innovaccerTinname, tin) => {
    let practiceGroup = {};

    let groupData = practiceMasterData.practiceGroups.filter((item) => {
      return item.supergroupId == supergroupId;
    });
    if (groupData?.length > 0) {
      practiceGroup = groupData[0];
    }
    let supergroup = GetAllSuperGroups.filter((item) => {
      return item.id == supergroupId;
    });



let id =0;
    if(tin) {
   let tinOption= tinOptions.filter((item) => {
      return item.value == tin;
    });


    if(tinOption?.length>0){
      id = tinOption[0].id;
    }
  }




    if (supergroup?.length > 0) {
      return {
        ...{ supergroup: supergroup[0] },
        ...practiceGroup,
        innovaccerTinname,
        tin,
        id:id,
      };
    }
  };

  const practiceInformationSchema = Yup.object().shape({
    reportingGroupId: Yup.string().required(
      "Reporting Group ID cannot be blank"
    ),
    superGroup: Yup.string().required("Super Group cannot be blank"),
    tinLocal: Yup.string().required("Tax Identification Number cannot be blank"),
    //primaryNpi: Yup.string().required("NPI cannot be blank"),
    //   .matches(/^[0-9]+$/, "Must be only digits"),
    //emRid: Yup.string().required("EMR cannot be blank"),
    specialty: Yup.string().required("Specialty cannot be blank"),
    telehealth: Yup.string().required("TeleHealth cannot be blank"),
    rsoName: Yup.string().required("Market Region cannot be blank"),
    practiceNameLegal: Yup.string().required(
      "Practice Name Legal cannot be blank"
    ),
    location1: Yup.string().required("Location1 Name cannot be blank"),

    contractFirstName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid First Name"),
    contractMiddleName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Middle Name"),
    contractLastName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Last Name"),
    // lowerAgeLimit: Yup.string().required(
    //   "Lower Age Limit for New Patients cannot be blank"
    // ),

    //tin: Yup.string().required("Tax Identification Number cannot be blank"),

    //   .max(10, "Practice NPI should be 10 digits")
    //   .matches(/^[0-9]+$/, "Must be only digits"),
    //  emr: Yup.string().required("EMR cannot be blank"),
    // lowerAgeLimit: Yup.string().required(
    //   "Lower Age Limit for New Patients cannot be blank"
    // ),

    //reportingGroupId: Yup.string().required("Reporting Group ID cannot be blank"),
    // supergroup: Yup.string().required("Super Group cannot be blank"),
    // tin: Yup.string().required("Tax Identification Number cannot be blank"),
    // primaryNpi: Yup.string().required("NPI cannot be blank"),
    //   .matches(/^[0-9]+$/, "Must be only digits"),
    emrLocal: Yup.string().required("EMR cannot be blank"),
    //specialty: Yup.string().required("Specialty cannot be blank"),
    //telehealth: Yup.string().required("TeleHealth cannot be blank"),

    //rsoName: Yup.string().required("Market Region cannot be blank"),
   InnovaccerTinname: Yup.string().required("Innovaccer name cannot be blank"),
   lowerAgeNumber: Yup.string().required("Age Limit cannot be blank"),
  });
  const [lowerAgeNumber, setLowerAgeNumber] = useState("");
  const [lowerAgeName, setLowerAgeName] = useState("");
  const onSubmitFun = (values) => {
    let ageLimit = (lowerAgeNumber === "" && (lowerAgeName === "Newborn" || lowerAgeName === "No Age Limit" || lowerAgeName === "N/A"));
    let ageLimitNos = (lowerAgeNumber !== "" && (lowerAgeName === "Days" || lowerAgeName === "Weeks" || lowerAgeName === "Months" || lowerAgeName === "Years"));
    if(values.practiceNameLegal !== "" && values.reportingGroupId &&
      values.supergroup !== "" && values.emrLocal !== "" &&
      values.specialty !== "" && values.telehealth !== "" &&
      values.location1 !== "" && values.rsoName !== "" &&
      values.InnovaccerTinname !== "" && values.tinLocal?.length === 9 &&
      (ageLimit || ageLimitNos || (lowerAgeNumber === "" && lowerAgeName == ""))
    ) {
    setEditMode(false);
    try {
      let specialties = [
        getSpecialtyByText(values.specialty, 3),
        getSpecialty1ByText(values.specialty1, 4),
        getSpecialty2ByText(values.specialty2, 5),
        getSpecialty3ByText(values.specialty3, 6),
      ];

      specialties =
        specialties.length > 0
          ? specialties.filter((value) => Object.keys(value).length !== 0)
          : [];
      // let specialties = [
      //   getSpecialtyObject(values.specialty, 3),
      //   getSpecialtyObject(values.specialty1, 4),
      //   getSpecialtyObject(values.specialty2, 5),
      //   getSpecialtyObject(values.specialty3, 6),
      // ];

      // specialties =
      //   specialties.length > 0
      //     ? specialties.filter((value) => Object.keys(value).length !== 0)
      //     : [];

      let careList = [];
      if (inputList.length > 0) {
        inputList.map((data) => {
          let list = {};
          list["mdofficeId"] = data?.mdofficeId || "";
          list["firstName"] = data?.firstName || "";
          list["lastName"] = data?.lastName || "";
          list["middleName"] = data?.middleName || "";
          list["suffixId"] = parseInt(data?.suffixId) || "";
          list["titleId"] = parseInt(data?.titleId) || "";
          list["prefixId"] = parseInt(data?.prefixId) || 0;
          list["personId"] = data?.personId;
          careList.push(list);
        });
      }
      let practiceGroup = {};
      let emr = {};
      if (supergroupId|| values.tinLocal) {
        practiceGroup = createSuperGroup(
          supergroupId,
          values.InnovaccerTinname,
          values.tinLocal

        );
      }

      if (values.emrLocal) {
        emr = getEmrObject(values.emrLocal);
      }

      let payload = {
        ...practiceInformationData,
        ...values,
        ...{ specialtySpecialtyType: specialties },
        practiceGroup: practiceGroup,
        emr: emr,

        ...{
          isSqcn: convertBoolean(values?.isSqcn),
          isPrimaryCare: convertBoolean(values?.isPrimaryCare),
          isAco: convertBoolean(values?.isAco),
          isAffiliated: convertBoolean(values?.isAffiliated),
          takingNewPatients: convertBoolean(values?.takingNewPatients),
          takingMedicaidPatients: convertBoolean(
            values?.takingMedicaidPatients
          ),

          hasEpicCareLinkAccess: convertBoolean(values?.hasEpicCareLinkAccess),
          uses837: convertBoolean(values?.uses837),
          adt: convertBoolean(values?.adt),
          ccda: convertBoolean(values?.ccda),
          oru: convertBoolean(values?.oru),
          aetnaBROnly: convertBoolean(values?.aetnaBROnly),
          johnHopkinsUSFamilyHealthPlan: convertBoolean(
            values?.johnHopkinsUSFamilyHealthPlan
          ),
          optimaCommercial: convertBoolean(values?.optimaCommercial),
          optimaMedicaid: convertBoolean(values?.optimaMedicaid),
          humanaMA: convertBoolean(values?.humanaMA),
          optimaMedicareAdvantage: convertBoolean(
            values?.optimaMedicareAdvantage
          ),
          superGroup: values.superGroup,
          supergroupId: values?.supergroupId,
          practiceGroupId: values?.practiceGroupId,
          practiceGroup: {
            tin: values.tinLocal,
            InnovaccerTinname: values.InnovaccerTinname || "",
            supergroupId: values?.supergroupId,
            practiceGroupName: values?.practiceGroupName || "",
          },
          careManagers: careList,
          rsoId: parseInt(values.rsoId),
          tin: values.tinLocal,
          rsoName: values.rsoName,
          updateUser: updateUser,
        },
      };




console.log("----------payload-----------------",payload);





      setPracticeInformationData(payload);
      const contractPayload = {
        id: parseInt(selectedPracticeIdByAdmin) || 0,
        startDate: values?.contractDate && formatDate(values?.contractDate),
        termDate: values?.contractTermDate && formatDate(values?.contractTermDate),
        practiceId: parseInt(selectedPracticeIdByAdmin) || 0,
        tin: values?.tinLocal || "",
        contractAssigneeTypeId: values?.contractAssigneeTypeId,
        notesRegardingTermDates: values?.notesRegardingTermDates,
        personContract: {
          sourceSystem: "SQCN",
          updateUser: "SYSTEM",
          lastConfirmed: new Date(),
          isDeleted: false,
          personId: 0,
          npi: values?.primaryNpi,
          prefixId: values?.contractPrefixId,
          firstName: values?.contractFirstName,
          middleName: values?.contractMiddleName,
          lastName: values?.contractLastName,
          suffixId: values?.contractSuffixId,
          titleId: values?.contractTitleId,
        },
      };
      setPracticeContractData(contractPayload);
    } catch (error) {
      console.log("", error);
    }
  }
  };

  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/[^0-9]/g, ""),
  });
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const [contractYesFields, setContractYesFields] = useState(false);
  const [titleData, setTitleData] = useState([]);
  const [prefixData, setPrefixData] = useState([]);
  const [allSuffixData, setAllSuffixData] = useState([]);
  const [contractDesc, setContractDesc] = useState([]);
  const getSuffixByName = (id) => {
    if(allSuffixData){
      const suffixId = allSuffixData?.filter((suffix) => suffix.id === id);
      if(suffixId){
        return suffixId[0]?.suffixName || null;
      }
    }
  }
  const getTitleById = (id) => {
    if(titleData){
      const titleId = titleData?.filter((title) => title.id === id);
      if(titleId){
        return titleId[0]?.title || null;
      }
    }
  }
  const getPrefixById = (id) => {
    if (prefixData.length > 0) {
      const prefix = prefixData.filter((item) => item.id === id);
      if (prefix.length > 0) {
        return prefix[0].prefixName;
      }
    }
    return "";
  };
  const getPrefixByName = (prefixName) => {
    if (prefixData?.length > 0) {
      const prefix = prefixData?.filter(
        (item) => item.prefixName === prefixName
      );
      if (prefix.length > 0) {
        return prefix[0].id;
      }
    }
    return "";
  };
  const GetContractDetails = () => {
    fetch(base_url + "/api/v1/practices/GetContractTypeDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setContractDesc(data);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      GetContractDetails();
    };
    fetch();
  }, []);

  const GetTitleDetails = () => {
    fetch(base_url + "/api/v1/contact/GetTitleDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTitleData(data?.titleTypeList || []);
        setPrefixData(data?.prefixList || []);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      GetTitleDetails();
    };
    fetch();
  }, []);
  
  const { data: GetSuffixData } = useFetch("/api/v1/contact/GetSuffixData");
  useEffect(() => {
    if (GetSuffixData) {
      setAllSuffixData(GetSuffixData);
    }
  }, [GetSuffixData]);
  const prefix_mapTitles = [
    "16",
    "41",
    "24",
    "61",
    "31",
    "62",
    "63",
    "5",
    "11",
    "36",
    "1",
    "66",
    "67",
    "32",
    "68",
    "22",
    "84",
    "23",
    "45",
  ];
  useEffect(() => {
    if(initialValues.contractAssigneeTypeId === 1) {
      setContractYesFields(true);
    }
    if (initialValues?.lowerAgeLimit) {
      if (!isNaN(initialValues?.lowerAgeLimit)) {
        // If it's a number, set it to the primary input and set the secondary input to a default value
        setLowerAgeNumber(initialValues?.lowerAgeLimit);
        setLowerAgeName(""); // Default unit, can be adjusted
      } else {
        const match = initialValues?.lowerAgeLimit.match(/^(\d+)\s*(Days|Weeks|Months|Years)$/);
        if (match !== null) {
          setLowerAgeNumber(match[1]);
          setLowerAgeName(match[2]);
        } else {
          setLowerAgeNumber("");
          setLowerAgeName(initialValues?.lowerAgeLimit);
        }
        if(initialValues?.lowerAgeLimit === "Newborn" || initialValues?.lowerAgeLimit === "No Age Limit" || initialValues?.lowerAgeLimit === "N/A") {
          setLowerAgeLimitDisable(true);
          setLowerAgeLimitInvalid(false);
        } else {
          setLowerAgeLimitDisable(false);
          if(lowerAgeNumber === "") {
            setLowerAgeLimitInvalid(true);
          } else {
            setLowerAgeLimitInvalid(false);
          }
        }
      }
    }
  }, [initialValues]);
  console.log("ini------", initialValues );
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={practiceInformationSchema}
      //onSubmit={onSubmitFun}
      onSubmit={(values) => {
        onSubmitFun(values);
      }}
      enableReinitialize={true}
    >
      {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <div className="main-block">
              <div className="block">
                <div className="title" data-testid="title">
                  {labels.Practice_ID}{" "}
                  {/* {practiceNameL?.status === "Pending" && (
                    <span>
                      <ApproveIcon />
                    </span>
                  )} */}
                </div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    //d="id"
                    // name={"id"}
                    autoComplete="off"
                    // value={values.id}
                    // defaultValue={values.id}
                    className="nb-input"
                    disabled
                    onChange={handleChange}
                    // isInvalid={!!errors.practiceGroupId}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                      {errors.practiceGroupId}
                    </Form.Control.Feedback> */}
                </div>
                {/* )} */}
              </div>
              <div className="block">
                <div className="title">{labels.Practice_Reporting_ID}</div>
                <div className="info">
                  <Form.Control
                    type="number"
                    placeholder="---"
                    maxLength={10}
                    id="reportingGroupId"
                    name={"reportingGroupId"}
                    autoComplete="off"
                    value={values.reportingGroupId}
                    defaultValue={values.reportingGroupId}
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!values.reportingGroupId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {!values.reportingGroupId ? errors.reportingGroupId : null}
                  </Form.Control.Feedback>
                </div>
                {/* )} */}
              </div>
              <div className="block">
                <div className="title">{labels.Start_Date}</div>
                <div className="input-group search-container-date">
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={values.startDate}
                    name={"startDate"}
                    onChange={(date) =>
                      handleChange({
                        target: { name: "startDate", value: date },
                      })
                    }
                    placeholderText="--"
                    data-testid="editproviderStartDate"
                    className="nb-input width-full"
                    defaultValue={new Date(values.startDate)}
                  />
                  <button
                    type="button"
                    className="search-button right cursor-auto"
                  >
                    <img src={DatePickerIcon} alt="DatePicker Icon" />
                  </button>
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.Term_Date}</div>

                <div className="input-group search-container-date">
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={values.termDate}
                    minDate={values.startDate}
                    onChange={(date) =>
                      handleChange({
                        target: { name: "termDate", value: date },
                      })
                    }
                    placeholderText="--"
                    data-testid="editproviderStartDate"
                    className="nb-input width-full"
                    value={values.termDate || "--"}
                  />
                  <button
                    type="button"
                    className="search-button right cursor-auto"
                  >
                    <img src={DatePickerIcon} alt="DatePicker Icon" />
                  </button>
                </div>
              </div>
            </div>
            <div className="main-block">
              <div className="block">
                <p className="details-label mb-radio"> {labels.SQCN}</p>
                <div className="info" data-testid="AcceptingNewPatients">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="isSqcn"
                    name={"isSqcn"}
                    className="contactradio"
                    data-testid="isSqcn"
                    value={true}
                    checked={convertBoolean(values?.isSqcn)}
                    defaultChecked={convertBoolean(values?.isSqcn)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="isSqcn"
                    name={"isSqcn"}
                    data-testid="isSqcn"
                    checked={!convertBoolean(values?.isSqcn)}
                    defaultChecked={!convertBoolean(values?.isSqcn)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <p className="details-label mb-radio"> {labels.ACO}</p>
                <div className="info" data-testid="isAco">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="isAco"
                    className="contactradio"
                    data-testid="isAco"
                    value={true}
                    name={"isAco"}
                    checked={convertBoolean(values?.isAco)}
                    defaultChecked={convertBoolean(values?.isAco)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="isAco"
                    name={"isAco"}
                    data-testid="isAcoNo"
                    checked={!convertBoolean(values?.isAco)}
                    defaultChecked={!convertBoolean(values?.isAco)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <p className="details-label mb-radio"> {labels.Affiliated}</p>
                <div className="info" data-testid="isAffiliateds">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="isAffiliated"
                    name={"isAffiliated"}
                    className="contactradio"
                    data-testid="isAffiliated"
                    value={true}
                    checked={convertBoolean(values?.isAffiliated)}
                    defaultChecked={convertBoolean(values?.isAffiliated)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="isAffiliated"
                    name={"isAffiliated"}
                    data-testid="isAffiliated"
                    checked={!convertBoolean(values?.isAffiliated)}
                    defaultChecked={!convertBoolean(values?.isAffiliated)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.Market_Region}</div>
                <div className="info">
                  <Form.Select
                    size="sm"
                    type="text"
                    placeholder="---"
                    id="rsoName"
                    name={"rsoName"}
                    autoComplete="off"
                    value={values.rsoName}
                    defaultValue={values.rsoName}
                    className="nb-select"
                    // onChange={handleChange}
                    isInvalid={!values.rsoName}
                    //   />

                    onChange={(e) => {
                      handleChange({
                        target: { name: "rsoName", value: e.target.value },
                      });
                      let rsoId =
                        e.target[e.target.selectedIndex].getAttribute(
                          "data-market"
                        );
                      handleChange({
                        target: { name: "rsoId", value: rsoId },
                      });
                    }}
                  >
                    <option value={values.rsoName} key={values.rsoName}>
                      {values.rsoName}
                    </option>
                    {marketsData?.length > 0 &&
                      marketsData.map((market) => (
                        <option
                          key={market.id}
                          data-market={market.id}
                          value={market.rso}
                        >
                          {market.rso}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {!values.rsoName ? errors.rsoName : null}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>
            <div className="main-block">
              <Col md={3}>
                <div className="block">
                  <div className="title">{labels.Supergroup}</div>

                  <div className="info">
                    <Form.Select
                      size="sm"
                      type="text"
                      placeholder="---"
                      id="superGroup"
                      autoComplete="off"
                      name={"superGroup"}
                      value={values.superGroup}
                      defaultValue={values.superGroup}
                      className="nb-select"
                      //onChange={handleChange}

                      isInvalid={!values.superGroup}
                      onChange={(e) => {
                        handleChange({
                          target: { name: "superGroup", value: e.target.value },
                        });

                        let superId =
                          e.target[e.target.selectedIndex].getAttribute(
                            "data-inv"
                          );

                        // setFilterTin(superId);

                        handleChange({
                          target: {
                            name: "supergroupId",
                            value: superId,
                          },
                        });
                        handleChange({
                          target: { name: "tin", value: "" },
                        });
                      }}
                    >
                      <option
                        value={values.superGroup}
                        key={values.supergroupId}
                        data-inv={values.supergroupId}
                      >
                        {values.superGroup}
                      </option>
                      {GetAllSuperGroups?.length > 0 &&
                        GetAllSuperGroups.map((superGroup) => (
                          <option
                            key={superGroup.id}
                            data-inv={superGroup.id}
                            value={superGroup.supergroupCode}
                          >
                            {superGroup.supergroupCode}
                          </option>
                        ))}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      {!values.superGroup ? errors.superGroup : null}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </Col>

              <Col md={3}>
                <div className="block">
                  <div className="title">{labels.TIN}</div>
                  <div className="info">
                    {/* <Form.Select
                      size="sm"
                      type="text"
                      placeholder="---"
                      id="tin"
                      name="tin"
                      autoComplete="off"
                      value={values.tin}
                      className="nb-select"
                      // onChange={handleChange}
                      isInvalid={!values.tin}
                      onChange={(e) => {
                        handleChange({
                          target: { name: "tin", value: e.target.value },
                        });
                        let InnovaccerTinname =
                          e.target[e.target.selectedIndex].getAttribute(
                            "data-inv"
                          );
                        handleChange({
                          target: {
                            name: "InnovaccerTinname",
                            value: InnovaccerTinname,
                          },
                        });

                        let superGroupId =
                          e.target[e.target.selectedIndex].getAttribute(
                            "data-practice"
                          );

                        handleChange({
                          target: {
                            name: "supergroupId",
                            value: superGroupId,
                          },
                        });

                        setsupergroupId(superGroupId);
                      }}
                    >
                      <option value={values.tin}>{values.tin}</option>
                      {tinOptions?.length > 0 &&
                        tinOptions.map((tinObj) => (
                          <option
                            key={tinObj.tin}
                            data-inv={tinObj.innovaccerTinname}
                            data-practice={tinObj.practiceGroupId}
                            value={tinObj.tin}
                          >
                            {tinObj.tin}
                          </option>
                        ))}
                    </Form.Select> */}


                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="tinInput"
                    autoComplete="off"
                    name="tinInput"
                    value={tinInput}
                    defaultValue={tinInput}
                    className="nb-input"
                    isInvalid={!tinInput}
                    hidden
                  />
                  <CreatableSelect
                    placeholder="---"
                    type="text"
                    id="tin"
                    name="tinLocal"
                    autoComplete="off"
                    className="nb-select"
                    options={tinOptions}
                    maxLength={9}
                    onInputChange={inputValue =>
                      (inputValue.length === 9 ? inputValue : inputValue.substring(0, 9))
                    }
                    onCreateOption={(selectedOption) => {
                      setTinInput(selectedOption);
                      setFieldValue("tinLocal", selectedOption.replace(/[^0-9]/g, ""));
                      setFieldValue("InnovaccerTinname", "");
                      const newOption = createOption(selectedOption);
                      setFieldValue("tinOption", newOption);
                    }}
                    onChange={(selectedOption) => {
                      setTinInput(selectedOption.value);
                      handleChange({
                        target: {
                          name: "tinLocal",
                          value: selectedOption.value,
                        },
                      });
                      handleChange({
                        target: {
                          name: "tin",
                          value: selectedOption.value,
                        },
                      });



                      let selectedTin = tinApiOptions.filter((item) => {
                        return item.tin == selectedOption.value;
                      });

                      if(selectedTin?.length>0){
                        handleChange({
                          target: {
                            name: "InnovaccerTinname",
                            value: selectedTin?.[0]?.innovaccerTinname,
                          },
                        });

                        handleChange({
                          target: {
                            name: "supergroupId",
                            value: selectedTin?.[0]?.supergroupId,
                          },
                        });
                        handleChange({
                          target: {
                            name: "practiceGroupId",
                            value: selectedTin?.[0]?.practiceGroupId,
                          },
                        });

                        setsupergroupId(selectedTin?.[0]?.practiceGroupId);

                      }else{

                        handleChange({
                          target: {
                            name: "InnovaccerTinname",
                            value: "",
                          },
                        });

                        handleChange({
                          target: {
                            name: "supergroupId",
                            value: values.supergroupId,
                          },
                        });
                        handleChange({
                          target: {
                            name: "practiceGroupId",
                            value: 0,
                          },
                        });

                        setsupergroupId(0);
                      }

                      handleChange({
                        target: {
                          name: "tinOption",
                          value: selectedOption,
                        },
                      });
                    }}
                    isInvalid={!values.tinLocal}
                    value={values.tinOption}
                    defaultValue={values.tinOption}
                  />
                  {tinInput?.length === 0 ?
                    <div className="invalid-feedback-1">
                      Tax Identification Number cannot be blank
                    </div> : tinInput?.length !== 9 ? 
                    <div className="invalid-feedback-1">
                      Tax Identification Number should be 9 digits
                    </div> : null
                  }
                  {/* <div className={tinInput?.length !== 9 && "invalid-feedback-1"}>
                    {tinInput?.length !== 9 && "Tax Identification Number should be 9 digits"}
                  </div> */}
                  {/* <Form.Control.Feedback type="invalid">
                    {!values.tinInput ? errors.tinInput : null}
                  </Form.Control.Feedback> */}
                </div>
                </div>
              </Col>
              <div className="block">
                <div className="title">{labels.Innovaccer_Designated_Name}</div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="InnovaccerTinname"
                    autoComplete="off"
                    name="InnovaccerTinname"
                    value={values.InnovaccerTinname}
                    defaultValue={values.InnovaccerTinname}
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!values.InnovaccerTinname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {!values.InnovaccerTinname ? errors.InnovaccerTinname : null}
                  </Form.Control.Feedback>
                </div>
                {/* )} */}
              </div>
            </div>
            <div className="main-block">
              <div className="block">
                <div className="title">{labels.Practice_Name_Legal}</div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="practiceNameLegal"
                    name="practiceNameLegal"
                    autoComplete="off"
                    value={values.practiceNameLegal}
                    defaultValue={values.practiceNameLegal}
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!values.practiceNameLegal}
                  />
                  <Form.Control.Feedback type="invalid">
                    {!values.practiceNameLegal
                      ? errors.practiceNameLegal
                      : null}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.Practice_Name_Location1}</div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="location1"
                    name="location1"
                    autoComplete="off"
                    value={values.location1}
                    defaultValue={values.location1}
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!values.location1}
                  />
                  <Form.Control.Feedback type="invalid">
                    {!values.location1 ? errors.location1 : null}
                  </Form.Control.Feedback>
                </div>
              </div>

              <Col md={2}>
                <div className="block">
                  <div className="title">{labels.Primary_NPI}</div>
                  <div className="info">
                    <Form.Control
                      type="tel"
                      placeholder="---"
                      maxLength={10}
                      id="primaryNpi"
                      name="primaryNpi"
                      autoComplete="off"
                      value={values.primaryNpi}
                      defaultValue={values.primaryNpi}
                      className="nb-input"
                      onChange={handleChange}
                      // isInvalid={!values.primaryNpi}
                    />

                    {/* <Form.Control.Feedback type="invalid">
                      {!values.primaryNpi ?errors.primaryNpi:null}
                    </Form.Control.Feedback> */}
                  </div>
                  {/* )} */}
                </div>
              </Col>

              <Col md={2}>
                <div className="block">
                  <div className="title">{labels.Secondary_NPI}</div>
                  <div className="info">
                    <Form.Control
                      type="tel"
                      placeholder="---"
                      maxLength={10}
                      id="secondaryNpi"
                      name="secondaryNpi"
                      autoComplete="off"
                      value={values.secondaryNpi}
                      defaultValue={values.secondaryNpi}
                      className="nb-input"
                      onChange={handleChange}
                      // isInvalid={!!errors.secondaryNpi}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                    {errors.secondaryNpi}
                  </Form.Control.Feedback> */}
                  </div>
                </div>
              </Col>
            </div>

            <div className="main-block">
              <div className="block">
                <div className="title">{labels.EMR_Vendor}</div>
                <div className="info">
    
                  <CreatableSelect
                    placeholder="---"
                    type="text"
                    id="emr"
                    name="emrLocal"
                    options={emrOptions}
                    className="nb-select nb-input"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "emrLocal",
                          value: selectedOption.value,
                        },
                      });
                      handleChange({
                        target: {
                          name: "emrOption",
                          value: selectedOption,
                        },
                      });
                    }}
                    isInvalid={!values.emrLocal}
                    value={values.emrOption}
                  />
                  <Form.Control.Feedback type="invalid">
                    {!values.emrLocal ? errors.emrLocal : null}
                  </Form.Control.Feedback>

                  {/* <Form.Select
                    size="sm"
                    type="text"
                    placeholder="---"
                    id="emr"
                    name="emrLocal"
                    autoComplete="off"
                    value={values.emrLocal}
                    defaultValue={values.emrLocal}
                    className="nb-select"
                    isInvalid={!values.emrLocal}
                    //onChange={handleChange}

                    onChange={(e) => {
                      handleChange({
                        target: { name: "emrLocal", value: e.target.value },
                      });
                      let emRid =
                        e.target[e.target.selectedIndex].getAttribute(
                          "data-emrId"
                        );
                      handleChange({
                        target: { name: "emRid", value: emRid },
                      });
                    }}
                  >
                    <option value={values.emr?.emr}>{values.emr?.emr}</option>
                    {practiceMasterData?.emRs.length > 0 &&
                      practiceMasterData?.emRs.map((emrItem, index) => (
                        <option
                          key={emrItem.emr + index}
                          data-emrId={emrItem.id}
                          value={emrItem.emr}
                        >
                          {emrItem.emr}
                        </option>
                      ))}
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {!values.emrLocal ? errors.emrLocal : null}
                  </Form.Control.Feedback> */}
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.TeleHealth}</div>
                <div className="info">
                  <CreatableSelect
                    placeholder="---"
                    id="telehealth"
                    name="telehealth"
                    classNamePrefix='filter'
                    options={teleHealthOptions}
                    className="nb-select nb-input"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "telehealth",
                          value: selectedOption.value,
                        },
                      });
                      handleChange({
                        target: {
                          name: "telehealthOption",
                          value: selectedOption,
                        },
                      });
                    }}
                    isInvalid={!values.telehealth}
                    value={values.telehealthOption}
                  />
                  <Form.Control.Feedback type="invalid">
                    {!values.telehealth ? errors.telehealth : null}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.Practice_Management_System}</div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="practiceManagementSystem"
                    name="practiceManagementSystem"
                    autoComplete="off"
                    value={values.practiceManagementSystem}
                    defaultValue={values.practiceManagementSystem}
                    className="nb-input"
                    onChange={handleChange}
                    // isInvalid={!!errors.practiceManagementSystem}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                      {errors.practiceManagementSystem}
                    </Form.Control.Feedback> */}
                </div>
                {/* )} */}
              </div>
            </div>

            <div className="main-block">
              <div className="block">
                <div className="title">{labels.Practice_Specialty}</div>
                {/* <div className="info">
                  <Form.Select
                    size="sm"
                    type="text"
                    placeholder="---"
                    id="specialty"
                    autoComplete="off"
                    name={"specialty"}
                    value={values.specialty}
                    defaultValue={values.specialty}
                    className="nb-select"
                    isInvalid={!values.specialty}
                    onChange={handleChange}
                  >
                    <option value={values.specialty}>{values.specialty}</option>
                    {practiceMasterData?.specialities.length > 0 &&
                      practiceMasterData?.specialities.map(
                        (specialitiesItem, index) => (
                          <option
                            key={specialitiesItem.specialtyName + index}
                            value={specialitiesItem.specialtyName}
                          >
                            {specialitiesItem.specialtyName}
                          </option>
                        )
                      )}
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {!values.specialty ? errors.specialty : null}
                  </Form.Control.Feedback>
                </div> */}

                <div className="info">
                  <Form.Select
                    size="sm"
                    type="text"
                    placeholder="---"
                    id="specialty"
                    autoComplete="off"
                    name={"specialty"}
                    value={values.specialty}
                    defaultValue={values.specialty}
                    className="nb-select"
                    isInvalid={!values.specialty}
                    onChange={handleChange}
                  >
                    <option value={values.specialty}>{values.specialty}</option>

                    {practiceSpclData?.length > 0 &&
                      practiceSpclData?.map((specialitiesItem, index) => (
                        <option
                          key={specialitiesItem.name + index}
                          value={specialitiesItem.name}
                        >
                          {specialitiesItem.name}
                        </option>
                      ))}
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {!values.specialty ? errors.specialty : null}
                  </Form.Control.Feedback>
                </div>

                {/* <div className="info">
                          <PracticeSpecialty
                            specialtydata={specialtydata}
                            specialtyDetailsByspecialtype={
                              specialtyDetailsByspecialtype
                            }
                            selectedSpecialtyIdfn={selectedSpecialtyIdfn}
                            validationError={!spltyName?.length > 0}
                            validationError1={
                              newSpecialty && spltyName?.length > 1
                            }
                          />
                        </div> */}
              </div>
              <div className="block">
                <div className="title">{labels.Practice_Sub_Specialty1}</div>
                <div className="info">
                  <Form.Select
                    size="sm"
                    type="text"
                    placeholder="---"
                    id="specialty1"
                    autoComplete="off"
                    name={"specialty1"}
                    value={values.specialty1}
                    defaultValue={values.specialty1}
                    className="nb-select"
                    onChange={handleChange}
                  >
                    <option value={""}>{""}</option>
                    {practiceSubSpcl1Data?.length > 0 &&
                      practiceSubSpcl1Data?.map(
                        (specialitiesItem, index) => (
                          <option
                            key={specialitiesItem.name + index}
                            value={specialitiesItem.name}
                          >
                            {specialitiesItem.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.Practice_Sub_Specialty2}</div>
                <div className="info">
                  <Form.Select
                    size="sm"
                    type="text"
                    placeholder="---"
                    id="specialty2"
                    name={"specialty2"}
                    autoComplete="off"
                    value={values.specialty2}
                    defaultValue={values.specialty2}
                    className="nb-select"
                    onChange={handleChange}
                  >
                    <option value={""}>{""}</option>
                    {practiceSubSpcl2Data?.length > 0 &&
                      practiceSubSpcl2Data?.map(
                        (specialitiesItem, index) => (
                          <option
                            key={specialitiesItem.name + index}
                            value={specialitiesItem.name}
                          >
                            {specialitiesItem.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                </div>
              </div>
            </div>

            <div className="main-block">
              <div className="block">
                <div className="title">{labels.Practice_Sub_Specialty3}</div>
                <div className="info">
                  <Form.Select
                    size="sm"
                    type="text"
                    placeholder="---"
                    id="specialty3"
                    name={"specialty3"}
                    autoComplete="off"
                    value={values.specialty3}
                    defaultValue={values.specialty3}
                    className="nb-select"
                    onChange={handleChange}
                  >
                    <option value={""}>{""}</option>
                    {practiceSubSpcl3Data?.length > 0 &&
                      practiceSubSpcl3Data?.map(
                        (specialitiesItem, index) => (
                          <option
                            key={specialitiesItem.name + index}
                            value={specialitiesItem.name}
                          >
                            {specialitiesItem.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                </div>
              </div>
              <div className="block">
                <p className="details-label mb-radio"> {labels.Primary_Care}</p>
                <div className="info" data-testid="isPrimaryCare">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="isPrimaryCare"
                    name="isPrimaryCare"
                    className="contactradio"
                    data-testid="isPrimaryCare"
                    value={true}
                    checked={convertBoolean(values?.isPrimaryCare)}
                    defaultChecked={convertBoolean(values?.isPrimaryCare)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="isPrimaryCare"
                    name="isPrimaryCare"
                    data-testid="isPrimaryCare"
                    checked={!convertBoolean(values?.isPrimaryCare)}
                    defaultChecked={!convertBoolean(values?.isPrimaryCare)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.Previous_Practice_Name}</div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="previousPracticeName"
                    name="previousPracticeName"
                    autoComplete="off"
                    value={values.previousPracticeName}
                    defaultValue={values.previousPracticeName}
                    className="nb-input"
                    onChange={handleChange}
                    //  isInvalid={!!errors.previousPracticeName}
                  />
                </div>
              </div>
            </div>

            <div className="main-block">
              <div className="block">
                <p className="details-label mb-radio">
                  {labels.Accepting_New_Patients}
                </p>
                <div className="info" data-testid="AcceptingNewPatients">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="takingNewPatients"
                    name="takingNewPatients"
                    className="contactradio"
                    data-testid="takingNewPatients"
                    value={true}
                    checked={convertBoolean(values?.takingNewPatients)}
                    defaultChecked={convertBoolean(values?.takingNewPatients)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    id="takingNewPatients"
                    name="takingNewPatients"
                    data-testid="takingNewPatients"
                    value={false}
                    checked={!convertBoolean(values?.takingNewPatients)}
                    defaultChecked={!convertBoolean(values?.takingNewPatients)}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="block">
                <p className="details-label mb-radio">
                  {labels.Accepting_New_Medicaid_Patients}
                </p>
                <div className="info" data-testid="AcceptingNewPatients">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="takingMedicaidPatients"
                    name="takingMedicaidPatients"
                    className="contactradio"
                    data-testid="takingMedicaidPatients"
                    value={true}
                    checked={convertBoolean(values?.takingMedicaidPatients)}
                    defaultChecked={convertBoolean(
                      values?.takingMedicaidPatients
                    )}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    id="takingMedicaidPatients"
                    name="takingMedicaidPatients"
                    data-testid="takingMedicaidPatients"
                    value={false}
                    className="contactradio"
                    checked={!convertBoolean(values?.takingMedicaidPatients)}
                    defaultChecked={
                      !convertBoolean(values?.takingMedicaidPatients)
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="block">
                <div className="title">{labels.Lower_Age_Limit}</div>
                <div className="info">
                  <Row>
                    <Col md={2}>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="lowerAgeNumber"
                        autoComplete="off"
                        name="lowerAgeNumber"
                        value={lowerAgeNumber}
                        defaultValue={lowerAgeNumber}
                        className="nb-input"
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("lowerAgeLimit", value ? `${value+lowerAgeName}` : lowerAgeName);
                          setLowerAgeNumber(value);
                          if(value === ""){
                            setLowerAgeLimitInvalid(true);
                          } else {
                            setLowerAgeLimitInvalid(false);
                          }
                        }}
                        maxLength={4}
                        disabled={lowerAgeLimitDisable}
                        isInvalid={lowerAgeLimitInvalid}
                      />
                      <Form.Control.Feedback type="invalid">
                        {lowerAgeLimitInvalid && errors.lowerAgeNumber}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6}>
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder={lowerAgeLimitDisable ? "---" : ""}
                        id="lowerAgeName"
                        name={"lowerAgeName"}
                        autoComplete="off"
                        value={lowerAgeName}
                        defaultValue={lowerAgeName}
                        className="nb-select"
                        onChange={(e) => {
                          const { value } = e.target;
                          setLowerAgeName(value);
                          if(value === "Newborn" || value === "No Age Limit" || value === "N/A") {
                            setFieldValue("lowerAgeLimit", value);
                            setLowerAgeLimitDisable(true);
                            setLowerAgeLimitInvalid(false);
                            setLowerAgeNumber("");
                          } else {
                            setFieldValue("lowerAgeLimit", lowerAgeNumber ? `${lowerAgeNumber+value}` : value);
                            setLowerAgeLimitDisable(false);
                            if(lowerAgeNumber === "") {
                              setLowerAgeLimitInvalid(true);
                            } else {
                              setLowerAgeLimitInvalid(false);
                            }
                          }
                        }}
                      >
                        {(lowerAgeName === "" || lowerAgeName === null) && <option
                          key={values.lowerAgeName}
                          data-lowerAgeName={values.lowerAgeName}
                          value={values.lowerAgeName}
                        >
                          {values.lowerAgeName}
                        </option>}
                        {lowerAgeLimitData?.length > 0 &&
                          lowerAgeLimitData?.map(
                            (lowerAgeLimitItem, index) => (
                              <option
                                key={lowerAgeLimitItem.name + index}
                                data-lowerAgeName={lowerAgeLimitItem.name}
                                value={lowerAgeLimitItem.name}
                              >
                                {lowerAgeLimitItem.name}
                              </option>
                            )
                          )}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <AddCareManager
              careManagers={careManagers}
              inputList={inputList}
              setInputList={setInputList}
              selectedPracticeIdByAdmin={selectedPracticeIdByAdmin}
            />
            <div className="main-block not-flex">
              <Row>
                <Col md={3}>
                  <div className="block">
                    <div className="title">
                      {labels.Contract}
                    </div>
                    <div className="info">
                      <Form.Select
                        size="sm"
                        type="text"
                        placeholder="---"
                        id="contractAssigneeTypeDesc"
                        name={"contractAssigneeTypeDesc"}
                        autoComplete="off"
                        value={values.contractAssigneeTypeDesc || "No"}
                        defaultValue={values.contractAssigneeTypeDesc || "No"}
                        className="nb-select"
                        isInvalid={!values.contractAssigneeTypeDesc}
                        onChange={(e) => {
                          handleChange({
                            target: { name: e.target.value },
                          });
                          setFieldValue("contractAssigneeTypeDesc", e.target.value);
                          let id =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-contractid"
                            );
                          setFieldValue("contractAssigneeTypeId", parseInt(id));
                          if(e.target.value === "Yes") {
                            setContractYesFields(true);
                          } else {
                            setContractYesFields(false);
                          }
                        }}
                      >
                        <option value={values.contractAssigneeTypeDesc} key={values.contractAssigneeTypeDesc}>
                          {values.contractAssigneeTypeDesc}
                        </option>
                        {contractDesc?.length > 0 &&
                          contractDesc.map((item) => (
                            <option
                              key={item.contractAssigneeTypeId}
                              data-contractid={item.contractAssigneeTypeId}
                              value={item.contractAssigneeTypeDesc}
                            >
                              {item.contractAssigneeTypeDesc}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  </div>
                </Col>
                {contractYesFields && (
                  <>
                    <Col md={3}></Col>
                    <Col md={3}>
                      <div className="block">
                        <div className="title">
                          {labels.Contract_Date}
                        </div>
                        <div className="input-group search-container-date padding-top4">
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={values?.contractDate}
                            name={"contractDate"}
                            onChange={(date) =>
                              handleChange({
                                target: { name: "contractDate", value: date },
                              })
                            }
                            placeholderText="--"
                            data-testid="contractDate"
                            className="nb-input width-full"
                            defaultValue={new Date(values?.contractDate)}
                          />
                          <button
                            type="button"
                            className="search-button right cursor-auto"
                          >
                            <img src={DatePickerIcon} alt="DatePicker Icon" />
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="block">
                        <div className="title">{labels.Contract_Term_Date}</div>
                        <div className="input-group search-container-date padding-top4">
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={values?.contractTermDate}
                            name={"contractTermDate"}
                            onChange={(date) =>
                              handleChange({
                                target: { name: "contractTermDate", value: date },
                              })
                            }
                            placeholderText="--"
                            data-testid="contractTermDate"
                            className="nb-input width-full"
                            defaultValue={new Date(values?.contractTermDate)}
                            minDate={values?.contractDate}
                          />
                          <button
                            type="button"
                            className="search-button right cursor-auto"
                          >
                            <img src={DatePickerIcon} alt="DatePicker Icon" />
                          </button>
                        </div>
                      </div>
                    </Col>
                  </>)}
              </Row>
              {contractYesFields && (
                <>
                <Row>
                  <div className="block upper-space">
                    <div className="title">
                      {labels.Contract_Signee}
                    </div>
                  </div>
                  <Col md={3}></Col>
                </Row>
                <Row>
                  <div className="block upper-space flex">
                    <Col md={1}>
                      <div className="block">
                        <div className="title">
                          {labels.Prefix}
                        </div>
                        <div className="info">
                          <Form.Select
                            size="md"
                            placeholder="---"
                            id="prefix"
                            autoComplete="off"
                            value={getPrefixById(values?.contractPrefixId)}
                            defaultValue={getPrefixById(values?.contractPrefixId)}
                            className="nb-select"
                            name="prefix"
                            onChange={(e) => {
                              const { value } = e.target;
                              console.log("value--", value);
                              
                              handleChange(value);
                              let id =
                                e.target[e.target.selectedIndex].getAttribute(
                                  "data-prefixid"
                                );
                              setFieldValue("contractPrefixId", parseInt(id));
                            }}
                          >
                            <option value={getPrefixById(values?.contractPrefixId)}>{getPrefixById(values?.contractPrefixId)}</option>
                            {prefixData.map((prefixItem, index) => (
                              <option
                                key={prefixItem.prefixName + index}
                                value={prefixItem.prefixName}
                                data-prefixid={prefixItem.id}
                              >
                                {prefixItem.prefixName}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="block">
                        <div className="title">
                          {labels.First_Name}
                        </div>
                        <div className="info">
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="contractFirstName"
                            autoComplete="off"
                            name="contractFirstName"
                            value={values?.contractFirstName}
                            defaultValue={values?.contractFirstName}
                            className="nb-input padding-top8"
                            onChange={handleChange}
                            isInvalid={errors.contractFirstName && values?.contractFirstName}
                          />
          <Form.Control.Feedback type="invalid">
                    {errors.contractFirstName}
                  </Form.Control.Feedback>  
                  </div> 

                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="block">
                        <div className="title">
                          {labels.MIDDLE_NAME}
                        </div>
                        <div className="info">
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="contractMiddleName"
                            autoComplete="off"
                            name="contractMiddleName"
                            value={values?.contractMiddleName}
                            defaultValue={values?.contractMiddleName}
                            className="nb-input padding-top8"
                            onChange={handleChange}
                           
                            isInvalid={errors.contractMiddleName && values?.contractMiddleName}
                          />
           <Form.Control.Feedback type="invalid">
                    {errors.contractMiddleName}
                  </Form.Control.Feedback>  
                  </div>  
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="block">
                        <div className="title">
                          {labels.Last_Name}
                        </div>
                        <div className="info">
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="contractLastName"
                            autoComplete="off"
                            name="contractLastName"
                            value={values?.contractLastName}
                            defaultValue={values?.contractLastName}
                            className="nb-input padding-top8"
                            onChange={handleChange}
                            isInvalid={errors.contractLastName && values?.contractLastName}
                          />
                           <Form.Control.Feedback type="invalid">
                    {errors.contractLastName}
                  </Form.Control.Feedback>  
                  </div> 
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className="block">
                        <div className="title">
                          {labels.SUFFIX}
                        </div>
                        <div className="info">
                          <Form.Select
                            size="md"
                            id="suffix"
                            autoComplete="off"
                            name="suffix"
                            value={getSuffixByName(values?.contractSuffixId)}
                            defaultValue={getSuffixByName(values?.contractSuffixId)}
                            className="nb-select"
                            onChange={(e) => {
                              const { value } = e.target;
                              handleChange(value);
                              let id =
                                e.target[e.target.selectedIndex].getAttribute(
                                  "data-suffixid"
                                );
                              setFieldValue("contractSuffixId", parseInt(id));
                            }}
                          >
                            <option value={getSuffixByName(values?.contractSuffixId)}>{getSuffixByName(values?.contractSuffixId)}</option>
                            {allSuffixData?.length > 0 &&
                              allSuffixData?.map((suffix) => (
                                <option
                                  key={suffix.id}
                                  data-suffixid={suffix.id}
                                  value={suffix.suffixName}
                                >
                                  {suffix.suffixName}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className="block">
                        <div className="title">
                          {labels.Title}
                        </div>
                        <div className="info">
                          <Form.Select
                            size="sm"
                            id="contractTitleName"
                            autoComplete="off"
                            name="contractTitleName"
                            value={getTitleById(values?.contractTitleId)}
                            defaultValue={getTitleById(values?.contractTitleId)}
                            className="nb-select"
                            onChange={(e) => {
                              handleChange(e);
                              let id =
                                e.target[e.target.selectedIndex].getAttribute(
                                  "data-title"
                                );
                              setFieldValue("contractTitleId", parseInt(id));
                              if (prefix_mapTitles.includes(id)) {
                                // handlePrefixChange(id);
                                setFieldValue("prefix", "Dr");
                                const DrId = getPrefixByName("Dr");
                                setFieldValue("contractPrefixId", parseInt(DrId));
                              }
                            }}
                          >
                            <option value={getTitleById(values?.contractTitleId)}>{getTitleById(values?.contractTitleId)}</option>
                            {titleData.map((titleItem, index) => (
                              <option
                                key={titleItem.title + index}
                                value={titleItem.title}
                                data-title={titleItem.id}
                              >
                                {titleItem.title}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Row>
                </>
              )}
            </div>
            <div className="main-block">
              <div className="block">
                <div className="title">
                  {labels.Practice_Name_Before_Editing_Innovaccer}
                </div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="practiceNamebeforeEditing"
                    name="practiceNamebeforeEditing"
                    autoComplete="off"
                    value={values.practiceNamebeforeEditing}
                    defaultValue={values.practiceNamebeforeEditing}
                    className="nb-input"
                    onChange={handleChange}
                    // isInvalid={!!errors.telemedicine}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.Notes_Regarding_Payments}</div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="payments"
                    autoComplete="off"
                    name="payments"
                    value={values.payments}
                    defaultValue={values.payments}
                    className="nb-input"
                    onChange={handleChange}
                    //isInvalid={!!errors.telemedicine}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                </div>
              </div>
            </div>

            <div className="main-block">
              <div className="block">
                <div className="title">
                  {labels.Additional_Comments_For_Audits}
                </div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="additionalCommentsForAudits"
                    name="additionalCommentsForAudits"
                    autoComplete="off"
                    value={values.additionalCommentsForAudits}
                    defaultValue={values.additionalCommentsForAudits}
                    className="nb-input"
                    onChange={handleChange}
                    // isInvalid={!!errors.emr}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                      {errors.emr}
                    </Form.Control.Feedback> */}
                </div>
                {/* )} */}
              </div>
              <div className="block">
                <div className="title">{labels.Notes_Regarding_Term_Dates}</div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="notesRegardingTermDates"
                    name="notesRegardingTermDates"
                    autoComplete="off"
                    value={values.notesRegardingTermDates}
                    defaultValue={values.notesRegardingTermDates}
                    className="nb-input"
                    disabled={!values?.termDate}
                    onChange={handleChange}
                    // isInvalid={!!errors.notesRegardingTermDates}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                </div>
              </div>
              <div className="block">
                <div className="title">{labels.Claims_Clearing_House}</div>
                <div className="info">
                  <Form.Control
                    type="text"
                    placeholder="---"
                    id="claimsClearingHouse"
                    name="claimsClearingHouse"
                    autoComplete="off"
                    value={values.claimsClearingHouse}
                    defaultValue={values.claimsClearingHouse}
                    className="nb-input"
                    onChange={handleChange}
                    // isInvalid={!!errors.claimsClearingHouse}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.telemedicine}
                  </Form.Control.Feedback> */}
                </div>
              </div>
            </div>

            <div className="main-block">
              <div className="block">
                <p className="details-label mb-radio">{labels._837}</p>
                <div className="info" data-testid="uses837">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="uses837"
                    className="contactradio"
                    name="uses837"
                    data-testid="uses837"
                    value={true}
                    checked={convertBoolean(values?.uses837)}
                    defaultChecked={convertBoolean(values?.uses837)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="uses837"
                    name="uses837"
                    data-testid="uses837"
                    checked={!convertBoolean(values?.uses837)}
                    defaultChecked={!convertBoolean(values?.uses837)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <p className="details-label mb-radio"> {labels.CCDA}</p>
                <div className="info" data-testid="AcceptingNewPatients">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="ccda"
                    name="ccda"
                    className="contactradio"
                    data-testid="ccda"
                    value={true}
                    checked={convertBoolean(values?.ccda)}
                    defaultChecked={convertBoolean(values?.ccda)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="ccda"
                    name="ccda"
                    data-testid="ccda"
                    checked={!convertBoolean(values?.ccda)}
                    defaultChecked={!convertBoolean(values?.ccda)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block" />
            </div>

            <div className="main-block">
              <div className="block">
                <p className="details-label mb-radio">
                  {" "}
                  {labels.Aetna_BR_Only}
                </p>
                <div className="info" data-testid="aetnaBROnly">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="aetnaBROnly"
                    name="aetnaBROnly"
                    className="contactradio"
                    data-testid="aetnaBROnly"
                    value={true}
                    checked={convertBoolean(values?.aetnaBROnly)}
                    defaultChecked={convertBoolean(values?.aetnaBROnly)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    name="aetnaBROnly"
                    id="aetnaBROnly"
                    data-testid="aetnaBROnly"
                    checked={!convertBoolean(values?.aetnaBROnly)}
                    defaultChecked={!convertBoolean(values?.aetnaBROnly)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <p className="details-label mb-radio"> {labels.Humana_MA}</p>
                <div className="info" data-testid="humanaMA">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="humanaMA"
                    name="humanaMA"
                    className="contactradio"
                    data-testid="humanaMA"
                    value={true}
                    checked={convertBoolean(values?.humanaMA)}
                    defaultChecked={convertBoolean(values?.humanaMA)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="humanaMA"
                    name="humanaMA"
                    data-testid="humanaMA"
                    checked={!convertBoolean(values?.humanaMA)}
                    defaultChecked={!convertBoolean(values?.humanaMA)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <p className="details-label mb-radio">
                  {" "}
                  {labels.John_Hopkins_US}
                </p>
                <div className="info" data-testid="AcceptingNewPatients">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    name="johnHopkinsUSFamilyHealthPlan"
                    id="johnHopkinsUSFamilyHealthPlan"
                    className="contactradio"
                    data-testid="johnHopkinsUSFamilyHealthPlan"
                    value={true}
                    checked={convertBoolean(
                      values?.johnHopkinsUSFamilyHealthPlan
                    )}
                    defaultChecked={convertBoolean(
                      values?.johnHopkinsUSFamilyHealthPlan
                    )}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    name="johnHopkinsUSFamilyHealthPlan"
                    id="johnHopkinsUSFamilyHealthPlan"
                    data-testid="johnHopkinsUSFamilyHealthPlan"
                    checked={
                      !convertBoolean(values?.johnHopkinsUSFamilyHealthPlan)
                    }
                    defaultChecked={
                      !convertBoolean(values?.johnHopkinsUSFamilyHealthPlan)
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="main-block">
              <div className="block">
                <p className="details-label mb-radio">
                  {labels.SHP_Commercial}
                </p>
                <div className="info" data-testid="optimaCommercial">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="optimaCommercial"
                    name="optimaCommercial"
                    className="contactradio"
                    data-testid="optimaCommercial"
                    value={true}
                    checked={convertBoolean(values?.optimaCommercial)}
                    defaultChecked={convertBoolean(values?.optimaCommercial)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="optimaCommercial"
                    name="optimaCommercial"
                    data-testid="optimaCommercial"
                    checked={!convertBoolean(values?.optimaCommercial)}
                    defaultChecked={!convertBoolean(values?.optimaCommercial)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <p className="details-label mb-radio">
                  {" "}
                  {labels.SHP_Medicaid}
                </p>
                <div className="info" data-testid="optimaMedicaid">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="optimaMedicaid"
                    name="optimaMedicaid"
                    className="contactradio"
                    data-testid="optimaMedicaid"
                    value={true}
                    checked={convertBoolean(values?.optimaMedicaid)}
                    defaultChecked={convertBoolean(values?.optimaMedicaid)}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="optimaMedicaid"
                    name="optimaMedicaid"
                    data-testid="optimaMedicaid"
                    checked={!convertBoolean(values?.optimaMedicaid)}
                    defaultChecked={!convertBoolean(values?.optimaMedicaid)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="block">
                <p className="details-label mb-radio"> {labels.SHP_MA}</p>
                <div className="info" data-testid="humanaMA">
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="optimaMedicareAdvantage"
                    name="optimaMedicareAdvantage"
                    className="contactradio"
                    data-testid="optimaMedicareAdvantage"
                    value={true}
                    checked={convertBoolean(values?.optimaMedicareAdvantage)}
                    defaultChecked={convertBoolean(
                      values?.optimaMedicareAdvantage
                    )}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value={false}
                    id="optimaMedicareAdvantage"
                    name="optimaMedicareAdvantage"
                    data-testid="optimaMedicareAdvantage"
                    checked={!convertBoolean(values?.optimaMedicareAdvantage)}
                    defaultChecked={
                      !convertBoolean(values?.optimaMedicareAdvantage)
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="add-location-footer">
          <p>Please confirm that all information is correct</p>
          <div className="add-location-buttons">
            <button
              className="white-btn"
              data-testid="EditCancelButton"
              onClick={() => {}}
            >
              Cancel
            </button>
            <button
              className="blue-btn"
              type="submit"
              data-testid="EditConfirmButton"
              value="submit"
              disabled={false}
            >
              {isLoading ? "Saving..." : "Confirm and Save"}
            </button>
          </div>
        </div> */}

          <Row className="mt-3 d-flex flex-row-reverse">
            <Col md={3} className="text-right">
              <button
                className="blue-btn"
                type="submit"
                onClick={() => {
                  tinInput === "000000000" && setTinInput("");
                  onSubmitFun(values);
                  // handleSubmit();
                }}
                value="submit"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
