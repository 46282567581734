import React, { useState, useMemo, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { listToRecord } from "utils";
import "./_style.scss";
import PracticePreview from "Components/UI/Molecules/PracticePreview";
import PracticeDropdown from "Components/Smart-Components/PracticeDropdown";

function PracticeBanner({ practiceListData, selectHandler, titleHandler }) {
  const [selectedPracticeId, setSelectedPracticeID] = useState(
    () => localStorage.getItem("selectedPracticeId") || practiceListData?.practiceDetails[0]?.practiceId
  );

  const practiceMap = useMemo(
    () => listToRecord(practiceListData?.practiceDetails, "practiceId"),
    [practiceListData]
  );
  const clickHandler = (practiceId) => {
    setSelectedPracticeID(practiceId);
    selectHandler(practiceId);
  };

  const selectedPractice =
    practiceMap[selectedPracticeId] || practiceListData?.practiceDetails[0];

  const { practiceName, locationCount, providerCount } = selectedPractice;

  useEffect(() => {
    titleHandler(practiceName);
  }, [practiceName]);

  useEffect(() => {
    localStorage.setItem("selectedPracticeId", selectedPracticeId);
  }, [selectedPracticeId]);

  return (
    <React.Fragment>
      <Container>
        <div className="practice-banner">
          <Row>
            <Col md={10} xs={12}>
              <PracticePreview
                practiceTitle={practiceName}
                locationCount={locationCount}
                providerListCount={providerCount}
              />
            </Col>
            <Col md={2} xs={12}>
              <PracticeDropdown
                practiceListData={practiceListData?.practiceDetails}
                selectedPracticeId={selectedPracticeId}
                practiceMap={practiceMap}
                onItemSelected={clickHandler}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default PracticeBanner;
