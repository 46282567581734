import React from "react";
import moment from "moment/moment";
import labels from "../../../Static/Dictionary.json";
import { Block } from "../../Atoms/Block";
import { Row } from "react-bootstrap";

export const ChangeRequest = ({ ChangeRequestData }) => {
  return (
    <>
      <div className="Approvalbox">
        <div className="mainblockbox">
          <Row>
            <Block
              labelText={labels.Request_Type}
              details={ChangeRequestData?.requestType}
            />
            <Block
              labelText={labels.Change_Initiated_By}
              details={ChangeRequestData?.changetInitiatedBy}
            />
            <Block
              labelText={labels.Change_Initiated_Date}
              details={
                ChangeRequestData?.changeInitiatedDate
                  ? moment(ChangeRequestData?.changeInitiatedDate).format("MM/DD/YYYY")
                  : "--"
              }
            />
          </Row>
        </div>
      </div>
    </>
  );
};
