import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import { firstLetterOfTwo } from "utils";
import { Link } from "react-router-dom";
import { Container, Col, Row, Breadcrumb, Alert } from "react-bootstrap";
import practiceIcon from "./images/Icon-b-practice.svg";
import updatedIcon from "./images/updatedIcon.svg";
import closeIcon from "./images/closeIcon.svg";
import providerListIcon from "./images/Icon-b-provider-list.svg";
import userIcon from "./images/Icon-b-user.svg";
import moment from "moment/moment";
import labels from "Static/Dictionary.json";
import AddNewPracticeLocation from "Components/UI/Molecules/AddNewPracticeLocation";
import UpdateRequired from "./images/Icon-update-required.svg";
import Updated from "./images/Icon-updated.svg";
import Inactive from "./images/Icon-inactive.svg";
import ApproveIcon from "Components/UI/Atoms/ApproveIcon";
import ApprovalcloseIcon from "./images/close-icon.svg";
import AddIcon from "../PracticeInformation/AddIcon.svg";
import AlertWarning from "Components/UI/Atoms/AlertWarning";
import { prefixData } from "utils";
import Spinner from "Components/Hooks/Spinner";

export const providerStatus = (givenDate, inactiveProvider) => {
  var lastConfirmedDate = givenDate;
  var today = new Date();
  var termDate = new Date(inactiveProvider);
  var termDiff = termDate - today;
  var diff = moment(today).diff(lastConfirmedDate, "days");

  if (termDiff < 0 && inactiveProvider !== null) {
    return (
      <p className="statusText" data-testid="inactive">
        <img src={Inactive} alt="Inactive" />
        &nbsp;Inactive
      </p>
    );
  } else if (diff > 90) {
    return (
      <p className="statusText" data-testid="updateRequired">
        <img src={UpdateRequired} alt="Update Required" />
        &nbsp;Update Required
      </p>
    );
  } else {
    return (
      <p className="statusText" data-testid="updated">
        <img src={Updated} alt="Updated" />
        &nbsp;Updated
      </p>
    );
  }
};

const ProviderSummary = ({
  practiceTitle,
  providerSummary,
  isproviderServerError,
  id,
  state,
  handleClose,
  selectedPracticeId,
  PersonRole
}) => {
  const [changeFieldData, setChangeFieldData] = useState([]);
  const token = cookies.get("access");
  const selectedPracticeId1 = localStorage.getItem("selectedPracticeId") || selectedPracticeId;
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const fetchPracticeChangeRecords = () => {
    fetch(
      base_url +
      `/api/v1/providers/GetProviderUnderProcessById?providerId=${id}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setChangeFieldData(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchPracticeChangeRecords();
    };
    fetch();
  }, [id]);
  console.log("cd", changeFieldData, providerSummary?.prefixId);
  const providerNpiData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "NPI"
    ) : "";
  const providerSpeialtyData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "boardspecialty"
    ) : "";
  const providerPrimaryLocationData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "PrimaryLocation"
    ) : "";

  const firstLetters = firstLetterOfTwo(
    providerSummary.firstName,
    providerSummary.lastName
  );
  const specialtyDetailsByspecialtype =
    providerSummary?.specialtySpecialtyType?.find(
      (item) => item?.specialtyType?.typeSpecialty === "Board Specialty1"
    );
  const prefferedEmailByemailtype = providerSummary?.email?.find(
    (item) => item?.emailType?.typeEmail === "Preferred"
  );
  const sentaraEmailByemailtype = providerSummary?.email?.find(
    (item) => item?.emailType?.typeEmail === "Sentara"
  );
  const handleClickStorage = () => {
    localStorage.removeItem("tab");
  };
  return (
    <div>
      {isproviderServerError && <Spinner />}
      {!isproviderServerError && (
        <Container className="mt-40">
          <Breadcrumb>
            <Breadcrumb.Item data-testid="practicetitle">
              <Link
                to={"/"}
                data-testid="practicename"
                onClick={handleClickStorage}
              >
                <img src={practiceIcon} alt="Practice Icon" /> {practiceTitle}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item data-testid="providerlist">
              <Link to={"/"} data-testid="providerlist">
                <img src={providerListIcon} alt="Practice Icon" /> Provider List
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active data-testid="providername">
              <img src={userIcon} alt="Practice Icon" />
              {providerSummary?.firstName} {providerSummary?.lastName}
            </Breadcrumb.Item>
          </Breadcrumb>
          {state?.isEdit && (
            <div>
              <Alert variant="success" data-testid="providerProfileupdated">
                <img
                  src={updatedIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
                Provider profile has been successfully updated
                <button
                  onClick={handleClose}
                  className="close-icon-button"
                  data-testid="providerProfileupdatedclose"
                >
                  <img
                    src={closeIcon}
                    alt="Updated Icon"
                    className="updated-icon"
                  />
                </button>
              </Alert>
            </div>
          )}
          {state?.isNEdit && (
            <AlertWarning
              ApprovalIcon={AddIcon}
              ApprovalcloseIcon={ApprovalcloseIcon}
              AlertText="Changes to provider information sent for SQCN Admin review"
              ALertHandleClick={handleClose}
            />
          )}
          {state?.isCreate && (
            <div>
              <Alert variant="success" data-testid="NewproviderProfile">
                <img
                  src={updatedIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
                New Provider successfully updated
                <button
                  onClick={handleClose}
                  className="close-icon-button"
                  data-testid="NewproviderProfileclose"
                >
                  <img
                    src={closeIcon}
                    alt="Updated Icon"
                    className="updated-icon"
                  />
                </button>
              </Alert>
            </div>
          )}
          <div className="view-provider">
            <Row className="provider-summary-actions">
              <Col md={6} xs={12}>
                <>
                  <p className="updated-text" data-testid="providerstatus">
                    {providerStatus(
                      providerSummary?.lastConfirmed,
                      providerSummary?.practiceAssignment?.find((practice) =>
                        practice?.practiceId === parseInt(selectedPracticeId1))?.termDate
                    )}
                  </p>
                </>
              </Col>
              <Col md={6} xs={12} className="text-right">
                <span
                  className="last-updated-time"
                  data-testid="providerlastupdatedtime"
                >
                  Last update on{" "}
                  {moment
                    .parseZone(providerSummary?.lastConfirmed)
                    .local()
                    .format("MM/DD/YYYY [at] hh:mm A")}
                </span>
                {PersonRole !== "SQCNAdmin" &&
                  <Link
                    className="edit-provider-button"
                    data-testid="Editbuttonlink"
                    to={`/edit-provider/${id}`}
                  >
                    Edit
                  </Link>
                }
              </Col>
            </Row>
            <Row>
              <Col md={2} xs={12}>
                <div className="provider-profile">
                  <div
                    className="firstletter justify-content-center  d-flex align-items-center"
                    data-testid="providerfirstname"
                  >
                    {firstLetters}
                  </div>
                </div>
              </Col>
              <Col md={10} xs={12}>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.PREFIX}</div>
                    <div className="info" data-testid="prefixdata">
                      {prefixData(providerSummary?.prefixId)}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.FIRST_NAME}</div>
                    {providerSummary?.firstName ? (
                      <div className="info" data-testid="firstNamedata">
                        {providerSummary?.firstName}
                      </div>
                    ) : (
                      <div className="info" data-testid="firstNamenodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.MIDDLE_INTITIAL}</div>
                    {providerSummary?.middleName ? (
                      <div className="info" data-testid="midlleNamedata">
                        {providerSummary.middleName}
                      </div>
                    ) : (
                      <div className="info" data-testid="middleNamenodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.LAST_NAME}</div>
                    {providerSummary?.lastName ? (
                      <div className="info" data-testid="lastNamedata">
                        {providerSummary?.lastName}
                      </div>
                    ) : (
                      <div className="info" data-testid="lastNamenodata">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.TITLE}</div>
                    {providerSummary?.titleType[0]?.title ? (
                      <div className="info" data-testid="titledata">
                        {providerSummary?.titleType[0]?.title}
                      </div>
                    ) : (
                      <div className="info" data-testid="titlenodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.BOARD_SPECIALITY}
                      {providerSpeialtyData?.status === "Pending" && (
                        <span>
                          <ApproveIcon />
                        </span>
                      )}
                    </div>
                    {providerSpeialtyData?.newValue2 || providerSpeialtyData?.newValue || specialtyDetailsByspecialtype?.specialty?.specialtyName ? (
                      <div className="info" data-testid="specialtydata">
                        {providerSpeialtyData?.newValue2 || providerSpeialtyData?.newValue ||
                          specialtyDetailsByspecialtype?.specialty
                            ?.specialtyName
                        }
                      </div>
                    ) : (
                      <div className="info" data-testid="specialtynodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.NPI}
                      {providerNpiData?.status === "Pending" && (
                        <span>
                          <ApproveIcon />
                        </span>
                      )}
                    </div>
                    {(providerNpiData?.length > 0 && providerNpiData?.newValue) || providerSummary?.npi ? (
                      <div className="info" data-testid="npidata">
                        {providerNpiData?.newValue || providerSummary?.npi}
                      </div>
                    ) : (
                      <div className="info" data-testid="npinodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.MDOFFICE_ID}</div>
                    <div className="info">{providerSummary?.mdofficeId}</div>
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.PREFERED_EMAIL_ADDRESS}</div>
                    {prefferedEmailByemailtype?.emailAddress ? (
                      <div className="info" data-testid="emaildata">
                        {prefferedEmailByemailtype?.emailAddress}
                      </div>
                    ) : (
                      <div className="info" data-testid="emailnodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.SENTARA_EMAIL_ADDRESS}</div>
                    {sentaraEmailByemailtype?.emailAddress ? (
                      <div className="info" data-testid="sentaraEmaildata">
                        {sentaraEmailByemailtype?.emailAddress ||
                          sentaraEmailByemailtype.emailAddress === ""}
                      </div>
                    ) : (
                      <div className="info" data-testid="sentaraEmailnodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block"></div>
                  <div className="block"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12}>
                <div>
                  <div className="location-banner provider-loc" >
                    <h4>Provider Locations</h4>
                  </div>
                  <AddNewPracticeLocation
                    providerSummaryData={providerSummary}
                    selectedPracticeId1={selectedPracticeId1}
                    providerPrimaryLocationData={providerPrimaryLocationData}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
};

export default ProviderSummary;
