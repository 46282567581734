import React from "react";
import labels from "../../../Static/Dictionary.json";
import { Block } from "../../Atoms/Block";
import { Row } from "react-bootstrap";
import { prefixName } from "utils";

export const ProviderInformation = ({ providerInfoData }) => {
  return (
    <>
      <div className="Approvalbox">
        <div className="mainblockbox mb-2">
          <Row>
            <Block
              labelText={labels.Provider_Prefix}
              details={prefixName(`${providerInfoData?.providerPrefix}`)}
            />
            <Block
              labelText={labels.Provider_Name}
              details={providerInfoData?.providerFirstName ? `${providerInfoData?.providerFirstName} ${" "} 
              ${providerInfoData?.providerMiddleName ? providerInfoData?.providerMiddleName : ""} ${" "}
              ${providerInfoData?.providerLastName}` : "--"}
            />
            <Block labelText={labels.Title} details={providerInfoData?.title} />
          </Row>
        </div>
        <div className="mainblockbox mb-2">
          <Row>
            <Block
              labelText={labels.MDOFFICE_ID}
              details={providerInfoData?.mdooficeId}
            />
            <Block
              labelText={labels.Provider_Preferred_Email}
              details={providerInfoData?.providerPreferredEmail}
            />
          </Row>
        </div>
      </div>
    </>
  );
};
