import Modal from "react-bootstrap/Modal";

function LocationValidationModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="add-provider-modal"
      data-testid="addprovidermodal"
    >
      <Modal.Header closeButton className="btn_close_padding">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="margin_bottom1"
        >
          Valid Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="margin_bottom2">{props.locationdetails}</p>
        <p>{props.locationDetails2}</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button
          onClick={() => {
            props.onHide();
          }}
          className="white-btn"
          data-testid="close-button"
        >
          Skip
        </button>
        <button
          onClick={() => {
            props.onHide();
            props.setLocDetails(true);
          }}
          className="rounded-pill save_btn blue-btn btn btn-primary"
          data-testid="close-button"
        >
          Proceed
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default LocationValidationModal;
