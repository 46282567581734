import React, { useState } from "react";
import moment from "moment/moment";
import labels from "../../Static/Dictionary.json";
import details from "../../Static/Practice.json";
import { Block } from "../../UI/Atoms/Block";
import { Col, Row } from "react-bootstrap";
import { Heading } from "../../UI/Atoms/Heading";
import Checkbox from "../../UI//Atoms/Checkbox";
import { ReviewSubmit } from "../PracticeApprovalBoard/ReviewSubmit";
import { prefixName } from "utils";

export const AddNewProvider = ({
  RequestTypeData,
  addProviderReviewData,
  practiceInfoId,
  providerReviewApproval,
  providerReviewReject,
  fetchReviewProviderList,
  approvedStatusChange,
  statusChange,
  statusChangeReject,
  rejectStatusChange,
  setChangeProviderMode
}) => {
  const [isCheck, setIsCheck] = useState([]);

  const handleClick = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  console.log("isc", isCheck);
  const providerLocationsData = addProviderReviewData?.providerLocations;
  return (
    <>
      <Heading text="Add Provider" />
      <div className="Approvalbox">
        <div className="location-name-box">
          <Row>
            <Col md={10}>
              <div className="checkbox-text">
                {addProviderReviewData?.approvalStatus === "Pending" &&
                  !approvedStatusChange && !rejectStatusChange &&
                  <Checkbox
                    type="checkbox"
                    id={0}
                    handleClick={(e) => handleClick(e, 0)}
                    isChecked={isCheck.includes(0)}
                  />
                }
              </div>
              <h6>{addProviderReviewData?.providerFirstName ? <>
                {addProviderReviewData?.providerFirstName}&nbsp;
                {addProviderReviewData?.providerMiddletName}&nbsp;
                {addProviderReviewData?.providerLastName}</> : "--"}
              </h6>
            </Col>
            <Col md={2}>
              {!approvedStatusChange && !rejectStatusChange &&
                <p className="status-pending mt-1">Pending</p>
              }
              {approvedStatusChange && providerReviewApproval &&
                <p className="status-approve mt-1">Approved</p>
              }
              {rejectStatusChange && providerReviewReject &&
                <p className="status-rejected mt-1">Rejected</p>
              }
            </Col>
          </Row>
        </div>
        <div className="mainblockbox mb-2">
          <Row>
            <Block
              labelText={labels.Provider_Prefix}
              details={prefixName(`${addProviderReviewData?.providerPrefix}`)}
            />
            <Block
              labelText={labels.Provider_Name}
              details={addProviderReviewData?.providerFirstName ? `${addProviderReviewData?.providerFirstName} ${" "}
              ${addProviderReviewData?.providerMiddletName ? addProviderReviewData?.providerMiddletName : ""} ${" "} 
              ${addProviderReviewData?.providerLastName}` : "--"}
            />
            <Block labelText={labels.Title} details={addProviderReviewData?.title} />
          </Row>
        </div>
        <div className="mainblockbox mb-2">
          <Row>
            <Block labelText={labels.NPI} details={addProviderReviewData?.npi} />
            <Block
              labelText={labels.Board_Specialty}
              details={addProviderReviewData?.boardSpecialty}
            />
            <Block
              labelText={labels.MDOFFICE_ID}
              details={addProviderReviewData?.mdOfficeId}
            />
          </Row>
        </div>
        <div className="mainblockbox">
          <Row>
            <Block
              labelText={labels.Provider_Preferred_Email}
              details={addProviderReviewData?.providerPreferredEmail}
            />
            <Block
              labelText={labels.Sentara_Email}
              details={addProviderReviewData?.providerSentaraEmail}
            />
          </Row>
        </div>
        {providerLocationsData?.length > 0 &&
          <div>

            <p className="location-text">{details.Location_Information} </p>
            {providerLocationsData?.map((item) => (
              <div className="mainblockbox mb-2" key={item?.locationId}>
                <Row>
                  <Block
                    labelText={labels.Provider_Location}
                    details={item?.providerLocation}
                  />
                  <Block
                    labelText={labels.Provider_at_Location}
                    details={item?.providerAtLocation}
                  />
                  <Block
                    labelText={labels.Location_Type}
                    details={item?.locationAssignmentType}
                  />
                </Row>
                <Row className="mt-3">
                  <Block
                    labelText={labels.Start_Date}
                    details={
                      item?.startDate
                        ? moment(item?.startDate).format("MM/DD/YYYY")
                        : "--"
                    }
                  />
                  <Block
                    labelText={labels.End_Date}
                    details={
                      item?.endDate
                        ? moment(item?.endDate).format("MM/DD/YYYY")
                        : "--"
                    }
                  />
                </Row>
              </div>
            ))}
          </div>
        }
      </div>
      <ReviewSubmit
        RequestTypeDataID={RequestTypeData}
        isCheck={isCheck}
        personReqId={addProviderReviewData?.personId}
        practiceInfoId={practiceInfoId}
        fetchReviewProviderList={fetchReviewProviderList}
        providerReviewApproval={providerReviewApproval}
        providerReviewReject={providerReviewReject}
        setIsCheck={setIsCheck}
        statusChange={statusChange}
        statusChangeReject={statusChangeReject}
        setChangeProviderMode={setChangeProviderMode}
        isProviderApproval={true}
      />
    </>
  );
};
