import { Container, Alert } from "react-bootstrap";
import icon from "./Icon.svg";

const errorStyle = {
  color: "#000",
};

export default function ErrorServer() {
  return (
    <Alert variant="danger" data-testid="error-server">
      <Container>
        <p className="mb-0" style={errorStyle}>
          <img src={icon} alt="error-icon" className="error-icon"></img>{" "}
          Something went wrong. Please try again.
        </p>
      </Container>
    </Alert>
  );
}
