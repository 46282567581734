import React from "react";
import { Row, Col } from "react-bootstrap";
import LocationPreview from "Components/UI/Molecules/LocationPreview";
import LocationDropdown from "Components/Smart-Components/LocationDropdown";
import locationIcon from "./mapIcon.svg";
import "./_style.scss";

function ChangeLocation({
  locationListData,
  locationTitle,
  selectedLocationId,
  locationMap,
  onItemSelected,
  setLocationBox,
  manageStyle
}) {
  return (
    <React.Fragment>
      <div className="location-banner">
        <Row>
          <Col md={7} xs={12}>
            <LocationPreview locationTitle={locationTitle} />
          </Col>
          <Col md={5} xs={12}>
            <Row>
              <Col md={6} xs={12} className="mange-column">
                <button  style={manageStyle} className="manage-button" onClick={() => setLocationBox(true)}>
                  <img
                    src={locationIcon}
                    alt="Location Icon"
                    className="location-icon"
                  />Manage Locations
                </button>
              </Col>
              <Col md={6} xs={12} className="location-column">
                <LocationDropdown
                  locationListData={locationListData}
                  selectedLocationId={selectedLocationId}
                  locationMap={locationMap}
                  onItemSelected={onItemSelected}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default ChangeLocation;
