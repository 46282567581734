/* eslint-disable indent */
import React from "react";
import("./_style.scss");

export const BannerHeading = (props) => {
  if (!props?.npiSearchBanner) {
    return (
      <>
        <div className="manage-practice-banner">
          <h4 className="practice-Heading">{props?.headingText}</h4>
          <p className="sub-heading">{props?.subText}</p>
        </div>
      </>
    );
  } else {
    const { name, npi } = props.headingText;
    return (
      <>
        <div className="manage-practice-banner">
          <h4 className="practice-Heading">{name + "  " + npi}</h4>
          <p className="sub-heading">{props?.subText}</p>
        </div>
      </>
    );
  }
};
