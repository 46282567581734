export const listToRecord = (list, mapKey) => {
  return list?.reduce((prevValue, nextValue) => {
    prevValue[nextValue[mapKey]] = nextValue;
    return prevValue;
  }, {});
};

export const sortAlphabaticalOrder = (list) =>
  list?.sort((a, b) => (a?.practiceName > b?.practiceName ? 1 : 1));

export const sortAlphabaticalOrderLocation = (list) =>
  list?.sort((a, b) => (a.locationName > b.locationName ? 1 : -1));

export const findByPracticeRoleType = (data, searchTerm) =>
  data?.filter((obj) =>
    obj.practiceAssignment?.find(
      (practiceAssignment) => practiceAssignment.roleType.role === searchTerm
    )
  );

// export const findByChangeFields = (data, searchTerm) =>
//   data?.length > 0 && (data?.filter((item) => item?.field === searchTerm)
//   );
export const firstLetterOfTwo = (firstName, lastName) =>
  firstName?.charAt(0)?.toUpperCase() + lastName?.charAt(0)?.toUpperCase();

export const getLocations = (data) => {
  let locations = [];
  let locationWithPracticeId = [];
  for (let i = 0; i < data?.length; i++) {
    locationWithPracticeId = data[i]?.location?.map((location) => ({
      pratciId: data[i]?.id,
      ...location,
    }));

    const location = [...locationWithPracticeId];
    locations = [...locations, ...location];
  }
  return locations;
};

export const getLocationList = (data) => {
  console.log("gll", data);
  let locationList = [];
  for (let i = 0; i < data?.length; i++) {
    for (let j = 0; j < data[i]?.locationDetails?.length; j++) {
      let obj = {
        ...data[i]?.locationDetails[j],
        pratciId: data[i]?.practiceId,
      };
      locationList.push(obj);
    }
  }
  console.log("llll", locationList);
  return locationList;
};

export const filterByLocationName = (data, searchTerm) =>
  data.filter((location) =>
    location.locationName.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const changeStatus = (givenStatus) => {
  if (givenStatus === "Approved") {
    return <p className="status-approve">Approved</p>;
  } else if (givenStatus === "Rejected") {
    return <p className="status-rejected">Rejected</p>;
  } else {
    return <p className="status-pending">Pending</p>
  }
};

export const prefixData = (givenId) => {
  if (givenId === 1) {
    return "--";
  } else if (givenId === 2) {
    return "Mr";
  } else if (givenId === 3) {
    return "Ms"
  } else if (givenId === 4) {
    return "Dr"
  } else {
    return "--"
  }
};

export const prefixName = (givenData) => {
  if (givenData === "") {
    return "--";
  } else if (givenData === "Mr") {
    return "Mr";
  } else if (givenData === "Ms") {
    return "Ms"
  } else if (givenData === "Dr") {
    return "Dr"
  } else {
    return "--"
  }
};

export const uniqueObjectsFilterById = (data) => {
  const uniqueObj = [];
  const uniqueIds = new Set();
  data?.forEach((item) => {
    if (!uniqueIds?.has(item?.id)) {
      uniqueIds?.add(item?.id)
      uniqueObj?.push((item))
    }
  })
  return uniqueObj;
}

export const convertBoolean=(v)=>{ return v==="false" || v==="null" || v==="NaN" || v==="undefined" || v==="0" ? false : !!v;  }
export const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/

export const ALPHABETS_AND_SPECIAL_CHARS_REGEX = /^[A-Za-z@~`!@#$%^&*()_=+\\';:"/?><,.\s-]*$/;

export const phoneNumberAutoFormat = (phoneNumber) => {
  const number = phoneNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");

};
