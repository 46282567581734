import { useParams } from "react-router-dom";
import { useFetch } from "Components/Hooks/Fetch";
import Spinner from "Components/Hooks/Spinner";
import AddProvider from "../ProviderList/AddProvider";
import jwt_decode from "jwt-decode";
import cookies from "js-cookie";

const AddProviderPage = ({
  practiceTitle,
  selectedPracticeId,
  practiceManagerId,
}) => {
  const { id } = useParams();
  const token = cookies.get("access");
  const decodeToken = jwt_decode(token);
  const { data, loading } = useFetch(`/api/v1/providers/${id}?practicemanagerid=${decodeToken?.id || practiceManagerId}`);
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <AddProvider
        providerSummary={data}
        id={id}
        selectedPracticeId={selectedPracticeId}
        practiceTitle={practiceTitle}
        practiceManagerId={decodeToken?.id || practiceManagerId}
      />
    </>
  );
};

export default AddProviderPage;
