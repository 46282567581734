import React, { useState } from "react";
import labels from "../../Static/Dictionary.json";
import { Table } from "react-bootstrap";
import Checkbox from "../../UI/Atoms/Checkbox";
import { ReviewSubmit } from "../PracticeApprovalBoard/ReviewSubmit";
import { changeStatus } from "utils";
import cookies from "js-cookie";

export const ChangeProviderInformation = ({
  RequestTypeData,
  providerReviewId,
  changeProviderReviewData,
  practiceInfoId,
  providerReviewApproval,
  providerReviewReject,
  statusChange,
  statusChangeReject,
  setChangeProviderMode,
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [changeProviderList, setChangeProviderList] = useState(changeProviderReviewData);

  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;

  const fetchReviewChangeProviderList = () => {
    fetch(
      base_url +
      `/api/v1/providers/GetProviderReviewDetails?requestTypeId=${providerReviewId[0]}&practiceId=${providerReviewId[1]}&personId=${providerReviewId[3]}&updateUser=${providerReviewId[5]}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setChangeProviderList(data?.changeFieldDetails);
      });
  };

  const handleSelectAll = () => {
    console.log("cal", isCheckAll);
    if (!isCheckAll) {
      setIsCheckAll(!isCheckAll);
      const selectedValues = changeProviderReviewData?.filter(
        (item) => item?.approvalStatus !== "Rejected" &&
          item?.approvalStatus !== "Approved" &&
          item?.approvalStatus === "Pending"
      );
      setIsCheck(selectedValues?.map((item) => item?.changeFieldId));
    } else {
      setIsCheckAll(!isCheckAll);
      setIsCheck([]);
    }
  };
  const handleClick = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  console.log("isc", isCheck);
  return (
    <>
      {changeProviderList?.length > 0 &&
        <div>
          <div className="manage-location-table1">
            <Table data-testid="providerlisttable">
              <thead>
                <tr>
                  <th className="checkhead">
                    <Checkbox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                      className="dropbtn"
                    />
                  </th>
                  <th>{labels.Field_Name}</th>
                  <th>{labels.Old_Value}</th>
                  <th>{labels.New_Value}</th>
                  <th>{labels.Status}</th>
                </tr>
              </thead>
              <tbody data-testid="ProviderData">
                {changeProviderList?.map((item) => (
                  <tr key={item?.changeFieldId}>
                    <td className="checkhead">
                      {item?.approvalStatus === "Pending" &&
                        <Checkbox
                          key={item?.changeFieldId}
                          type="checkbox"
                          id={item?.changeFieldId}
                          handleClick={(e) => handleClick(e, item?.changeFieldId)}
                          isChecked={isCheck?.includes(item?.changeFieldId)}
                        />
                      }
                    </td>
                    <td>{item?.changeFieldName}</td>
                    <td>{item?.oldValue} </td>
                    <td>{item?.newValue}</td>
                    <td>{changeStatus(item?.approvalStatus)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <ReviewSubmit
            RequestTypeDataID={RequestTypeData}
            isCheck={isCheck}
            practiceInfoId={practiceInfoId}
            personReqId={changeProviderReviewData[0]?.personId}
            providerReviewApproval={providerReviewApproval}
            providerReviewReject={providerReviewReject}
            fetchReviewProviderList={fetchReviewChangeProviderList}
            setIsCheck={setIsCheck}
            statusChange={statusChange}
            statusChangeReject={statusChangeReject}
            setChangeProviderMode={setChangeProviderMode}
            isProviderApproval={true}
          />
        </div>
      }
    </>
  );
};
