import { useParams } from "react-router-dom";
import { useFetch } from "Components/Hooks/Fetch";
import Spinner from "Components/Hooks/Spinner";
import EditProvider from "../ProviderList/EditProvider";
import jwt_decode from "jwt-decode";
import cookies from "js-cookie";

const EditProviderPage = ({
  practiceTitle,
  selectedPracticeId,
  practiceManagerId,
}) => {
  const { id } = useParams();
  const token = cookies.get("access");
  const decodeToken = jwt_decode(token);
  console.log(
    "editt",
    practiceTitle,
    selectedPracticeId,
    practiceManagerId,
    id
  );
  const { data, loading } = useFetch(
    `/api/v1/providers/${id}?practicemanagerid=${decodeToken?.id || practiceManagerId}`
  );
  if (loading) {
    return <Spinner />;
  }
  console.log(data);
  return (
    <>
      <EditProvider
        practiceManagerId={decodeToken?.id || practiceManagerId}
        providerSummary={data}
        id={id}
        selectedPracticeId={selectedPracticeId}
        practiceTitle={practiceTitle}
      />
    </>
  );
};

export default EditProviderPage;
