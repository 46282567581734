import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import { PracticeInformation } from "../../UI/Organisms/PracticeInformation/PracticeInformation";
import { ChangeRequest } from "../../UI/Organisms/ChangeRequest";
import { ProviderInformation } from "../../UI/Organisms/ProviderInformation";
import { AddNewProvider } from "./AddNewProvider";
import { ChangeProviderInformation } from "./ChangeProviderInformation";
import ReviewAlert from "../../UI/Atoms/ReviewAlert";
import AddIcon from "../PracticeApprovalBoard/images/updatedIcon.svg";
import ApprovalcloseIcon from "../PracticeApprovalBoard/images/closeIcon.svg";
import RejectAddIcon from "../PracticeApprovalBoard/images/reject-Icon.svg";
import RejectcloseIcon from "../PracticeApprovalBoard/images/close-icon.svg";
import { AddExistingProvider } from "./AddExistingProvider";
import { Button } from "../../UI/Atoms/Button";

export const ReviewProviderApproval = ({
  providerReviewId,
  approvedStatusChange,
  statusChange,
  statusChangeReject,
  rejectStatusChange,
  approvedLocStatusChange,
  locStatusChange,
  rejectLocStatusChange,
  locRejectStatusChange,
  setChangeProviderMode
}) => {
  const [providerReviewList, setProviderReviewList] = useState([]);
  const [reviewApprove, setReviewApprove] = useState(false);
  const [reviewReject, setReviewReject] = useState(false);
  const [reviewLocApprove, setReviewLocApprove] = useState(false);
  const [reviewLocReject, setReviewLocReject] = useState(false);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  console.log("preId", providerReviewId);
  const fetchReviewProviderList = () => {
    fetch(
      base_url +
      `/api/v1/providers/GetProviderReviewDetails?requestTypeId=${providerReviewId[0]}&practiceId=${providerReviewId[1]}&personId=${providerReviewId[3]}&updateUser=${providerReviewId[5]}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setProviderReviewList(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchReviewProviderList();
    };
    fetch();
  }, []);
  const handleClick = () => {
    setReviewApprove(false);
    setReviewReject(false);
    setReviewLocReject(false);
    setReviewLocApprove(false);
  };
  return (
    <>
      {reviewApprove && !reviewLocApprove && !reviewLocReject &&
        ((providerReviewList?.providerReviewChangeDTO?.requestTypeId === 11) ||
          (providerReviewList?.providerReviewChangeDTO?.requestTypeId === 10)) &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="Addition of provider successfully approved"
          ALertHandleClick={handleClick}
        />
      }
      {reviewReject && 
        ((providerReviewList?.providerReviewChangeDTO?.requestTypeId === 11) ||
          (providerReviewList?.providerReviewChangeDTO?.requestTypeId === 10)) &&
        <ReviewAlert
          ApprovalIcon={RejectAddIcon}
          variant="warning"
          ApprovalcloseIcon={RejectcloseIcon}
          AlertText="Addition of provider rejected"
          ALertHandleClick={handleClick}
        />
      }
      {reviewApprove && providerReviewList?.providerReviewChangeDTO?.requestTypeId === 12 &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="Changes to provider information successfully approved"
          ALertHandleClick={handleClick}
        />
      }
      {reviewReject && providerReviewList?.providerReviewChangeDTO?.requestTypeId === 12 &&
        <ReviewAlert
          ApprovalIcon={RejectAddIcon}
          variant="warning"
          ApprovalcloseIcon={RejectcloseIcon}
          AlertText="Changes to provider information rejected"
          ALertHandleClick={handleClick}
        />
      }
      {reviewLocApprove &&
        <ReviewAlert
          ApprovalIcon={AddIcon}
          variant="success"
          ApprovalcloseIcon={ApprovalcloseIcon}
          AlertText="Primary location change request is successfully approved"
          ALertHandleClick={handleClick}
        />
      }
      {reviewLocReject &&
        <ReviewAlert
          ApprovalIcon={RejectAddIcon}
          variant="warning"
          ApprovalcloseIcon={RejectcloseIcon}
          AlertText="Primary location change request is Rejected"
          ALertHandleClick={handleClick}
        />
      }
      <Button
        text="Cancel"
        className="Cancel-button"
        onClick={() => setChangeProviderMode(false)}
        type="submit"
      />
      <ChangeRequest ChangeRequestData={providerReviewList?.providerReviewChangeDTO} />
      <PracticeInformation practiceInfoData={providerReviewList?.practiceDetails} />
      {providerReviewList?.providerReviewChangeDTO?.requestTypeId === 11 &&
        providerReviewList?.reviewAddProviderModel?.personId !== null &&
        <AddExistingProvider
          addProviderReviewData={providerReviewList?.reviewAddProviderModel}
          RequestTypeData={providerReviewList?.providerReviewChangeDTO.requestTypeId}
          updateUserId={providerReviewId[5]}
          practiceInfoId={providerReviewList?.practiceDetails?.practiceId}
          providerReviewApproval={() => setReviewApprove(true)}
          providerReviewReject={() => setReviewReject(true)}
          locationReviewApproval={() => setReviewLocApprove(true)}
          locationReviewReject={() => setReviewLocReject(true)}
          // fetchReviewProviderList={fetchReviewProviderList}
          approvedStatusChange={approvedStatusChange}
          locStatusChange={locStatusChange}
          approvedLocStatusChange={approvedLocStatusChange}
          statusChange={statusChange}
          rejectStatusChange={rejectStatusChange}
          statusChangeReject={statusChangeReject}
          rejectLocStatusChange={rejectLocStatusChange}
          locRejectStatusChange={locRejectStatusChange}
          setChangeProviderMode={setChangeProviderMode}
        />
      }
      {providerReviewList?.providerReviewChangeDTO?.requestTypeId === 10 &&
        providerReviewList?.reviewAddProviderModel?.personId !== null &&
        <AddNewProvider
          addProviderReviewData={providerReviewList?.reviewAddProviderModel}
          RequestTypeData={providerReviewList?.providerReviewChangeDTO.requestTypeId}
          practiceInfoId={providerReviewList?.practiceDetails?.practiceId}
          providerReviewApproval={() => setReviewApprove(true)}
          providerReviewReject={() => setReviewReject(true)}
          fetchReviewProviderList={fetchReviewProviderList}
          approvedStatusChange={approvedStatusChange}
          statusChange={statusChange}
          rejectStatusChange={rejectStatusChange}
          statusChangeReject={statusChangeReject}
          setChangeProviderMode={setChangeProviderMode}
        />
      }
      {providerReviewList?.providerReviewChangeDTO?.requestTypeId === 12 &&
        <>
          <ProviderInformation
            providerInfoData={providerReviewList?.providerDetails}
          />
          <ChangeProviderInformation
            changeProviderReviewData={providerReviewList?.changeFieldDetails}
            providerReviewId={providerReviewId}
            RequestTypeData={providerReviewList?.providerReviewChangeDTO?.requestTypeId}
            practiceInfoId={providerReviewList?.practiceDetails?.practiceId}
            providerReviewApproval={() => setReviewApprove(true)}
            providerReviewReject={() => setReviewReject(true)}
            // fetchReviewProviderList={fetchReviewProviderList}
            statusChange={statusChange}
            statusChangeReject={statusChangeReject}
            setChangeProviderMode={setChangeProviderMode}
          />
        </>
      }
    </>
  );
};
