/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, OverlayTrigger, Form, Container } from "react-bootstrap";
import cookies from "js-cookie";
import { findByPracticeRoleType } from "utils";
import labels from "Static/Dictionary.json";
import { Alert } from "react-bootstrap";

import tooltipIcon from "./tooltipIcon.svg";
import icon from "../ProviderList/Icon.svg";


import userRemoveIcon from "./images/icon-remove.svg";
import ErrorServer from "Components/Hooks/Error404";
import closeIcon from "../ProviderList/images/closeIcon.svg";
import updatedIcon from "../ProviderList/images/updatedIcon.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { EMAIL_REGEX,ALPHABETS_AND_SPECIAL_CHARS_REGEX } from "utils";
import { phoneNumberAutoFormat } from "utils";    
import("./_style.scss");

function ContactInformation({
  data,
  selectedPracticeId,
  isContactServerError,
  setEditModeBox,
  popover,
  popover1,
  popover2,
  popover3,
  titleData,
  suffixData,
  prefixData,
  setContactInformationData,
  type,
  practiceManagerId,
  SetShowSuccessMessage,
  fetchContactInformation
}) {
  const [pmData, setPMData] = useState();
  const [managerData, setManagerData] = useState();
  const [additionalPoc, setAdditionalPoc] = useState([]);
  const [adData, setAdData] = useState();
  const [directorData, setDirectorData] = useState([]);
  const [directFirstData, setDirectFirstData] = useState();
  const [executiveData, setExecutiveData] = useState([]);
  const [executiveFirstData, setExecutiveFirstData] = useState();
  const [pcData, setPcData] = useState([]);
  const [physcData, setPhyscData] = useState();
  const [validationError, setValidationError] = useState({});
  const [reInitialize, setReInitialize] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  //state to hold orginal mdofficeid change
  const [pmMDofficeDetails, setPmMDofficeDetails] = useState({phoneNumber:"", email:""});
  const [apcMDofficeDetails, setApcMDofficeDetails] = useState({phoneNumber:"", email:""});
  const [dirMDofficeDetails, setDirMDofficeDetails] = useState({phoneNumber:"", email:""});
  const [exeMDofficeDetails, setExeMDofficeDetails] = useState({phoneNumber:"", email:""});
  const [pcMDofficeDetails, setPcMDofficeDetails] = useState({phoneNumber:"", email:""});
  const [addSaveMessage, setAddSaveMessage] = useState(false);
  const [managerIdNull, setManagerIdNull] = useState(false);
  const [apcIdNull, setApcIdNull] = useState(false);
  const [dirIdNull, setDirIdNull] = useState(false);
  const [exeIdNull, setExeIdNull] = useState(false);
  const [pcIdNull, setPcIdNull] = useState(false);

  const base_url = process.env.REACT_APP_SERVER_URL;
  const provider_base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const prefix_mapTitles = ["16","41","24","61","31","62","63","5","11","36","1","66","67","32","68","22","84","23","45"];
  const errorStyle = {
    color: "#000",
  };

  const [practicePhysicianChampion] = findByPracticeRoleType(
    data,
    "Practice Physician Champion"
  );
  const [practiceManager] = findByPracticeRoleType(data, "Practice Manager");
  const [director] = findByPracticeRoleType(data, "Director");
  const [AdditionalPoint] = findByPracticeRoleType(
    data,
    "Additional Practice Contact"
  );
  const [executive] = findByPracticeRoleType(
    data,
    "Executive/Leadership Contact"
  );
  const primarySqcnByRoleTypeId = pmData?.practiceAssignment?.find(
    (item) => item?.roleTypeId === 5
  );
  const addprimarySqcnByRoleTypeId = adData?.practiceAssignment?.find(
    (item) => item?.roleTypeId === 5
  );
  const [additionalPocValuesData, setAdditionalPocValuesData] = useState({
    additionalID: additionalPoc[0]?.id || AdditionalPoint?.id || null,
    additionalFName: "",
    additionalLName: additionalPoc[0]?.lastName || adData?.lastName || "",
    additionalEmail: additionalPoc[0]?.email?.[0]?.emailAddress || adData?.email?.[0]?.emailAddress || "",
    additionalPhNo: additionalPoc[0]?.phoneNumber?.[0]?.number || adData?.phoneNumber?.[0]?.number || "",
    additionalPhNoExt: additionalPoc[0]?.phoneNumber?.[0]?.extention || adData?.phoneNumber?.[0]?.extention || "",
    additionalMdId: additionalPoc[0]?.mdofficeId || adData?.mdofficeId || "",
    additionalSqcnY: (additionalPoc[0]?.practiceAssignment &&
      additionalPoc[0]?.practiceAssignment.find(item => item?.roleTypeId === 5)
      ? "Yes"
      : false) || (addprimarySqcnByRoleTypeId?.roleTypeId === 5 ? "Yes" : "No"),
    additionalSqcnN: addprimarySqcnByRoleTypeId?.roleTypeId !== 5 ? "No" : "Yes" || true
  });
  const [directorvalueData, setDirectorValueData] = useState({
    directorID: directorData && directorData[0]?.id && directorData[0]?.id ||
      director && director?.id && director?.id || null,
    directorFName: directorData && directorData[0]?.firstName && directorData[0]?.firstName ||
      directFirstData?.firstName && directFirstData?.firstName !== "" && directFirstData?.firstName || "",
    directorLName: directorData && directorData[0]?.lastName && directorData[0]?.lastName ||
      directFirstData?.lastName && directFirstData?.lastName !== "" && directFirstData?.lastName || "",
    directorMName: directorData && directorData[0]?.middleName && directorData[0]?.middleName ||
      directFirstData?.middleName && directFirstData?.middleName !== "" && directFirstData?.middleName || "",
    directorSuffix : directorData && directorData[0]?.suffixId && directorData[0]?.suffixId ||
      directFirstData?.suffixId && directFirstData?.suffixId !== "" && directFirstData?.suffixId || "",
    directorPrefix : directorData && directorData[0]?.prefixId && directorData[0]?.prefixId ||
      directFirstData?.prefixId && directFirstData?.prefixId !== "" && directFirstData?.prefixId || "",
    directorTitle : directorData && directorData[0]?.titleId && directorData[0]?.titleId ||
      directFirstData?.titleId && directFirstData?.titleId !== "" && directFirstData?.titleId || "",
    directorEmail:
      (directorData && directorData[0]?.email && directorData[0].email[0]?.emailAddress) ||
      (directFirstData && directFirstData.email && directFirstData.email[0]?.emailAddress) ||
      "",
    directorPhNo:
      (directorData && directorData[0]?.phoneNumber && directorData[0].phoneNumber[0]?.number) ||
      (directFirstData && directFirstData.phoneNumber && directFirstData.phoneNumber[0]?.number) ||
      "",
    directorPhNoExt:
      (directorData && directorData[0]?.phoneNumber && directorData[0]?.phoneNumber[0]?.extention) ||
      (directFirstData && directFirstData?.phoneNumber && directFirstData?.phoneNumber[0]?.extention) ||
      "",
    directorMdId: directorData && directorData[0]?.mdofficeId && directorData[0]?.mdofficeId ||
      directFirstData?.mdofficeId && directFirstData?.mdofficeId !== "" && directFirstData?.mdofficeId || "",
    directorSqcnY: (directorData &&
      directorData[0]?.practiceAssignment &&
      directorData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
      ? "Yes"
      : false) ||
      (directFirstData?.practiceAssignment?.find((item) => item?.roleTypeId === 5) ? "Yes" : "No"),
    directorSqcnN: directFirstData?.practiceAssignment?.find((item) => item?.roleTypeId !== 5) ? "No" : "Yes" || true
  });
  const [executiveValueData, setExecutiveValueData] = useState({
    executiveID: executiveData && executiveData[0]?.id && executiveData[0]?.id ||
      executive && executive?.id && executive?.id || null,
    executiveFName: executiveData && executiveData[0]?.firstName && executiveData[0]?.firstName ||
      executiveFirstData?.firstName && executiveFirstData?.firstName !== "" && executiveFirstData?.firstName || "",
    executiveLName: executiveData && executiveData[0]?.lastName && executiveData[0]?.lastName ||
      executiveFirstData?.lastName && executiveFirstData?.lastName !== "" && executiveFirstData?.lastName || "",
    executiveMName: executiveData && executiveData[0]?.middleName && executiveData[0]?.middleName ||
      executiveFirstData?.middleName && executiveFirstData?.middleName !== "" && executiveFirstData?.middleName || "",
    executiveSuffix : executiveData && executiveData[0]?.suffixId && executiveData[0]?.suffixId ||
      executiveFirstData?.suffixId && executiveFirstData?.suffixId !== "" && executiveFirstData?.suffixId || "",
    executivePrefix : executiveData && executiveData[0]?.prefixId && executiveData[0]?.prefixId ||
      executiveFirstData?.prefixId && executiveFirstData?.prefixId !== "" && executiveFirstData?.prefixId || "",
    executiveTitle : executiveData && executiveData[0]?.titleId && executiveData[0]?.titleId ||
      executiveFirstData?.titleId && executiveFirstData?.titleId !== "" && executiveFirstData?.titleId || "",
    executiveEmail:
      (executiveData && executiveData[0]?.email && executiveData[0].email[0]?.emailAddress) ||
      (executiveFirstData && executiveFirstData.email && executiveFirstData.email[0]?.emailAddress) ||
      "",
    executivePhNo:
      (executiveData && executiveData[0]?.phoneNumber && executiveData[0].phoneNumber[0]?.number) ||
      (executiveFirstData && executiveFirstData.phoneNumber && executiveFirstData.phoneNumber[0]?.number) ||
      "",
    executivePhNoExt:
      (executiveData && executiveData[0]?.phoneNumber && executiveData[0]?.phoneNumber[0]?.extention) ||
      (executiveFirstData && executiveFirstData?.phoneNumber && executiveFirstData?.phoneNumber[0]?.extention) ||
      "",
    executiveMdId: executiveData && executiveData[0]?.mdofficeId && executiveData[0]?.mdofficeId ||
      executiveFirstData?.mdofficeId && executiveFirstData?.mdofficeId !== "" && executiveFirstData?.mdofficeId || "",
  });
  const [physicianValueData, setPhysicianvalueData] = useState({
    PhysicianID: pcData && pcData[0]?.id && pcData[0]?.id ||
      practicePhysicianChampion && practicePhysicianChampion?.id && practicePhysicianChampion?.id || null,
    PhysicianFName: pcData && pcData[0]?.firstName && pcData[0]?.firstName || physcData?.firstName || "",
    PhysicianMName: pcData && pcData[0]?.middleName && pcData[0]?.middleName || physcData?.middleName || "",
    PhysicianSuffix: pcData && pcData[0]?.suffixId && pcData[0]?.suffixId || physcData?.suffixId || "",
    PhysicianPrefix: pcData && pcData[0]?.prefixId && pcData[0]?.suffixId || physcData?.prefixId || "",
    PhysicianTitle: pcData && pcData[0]?.titleId && pcData[0]?.suffixId || physcData?.titleId || "",
    PhysicianLName: pcData && pcData[0]?.lastName && pcData[0]?.lastName || physcData?.lastName || "",
    PhysicianEmail:
      (pcData && pcData[0]?.email && pcData[0].email[0]?.emailAddress) ||
      (physcData && physcData.email && physcData.email[0]?.emailAddress) ||
      "",
    physicianPhNo:
      (pcData && pcData[0]?.phoneNumber && pcData[0].phoneNumber[0]?.number) ||
      (physcData && physcData.phoneNumber && physcData.phoneNumber[0]?.number) ||
      "",
    physicianPhNoExt:
      (pcData && pcData[0]?.phoneNumber && pcData[0]?.phoneNumber[0]?.extention) ||
      (physcData && physcData?.phoneNumber && physcData?.phoneNumber[0]?.extention) ||
      "",
    PhysicianMdId: pcData && pcData[0]?.mdofficeId && pcData[0]?.mdofficeId ||
      physcData?.mdofficeId || "",
    physicianSqcnY: (pcData &&
      pcData[0]?.practiceAssignment &&
      pcData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
      ? "Yes"
      : false) ||
      (physcData?.practiceAssignment?.find((item) => item?.roleTypeId === 5) ? "Yes" : "No"),
    physicianSqcnN: physcData?.practiceAssignment?.find((item) => item?.roleTypeId !== 5) ? "No" : "Yes" || true
  });
  const prevSelectedPracticeId = useRef(selectedPracticeId);
  useEffect(() => {
    if (prevSelectedPracticeId.current !== selectedPracticeId) {
      setEditModeBox(false);
    }
    prevSelectedPracticeId.current = selectedPracticeId;
  }, [selectedPracticeId, setEditModeBox]);

  useEffect(() => {
    setPMData({ ...pmData, ...practiceManager });
    setAdData({ ...adData, ...AdditionalPoint });
    setDirectFirstData({ ...directFirstData, ...director });
    setExecutiveFirstData({ ...executiveFirstData, ...executive });
    setPhyscData({ ...physcData, ...practicePhysicianChampion });
  }, [practiceManager, AdditionalPoint, director, executive, practicePhysicianChampion]);

  useEffect(() => {
    if(addSaveMessage) {
      const timeoutId = setTimeout(() => {
        setAddSaveMessage(false);
      }, 5000);
  
      return () => clearTimeout(timeoutId);
    }
  }, [addSaveMessage]);

  const handleMdOfficeIdChange = (e, role, roleId) => {
    const { value } = e.target;
    if (value.trim() !== "") {
      switch (role) {
        case "manager":
          setPcMDofficeDetails({phoneNumber:"", email:""});
          break;
        case "additionalPoc":
          setApcMDofficeDetails({phoneNumber:"", email:""});
          break;
        case "director":
          setDirMDofficeDetails({phoneNumber:"", email:""});
          break;
        case "executive":
          setExeMDofficeDetails({phoneNumber:"", email:""});
          break;
        case "pc":
          setPcMDofficeDetails({phoneNumber:"", email:""});
          break;
        default:
          break;
      }
      fetchContactRecords(value, role, roleId);
    } else {
      switch (role) {
        case "manager":
          setManagerData({});
          setPcMDofficeDetails({phoneNumber:"", email:""});
          break;
        case "additionalPoc":
          setAdditionalPoc({});
          setApcMDofficeDetails({phoneNumber:"", email:""});
          break;
        case "director":
          setDirectorData({});
          setDirMDofficeDetails({phoneNumber:"", email:""});
          break;
        case "executive":
          setExecutiveData({});
          setExeMDofficeDetails({phoneNumber:"", email:""});
          break;
        case "pc":
          setPcData({});
          setPcMDofficeDetails({phoneNumber:"", email:""});
          break;
        default:
          break;
      }
    }
  };

  const fetchContactRecords = async (mdOfficeId, role, roleId) => {
    if(mdOfficeId?.length > 4) {
      let url ="";
      if(type==="add") {
        url = provider_base_url + `/api/v1/providers/GetPersonByMdOfficeId?mdofficeid=${mdOfficeId}`;
      } 
      else {
        url = base_url + `/api/v1/practices/ContactInfoByMdofficeIdAndPracticeId?practiceid=${selectedPracticeId}&mdOfficeId=${mdOfficeId}&roleTypId=${roleId}`;

      }
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            "ocp-apim-subscription-key": subscriptionKey,
          }),
        }
      );
      if (Number(response?.status) === 200) {
        const data = await response.json();
        if (type === "add") {
          switch (role) {
            case "manager":
              setManagerData({ ...managerData, ...[data] });
              setPmMDofficeDetails({
                email: data?.email[0]?.emailAddress || "",
                phoneNumber : data.phoneNumber[0]?.number || ""
              });
              setPMData({});
              break;
            case "additionalPoc":
              setAdditionalPoc({ ...additionalPoc, ...[data] });
              setAdData({});
              setApcMDofficeDetails({
                email: data?.email[0]?.emailAddress || "",
                phoneNumber : data.phoneNumber[0]?.number || ""
              });
              setReInitialize(true);
              break;
            case "director":
              setDirectorData({ ...directorData, ...[data] });
              setDirectFirstData({});
              setDirMDofficeDetails({
                email: data?.email[0]?.emailAddress || "",
                phoneNumber : data.phoneNumber[0]?.number || ""
              });
              setReInitialize(true);
              break;
            case "executive":
              setExecutiveData({ ...executiveData, ...[data] });
              setExecutiveFirstData({});
              setExeMDofficeDetails({
                email: data?.email[0]?.emailAddress || "",
                phoneNumber : data.phoneNumber[0]?.number || ""
              });
              setReInitialize(true);
              break;
            case "pc":
              setPcData({ ...pcData, ...[data] });
              setPhyscData({});
              setPcMDofficeDetails({
                email: data?.email[0]?.emailAddress || "",
                phoneNumber : data.phoneNumber[0]?.number || ""
              });
              setReInitialize(true);
              break;
            default:
              break;
          }
        }
        else {
          switch (role) {
            case "manager":
              setManagerData({ ...managerData, ...data });
              setPMData({});
              break;
            case "additionalPoc":
              setAdditionalPoc({ ...additionalPoc, ...data });
              setAdData({});
              setReInitialize(true);
              break;
            case "director":
              setDirectorData({ ...directorData, ...data });
              setDirectFirstData({});
              setReInitialize(true);
              break;
            case "executive":
              setExecutiveData({ ...executiveData, ...data });
              setExecutiveFirstData({});
              setReInitialize(true);
              break;
            case "pc":
              setPcData({ ...pcData, ...data });
              setPhyscData({});
              setReInitialize(true);
              break;
            default:
              break;
          }
        }
      } else {
        if (type === "add") {
          switch (role) {
            case "manager":
              setManagerIdNull(true);
              // setPMData({});
              break;
            case "additionalPoc":
              setApcIdNull(true);
              setReInitialize(true);
              break;
            case "director":
              setDirIdNull(true);
              setReInitialize(true);
              break;
            case "executive":
              setExeIdNull(true);
              setReInitialize(true);
              break;
            case "pc":
              setPcIdNull(true);
              setReInitialize(true);
              break;
            default:
              break;
          }
        }
        else {
          switch (role) {
            case "manager":
              setManagerIdNull(true);
              // setPMData({});
              break;
            case "additionalPoc":
              setApcIdNull(true);
              setReInitialize(true);
              break;
            case "director":
              setDirIdNull(true);
              setReInitialize(true);
              break;
            case "executive":
              setExeIdNull(true);
              setReInitialize(true);
              break;
            case "pc":
              setPcIdNull(true);
              setReInitialize(true);
              break;
            default:
              break;
          }
        }
      }
    
    }
  };
  const handleRemoveData = (role) => {
    switch (role) {
      case "manager":
        setManagerData({});
        setPMData({});
        break;
      case "additionalPoc":
        setAdditionalPoc({});
        setAdData({});
        break;
      case "director":
        setDirectorData({});
        setDirectFirstData({});
        break;
      case "executive":
        setExecutiveData({});
        setExecutiveFirstData({});
        break;
      case "pc":
        setPcData({});
        setPhyscData({});
        break;
      default:
        break;
    }
  }
  const handleInputChange = (e) => {
    const { id, value } = e.target;


    if(id==="additionalTitle" && prefix_mapTitles.includes(value) ) {
      setAdditionalPocValuesData((prevValues) => ({
        ...prevValues,
        [id]: value,
        additionalPrefix: "4"
      }));
    } 
    else {
      setAdditionalPocValuesData((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    }
    
    try {
      if (!value.trim()) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        contactInformationSchema.fields[id].validateSync(value, {
          abortEarly: false,
        });
  
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    } catch (error) {
      if (error && error?.errors && Array.isArray(error?.errors) && error?.errors?.length > 0) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: error?.errors[0],
        }));
      } else {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    }
  };

  const handledirInputChange = (e) => {
    const { id, value } = e.target;
    if(id==="directorTitle" && prefix_mapTitles.includes(value) ) {
      setDirectorValueData((prevValues) => ({
        ...prevValues,
        [id]: value,
        directorPrefix: "4"
      }));
    } 
    else {
      setDirectorValueData((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    } 
     try {
      if (!value.trim()) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        contactInformationSchema.fields[id].validateSync(value, {
          abortEarly: false,
        });
  
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    } catch (error) {
      if (error && error?.errors && Array.isArray(error?.errors) && error?.errors?.length > 0) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: error?.errors[0],
        }));
      } else {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    }
  };

  const handleExecutiveInputChange = (e) => {
    const { id, value } = e.target;
    if(id==="executiveTitle" && prefix_mapTitles.includes(value) ) {
      setExecutiveValueData((prevValues) => ({
        ...prevValues,
        [id]: value,
        executivePrefix: "4"
      }));
    } 
    else {
      setExecutiveValueData((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    } 
     try {
      if (!value.trim()) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        contactInformationSchema.fields[id].validateSync(value, {
          abortEarly: false,
        });
  
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    } catch (error) {
      if (error && error?.errors && Array.isArray(error?.errors) && error?.errors?.length > 0) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: error?.errors[0],
        }));
      } else {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    }
  };

  const handlephyInputChange = (e) => {
    const { id, value } = e.target;
    if(id==="PhysicianTitle" && prefix_mapTitles.includes(value) ) {
      setPhysicianvalueData((prevValues) => ({
        ...prevValues,
        [id]: value,
        PhysicianPrefix: "4"
      }));
    } 
    else {
      setPhysicianvalueData((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    }
    try {
      if (!value.trim()) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        contactInformationSchema.fields[id].validateSync(value, {
          abortEarly: false,
        });
  
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    } catch (error) {
      if (error && error?.errors && Array.isArray(error?.errors) && error?.errors?.length > 0) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: error?.errors[0],
        }));
      } else {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    }
  };

  React.useEffect(() => {
    setAdditionalPocValuesData({
      additionalID: additionalPoc[0]?.id || AdditionalPoint?.id || null,
      additionalFName: additionalPoc[0]?.firstName || adData?.firstName || "",
      additionalLName: additionalPoc[0]?.lastName || adData?.lastName || "",
      additionalMName: additionalPoc[0]?.middleName || adData?.middleName || "",
      additionalSuffix: additionalPoc[0]?.suffixId || adData?.suffixId || "",
      additionalPrefix: additionalPoc[0]?.prefixId || adData?.prefixId || "",
      additionalTitle:  additionalPoc[0]?.titleType?.[0]?.id || adData?.titleType?.[0]?.id || "",
      additionalEmail: additionalPoc[0]?.email?.[0]?.emailAddress || adData?.email?.[0]?.emailAddress || "",
      additionalPhNo: additionalPoc[0]?.phoneNumber?.[0]?.number || adData?.phoneNumber?.[0]?.number || "",
      additionalPhNoExt: additionalPoc[0]?.phoneNumber?.[0]?.extention || adData?.phoneNumber?.[0]?.extention || "",
      additionalMdId: additionalPoc[0]?.mdofficeId || adData?.mdofficeId || "",
      additionalSqcnY: (additionalPoc[0]?.practiceAssignment &&
        additionalPoc[0]?.practiceAssignment.find(item => item?.roleTypeId === 5)
        ? "Yes"
        : false) || (addprimarySqcnByRoleTypeId?.roleTypeId === 5 ? "Yes" : "No"),
      additionalSqcnN: addprimarySqcnByRoleTypeId?.roleTypeId !== 5 ? "No" : "Yes" || true
    });
  }, [additionalPoc, adData]);

  React.useEffect(() => {
    setDirectorValueData({
      directorID: directorData && directorData[0]?.id && directorData[0]?.id ||
        director && director?.id && director?.id || null,
      directorFName: directorData && directorData[0]?.firstName && directorData[0]?.firstName ||
        directFirstData?.firstName && directFirstData?.firstName !== "" && directFirstData?.firstName || "",
      directorLName: directorData && directorData[0]?.lastName && directorData[0]?.lastName ||
        directFirstData?.lastName && directFirstData?.lastName !== "" && directFirstData?.lastName || "",
      directorMName: directorData && directorData[0]?.middleName && directorData[0]?.middleName ||
        directFirstData?.middleName && directFirstData?.middleName !== "" && directFirstData?.middleName || "",
      directorSuffix: directorData && directorData[0]?.suffixId && directorData[0]?.suffixId ||
        directFirstData?.suffixId && directFirstData?.suffixId !== "" && directFirstData?.suffixId || "",
      directorPrefix: directorData && directorData[0]?.prefixId && directorData[0]?.prefixId ||
        directFirstData?.prefixId && directFirstData?.prefixId !== "" && directFirstData?.prefixId || "",
      directorTitle: 
        (directorData && directorData[0]?.titleType && directorData[0].titleType[0]?.id) ||
        (directFirstData && directFirstData.titleType && directFirstData.titleType[0]?.id) ||
        "",
      directorEmail:
        (directorData && directorData[0]?.email && directorData[0].email[0]?.emailAddress) ||
        (directFirstData && directFirstData.email && directFirstData.email[0]?.emailAddress) ||
        "",
      directorPhNo:
        (directorData && directorData[0]?.phoneNumber && directorData[0].phoneNumber[0]?.number) ||
        (directFirstData && directFirstData.phoneNumber && directFirstData.phoneNumber[0]?.number) ||
        "",
      directorPhNoExt:
        (directorData && directorData[0]?.phoneNumber && directorData[0]?.phoneNumber[0]?.extention) ||
        (directFirstData && directFirstData?.phoneNumber && directFirstData?.phoneNumber[0]?.extention) ||
        "",
      directorMdId: directorData && directorData[0]?.mdofficeId && directorData[0]?.mdofficeId ||
        directFirstData?.mdofficeId && directFirstData?.mdofficeId !== "" && directFirstData?.mdofficeId || "",
      directorSqcnY: (directorData &&
        directorData[0]?.practiceAssignment &&
        directorData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
        ? "Yes"
        : false) ||
        (directFirstData?.practiceAssignment?.find((item) => item?.roleTypeId === 5) ? "Yes" : "No"),
      directorSqcnN: directFirstData?.practiceAssignment?.find((item) => item?.roleTypeId !== 5) ? "No" : "Yes" || true
    });
    console.log("Director", directorData && directorData[0]?.mdofficeId && directorData[0]?.mdofficeId ||
    directFirstData?.mdofficeId && directFirstData?.mdofficeId !== "" && directFirstData?.mdofficeId || "")
  }, [directorData, directFirstData]);

  React.useEffect(() => {
    setExecutiveValueData({
      executiveID: executiveData && executiveData[0]?.id && executiveData[0]?.id ||
        executive && executive?.id && executive?.id || null,
      executiveFName: executiveData && executiveData[0]?.firstName && executiveData[0]?.firstName ||
        executiveFirstData?.firstName && executiveFirstData?.firstName !== "" && executiveFirstData?.firstName || "",
      executiveLName: executiveData && executiveData[0]?.lastName && executiveData[0]?.lastName ||
        executiveFirstData?.lastName && executiveFirstData?.lastName !== "" && executiveFirstData?.lastName || "",
      executiveMName: executiveData && executiveData[0]?.middleName && executiveData[0]?.middleName ||
        executiveFirstData?.middleName && executiveFirstData?.middleName !== "" && executiveFirstData?.middleName || "",
      executiveSuffix: executiveData && executiveData[0]?.suffixId && executiveData[0]?.suffixId ||
        executiveFirstData?.suffixId && executiveFirstData?.suffixId !== "" && executiveFirstData?.suffixId || "",
      executivePrefix: executiveData && executiveData[0]?.prefixId && executiveData[0]?.prefixId ||
        executiveFirstData?.prefixId && executiveFirstData?.prefixId !== "" && executiveFirstData?.prefixId || "",
      executiveTitle: 
      (executiveData && executiveData[0]?.titleType && executiveData[0].titleType[0]?.id) ||
      (executiveFirstData && executiveFirstData.titleType && executiveFirstData.titleType[0]?.id) ||
      "",
      executiveEmail:
        (executiveData && executiveData[0]?.email && executiveData[0].email[0]?.emailAddress) ||
        (executiveFirstData && executiveFirstData.email && executiveFirstData.email[0]?.emailAddress) ||
        "",
      executivePhNo:
        (executiveData && executiveData[0]?.phoneNumber && executiveData[0].phoneNumber[0]?.number) ||
        (executiveFirstData && executiveFirstData.phoneNumber && executiveFirstData.phoneNumber[0]?.number) ||
        "",
      executivePhNoExt:
        (executiveData && executiveData[0]?.phoneNumber && executiveData[0]?.phoneNumber[0]?.extention) ||
        (executiveFirstData && executiveFirstData?.phoneNumber && executiveFirstData?.phoneNumber[0]?.extention) ||
        "",
      executiveMdId: executiveData && executiveData[0]?.mdofficeId && executiveData[0]?.mdofficeId ||
        executiveFirstData?.mdofficeId && executiveFirstData?.mdofficeId !== "" && executiveFirstData?.mdofficeId || "",
    });
  }, [executiveData, executiveFirstData]);

  React.useEffect(() => {
    setPhysicianvalueData({
      PhysicianID: pcData && pcData[0]?.id && pcData[0]?.id ||
        practicePhysicianChampion && practicePhysicianChampion?.id && practicePhysicianChampion?.id || null,
      PhysicianFName: pcData && pcData[0]?.firstName && pcData[0]?.firstName || physcData?.firstName || "",
      PhysicianLName: pcData && pcData[0]?.lastName && pcData[0]?.lastName || physcData?.lastName || "",
      PhysicianMName: pcData && pcData[0]?.middleName && pcData[0]?.middleName || physcData?.middleName || "",
      PhysicianSuffix: pcData && pcData[0]?.suffixId && pcData[0]?.suffixId || physcData?.suffixId || "",
      PhysicianPrefix: pcData && pcData[0]?.prefixId && pcData[0]?.prefixId || physcData?.prefixId || "",
      PhysicianTitle: 
      (pcData && pcData[0]?.titleType && pcData[0].titleType[0]?.id) ||
      (physcData && physcData.titleType && physcData.titleType[0]?.id) ||
      "",
      PhysicianEmail:
        (pcData && pcData[0]?.email && pcData[0].email[0]?.emailAddress) ||
        (physcData && physcData.email && physcData.email[0]?.emailAddress) ||
        "",
      physicianPhNo:
        (pcData && pcData[0]?.phoneNumber && pcData[0].phoneNumber[0]?.number) ||
        (physcData && physcData.phoneNumber && physcData.phoneNumber[0]?.number) ||
        "",
      physicianPhNoExt:
        (pcData && pcData[0]?.phoneNumber && pcData[0]?.phoneNumber[0]?.extention) ||
        (physcData && physcData?.phoneNumber && physcData?.phoneNumber[0]?.extention) ||
        "",
      PhysicianMdId: pcData && pcData[0]?.mdofficeId && pcData[0]?.mdofficeId ||
        physcData?.mdofficeId || "",
      physicianSqcnY: (pcData &&
        pcData[0]?.practiceAssignment &&
        pcData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
        ? "Yes"
        : false) ||
        (physcData?.practiceAssignment?.find((item) => item?.roleTypeId === 5) ? "Yes" : "No"),
      physicianSqcnN: physcData?.practiceAssignment?.find((item) => item?.roleTypeId !== 5) ? "No" : "Yes" || true
    });
  }, [pcData, physcData]);

  const initialValues = {
    practiceManagerID: managerData && managerData[0]?.id && managerData[0]?.id ||
      practiceManager && practiceManager?.id && practiceManager?.id || null,
    practiceManagerName: managerData && managerData[0]?.firstName && managerData[0]?.firstName ||
      pmData?.firstName || "",
    practiceManagerLName: managerData && managerData[0]?.lastName && managerData[0]?.lastName ||
      pmData?.lastName || "",
    practiceManagerMName: managerData && managerData[0]?.middleName && managerData[0]?.middleName ||
      pmData?.middleName || "",
    practiceManagerSuffix: managerData && managerData[0]?.suffixId && managerData[0]?.suffixId ||
      pmData?.suffixId || "",
    practiceManagerPrefix: managerData && managerData[0]?.prefixId && managerData[0]?.prefixId ||
      pmData?.prefixId || "",
    practiceManagerTitle: managerData && managerData[0]?.titleType && managerData[0]?.id ||
      (pmData && pmData.titleType && pmData.titleType[0]?.id) || "",
    practiceManagerEmail:
      (managerData && managerData[0]?.email && managerData[0].email[0]?.emailAddress) ||
      (pmData && pmData.email && pmData.email[0]?.emailAddress) ||
      "",
    practiceManagerPhNo:
      (managerData && managerData[0]?.phoneNumber && managerData[0].phoneNumber[0]?.number) ||
      (pmData && pmData.phoneNumber && pmData.phoneNumber[0]?.number) ||
      "",
    practiceManagerPhNoExt:
      (managerData && managerData[0]?.phoneNumber && managerData[0]?.phoneNumber[0]?.extention) ||
      (pmData && pmData?.phoneNumber && pmData?.phoneNumber[0]?.extention) ||
      "",
    practiceManagerMdId: managerData && managerData[0]?.mdofficeId && managerData[0]?.mdofficeId ||
      pmData?.mdofficeId || "",
    practiceManagerSqcnY:
      (managerData &&
        managerData[0]?.practiceAssignment &&
        managerData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
        ? "Yes"
        : false) ||
      (primarySqcnByRoleTypeId?.roleTypeId === 5 ? "Yes" : "No"),
    practiceManagerSqcnN: primarySqcnByRoleTypeId?.roleTypeId !== 5 ? "No" : "Yes" || true
  };

  const contactInformationSchema = Yup.object().shape({
    practiceManagerName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    practiceManagerLName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    practiceManagerMName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    
    practiceManagerEmail: Yup.string()
    .matches(EMAIL_REGEX, "Invalid email format"),
    practiceManagerPhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX"),
    practiceManagerMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
    additionalFName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    additionalLName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),


      additionalMName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    additionalEmail: Yup.string().notRequired().matches(EMAIL_REGEX, "Invalid email format"),
    additionalPhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX"),
    additionalMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
    directorFName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    directorLName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),

      directorMName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
      
    directorEmail: Yup.string().notRequired().matches(EMAIL_REGEX, "Invalid email format"),
    directorPhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX"),
    directorMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
    executiveFName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    executiveLName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),


      executiveMName: Yup.string()
      .notRequired()
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    executiveEmail: Yup.string().notRequired().matches(EMAIL_REGEX, "Invalid email format"),
    executivePhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX"),
    executiveMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
      PhysicianMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
    PhysicianFName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    PhysicianLName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),

    PhysicianMName: Yup.string()
    .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Invalid Name"),
    PhysicianEmail: Yup.string()
    .matches(EMAIL_REGEX, "Invalid email format"),
    physicianPhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX")
  });
  const getSuffixByName = (id) => {
    if(suffixData){
      const suffixId = suffixData?.filter((suffix) => suffix.id === id);
      if(suffixId){
        return suffixId[0]?.suffixName || null;
      }
    }
  }
  const onSubmitFun = async (values) => {
    setErrorMessage("");
    let objData = {};

    const pm = {
      personId: managerIdNull === true ? 0 : values.practiceManagerID??0,
      mdofficeId: values.practiceManagerMdId,
      firstName: values.practiceManagerName,
      lastName: values.practiceManagerLName,
      middleName: values.practiceManagerMName,
      suffixId: parseInt(values.practiceManagerSuffix),
      prefixId: values.practiceManagerPrefix==="" ? "" :Number(values.practiceManagerPrefix),
      titleId: values.practiceManagerTitle === "" ? "" :Number(values.practiceManagerTitle),
      phone: values.practiceManagerPhNo,
      ext: values.practiceManagerPhNoExt,
      email: values.practiceManagerEmail,
      psc: values.practiceManagerSqcnY === "Yes" ? 1 : 0,
      IsPrimaryCare : false
    };
    objData = { ...objData, pm };

    const apc = {
      personId: apcIdNull === true ? 0 : additionalPocValuesData.additionalID??0,
      mdofficeId: additionalPocValuesData.additionalMdId || "",
      firstName: additionalPocValuesData.additionalFName || "",
      lastName: additionalPocValuesData.additionalLName || "",
      middleName: additionalPocValuesData.additionalMName || "",
      suffixId: parseInt(additionalPocValuesData.additionalSuffix) || "",
      prefixId: additionalPocValuesData.additionalPrefix === "" ? "" : Number(additionalPocValuesData.additionalPrefix),
      titleId: additionalPocValuesData.additionalTitle === "" ? "" :Number(additionalPocValuesData.additionalTitle),
      phone: additionalPocValuesData.additionalPhNo || "",
      ext: additionalPocValuesData.additionalPhNoExt || "",
      email: additionalPocValuesData.additionalEmail || "",
      psc: additionalPocValuesData.additionalSqcnY === "Yes" ? 1 : 0,
      IsPrimaryCare : false
    };
    objData = { ...objData, apc };

    const director = {
      personId: dirIdNull === true ? 0 : directorvalueData.directorID??0,
      mdofficeId: directorvalueData.directorMdId || "",
      firstName: directorvalueData.directorFName || "",
      lastName: directorvalueData.directorLName || "",
      middleName: directorvalueData.directorMName || "",
      suffixId: parseInt(directorvalueData.directorSuffix) || "",//
      prefixId: directorvalueData.directorPrefix==="" ? "" : Number(directorvalueData.directorPrefix),
      titleId: directorvalueData.directorTitle==="" ? "" : Number(directorvalueData.directorTitle),
      phone: directorvalueData.directorPhNo || "",
      ext: directorvalueData.directorPhNoExt || "",
      email: directorvalueData.directorEmail || "",
      psc: directorvalueData.directorSqcnY === "Yes" ? 1 : 0,
      IsPrimaryCare : false
    };
    objData = { ...objData, director };

    const executive = {
      personId: exeIdNull === true ? 0 : executiveValueData.executiveID??0,
      mdofficeId: executiveValueData.executiveMdId || "",
      firstName: executiveValueData.executiveFName || "",
      lastName: executiveValueData.executiveLName || "",
      middleName: executiveValueData.executiveMName || "",
      suffixId: parseInt(executiveValueData.executiveSuffix) || "",
      prefixId: executiveValueData.executivePrefix === "" ? "" :Number(executiveValueData.executivePrefix),
      titleId: executiveValueData.executiveTitle=== "" ? "": Number(executiveValueData.executiveTitle),
      phone: executiveValueData.executivePhNo || "",
      ext: executiveValueData.executivePhNoExt || "",
      email: executiveValueData.executiveEmail || "",
      IsPrimaryCare : false
    };
    objData = { ...objData, executive };

    const pc = {
      personId: pcIdNull === true ? 0 : physicianValueData.PhysicianID??0,
      mdofficeId: physicianValueData.PhysicianMdId,
      firstName: physicianValueData.PhysicianFName,
      lastName: physicianValueData.PhysicianLName,
      middleName: physicianValueData.PhysicianSuffix,
      suffixId: parseInt(physicianValueData.PhysicianSuffix),
      prefixId: physicianValueData.PhysicianPrefix=== "" ? "" : Number(physicianValueData.PhysicianPrefix),
      titleId: physicianValueData.PhysicianTitle==="" ? "" : Number(physicianValueData.PhysicianTitle),
      email: physicianValueData.PhysicianEmail,
      phone: physicianValueData.physicianPhNo,
      ext: physicianValueData.physicianPhNoExt,
      psc: physicianValueData.physicianSqcnY === "Yes" ? 1 : 0,
      IsPrimaryCare : false
    };
    objData = { ...objData, pc };

    console.log(objData);
    const payLoad = {
      ...objData,
    };
    if(values.practiceManagerSqcnY === "Yes" || additionalPocValuesData.additionalSqcnY === "Yes" || directorvalueData.directorSqcnY === "Yes" || physicianValueData.physicianSqcnY === "Yes"){
      if(type==="add") {
        let formattedContactInformation = [];
        Object.keys(payLoad).forEach(key => {
          if(payLoad[key].mdofficeId) {
            let roleTypeId = "";
            switch (key) {
              case "pm":
                roleTypeId = 1;
                break;
              case "pc":
                roleTypeId = 8;
                break;
              case "apc":
                roleTypeId = 4;
                break;
              case "executive":
                roleTypeId = 6;
                break;
              case "director":
                roleTypeId = 3;
                break;
              default:
                break;
            }
            let contactObj = {
              "startDate": null,
              "termDate": null,
              "practiceId": 0,
              "roleTypeId": roleTypeId,
              "personId": payLoad[key].personId, 
              "person": {
                ...payLoad[key],
                id: payLoad[key].personId,
                "email": [
                  {           
                    "emailAddress": payLoad[key].email,
                    "emailTypeId":key==="pc" ? 1 : 2
                  }
                ],
                "phoneNumber": [
                  {            
                    "number": payLoad[key].phone,
                    "phoneTypeId": 3,
                    "extention": payLoad[key].ext,           
                  }
                ],
              },
              "assignmentTypeId": 1,
              "roleType": null,            
              "status": "Approved",
              "sourceSystem": "SQCN",
              "updateUser": practiceManagerId,
            };
  
            //delete email and phone if no change
            switch (key) {
              case "pm":
                if(pmMDofficeDetails.email === payLoad[key].email) {
                  delete contactObj.person.email;
                }
                if(pmMDofficeDetails.phoneNumber === payLoad[key].phone) {
                  delete contactObj.person.phoneNumber;
                  delete contactObj.person.phone;
                }
                break;
              case "pc":
                if(pcMDofficeDetails.email === payLoad[key].email) {
                  delete contactObj.person.email;
                }
                if(pcMDofficeDetails.phoneNumber === payLoad[key].phone) {
                  delete contactObj.person.phoneNumber;
                  delete contactObj.person.phone;
                }
                break;
              case "apc":
                if(apcMDofficeDetails.email === payLoad[key].email) {
                  delete contactObj.person.email;
                }
                if(apcMDofficeDetails.phoneNumber === payLoad[key].phone) {
                  delete contactObj.person.phoneNumber;
                  delete contactObj.person.phone;
                }
                break;
              case "executive":
                if(exeMDofficeDetails.email === payLoad[key].email) {
                  delete contactObj.person.email;
                }
                if(exeMDofficeDetails.phoneNumber === payLoad[key].phone) {
                  delete contactObj.person.phoneNumber;
                  delete contactObj.person.phone;
                }
                break;
              case "director":
                if(dirMDofficeDetails.email === payLoad[key].email) {
                  delete contactObj.person.email;
                }
                if(dirMDofficeDetails.phoneNumber === payLoad[key].phone) {
                  delete contactObj.person.phoneNumber;
                  delete contactObj.person.phone;
                }
                break;
              default:
                break;
            }
            formattedContactInformation.push(contactObj)
          }
        });
        setContactInformationData([...formattedContactInformation]);
        setAddSaveMessage(true);
        setErrorMessage("");
      } 
      else {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "access-control-allow-credentials": "true",
            "ocp-apim-subscription-key": subscriptionKey,
            "api-supported-versions": "1.0",
          },
          body: JSON.stringify(payLoad),
        };
        
        const response = await fetch(
          base_url + `/api/v1/practices/${selectedPracticeId}/practiceinsertupdate`,
          requestOptions
        );
        const result = await response.json();
        let status=  response?.status
        if (Number(status) === 200) {
          // setContactInfoEdit(true);
          fetchContactInformation();
          setEditModeBox(false);
          SetShowSuccessMessage(true);
          setErrorMessage("");
        }
        else if(result?.message) {
          setErrorMessage(result?.message);
        }
      }
    } else {
      setErrorMessage("One primary SQCN contact is required");
    }
  };
  return (
    <React.Fragment>
      {isContactServerError && <ErrorServer />}
      {!isContactServerError && (
        <div className="contact-information">
          <Formik
            initialValues={initialValues}
            validationSchema={contactInformationSchema}
            onSubmit={onSubmitFun}
            enableReinitialize={reInitialize}
          >
            {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <h6 className="contact-tilte">Practice Manager</h6>
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">
                        {labels.MDOFFICE_ID}{" "}
                        <OverlayTrigger
                          trigger={["hover", "hover"]}
                          placement="right"
                          overlay={popover}
                        >
                          <img
                            src={tooltipIcon}
                            alt="Tooltip Icon"
                            className="tooltip-icon"
                          />
                        </OverlayTrigger>
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="practiceManagerMdId"
                        autoComplete="off"
                        value={values.practiceManagerMdId}
                        defaultValue={values.practiceManagerMdId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "manager", 1);
                          setReInitialize(true);
                          handleChange(e);
                        }}
                        isInvalid={
                          !!errors.practiceManagerMdId ||
                          values?.practiceManagerName && !values?.practiceManagerMdId
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.practiceManagerMdId ||
                          values?.practiceManagerName && !values?.practiceManagerMdId && (
                            <p>MDOffice ID cannot be blank</p>
                        )}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={2}>
                          <p className="details-label">{labels.PREFIX}</p>
                          <Form.Select
                            size="sm"
                            placeholder="---"
                            id="practiceManagerPrefix"
                            autoComplete="off"
                            value={values.practiceManagerPrefix}
                            defaultValue={values.practiceManagerPrefix}
                            className="nb-select form-select form-select-sm"
                            onChange={handleChange}
                            isInvalid={!!errors.practiceManagerPrefix ||
                              values?.practiceManagerMdId &&
                              !values?.practiceManagerPrefix ||
                              values?.practiceManagerPrefix &&
                              !values?.practiceManagerPrefix
                            }
                          >
                            <option value="">---</option>
                              {prefixData.map( (prefixItem,index) => <option key={prefixItem.prefixName+index} value={prefixItem.id}>{prefixItem.prefixName}</option> )}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.practiceManagerPrefix ||
                              (values?.practiceManagerMdId &&
                                !values?.practiceManagerPrefix) ||
                              (values?.practiceManagerName &&
                                !values?.practiceManagerPrefix) ? (
                              <p>Prefix cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="practiceManagerName"
                            autoComplete="off"
                            value={values.practiceManagerName}
                            defaultValue={values.practiceManagerName}
                            className="nb-input"
                            onChange={handleChange}
                            isInvalid={!!errors.practiceManagerName ||
                              values?.practiceManagerMdId && !values?.practiceManagerName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.practiceManagerName ||
                              values?.practiceManagerMdId && !values?.practiceManagerName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.MIDDLE_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="practiceManagerMName"
                            autoComplete="off"
                            value={values.practiceManagerMName}
                            defaultValue={values.practiceManagerMName}
                            className="nb-input"
                            onChange={handleChange}
                            isInvalid={errors.practiceManagerMName && values?.practiceManagerMName}


                            
                          />

<Form.Control.Feedback type="invalid">
                    {errors.practiceManagerMName}
                  </Form.Control.Feedback>  
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="practiceManagerLName"
                            autoComplete="off"
                            value={values.practiceManagerLName}
                            defaultValue={values.practiceManagerLName}
                            className="nb-input"
                            onChange={handleChange}
                            isInvalid={!!errors.practiceManagerLName ||
                              values?.practiceManagerMdId &&
                              !values?.practiceManagerLName ||
                              values?.practiceManagerName &&
                              !values?.practiceManagerLName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.practiceManagerLName ||
                              (values?.practiceManagerMdId &&
                                !values?.practiceManagerLName) ||
                              (values?.practiceManagerName &&
                                !values?.practiceManagerLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.SUFFIX}</p>
                          <Form.Select
                            size="sm"
                            type="text"
                            placeholder="---"
                            id="practiceManagerSuffix"
                            autoComplete="off"
                            value={values.practiceManagerSuffix}
                            defaultValue={values.practiceManagerSuffix}
                            className="nb-select form-select form-select-sm"
                            onChange={handleChange}
                          >
                              <option value="">---</option>
                              {suffixData.map( (suffix,index) => <option key={suffix+index} value={suffix.id}>{getSuffixByName(suffix.id)}</option> )}
                          </Form.Select>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.TITLE}</p>
                          <Form.Select
                            size="sm"
                            id="practiceManagerTitle"
                            autoComplete="off"
                            value={values.practiceManagerTitle}
                            defaultValue={values.practiceManagerTitle}
                            className="nb-select form-select form-select-sm"
                            onChange={(e) => {
                              const { value } = e.target;
                              handleChange(e);
                              if( prefix_mapTitles.includes(value) ) {
                                setFieldValue("practiceManagerPrefix","4");
                              } 
                            }}
                          >
                              <option value="">---</option>
                              {titleData.map( (titleItem,index) => <option key={titleItem.title+index} value={titleItem.id}>{titleItem.title}</option> )}
                          </Form.Select>
                        </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="practiceManagerEmail"
                        autoComplete="off"
                        value={values.practiceManagerEmail}
                        defaultValue={values.practiceManagerEmail}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={
                          !!errors.practiceManagerEmail ||
                          values?.practiceManagerMdId &&
                          !values?.practiceManagerEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.practiceManagerEmail ||
                          values?.practiceManagerMdId &&
                          !values?.practiceManagerEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col className="col_phone">
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        maxLength={12}
                        id="practiceManagerPhNo"
                        value={values.practiceManagerPhNo}
                        defaultValue={values.practiceManagerPhNo}
                        className="nb-input"
                        onChange={(e)=>{

                          let formattedNumber = phoneNumberAutoFormat(e.target.value);

                          const formattedObject = {
                           target: {id:e.target.id, name: e.target.name, value: formattedNumber }
                        };
                        handleChange(formattedObject);

                        }}
                        isInvalid={!!errors.practiceManagerPhNo ||
                          values?.practiceManagerMdId &&
                          !values?.practiceManagerPhNo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.practiceManagerPhNo ||
                          values?.practiceManagerMdId &&
                          !values?.practiceManagerPhNo && (
                            <p>Phone Number cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="practiceManagerPhNoExt"
                        value={values.practiceManagerPhNoExt}
                        defaultValue={values.practiceManagerPhNoExt}
                        className="nb-input"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="col_primarysqcn">
                      <p className="details-label mb-radio">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="practiceManagerSqcnY"
                        className="contactradio"
                        data-testid="practiceManagerSqcnY"
                        disabled={!values?.practiceManagerName}
                        value="Yes"
                        checked={values?.practiceManagerSqcnY === "Yes"}
                        defaultChecked={values?.practiceManagerSqcnY === "Yes"}
                        onChange={(e)=>{
                          handleChange(e);
                          setAdditionalPocValuesData({...additionalPocValuesData,additionalSqcnY:"No",additionalSqcnN:"Yes"})
                          setDirectorValueData({...directorvalueData,directorSqcnY:"No",directorSqcnN:"Yes"});
                          setPhysicianvalueData({...physicianValueData,physicianSqcnY:"No",physicianSqcnN:"Yes"});
                        }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="No"
                        id="practiceManagerSqcnY"
                        data-testid="practiceManagerSqcnY"
                        checked={values?.practiceManagerSqcnY === "No"}
                        defaultChecked={values?.practiceManagerSqcnY === "No"}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={3}>
                      <button
                        type="button"
                        className="Remove_data_button"
                        onClick={() => {
                          handleRemoveData("manager"),
                          setFieldValue("practiceManagerMdId", "");
                          setFieldValue("practiceManagerName", "");
                          setFieldValue("practiceManagerLName", "");
                          setFieldValue("practiceManagerMName", "");
                          setFieldValue("practiceManagerSuffix", "");
                          setFieldValue("practiceManagerPrefix", "");
                          setFieldValue("practiceManagerTitle", "");
                          setFieldValue("practiceManagerEmail", "");
                          setFieldValue("practiceManagerPhNo", "");
                          setFieldValue("practiceManagerPhNoExt", "");
                          setFieldValue("practiceManagerSqcnY", "No");
                          setReInitialize(false);
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove Practice Manager</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <h6 className="contact-tilte">
                  Additional Point Of Contact
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="right"
                    overlay={popover1}
                  >
                    <img
                      src={tooltipIcon}
                      alt="Tooltip Icon"
                      className="tooltip-icon"
                    />
                  </OverlayTrigger>
                </h6>
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="additionalMdId"
                        autoComplete="off"
                        value={additionalPocValuesData?.additionalMdId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "additionalPoc", 4);
                          setReInitialize(true);
                          handleInputChange(e);
                        }}
                        isInvalid={!!validationError?.additionalMdId ||
                          additionalPocValuesData?.additionalFName && !additionalPocValuesData?.additionalMdId
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.additionalMdId ||
                          additionalPocValuesData?.additionalFName && !additionalPocValuesData?.additionalMdId && (
                            <p>MDOffice ID cannot be blank</p>
                        )}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={2}>
                          <p className="details-label">{labels.PREFIX}</p>
                          <Form.Select
                            size="sm"
                            id="additionalPrefix"
                            autoComplete="off"
                            value={additionalPocValuesData?.additionalPrefix}
                            className="nb-select form-select form-select-sm"
                            onChange={handleInputChange}
                            isInvalid={
                              !!validationError?.additionalPrefix ||
                              additionalPocValuesData?.additionalMdId &&
                              !additionalPocValuesData?.additionalPrefix
                            }
                          >
                            <option value="">---</option>
                            {prefixData.map( (prefixItem,index) => <option key={prefixItem.prefixName+index} value={prefixItem.id}>{prefixItem.prefixName}</option> )}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                          {validationError?.additionalPrefix ||
                              additionalPocValuesData?.additionalMdId &&
                              !additionalPocValuesData?.additionalPrefix && (
                                <p>Prefix Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="additionalFName"
                            autoComplete="off"
                            value={additionalPocValuesData?.additionalFName}
                            className="nb-input"
                            onChange={handleInputChange}
                            isInvalid={
                              !!validationError?.additionalFName ||
                              additionalPocValuesData?.additionalMdId &&
                              !additionalPocValuesData?.additionalFName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.additionalFName ||
                              additionalPocValuesData?.additionalMdId &&
                              !additionalPocValuesData?.additionalFName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.MIDDLE_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="additionalMName"
                            autoComplete="off"
                            value={additionalPocValuesData.additionalMName}
                            className="nb-input"
                            onChange={handleInputChange}
                            
                            isInvalid={errors.additionalMName && values?.additionalMName}
                          />
                              <Form.Control.Feedback type="invalid">
                    {errors.additionalMName}
                  </Form.Control.Feedback>  
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="additionalLName"
                            autoComplete="off"
                            value={additionalPocValuesData?.additionalLName}
                            className="nb-input"
                            onChange={handleInputChange}
                            isInvalid={!!validationError?.additionalLName ||
                              (additionalPocValuesData?.additionalMdId &&
                                !additionalPocValuesData?.additionalLName) ||
                              (additionalPocValuesData?.additionalFName &&
                                !additionalPocValuesData?.additionalLName)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.additionalLName ||
                              (additionalPocValuesData?.additionalMdId &&
                                !additionalPocValuesData?.additionalLName) ||
                              (additionalPocValuesData?.additionalFName &&
                                !additionalPocValuesData?.additionalLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.SUFFIX}</p>
                          <Form.Select
                            size="sm"
                            type="text"
                            placeholder="---"
                            id="additionalSuffix"
                            autoComplete="off"
                            value={additionalPocValuesData?.additionalSuffix}
                            className="nb-select form-select form-select-sm"
                            onChange={handleInputChange}
                          >
                              <option value="">---</option>
                              {suffixData.map( (suffix,index) => <option key={suffix+index} value={suffix.id}>{getSuffixByName(suffix.id)}</option> )}  
                          </Form.Select>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.TITLE}</p>
                          <Form.Select
                            size="sm"
                            id="additionalTitle"
                            autoComplete="off"
                            value={additionalPocValuesData?.additionalTitle}
                            defaultValue={values.practiceManagerTitle}
                            className="nb-select form-select form-select-sm"
                            onChange={handleInputChange}
                          >
                            <option value="">---</option>
                            {titleData.map( (titleItem,index) => <option key={titleItem.title+index} value={titleItem.id}>{titleItem.title}</option> )}
                          </Form.Select>
                        </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="additionalEmail"
                        autoComplete="off"
                        value={additionalPocValuesData?.additionalEmail}
                        className="nb-input"
                        onChange={handleInputChange}
                        isInvalid={
                          !!validationError?.additionalEmail ||
                          additionalPocValuesData?.additionalMdId &&
                          !additionalPocValuesData?.additionalEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.additionalEmail ||
                          additionalPocValuesData?.additionalMdId &&
                          !additionalPocValuesData?.additionalEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col className="col_phone">
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        maxLength={12}
                        autoComplete="off"
                        id="additionalPhNo"
                        value={additionalPocValuesData?.additionalPhNo}
                        className="nb-input"
                       // onChange={handleInputChange}

                        onChange={(e)=>{

                          let formattedNumber = phoneNumberAutoFormat(e.target.value);

                          const formattedObject = {
                           target: {id: e.target.id, name: e.target.name, value: formattedNumber }
                        };
                        handleInputChange(formattedObject);

                        }}
                        isInvalid={
                          !!validationError?.additionalPhNo ||
                          additionalPocValuesData?.additionalMdId &&
                          !additionalPocValuesData?.additionalPhNo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.additionalPhNo ||
                          additionalPocValuesData?.additionalMdId &&
                          !additionalPocValuesData?.additionalPhNo && (
                            <p>Phone Number cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="additionalPhNoExt"
                        value={additionalPocValuesData?.additionalPhNoExt}
                        className="nb-input"
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col className="col_primarysqcn">
                      <p className="details-label mb-radio">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="additionalSqcnY"
                        className="contactradio"
                        data-testid="additionalSqcnY"
                        // disabled={!additionalPocValuesData?.additionalFName}
                        value="Yes"
                        checked={additionalPocValuesData?.additionalSqcnY === "Yes"}
                        defaultChecked={additionalPocValuesData?.additionalSqcnY === "Yes"}
                        onChange={(e)=>{
                          handleInputChange(e);
                          setFieldValue("practiceManagerSqcnY", "No");
                          setDirectorValueData({...directorvalueData,directorSqcnY:"No",directorSqcnN:"Yes"});
                          setPhysicianvalueData({...physicianValueData,physicianSqcnY:"No",physicianSqcnN:"Yes"});
                        }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="No"
                        id="additionalSqcnY"
                        data-testid="additionalSqcnY"
                        checked={additionalPocValuesData?.additionalSqcnY === "No"}
                        defaultChecked={additionalPocValuesData?.additionalSqcnY === "No"}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col md={3}>
                      <button
                        type="button"
                        className="Remove_data_button"
                        onClick={() => {
                          handleRemoveData("additionalPoc"),
                          setReInitialize(false),
                          setFieldValue("additionalMdId", "");
                          setFieldValue("additionalFName", "");
                          setFieldValue("additionalLName", "");
                          setFieldValue("additionalMName", "");
                          setFieldValue("additionalSuffix", "");
                          setFieldValue("additionalPrefix", "");
                          setFieldValue("additionalTitle", "");
                          setFieldValue("additionalEmail", "");
                          setFieldValue("additionalPhNo", "");
                          setFieldValue("additionalPhNoExt", "");
                          setFieldValue("additionalSqcnY", "No");
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove  Additional Point Of Contact</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <h6 className="contact-tilte">
                  Director{" "}
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="right"
                    overlay={popover2}
                  >
                    <img
                      src={tooltipIcon}
                      alt="Tooltip Icon"
                      className="tooltip-icon"
                    />
                  </OverlayTrigger>
                </h6>
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="directorMdId"
                        autoComplete="off"
                        value={directorvalueData?.directorMdId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "director", 3);
                          setReInitialize(true);
                          handledirInputChange(e);
                        }}
                        isInvalid={
                          !!validationError?.directorMdId ||
                          directorvalueData?.directorFName &&
                          !directorvalueData?.directorMdId
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.directorMdId ||
                          directorvalueData?.directorFName &&
                          !directorvalueData?.directorMdId && (
                            <p>MDOffice ID  cannot be blank</p>
                        )}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={2}>
                          <p className="details-label">{labels.PREFIX}</p>
                          <Form.Select
                            size="sm"
                            id="directorPrefix"
                            autoComplete="off"
                            value={directorvalueData?.directorPrefix}
                            className="nb-select form-select form-select-sm"
                            onChange={handledirInputChange}
                            isInvalid={
                              !!validationError?.directorPrefix ||
                              directorvalueData?.directorMdId &&
                              !directorvalueData?.directorPrefix
                            }
                          >
                            <option value="">---</option>
                            {prefixData.map( (prefixItem,index) => <option key={prefixItem.prefixName+index} value={prefixItem.id}>{prefixItem.prefixName}</option> )}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                          {validationError?.directorPrefix ||
                              directorvalueData?.directorMdId &&
                              !directorvalueData?.directorPrefix && (
                                <p>Prefix cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="directorFName"
                            autoComplete="off"
                            value={directorvalueData?.directorFName}
                            className="nb-input"
                            onChange={handledirInputChange}
                            isInvalid={
                              !!validationError?.directorFName ||
                              directorvalueData?.directorMdId &&
                              !directorvalueData?.directorFName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.directorFName ||
                              directorvalueData?.directorMdId &&
                              !directorvalueData?.directorFName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.MIDDLE_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="directorMName"
                            autoComplete="off"
                            value={directorvalueData?.directorMName}
                            className="nb-input"
                            onChange={handledirInputChange}
                            isInvalid={errors.directorMName && values?.directorMName}
                          />
                                <Form.Control.Feedback type="invalid">
                    {errors.directorMName}
                  </Form.Control.Feedback>  
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="directorLName"
                            autoComplete="off"
                            value={directorvalueData?.directorLName}
                            className="nb-input"
                            onChange={handledirInputChange}
                            isInvalid={
                              !!validationError?.directorLName ||
                              (directorvalueData?.directorMdId &&
                                !directorvalueData?.directorLName) ||
                              (directorvalueData?.directorFName &&
                                !directorvalueData?.directorLName)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.directorLName ||
                              (directorvalueData?.directorMdId &&
                                !directorvalueData?.directorLName) ||
                              (directorvalueData?.directorFName &&
                                !directorvalueData?.directorLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.SUFFIX}</p>
                          <Form.Select
                            size="sm"
                            type="text"
                            placeholder="---"
                            id="directorSuffix"
                            autoComplete="off"
                            value={directorvalueData?.directorSuffix}
                            className="nb-select form-select form-select-sm"
                            onChange={handledirInputChange}
                          >
                            <option value="">---</option>
                            {suffixData.map( (suffix,index) => <option key={suffix+index} value={suffix.id}>{getSuffixByName(suffix.id)}</option> )}  
                          </Form.Select>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.TITLE}</p>
                          <Form.Select
                            size="sm"
                            id="directorTitle"
                            autoComplete="off"
                            value={directorvalueData?.directorTitle}
                            className="nb-select form-select form-select-sm"
                            onChange={handledirInputChange}
                          >
                            <option value="">---</option>
                            {titleData.map( (titleItem,index) => <option key={titleItem.title+index} value={titleItem.id}>{titleItem.title}</option> )}
                          </Form.Select>
                        </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="directorEmail"
                        autoComplete="off"
                        value={directorvalueData?.directorEmail}
                        className="nb-input"
                        onChange={handledirInputChange}
                        isInvalid={
                          !!validationError?.directorEmail ||
                          directorvalueData?.directorMdId &&
                          !directorvalueData?.directorEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.directorEmail ||
                          directorvalueData?.directorMdId &&
                          !directorvalueData?.directorEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col className="col_phone">
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        maxLength={12}
                        id="directorPhNo"
                        value={directorvalueData?.directorPhNo}
                        className="nb-input"
                        //onChange={handledirInputChange}

                        onChange={(e)=>{

                          let formattedNumber = phoneNumberAutoFormat(e.target.value);

                          const formattedObject = {
                           target: {id: e.target.id, name: e.target.name, value: formattedNumber }
                        };
                        handledirInputChange(formattedObject);

                        }}
                        isInvalid={
                          !!validationError?.directorPhNo ||
                          directorvalueData?.directorMdId &&
                          !directorvalueData?.directorPhNo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.directorPhNo ||
                          directorvalueData?.directorMdId &&
                          !directorvalueData?.directorPhNo && (
                            <p>Phone Number cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="directorPhNoExt"
                        value={directorvalueData?.directorPhNoExt}
                        className="nb-input"
                        onChange={handledirInputChange}
                      />
                    </Col>
                    <Col className="col_primarysqcn">
                      <p className="details-label mb-radio">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="directorSqcnY"
                        className="contactradio"
                        data-testid="directorSqcnY"
                        disabled={!directorvalueData?.directorFName}
                        value="Yes"
                        checked={directorvalueData?.directorSqcnY === "Yes"}
                        defaultChecked={directorvalueData?.directorSqcnY === "Yes"}
                        onChange={(e)=>{
                          handledirInputChange(e);
                          setFieldValue("practiceManagerSqcnY", "No");
                          setAdditionalPocValuesData({...additionalPocValuesData,additionalSqcnY:"No",additionalSqcnN:"Yes"})
                          setPhysicianvalueData({...physicianValueData,physicianSqcnY:"No",physicianSqcnN:"Yes"});
                        }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="No"
                        id="directorSqcnY"
                        data-testid="directorSqcnY"
                        checked={directorvalueData?.directorSqcnY === "No"}
                        defaultChecked={directorvalueData?.directorSqcnY === "No"}
                        onChange={handledirInputChange}
                      />
                    </Col>
                    <Col md={3}>
                      <button
                        type="button"
                        className="Remove_data_button ms-5"
                        onClick={() => {
                          handleRemoveData("director"),
                          setFieldValue("directorMdId", "");
                          setFieldValue("directorFName", "");
                          setFieldValue("directorLName", "");
                          setFieldValue("directorMName", "");
                          setFieldValue("directorSuffix", "");
                          setFieldValue("directorPrefix", "");
                          setFieldValue("directorTitle", "");
                          setFieldValue("directorEmail", "");
                          setFieldValue("directorPhNo", "");
                          setFieldValue("directorPhNoExt", "");
                          setFieldValue("directorSqcnY", "No");
                          setReInitialize(false)
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove  Director</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <h6 className="contact-tilte" >
                  Executive/Leadership Point of Contact{" "}
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="right"
                    overlay={popover3}
                  >
                    <img
                      src={tooltipIcon}
                      alt="Tooltip Icon"
                      className="tooltip-icon"
                    />
                  </OverlayTrigger>
                </h6>
                <div className="contact-section" >
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="executiveMdId"
                        autoComplete="off"
                        value={executiveValueData?.executiveMdId}
                        className="nb-input"
                        onChange={(e) => {
                          //what is the role executive md ID
                          handleMdOfficeIdChange(e, "executive", 6);
                          setReInitialize(true);
                          handleExecutiveInputChange(e);
                        }}
                        isInvalid={
                          !!validationError?.executiveMdId ||
                          executiveValueData?.executiveFName &&
                          !executiveValueData?.executiveMdId
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.executiveMdId ||
                          executiveValueData?.executiveFName &&
                          !executiveValueData?.executiveMdId && (
                            <p>MDOffice ID  cannot be blank</p>
                        )}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={2}>
                          <p className="details-label">{labels.PREFIX}</p>
                          <Form.Select
                            size="sm"
                            placeholder="---"
                            id="executivePrefix"
                            autoComplete="off"
                            value={executiveValueData?.executivePrefix}
                            className="nb-select form-select form-select-sm"
                            onChange={handleExecutiveInputChange}
                            isInvalid={
                              !!validationError?.executivePrefix ||
                              executiveValueData?.executiveMdId &&
                              !executiveValueData?.executivePrefix
                            }
                          >
                            <option value="">---</option>
                            {prefixData.map( (prefixItem,index) => <option key={prefixItem.prefixName+index} value={prefixItem.id}>{prefixItem.prefixName}</option> )}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                          {validationError?.executivePrefix ||
                              executiveValueData?.executiveMdId &&
                              !executiveValueData?.executivePrefix && (
                                <p>Prefix cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="executiveFName"
                            autoComplete="off"
                            value={executiveValueData?.executiveFName}
                            className="nb-input"
                            onChange={handleExecutiveInputChange}
                            isInvalid={
                              !!validationError?.executiveFName ||
                              executiveValueData?.executiveMdId &&
                              !executiveValueData?.executiveFName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.executiveFName ||
                              executiveValueData?.executiveMdId &&
                              !executiveValueData?.executiveFName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.MIDDLE_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="executiveMName"
                            autoComplete="off"
                            value={executiveValueData?.executiveMName}
                            className="nb-input"
                            onChange={handleExecutiveInputChange}
                                  
                            isInvalid={errors.executiveMName && values?.executiveMName}
                          />
                             <Form.Control.Feedback type="invalid">
                    {errors.executiveMName}
                  </Form.Control.Feedback>  
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="executiveLName"
                            autoComplete="off"
                            value={executiveValueData?.executiveLName}
                            className="nb-input"
                            onChange={handleExecutiveInputChange}
                            isInvalid={
                              !!validationError?.executiveLName ||
                              (executiveValueData?.executiveMdId &&
                                !executiveValueData?.executiveLName) ||
                              (executiveValueData?.executiveFName &&
                                !executiveValueData?.executiveLName)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.executiveLName ||
                              (executiveValueData?.executiveMdId &&
                                !executiveValueData?.executiveLName) ||
                              (executiveValueData?.executiveFName &&
                                !executiveValueData?.executiveLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.SUFFIX}</p>
                          <Form.Select
                            size="sm"
                            type="text"
                            placeholder="---"
                            id="executiveSuffix"
                            autoComplete="off"
                            value={executiveValueData?.executiveSuffix}
                            className="nb-select form-select form-select-sm"
                            onChange={handleExecutiveInputChange}
                          >
                            <option value="">---</option>
                            {suffixData.map( (suffix,index) => <option key={suffix+index} value={suffix.id}>{getSuffixByName(suffix.id)}</option> )}
                          </Form.Select>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.TITLE}</p>
                          <Form.Select
                            size="sm"
                            id="executiveTitle"
                            autoComplete="off"
                            value={executiveValueData?.executiveTitle}
                            className="nb-select form-select form-select-sm"
                            onChange={handleExecutiveInputChange}
                          >
                            <option value="">---</option>
                            {titleData.map( (titleItem,index) => <option key={titleItem.title+index} value={titleItem.id}>{titleItem.title}</option> )}
                          </Form.Select>
                        </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="executiveEmail"
                        autoComplete="off"
                        value={executiveValueData?.executiveEmail}
                        className="nb-input"
                        onChange={handleExecutiveInputChange}
                        isInvalid={
                          !!validationError?.executiveEmail ||
                          executiveValueData?.executiveMdId &&
                          !executiveValueData?.executiveEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.executiveEmail ||
                          executiveValueData?.executiveMdId &&
                          !executiveValueData?.executiveEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        maxLength={12}
                        id="executivePhNo"
                        value={executiveValueData?.executivePhNo}
                        className="nb-input"
                        //onChange={handleExecutiveInputChange}

                        onChange={(e)=>{

                          let formattedNumber = phoneNumberAutoFormat(e.target.value);

                          const formattedObject = {
                           target: {id: e.target.id, name: e.target.name, value: formattedNumber }
                        };
                        handleExecutiveInputChange(formattedObject);

                        }}
                        isInvalid={
                          !!validationError?.executivePhNo ||
                          executiveValueData?.executiveMdId &&
                          !executiveValueData?.executivePhNo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.executivePhNo ||
                          executiveValueData?.executiveMdId &&
                          !executiveValueData?.executivePhNo && (
                            <p>Phone Number cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="executivePhNoExt"
                        value={executiveValueData?.executivePhNoExt}
                        className="nb-input"
                        onChange={handleExecutiveInputChange}
                      />
                    </Col>
                    <Col md={5}>
                      <button
                        type="button"
                        className="Remove_data_button ms-5"
                        onClick={() => {
                          handleRemoveData("executive"),
                          setFieldValue("executiveMdId", "");
                          setFieldValue("executiveFName", "");
                          setFieldValue("executiveLName", "");
                          setFieldValue("executiveMName", "");
                          setFieldValue("executiveSuffix", "");
                          setFieldValue("executivePrefix", "");
                          setFieldValue("executiveTitle", "");
                          setFieldValue("executiveEmail", "");
                          setFieldValue("executivePhNo", "");
                          setFieldValue("executivePhNoExt", "");
                          setReInitialize(false)
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove  Executive/leadership of contact</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <h6 className="contact-tilte">Physician Champion</h6>
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="PhysicianMdId"
                        autoComplete="off"
                        value={physicianValueData?.PhysicianMdId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "pc", 8);
                          setReInitialize(true);
                          handlephyInputChange(e);
                        }}
                        isInvalid={!!validationError?.PhysicianMdId}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.PhysicianMdId}
                      </Form.Control.Feedback>
                      {physicianValueData?.PhysicianFName && !physicianValueData?.PhysicianMdId && (
                        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                          MDOffice ID cannot be blank
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={2}>
                          <p className="details-label">{labels.PREFIX}</p>
                          <Form.Select
                            type="sm"
                            placeholder="---"
                            id="PhysicianPrefix"
                            autoComplete="off"
                            value={physicianValueData?.PhysicianPrefix}
                            className="nb-select form-select form-select-sm"
                            onChange={handlephyInputChange}
                            isInvalid={
                              !!validationError?.PhysicianPrefix ||
                              physicianValueData?.PhysicianMdId &&
                              !physicianValueData?.PhysicianPrefix
                            }
                          >
                            <option value="">---</option>
                          {prefixData.map( (prefixItem,index) => <option key={prefixItem.prefixName+index} value={prefixItem.id}>{prefixItem.prefixName}</option> )}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {validationError?.PhysicianPrefix ||
                              physicianValueData?.PhysicianMdId &&
                              !physicianValueData?.PhysicianPrefix && (
                                <p>Prefix cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="PhysicianFName"
                            autoComplete="off"
                            value={physicianValueData?.PhysicianFName}
                            className="nb-input"
                            onChange={handlephyInputChange}
                            isInvalid={
                              !!validationError?.PhysicianFName ||
                              physicianValueData?.PhysicianMdId &&
                              !physicianValueData?.PhysicianFName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.PhysicianFName ||
                              physicianValueData?.PhysicianMdId &&
                              !physicianValueData?.PhysicianFName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.MIDDLE_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="PhysicianMName"
                            autoComplete="off"
                            value={physicianValueData?.PhysicianMName}
                            className="nb-input"
                            onChange={handlephyInputChange}    
                            isInvalid={errors.PhysicianMName && values?.PhysicianMName}                      
                          />

<Form.Control.Feedback type="invalid">
                    {errors.PhysicianMName}
                  </Form.Control.Feedback>  
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="PhysicianLName"
                            autoComplete="off"
                            value={physicianValueData.PhysicianLName}
                            defaultValue={values.PhysicianLName}
                            className="nb-input"
                            onChange={handlephyInputChange}
                            isInvalid={
                              !!validationError?.PhysicianLName ||
                              (physicianValueData?.PhysicianMdId &&
                                !physicianValueData?.PhysicianLName) ||
                              (physicianValueData?.PhysicianFName &&
                                !physicianValueData?.PhysicianLName)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.PhysicianLName ||
                              (physicianValueData?.PhysicianMdId &&
                                !physicianValueData?.PhysicianLName) ||
                              (physicianValueData?.PhysicianFName &&
                                !physicianValueData?.PhysicianLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.SUFFIX}</p>
                          <Form.Select
                            size="sm"
                            type="text"
                            placeholder="---"
                            id="PhysicianSuffix"
                            autoComplete="off"
                            value={physicianValueData.PhysicianSuffix}
                            className="nb-select form-select form-select-sm"
                            onChange={handlephyInputChange}
                          >
                            <option value="">---</option>
                            {suffixData.map( (suffix,index) => <option key={suffix+index} value={suffix.id}>{getSuffixByName(suffix.id)}</option> )}
                          </Form.Select>
                        </Col>
                        <Col md={2}>
                          <p className="details-label">{labels.TITLE}</p>
                          <Form.Select
                            size="sm"
                            id="PhysicianTitle"
                            autoComplete="off"
                            value={physicianValueData.PhysicianTitle}
                            className="nb-select form-select form-select-sm"
                            onChange={handlephyInputChange}
                          >
                            <option value="">---</option>
                            {titleData.map( (titleItem,index) => <option key={titleItem.title+index} value={titleItem.id}>{titleItem.title}</option> )}
                          </Form.Select>
                        </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="PhysicianEmail"
                        autoComplete="off"
                        value={physicianValueData?.PhysicianEmail}
                        className="nb-input"
                        onChange={handlephyInputChange}
                        isInvalid={
                          !!validationError?.PhysicianEmail ||
                          physicianValueData?.PhysicianMdId &&
                          !physicianValueData?.PhysicianEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.PhysicianEmail ||
                          physicianValueData?.PhysicianMdId &&
                          !physicianValueData?.PhysicianEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col className="col_phone">
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"

                        maxLength={12}
                        id="physicianPhNo"
                        value={physicianValueData?.physicianPhNo}
                        className="nb-input"
                        //onChange={handlephyInputChange}
                        
                        onChange={(e)=>{

                          let formattedNumber = phoneNumberAutoFormat(e.target.value);

                          const formattedObject = {
                           target: {id: e.target.id, name: e.target.name, value: formattedNumber }
                        };
                        handlephyInputChange(formattedObject);

                        }}
                      />
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="physicianPhNoExt"
                        value={physicianValueData?.physicianPhNoExt}
                        className="nb-input"
                        onChange={handlephyInputChange}
                      />
                    </Col>
                    <Col className="col_primarysqcn">
                      <p className="details-label mb-radio">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="physicianSqcnY"
                        className="contactradio"
                        data-testid="physicianSqcnY"
                        disabled={!physicianValueData?.PhysicianFName}
                        value="Yes"
                        checked={physicianValueData?.physicianSqcnY === "Yes"}
                        defaultChecked={physicianValueData?.physicianSqcnY === "Yes"}
                        onChange={(e)=>{
                          handlephyInputChange(e);
                          setFieldValue("practiceManagerSqcnY", "No");
                          setAdditionalPocValuesData({...additionalPocValuesData,additionalSqcnY:"No",additionalSqcnN:"Yes"})
                          setDirectorValueData({...directorvalueData,directorSqcnY:"No",directorSqcnN:"Yes"});

                        }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="No"
                        id="physicianSqcnY"
                        data-testid="additionalSqcnY"
                        checked={physicianValueData?.physicianSqcnY === "No"}
                        defaultChecked={physicianValueData?.physicianSqcnY === "No"}
                        onChange={handlephyInputChange}
                      />
                    </Col>
                    <Col md={3}>
                      <button
                        type="button"
                        className="Remove_data_button ms-4"
                        onClick={() => {
                          handleRemoveData("pc"),
                          setFieldValue("PhysicianMdId", "");
                          setFieldValue("PhysicianFName", "");
                          setFieldValue("PhysicianLName", "");
                          setFieldValue("PhysicianMName", "");
                          setFieldValue("PhysicianSuffix", "");
                          setFieldValue("PhysicianPrefix", "");
                          setFieldValue("PhysicianTitle", "");
                          setFieldValue("PhysicianEmail", "");
                          setFieldValue("physicianPhNo", "");
                          setFieldValue("physicianPhNoExt", "");
                          setFieldValue("physicianSqcnY", "No");
                          setReInitialize(false)
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove  Physician Champion</span>
                      </button>
                    </Col>
                  </Row>
                  
                </div>
                <Row>
                  {errorMessage!=="" && (
                      <div>
                        <Alert variant="danger" data-testid="error-server">
                          <Container>
                            <p className="mb-0" style={errorStyle}>
                              <img src={icon} alt="error-icon" className="error-icon"></img>{" "}
                              {errorMessage}
                            </p>
                          </Container>
                        </Alert>
                      </div>
                  )}
                  {addSaveMessage && (
                    <div>
                      <Alert variant="success" data-testid="providerProfileupdated">
                        <img
                          src={updatedIcon}
                          alt="Updated Icon"
                          className="updated-icon"
                        />
                          Contact information Saved locally, please select final Confirm and Save
                        <button
                          onClick={() => setAddSaveMessage(false)}
                          className="close-icon-button"
                          data-testid="providerProfileupdatedclose"
                        >
                          <img
                            src={closeIcon}
                            alt="Updated Icon"
                            className="updated-icon"
                          />
                        </button>
                      </Alert>
                    </div>
                  )}
                  <Col md={9}>
                  </Col>
                  <Col md={3} className="text-right">
                    <button className="edit-contact-button" type="submit"
                      data-testid="EditConfirmButton"
                      value="submit"
                      disabled={
                        (!values?.practiceManagerMdId 
                        && !additionalPocValuesData?.additionalMdId 
                        && !directorvalueData?.directorMdId 
                        && !executiveValueData?.executiveMdId
                        && !physicianValueData?.PhysicianMdId) ||
                        values?.practiceManagerName && !values?.practiceManagerMdId ||
                        values?.practiceManagerMdId && !values?.practiceManagerPrefix ||
                        values?.practiceManagerMdId && !values?.practiceManagerName ||
                        values?.practiceManagerMdId && !values?.practiceManagerLName ||
                        values?.practiceManagerMdId && !values?.practiceManagerEmail ||
                        values?.practiceManagerMdId && !values?.practiceManagerPhNo ||
                        additionalPocValuesData?.additionalFName && !additionalPocValuesData?.additionalMdId ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalPrefix ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalFName ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalLName ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalEmail ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalPhNo ||
                        directorvalueData?.directorFName && !directorvalueData?.directorMdId ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorPrefix ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorFName ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorLName ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorEmail ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorPhNo ||
                        executiveValueData?.executiveFName && !executiveValueData?.executiveMdId ||
                        executiveValueData?.executiveMdId && !executiveValueData?.executivePrefix ||
                        executiveValueData?.executiveMdId && !executiveValueData?.executiveFName ||
                        executiveValueData?.executiveMdId && !executiveValueData?.executiveLName ||
                        executiveValueData?.executiveMdId && !executiveValueData?.executiveEmail ||
                        executiveValueData?.executiveMdId && !executiveValueData?.executivePhNo ||
                        physicianValueData?.PhysicianFName && !physicianValueData?.PhysicianMdId ||
                        physicianValueData?.PhysicianMdId && !physicianValueData?.PhysicianPrefix ||
                        physicianValueData?.PhysicianMdId && !physicianValueData?.PhysicianFName ||
                        physicianValueData?.PhysicianMdId && !physicianValueData?.PhysicianLName ||
                        physicianValueData?.PhysicianMdId && !physicianValueData?.PhysicianEmail
                      }
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      )
      }
    </React.Fragment >
  );
}

export default ContactInformation;
