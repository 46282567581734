import React from "react";
import dropIcon from "./dropdownIcon.svg";
import PropTypes from "prop-types";
import ListItem from "Components/UI/Atoms/ListItem";

function LocationDropdownList({ dataList, selectedItemId, onItemSelected }) {
  return (
    <div className="location-dropdown">
      <div className="location-change">
        Change Location{" "}
        <span>
          <img src={dropIcon} className="location-drop-icon" alt="dropdown" />
        </span>
      </div>
      <div className="location-list-block">
        <ul className="location-list">
          {dataList?.map(({ id, locationName }) => (
            <ListItem
              key={id}
              id={id}
              name={locationName}
              selected={id === selectedItemId}
              onItemClicked={onItemSelected}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

LocationDropdownList.propTypes = {
  name: PropTypes.string,
};

export default LocationDropdownList;
