/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Form, Table, Row, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//import icon from "../Icon.svg";
import deleteIcon from "../images/delete.svg";
import ".././_style.scss";
import sortIcon from "../images/Sort_arrow_new.svg";
import plusIcon from "../add-icon.svg";
// import Pagination from "../Pagination";
// import ErrorServer from "Components/Hooks/Error404";
// import cookies from "js-cookie";
import AddProviderModal from "Components/Admin/Components/Templates/ManageSqcnPractice/PracticeInformation/ProviderAccordion/AddProviderModal";
import ViewProvider from "Components/Admin/Components/Templates/ManageSqcnPractice/PracticeInformation/ProviderAccordion/ViewProvider";
import EditProviderPageAdmin from "../../DataPages/EditProviderPageAdmin";

import EditPracticeNewProviderAdmin from "../../DataPages/EditPracticeNewProviderAdmin";
export const stillAtLocation = (termDate) => {
  if (termDate === null) {
    return "Yes";
  } else {
    const today = new Date();
    const termDateObj = new Date(termDate);

    if (termDateObj > today) {
      return "Yes";
    } else {
      return "No";
    }
  }
};

const ProviderListAdminAddFlow = ({
  practiceTitle,
  practiceManagerId,
  setContactInformationData,
  contactInformationData,
  locationPayload,
  setLocationPayload,
  addFlow,
  //setProviderLocationPayload,
  setProviderContactPayload,
  titleData,
  prefixData
}) => {
  const decodeTokenId = window.localStorage.getItem("decodeToken");
  const [providerList, setProviderList] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(10);
  const [searchNpi, setSearchNpi] = useState("");
  const [searchFullName, setSearchFullName] = useState("");
  const [searchProviderLoc, setSearchProviderLoc] = useState("");
  const [searchPrimaryLoc, setSearchPrimaryLoc] = useState("");
  // const [filteredAllData, setFilteredAllData] = useState([]);
  const [activeProviders, setActiveProviders] = useState([]);
  const [viewProvidwereId, setViewProviderId] = useState();
  const [updateModalNew, setUpdateModalNew] = useState(false);

  const [localSelectedProvider, setLocalSelectedProvider] = useState({});

  const [sortOrder, setSortOrder] = useState("asc");

  const getPrimaryLocation = (provider) => {
    if (provider?.practiceAssignment?.length > 0) {
      let Practice = provider?.practiceAssignment?.filter(
        (assignment) => assignment.assignmentTypeId === 1
      );

      if (Practice?.length > 0) {
        let location = Practice[0]?.practice;
        let primaryLocation ={};
 if (location?.location.length !== 0) {
         primaryLocation = location?.location.filter(
          (location) => location.locationNumber === 1
        )[0];
 }

        return `${primaryLocation?.street1 ?? ""} ${
          primaryLocation?.street2 ?? ""
        }, ${primaryLocation?.city ?? ""}, ${primaryLocation?.state ?? ""} ${
          primaryLocation?.zipCode ?? ""
        }`;
      }
    }

    const providerPrimaryLocation =
      provider?.practiceAssignment?.[0]?.practice?.location.find(
        (location) => location.locationNumber === 1
      );
    return `${[providerPrimaryLocation]?.street1 ?? ""} ${
      providerPrimaryLocation?.street2 ?? ""
    }, ${providerPrimaryLocation?.city ?? ""}, ${
      providerPrimaryLocation?.state ?? ""
    } ${providerPrimaryLocation?.zipCode ?? ""}`;
  };

  const hasPrimaryLocation = (provider) => {
    let providerPrimaryLocation = "No";

    if (provider?.practiceAssignment?.length > 0) {
      provider?.practiceAssignment.map((assignment) => {
        if (assignment.assignmentTypeId === 1) {
          providerPrimaryLocation = "Yes";
        }
      });
    }

    return providerPrimaryLocation;
  };

  useEffect(() => {
    if (providerList.length > 0) {
      let contactInfo = [];
      let locations = [];
      // providerList.map((provider) => {
      //   let localObject = {
      //     startDate: null,
      //     termDate: null,
      //     roleTypeId: 2,
      //     person: {
      //       prefixId: provider?.prefixId,
      //       firstName: provider?.firstName,
      //       middleName: provider?.middleName,
      //       lastName: provider?.lastName,
      //       mdofficeId: provider?.mdofficeId,
      //       npi: provider?.npi,
      //       preferredEmailAddress: provider?.preferredEmailAddress,
      //       sentaraEmailAddress: provider?.sentaraEmailAddress,
      //       updateUser: practiceManagerId,
      //       providersEffectiveDate: provider.effectiveDate,
      //       providersNameBeforeEditing: provider.providersNameBeforeEditing,
      //       suffix: provider?.suffix,
      //       suffixId: provider?.suffixId,
      //       IsPrimaryCare: false,
      //       id: provider?.id || 0,
      //       titleId: provider?.titleId,
      //       specialtySpecialtyType: provider?.specialtySpecialtyType,
      //       email:provider?.email,
      //     },
      //     practiceId: provider?.id || 0,
      //    // assignmentTypeId: provider?.practiceAssignment?.[0].assignmentTypeId,

      //     practiceAssignment: provider?.practiceAssignment,
      //     assignmentTypeId: provider?.assignmentTypeId,
      //     status: "Approved",
      //     sourceSystem: "SQCN",
      //   };
      //   contactInfo.push(localObject);
      // });
      // console.log("providerList1===", providerList);
      let contactInfoValue = {};
      providerList?.map((provider) => {
        if(provider.existingProvider) {
          contactInfoValue = provider?.practiceAssignment?.map((practiceAssignment)=> ({
            // practiceId: practiceAssignment?.practice?.location?.id,
            contractedSpecialtyId: practiceAssignment?.contractedSpecialtyId,
            assignmentTypeId: practiceAssignment?.assignmentTypeId,
            roleTypeId: practiceAssignment?.roleTypeId,
            startDate: practiceAssignment?.startDate,
            termDate: practiceAssignment?.termDate,
            notesRegardingTermdatesforRoles: practiceAssignment?.notesRegardingTermdatesforRoles,
            // personId: provider?.id,
            personId: provider?.id === undefined ? 0 : provider?.id,
            person: {
              prefixId: provider?.prefixId,
              firstName: provider?.firstName,
              middleName: provider?.middleName,
              lastName: provider?.lastName,
              mdofficeId: provider?.mdofficeId,
              npi: provider?.npi,
              preferredEmailAddress: provider?.preferredEmailAddress,
              sentaraEmailAddress: provider?.sentaraEmailAddress,
              updateUser: practiceManagerId,
              providersEffectiveDate: provider.providersEffectiveDate,
              providersNameBeforeEditing: provider.providersNameBeforeEditing,
              suffix: provider?.suffix,
              suffixId: provider?.suffixId,
              IsPrimaryCare: false,
              id: provider?.id || 0,
              // titleId: provider?.titleId,
              specialtySpecialtyType: provider?.specialtySpecialtyType,
              email:provider?.email,
              titleType: provider?.titleType,
            },
            practiceId: practiceAssignment?.practice?.id || 0,
            practice: {
              ...practiceAssignment?.practice,
              practiceAssignment: []
            },
            status: "Approved",
            sourceSystem: "SQCN",
          }))
        } else {


//let localStartDate = provider?.practiceAssignment?.[0]?.startDate;


          contactInfoValue = {
            // practiceId: practiceAssignment?.practice?.location?.id,
           
           // roleTypeId: practiceAssignment?.roleTypeId,
            startDate: provider?.practiceAssignment?.[0]?.startDate,
            termDate: provider?.practiceAssignment?.[0]?.termDate,
            // personId: provider?.id,
            // personId: provider?.id === undefined ? 0 : provider?.id,
            // startDate: null,
            // termDate: null,
            roleTypeId: 2,

            notesRegardingTermdatesforRoles:provider?.practiceAssignment?.[0]?.notesRegardingTermdatesforRoles,
            person: {
              prefixId: provider?.prefixId,
              firstName: provider?.firstName,
              middleName: provider?.middleName,
              lastName: provider?.lastName,
              mdofficeId: provider?.mdofficeId,
              npi: provider?.npi,
              preferredEmailAddress: provider?.preferredEmailAddress,
              sentaraEmailAddress: provider?.sentaraEmailAddress,
              updateUser: practiceManagerId,
              providersEffectiveDate: provider.effectiveDate,
              providersNameBeforeEditing: provider.providersNameBeforeEditing,
              suffix: provider?.suffix,
              suffixId: provider?.suffixId,
              IsPrimaryCare: false,
              id: provider?.id || 0,
              titleId: provider?.titleId,
              specialtySpecialtyType: provider?.specialtySpecialtyType,
              email:provider?.email,
              practiceAssignment: provider?.practiceAssignment,
              titleType: provider?.titleType,
            },
            status: "Approved",
            sourceSystem: "SQCN",

          };
        }
        contactInfo.push(contactInfoValue);
      });
      //   providerList.map((provider) => {
      //     let localObject = {
      //       startDate: null,
      //       termDate: null,
      //       roleTypeId: 2,
      //       person: {
      //         prefixId: provider?.prefixId,
      //         firstName: provider?.firstName,
      //         middleName: provider?.middleName,
      //         lastName: provider?.lastName,
      //         mdofficeId: provider?.mdofficeId,
      //         npi: provider?.npi,
      //         preferredEmailAddress: provider?.preferredEmailAddress,
      //         sentaraEmailAddress: provider?.sentaraEmailAddress,
      //         updateUser: practiceManagerId,
      //         providersEffectiveDate: provider.effectiveDate,
      //         providersNameBeforeEditing: provider.providersNameBeforeEditing,
      //         suffix: provider?.suffix,
      //         suffixId: provider?.suffixId,
      //         IsPrimaryCare: false,
      //         id: provider?.id || 0,
      //         titleId: provider?.titleId,
      //         specialtySpecialtyType: provider?.specialtySpecialtyType,
      //         email:provider?.email,
      //       },
      //       practiceAssignment: provider?.practiceAssignment,
      //       status: "Approved",
      //       sourceSystem: "SQCN",
      //     };
      //     contactInfo2.push(localObject);
      //   });
      //   // setProviderContactPayload(contactInfo2);
      if (contactInfo.length > 0) {
        setProviderContactPayload(contactInfo);
      }
      console.log("ProviderPracticeAssignments==", contactInfo);

      if (locations.length > 0) {
        // setProviderLocationPayload(locations);
      }
    }
  }, [providerList]);

  useEffect(() => {
    const activeAllProviders =
      providerList != null && providerList.length > 0
        ? providerList?.filter((person) => {
            if (
              person?.practiceAssignment &&
              person?.practiceAssignment.length > 0
            ) {
              return person.practiceAssignment.some(
                (assignment) =>
                  assignment?.roleTypeId === 2 && assignment?.termDate === null
              );
            }
            return false;
          })
        : [];
    setActiveProviders(activeAllProviders);
    // localStorage.setItem("providerCount", activeAllProviders.length || 0);

    // fixSonar();
  }, []);

  // const sortedData = activeProviders?.sort((a, b) => {
  //   const statusA = a.practiceAssignment[0]?.status || a.status;
  //   const statusB = b.practiceAssignment[0]?.status || b.status;
  //   if (statusA === "Pending" && statusB !== "Pending") {
  //     return -1;
  //   } else if (statusA !== "Pending" && statusB === "Pending") {
  //     return 1;
  //   }
  //   return 0;
  // });

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = sortedData?.slice(
  //   indexOfFirstRecord,
  //   indexOfLastRecord
  // );
  // const nPages = Math.ceil(sortedData?.length / recordsPerPage);

  //console.log(currentRecords,nPages, "providerList");
  // const onUpdateCancel = () => {
  //   setUpdateBox(false);
  //   setIsCheck([]);
  //   setIsCheckAll(false);
  // };
  const handleSubmit = async () => {};

  const handleSearchFullNameChange = (e) => {
    const input = e.target.value;
    setSearchFullName(input);
  };
  const handleSearchProviderLocChange = (e) => {
    const input = e.target.value;
    setSearchProviderLoc(input);
  };
  const handleSearchNpiChange = (e) => {
    const input = e.target.value;
    setSearchNpi(input);
  };
  const handleSearchPrimaryLocChange = (e) => {
    const input = e.target.value;
    setSearchPrimaryLoc(input);
  };
  const createSearchPattern = (input) => {
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };
  const filterData = (item) => {
    const searchNpiPattern = createSearchPattern(searchNpi);
    const searchFullNamePattern = createSearchPattern(searchFullName);
    const searchProviderLocPattern = createSearchPattern(searchProviderLoc);
    const searchPrimaryLocPattern = createSearchPattern(searchPrimaryLoc);

    const fullName = `${item?.firstName || ""} ${item?.middleName || ""} ${
      item?.lastName || ""
    }`
      .toLowerCase()
      .replace(/\s/g, "");
    const providerLocName = stillAtLocation(
      item?.practiceAssignment?.find((role) => role?.roleTypeId === 2)?.termDate
    );
    const providerLocationName = providerLocName
      .toLowerCase()
      .replace(/\s/g, "");
    const npi = (item?.npi || "").toLowerCase().replace(/\s/g, "");
    const primaryLocationName = getLocationString(item)
      .toLowerCase()
      .replace(/\s/g, "");

    return (
      searchFullNamePattern.test(fullName) &&
      searchProviderLocPattern.test(providerLocationName) &&
      searchNpiPattern.test(npi) &&
      searchPrimaryLocPattern.test(primaryLocationName)
    );
  };
  const getLocationString = (provider) => {
    const providerPrimaryLocation = provider?.location;
    return `${providerPrimaryLocation?.street1 ?? ""}
    ${providerPrimaryLocation?.street2 ?? ""}, 
    ${providerPrimaryLocation?.city ?? ""}, 
    ${providerPrimaryLocation?.state ?? ""} 
    ${providerPrimaryLocation?.zipCode ?? ""}`;
  };
  useEffect(() => {
    const newFilteredData = providerList?.filter(filterData);
    setProviderList(newFilteredData);
  }, [searchNpi, searchFullName, searchProviderLoc, searchPrimaryLoc]);

  const sortNameData = () => {
    const sortedProviderData = [...providerList].sort((a, b) => {
      const fullNameA = `${(a?.lastName || "").trim()} ${(
        a?.middleName || ""
      ).trim()} ${(a?.firstName || "").trim()}`.toLowerCase();
      const fullNameB = `${(b?.lastName || "").trim()} ${(
        b?.middleName || ""
      ).trim()} ${(b?.firstName || "").trim()}`.toLowerCase();
      return sortOrder === "asc"
        ? fullNameB.localeCompare(fullNameA)
        : fullNameA.localeCompare(fullNameB);
    });
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setProviderList(sortedProviderData);
  };
  const sortLocationName = () => {
    const sortedProviderData = [...providerList].sort((a, b) => {
      const locationA = getLocationString(a);
      const locationB = getLocationString(b);
      return sortOrder === "asc"
        ? locationA.localeCompare(locationB)
        : locationB.localeCompare(locationA);
    });
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setProviderList(sortedProviderData);
  };

  const sortDataYes = () => {
    const sorted = [...providerList].sort((a, b) => {
      const providerLocNameA = stillAtLocation(
        a?.practiceAssignment?.find((role) => role?.roleTypeId === 2)?.termDate
      );
      const locationA = providerLocNameA.toLowerCase().replace(/\s/g, "");
      const providerLocNameB = stillAtLocation(
        b?.practiceAssignment?.find((role) => role?.roleTypeId === 2)?.termDate
      );
      const locationB = providerLocNameB.toLowerCase().replace(/\s/g, "");
      return sortOrder === "desc"
        ? locationA.localeCompare(locationB)
        : locationB.localeCompare(locationA);
    });
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
    setProviderList(sorted);
  };

  const providerIdList = activeProviders.map((item) => item?.id);
  const firstValue = providerIdList[0];
  localStorage.setItem("providerIdList", firstValue);

  const handleViewProviderClick = (providerId) => {
    setViewProviderId(providerId); // Set the provider ID to render ViewProvider component
    window.localStorage.setItem("providerIdByAdmin", providerId);

    let localProvider = providerList.filter(
      (provider) => provider.localId === providerId
    );
    setLocalSelectedProvider(localProvider[0]);
  };

  const handleDelete = (providerId) => {
    //  filteredAllData.filter(
    //   (item) => item?.id !== providerId
    // );
    //console.log(providerId, "providerIdeee");
    const remainingRecords = providerList.filter(
      (item) => item?.localId !== providerId
    );
    setProviderList(remainingRecords);
    localStorage.setItem("providerCount", remainingRecords.length);

    //deleteProvider(providerId);
    // setUpdatedProviderVal(remainingRecords);
    // console.log(remainingRecords,"remainingRecords")
    // setFilteredAllData(updatedProviderList);
  };
  const [modalShow, setModalShow] = useState(false);
  const [viewProviderIdModal, setViewProviderIdModal] = useState(); // State to hold the provider ID for ViewProvider component
  const [creatNpi, setCreatNpi] = useState();
  // console.log(currentRecords, updatedProviderList, "updatedProviderList");
  return (
    <div>
      {/* {isError && <ErrorServer />} */}
      {/* {!isError && ( */}
      <div>
        <div className="provider-actions">
          <div className="provider-button">
            {/* {decodeTokenId.IsSQCNAdmin !== "SQCNAdmin" && ( */}
            <>
              <div className="w-100 d-flex position-relative">
                <div className="w-100 text-end">
                  <Button
                    data-testid="addprovider"
                    variant="primary"
                    onClick={() => {
                      setModalShow(true);
                      setViewProviderId(null);
                    }}
                    className="rounded-pill add_new_practice btn btn-primary"
                    disabled={viewProviderIdModal ? true : false}
                  >
                    <img src={plusIcon} alt="Search Icon" />
                    Add New Provider
                  </Button>
                </div>
                {modalShow && (
                  <AddProviderModal
                    data-testid="addprovidermodal"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    isAdmin={true}
                    setViewProviderId={setViewProviderIdModal}
                    showCreatNpi={setCreatNpi}
                    providerList={providerList}
                  />
                )}
              </div>
            </>
            {/* )} */}
          </div>
        </div>
        <div className="provider-list-table">
          <Table data-testid="providerlisttable">
            <thead>
              <tr>
                <th className="provider_Column">NPI</th>
                <th className="provider_Column">Full name</th>
                <th>Provider Location</th>
                <th className="provider_Column1">Primary Location Name</th>
                <th>Action</th>
              </tr>

              <tr className="provider_wrapper">
                <td className="search_column">
                  <Form.Group className="search-npi" controlId="formGroupEmail">
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Search"
                      autoComplete="off"
                      className="search-input new"
                      value={searchNpi}
                      onChange={handleSearchNpiChange}
                      data-testid="LocationName"
                    />
                  </Form.Group>
                </td>

                <td className="search_column">
                  <Form.Group
                    className="search-npi loc1"
                    controlId="formGroupEmail"
                  >
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Search"
                      autoComplete="off"
                      className="search-input new"
                      value={searchFullName}
                      onChange={handleSearchFullNameChange}
                      data-testid="LocationName"
                    />
                  </Form.Group>
                  <button
                    className="sort_icon_arrow pl"
                    type="button"
                    onClick={sortNameData}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </td>
                <td className="search_column">
                  <Form.Group className="search-npi" controlId="formGroupEmail">
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Search"
                      autoComplete="off"
                      className="search-input new"
                      value={searchProviderLoc}
                      onChange={handleSearchProviderLocChange}
                      data-testid="LocationName"
                    />
                  </Form.Group>
                  <button
                    className="sort_icon_arrow pl"
                    type="button"
                    onClick={sortDataYes}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </td>

                <td className="search_column">
                  <Form.Group
                    className="search-npi loc2"
                    controlId="formGroupEmail"
                  >
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Search"
                      autoComplete="off"
                      className="search-input new"
                      value={searchPrimaryLoc}
                      onChange={handleSearchPrimaryLocChange}
                      data-testid="LocationName"
                    />
                  </Form.Group>
                  <button
                    className="sort_icon_arrow pl"
                    type="button"
                    onClick={sortLocationName}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </td>
              </tr>
            </thead>

            {providerList?.length > 0 && (
              <tbody data-testid="ProviderData">
                {providerList?.map((provider) => {
                  return (
                    <tr
                      className={
                        viewProvidwereId === provider.id
                          ? "tr_border_color"
                          : "tr_table"
                      }
                      key={provider?.id}
                    >
                      <td data-testid="providerPrimaryNpi">{provider?.npi}</td>

                      <td data-testid="providerName">
                        {provider?.practiceAssignment?.[0]?.status ===
                          "Pending" || provider?.status === "Pending" ? (
                          <>
                            <Link
                              key={provider?.id}
                              onClick={() => {
                                handleViewProviderClick(provider.localId);
                              }}
                            >
                              {provider?.firstName}&nbsp;
                              {provider?.middleName}
                              &nbsp;
                              {provider?.lastName},{" "}
                              {provider?.titleType[0]?.title}
                            </Link>
                          </>
                        ) : (
                          <Link
                            key={provider?.id}
                            onClick={() => {
                              handleViewProviderClick(provider.localId);
                            }}
                          >
                            {provider?.firstName}&nbsp;{provider?.middleName}
                            &nbsp;
                            {provider?.lastName}
                          </Link>
                        )}
                      </td>
                      <td
                        className="still-at-locations"
                        data-testid="providerStillAtLocation"
                      >
                        {hasPrimaryLocation(provider)}
                      </td>

                      {/* {provider?.location?.street1 ? ( */}
                      <td data-testid="providerPrimaryLocation">
                        {getPrimaryLocation(provider)}
                      </td>
                      {/* ) : (
                          <td data-testid="providerPrimaryLocation">--</td>
                        )} */}
                      <>
                        <td
                          className="text-center"
                          data-testid="providerStatus"
                        >
                          <button
                            type="button"
                            className="icon_style"
                            onClick={() => handleDelete(provider.localId)}
                          >
                            <img src={deleteIcon} alt="Sort Icon" />
                          </button>
                        </td>

                        <Modal
                          show={updateModalNew}
                          onHide={() => setUpdateModalNew(false)}
                          size="md"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          className="update-provider-modal"
                          data-testid="bulkupdateprovidermodal"
                        >
                          <Modal.Header closeButton></Modal.Header>
                          <Modal.Body>
                            <Row className="mt-3">
                              <h6 className="text-center">
                                Are you sure you would like to proceed?
                              </h6>
                            </Row>
                            <Row className="mt-3">
                              {/* <p>{provider?.location}</p>
            <p>{provider?.address}</p> */}
                            </Row>
                          </Modal.Body>
                          <Modal.Footer className="justify-content-center modal-footer-transparent">
                            <button
                              onClick={() => setUpdateModalNew(false)}
                              className="white-btn"
                              data-testid="close-button"
                            >
                              Cancel
                            </button>

                            <button
                              className="blue-btn"
                              onClick={handleSubmit}
                              type="submit"
                              data-testid="createnewproviderbutton"
                            >
                              Yes, Confirm
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
          {/* {activeProviders?.length > 0 && (
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setIsCheckAll={setIsCheckAll}
                isCheckAll={isCheckAll}
                isCheck={isCheck}
                selectedPractice={selectedPracticeId}
                data-testid="PaginationPage"
              />
            )} */}
        </div>

        {viewProvidwereId && (
          <EditProviderPageAdmin
            practiceTitle={practiceTitle}
            selectedPracticeId={viewProvidwereId}
            practiceManagerId={practiceManagerId}
            providerId={window.localStorage.getItem("providerIdByAdmin")}
            updatedProviderList={providerList}
            setViewProviderIdModal={setViewProviderIdModal}
            setUpdatedProviderList={setProviderList}
            addFlow={addFlow}
            localSelectedProvider={localSelectedProvider}
            setViewProviderId={setViewProviderId}
            titleData={titleData}
          />
        )}
        {viewProviderIdModal ? (
          <ViewProvider
            practiceTitle={window.localStorage.getItem("practiceTitle")}
            selectedPracticeId={window.localStorage.getItem(
              "selectedPracticeIdByAdmin"
            )}
            // practiceManagerId={window.localStorage.getItem("practiceManagerId")}
            practiceManagerId={
              decodeTokenId?.id ||
              window.localStorage.getItem("practiceManagerId")
            }
            personRoleName={""}
            locationPayload={locationPayload}
            setLocationPayload={setLocationPayload}
            setContactInformationData={setContactInformationData}
            contactInformationData={contactInformationData}
            addFlow={addFlow}
            updatedProviderList={providerList}
            setViewProviderIdModal={setViewProviderIdModal}
            setUpdatedProviderList={setProviderList}
            titleData={titleData}
          />
        ) : null}
        {creatNpi ? (
          <EditPracticeNewProviderAdmin
            practiceTitle={window.localStorage.getItem("practiceTitle")}
            practiceManagerId={
              decodeTokenId?.id ||
              window.localStorage.getItem("practiceManagerId")
            }
            selectedPracticeId={window.localStorage.getItem(
              "selectedPracticeIdByAdmin"
            )}
            locationPayload={locationPayload}
            setLocationPayload={setLocationPayload}
            setContactInformationData={setContactInformationData}
            contactInformationData={contactInformationData}
            setUpdatedProviderList={setProviderList}
            setViewProviderIdModal={setViewProviderIdModal}
            updatedProviderList={providerList}
            setCreatNpi={setCreatNpi}
            addFlow={addFlow}
            titleData={titleData}
            prefixData={prefixData}
          />
        ) : null}

        {/* <div className={`bulk-update-box ${isCheck.length > 0}`}>
            <Row>
              <Col md={8}>
                <p className="update-text">
                  By clicking &quot;Update&quot; you are confirming that all the
                  provider profiles you have selected are up to date.
                </p>
              </Col>
              <Col md={4} className="update-button">
                <button
                  className="white-btn-update"
                  onClick={onUpdateCancel}
                  data-testid="bulkupdateCancelButton"
                >
                  Cancel
                </button>
                <button
                  className="blue-btn-update"
                  //   onClick={() => setUpdateModal(true)}
                  type="submit"
                  data-testid="bulkupdateConfirmButton"
                >
                  Update
                </button>
              </Col>
            </Row>
          </div>
          {!(activeProviders?.length > 0) && (
            <div className="error-message" data-testid="noProviderData">
              <p>
                <img src={icon} alt="error-icon" className="error-icon"></img>
                Currently there are no providers at this Practice
              </p>
            </div>
          )} */}
      </div>
      {/* )} */}
    </div>
  );
};

export default ProviderListAdminAddFlow;
