import { useFetch } from "Components/Hooks/Fetch";
import Spinner from "Components/Hooks/Spinner";
import jwt_decode from "jwt-decode";
import cookies from "js-cookie";
import ExistingProvider from "Components/Templates/ProviderList/ExistingProvider";



const ViewProvider = ({
  practiceTitle,
  selectedPracticeId,
  practiceManagerId,
  locationPayload,
  setLocationPayload,
  setContactInformationData,
  contactInformationData,
  addFlow,
  setViewProviderIdModal,
  setUpdatedProviderList,
  updatedProviderList,
  providerListGetUpdate,
  setRefreshData,
  refreshData,
  titleData
}) => {
  const token = cookies.get("access");
  const decodeToken = jwt_decode(token);
  const providerId = window.localStorage.getItem("providerId");
  const { data, loading } = useFetch(`/api/v1/providers/${providerId}?practicemanagerid=${decodeToken?.id || practiceManagerId}&&isSqcnAdmin=true`);


  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <ExistingProvider
        providerSummary={data}
        id={providerId}
        selectedPracticeId={selectedPracticeId}
        practiceTitle={practiceTitle}
        practiceManagerId={decodeToken?.id || practiceManagerId}
        locationPayload={locationPayload}
        setLocationPayload={setLocationPayload}
        setContactInformationData= {setContactInformationData}
        contactInformationData= {contactInformationData}
        addFlow={addFlow}
        setViewProviderIdModal={setViewProviderIdModal}
        setUpdatedProviderList={setUpdatedProviderList}
        updatedProviderList={updatedProviderList}
        providerListGetUpdate={providerListGetUpdate}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
        titleData={titleData}
      />
    </>
  );
};

export default ViewProvider;
