import React from "react";
// import PropTypes from "prop-types";

import("./_style.scss");

function Paragraph({ locationCount, providerListCount }) {
  return (
    <p className="location-count" data-testid="locprovcount">
      {locationCount} Location(s) {providerListCount} Provider(s)
    </p>
  );
}

export default Paragraph;
