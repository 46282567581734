import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import "./style.css";
import { Table } from "react-bootstrap";
import { Button } from "../../UI/Atoms/Button";
import { Notification } from "../../UI/Atoms/Notification";
import PendingIcon from "./images/pending_icon.svg";
import { ReviewPracticeApproval } from "./ReviewPracticeApproval";
import Pagination from "./Pagination";

const PracticeApprovalBoard = () => {
  const [reviewMode, setReviewMode] = useState(false);
  const [changeFieldList, setChangeFieldList] = useState([]);
  const [practiceReviewId, setPracticeReviewId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;

  const fetchPracticeChangeRecordsList = () => {
    fetch(
      base_url +
      "/api/v1/providers/GetPracticeApprovalBoardList",
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setChangeFieldList(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchPracticeChangeRecordsList();
    };
    fetch();
  }, []);

  const handleReviewClick = (e, id, id1) => {
    const reviewId = [id, id1];
    console.log("on", reviewId, e);
    setPracticeReviewId(reviewId);
    setReviewMode(true);
  };
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = changeFieldList?.length > 0 && changeFieldList?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(changeFieldList?.length / recordsPerPage);
  console.log("cr", currentRecords);
  return (
    <React.Fragment>
      {reviewMode ? (
        <div>
          <ReviewPracticeApproval practiceReviewId={practiceReviewId} setReviewMode={setReviewMode} />
        </div>
      ) : (
        <div className="manage-location-table1">
          <Notification
            text={`${changeFieldList?.length || 0} Pending Approvals`}
            iconImage={PendingIcon}
          />
          <Table data-testid="providerlisttable">
            <thead>
              <tr>
                <th>Practice Name</th>
                <th>Practice Manager</th>
                <th>Request Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody data-testid="ProviderData">
              {currentRecords?.length > 0 && currentRecords?.map((item) => (
                <tr key={item?.changerecordsId}>
                  <td>{item?.practiceName}</td>
                  <td>{item?.practiceManagerFistName}&nbsp;
                    {item?.practiceManagerMiddleName}&nbsp;
                    {item?.practiceManagerLastName} </td>
                  <td>{item?.requestType}</td>
                  <td>
                    <Button
                      text="Review"
                      className="Review-button"
                      onClick={(e) => handleReviewClick(e,
                        item?.practiceId, item?.requestTypeId)}
                    />
                  </td>
                </tr>
              ))}
              {!(currentRecords.length > 0) && (
                <div className="error-message" data-testid="noProviderData">
                  <p>
                    <img src={PendingIcon} alt="error-icon" className="error-icon"></img>
                    Currently there are no pending approvals.
                  </p>
                </div>
              )}
            </tbody>
          </Table>
          {currentRecords?.length > 0 && (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              data-testid="PaginationPage"
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default PracticeApprovalBoard;
