import React, { useState, useEffect } from "react";
import DropdownList from "Components/UI/Molecules/Dropdownlist";
import Spinner from "Components/Hooks/Spinner";

function PracticeDropdown({
  practiceListData,
  selectedPracticeId,
  onItemSelected,
}) {
  const [locNameData, setLocName] = useState(practiceListData);
  const [loading, setLoading] = useState(true);
  const updateLocation1Name = () => {
    // First, map over dataList to update practiceName based on locationDetails
    const ascdata = practiceListData?.sort((a, b) => a.practiceName.localeCompare(b.practiceName));
    const data = ascdata?.map((data) => {
      if (data?.locationDetails) {
        const location = data?.locationDetails.find(
          (location) => location?.locationNumber === 1
        );
        if (location?.locationName) {
          setLoading(false);
          return {
            ...data,
            practiceName: location?.locationName || "",
          };
        } else {
          setLoading(true);
          return {};
        }
      } else {
        setLoading(true);
        return {};
      }
    });
    setLocName(data);
  };
  useEffect(() => {
    updateLocation1Name();
  }, []);
  if (loading) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <DropdownList
        dataList={locNameData}
        onItemSelected={onItemSelected}
        selectedItemId={selectedPracticeId}
      />
    </React.Fragment>
  );
}

export default PracticeDropdown;
