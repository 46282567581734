/* eslint-disable indent */
import React from "react";
import cookies from "js-cookie";
import { Button } from "../../UI/Atoms/Button";

export const ReviewProviderSubmit = ({
    RequestTypeDataID,
    isCheck,
    personReqId,
    practiceInfoId,
    providerReviewApproval,
    providerReviewReject,
    setIsCheck,
    statusChange,
    statusChangeReject,
    locStatusChange,
    updateUserId,
    updateAction,
    isCheckLoc,
    setIsCheckLoc,
    locRejectStatusChange,
    locationReviewApproval,
    locationReviewReject
}) => {
    const token = cookies.get("access");
    const base_url = process.env.REACT_APP_SERVER_URL1;
    const subscriptionKey = process.env.REACT_APP_TOKEN;

    const handleClick = (statusCode) => {
        handleSubmit(statusCode);
    };

    const handleSubmit = async (statusCode) => {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "access-control-allow-credentials": "true",
                "ocp-apim-subscription-key": subscriptionKey,
                "api-supported-versions": "1.0",
            },
            body: JSON.stringify(isCheck),
        };

        const response = await fetch(
            base_url +
            `/api/v1/providers/ApproveRejectReviewDetails?requestTypeId=${RequestTypeDataID}&statustoUpdate=${statusCode}&personId=${personReqId}&practiceId=${practiceInfoId}&updateUser=${updateUserId}&Action=${updateAction}`,
            requestOptions
        );

        console.log("rep", response, response?.status);
        if (response?.status === 200 && personReqId !== 0 && statusCode === "Approved" && updateAction === "APAL") {
            locStatusChange();
            setIsCheckLoc([]);
            providerReviewApproval(true);
            setIsCheck([]);
            statusChange();
        } else if (response?.status === 200 && personReqId !== 0 && statusCode === "Approved" && updateAction === "AL") {
            locStatusChange();
            setIsCheckLoc([]);
            setIsCheck([]);
            locationReviewApproval();
        } else if (response?.status === 200 && personReqId !== 0 && statusCode === "Approved") {
            providerReviewApproval(true);
            setIsCheck([]);
            statusChange();
        } else if (response?.status === 200 && personReqId !== 0 && statusCode === "Rejected" && updateAction === "AL") {
            setIsCheckLoc([]);
            setIsCheck([]);
            locRejectStatusChange();
            locationReviewReject();
        }
        else if (response?.status === 200 && personReqId !== 0 && statusCode === "Rejected") {
            providerReviewReject(true);
            setIsCheck([]);
            statusChangeReject();
        }
    };

    return (
        <div className="submit-box">
            <Button
                text="Cancel"
                className="Reject-button"
                disabled={!isCheck.length > 0 && !isCheckLoc?.length > 0}
                onClick={() => handleClick("Cancel")}
                type="submit"
            />
            <Button
                text="Reject"
                className="Reject-button"
                disabled={!isCheck.length > 0 && !isCheckLoc?.length > 0}
                onClick={() => handleClick("Rejected")}
                type="submit"
            />
            <Button
                text="Approve"
                className="Review-button"
                disabled={!isCheck.length > 0 && !isCheckLoc?.length > 0}
                onClick={() => handleClick("Approved")}
                type="submit"
            />
        </div>
    );
};
