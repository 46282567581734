import React from "react";
import Sidebar from "../Pages/Sidebar";
import Header from "../Pages/Header";
import ManagePpdAcess from "./ManagePpdAcess";
import { Popover } from "react-bootstrap";

const mdofficeTooltip = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">Please enter MDOffice ID</p>
    </Popover.Body>
  </Popover>
);
const superGroupTooltip = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">List of Supergroup</p>
    </Popover.Body>
  </Popover>
);
const allLocationsTooltip = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">All location1 names that are available for provisioning to the end-user</p>
    </Popover.Body>
  </Popover>
);
const provisionLocationsTooltip = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">Location1 names for which the user has PPD access</p>
    </Popover.Body>
  </Popover>
);

export const Tables = (props) => {
  return (
    <div className="dashboard d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flow-root",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Header
          headerText="Manage PPD Access"
          SQCNName={props?.pmName}
        />
        <div style={{ height: "100%", background: "#f5f5f5" }}>
          <div
            className="dashboard-body"
            style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}
          >
            <ManagePpdAcess
              mdofficeTooltip={mdofficeTooltip}
              superGroupTooltip={superGroupTooltip}
              allLocationsTooltip={allLocationsTooltip}
              provisionLocationsTooltip={provisionLocationsTooltip}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
