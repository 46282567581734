import React, { useState } from "react";
import labels from "../../Static/Dictionary.json";
import { Table } from "react-bootstrap";
import Checkbox from "../../UI/Atoms/Checkbox";
import { ReviewSubmit } from "./ReviewSubmit";
import { changeStatus } from "utils";

export const ChangePracticeInformation = ({
  changePracticeReviewData,
  RequestTypeData,
  practiceReviewApproval,
  practiceReviewReject,
  fetchReviewPracticeList,
  setReviewMode
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = () => {
    console.log("cal", isCheckAll);
    if (!isCheckAll) {
      setIsCheckAll(!isCheckAll);
      const selectedValues = changePracticeReviewData?.filter(
        (item) => item?.approvalStatus !== "Rejected" &&
          item?.approvalStatus !== "Approved" &&
          item?.approvalStatus === "Pending"
      );
      setIsCheck(selectedValues?.map((item) => item?.changeFieldId));
    } else {
      setIsCheckAll(!isCheckAll);
      setIsCheck([]);
    }
  };
  const handleClick = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  console.log("isc", isCheck);
  return (
    <>
      {changePracticeReviewData?.length > 0 &&
        <div>
          <div className="manage-location-table1">
            <Table data-testid="providerlisttable">
              <thead>
                <tr>
                  <th className="checkhead">
                    <Checkbox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                      className="dropbtn"
                    />
                  </th>
                  <th>{labels.Field_Name}</th>
                  <th>{labels.Old_Value}</th>
                  <th>{labels.New_Value}</th>
                  <th>{labels.Status}</th>
                </tr>
              </thead>
              <tbody data-testid="ProviderData">
                {changePracticeReviewData?.map((item) => (
                  <tr key={item?.changeFieldId}>
                    <td className="checkhead">
                      {item?.approvalStatus === "Pending" &&
                        <Checkbox
                          key={item?.changeFieldId}
                          type="checkbox"
                          id={item?.changeFieldId}
                          handleClick={(e) => handleClick(e, item?.changeFieldId)}
                          isChecked={isCheck?.includes(item?.changeFieldId)}
                        />
                      }
                    </td>
                    <td>{item?.changeFieldName}</td>
                    <td>{item?.oldValue} </td>
                    <td>{item?.newValue}</td>
                    <td>{changeStatus(item?.approvalStatus)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <ReviewSubmit
            RequestTypeDataID={RequestTypeData?.requestTypeId}
            isCheck={isCheck}
            practiceInfoId={changePracticeReviewData[0]?.practiceId}
            personReqId={0}
            practiceReviewApproval={practiceReviewApproval}
            practiceReviewReject={practiceReviewReject}
            fetchReviewPracticeList={fetchReviewPracticeList}
            setIsCheck={setIsCheck}
            setReviewMode={setReviewMode}
          />
        </div>
      }
    </>
  );
};
