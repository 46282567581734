import React, { useState } from "react";
import { Popover } from "react-bootstrap";
import ProviderList from "../ProviderList/ProviderList";
import providerTooltip from "Static/provider.json";
import cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import Spinner from "Components/Hooks/Spinner";
import { useFetch } from "Components/Hooks/Fetch";

const primaryLocationPopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{providerTooltip.primaryLocation}</p>
    </Popover.Body>
  </Popover>
);

const stillAtPracticePopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{providerTooltip.StillAtLocation}</p>
    </Popover.Body>
  </Popover>
);
const ProviderListPage = ({
  selectedPracticeId,
  locationListData,
  practiceManagerId,
  providerListUpdate,
  addNewProviderList,
  handleProviderClick,
  isAdmin,
  PersonRole,
}) => {
  const [isData, setIsData] = useState([]);
  const [isFirstData, setIsFirstData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { state } = useLocation();
  const navigation = useNavigate();
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  useEffect(() => {
    addNewProviderList(state?.isCreate);
  }, []);
  const providerListDataDetails = async () => {
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    const firstResponse = await fetch(
      base_url +
        `/api/v1/providers/ProviderDataByPracticeId?practiceId=${selectedPracticeId}&practicemanagerid=${practiceManagerId}&isSQCNAdmin=${isAdmin}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    );

    if (firstResponse.ok) {
      const firstData = await firstResponse.json();
      if (firstData?.length > 0) {
        firstData?.sort((a, b) => {
          const fullNameA = `${a?.lastName} ${a?.firstName}`;
          const fullNameB = `${b?.lastName} ${b?.firstName}`;
          return fullNameA.localeCompare(fullNameB);
        });
        const updatedDataPromises = firstData.map(async (item) => {
          if (item?.practiceAssignment[0]?.personId) {
            try {
              const secondResponse = await fetch(
                base_url +
                  `/api/v1/providers/GetProviderUnderProcessById?providerId=${item?.practiceAssignment[0]?.personId}`,
                {
                  method: "GET",
                  headers: new Headers({
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    "ocp-apim-subscription-key": subscriptionKey,
                  }),
                }
              );
              if (secondResponse.ok) {
                const secondData = await secondResponse.json();
                if (secondData?.length > 0) {
                  const providerPrimaryLocationData = secondData.find((el) => el?.field === "PrimaryLocation");
                  if (
                    providerPrimaryLocationData && 
                    providerPrimaryLocationData?.newValue?.toLowerCase() === item?.practiceLocation[0]?.locationName.toLowerCase()
                  ) {
                    if (item?.id?.toString() === providerPrimaryLocationData?.keyColumnValue.toString()) {
                      return { ...item, location1Status: "Pending" };
                    }
                  } else {
                    return { ...item, location1Status: "Approved" };
                  }
                } else {
                  return { ...item, location1Status: "Approved" };
                }
              }
            } catch (error) {
              console.error("Error fetching provider details:", error);
              return item;
            }
          }
          return item;
        });
        Promise.all(updatedDataPromises)
          .then((updatedFirstData) => {
            setIsFirstData(updatedFirstData);
            setIsError(false);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error in Promise.all:", error);
            setIsError(true);
            setIsLoading(false);
          });
      } else {
        setIsFirstData([]);
        setIsError(true);
      }
    } else {
      setIsFirstData([]);
      setIsError(true);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      providerListDataDetails();
    };
    fetch();
  }, [selectedPracticeId]);
  const providerListGetUpdate = async () => {
    console.log("abc"), providerListUpdate();
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    const firstResponse = await fetch(
      base_url +
        `/api/v1/providers/ProviderDataByPracticeId?practiceId=${selectedPracticeId}&practicemanagerid=${practiceManagerId}&isSQCNAdmin=${isAdmin}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    );
    if (firstResponse.ok) {
      const firstData = await firstResponse.json();
      if (firstData?.length > 0) {
        firstData?.sort((a, b) => {
          const fullNameA = `${a?.lastName} ${a?.firstName}`;
          const fullNameB = `${b?.lastName} ${b?.firstName}`;
          return fullNameA.localeCompare(fullNameB);
        });
        const updatedDataPromises = firstData.map(async (item) => {
          if (item?.practiceAssignment[0]?.personId) {
            try {
              const secondResponse = await fetch(
                base_url +
                  `/api/v1/providers/GetProviderUnderProcessById?providerId=${item?.practiceAssignment[0]?.personId}`,
                {
                  method: "GET",
                  headers: new Headers({
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    "ocp-apim-subscription-key": subscriptionKey,
                  }),
                }
              );
              if (secondResponse.ok) {
                const secondData = await secondResponse.json();
                if (secondData?.length > 0) {
                  const providerPrimaryLocationData = secondData.find((el) => el?.field === "PrimaryLocation");
                  if (
                    providerPrimaryLocationData && 
                    providerPrimaryLocationData?.newValue?.toLowerCase() === item?.practiceLocation[0]?.locationName.toLowerCase()
                  ) {
                    if (item?.id?.toString() === providerPrimaryLocationData?.keyColumnValue.toString()) {
                      return { ...item, location1Status: "Pending" };
                    }
                  } else {
                    return { ...item, location1Status: "Approved" };
                  }
                } else {
                  return { ...item, location1Status: "Approved" };
                }
              }
            } catch (error) {
              console.error("Error fetching provider details:", error);
              return item;
            }
          }
          return item;
        });
        Promise.all(updatedDataPromises)
          .then((updatedFirstData) => {
            setIsData(updatedFirstData);
            setIsError(false);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error in Promise.all:", error);
            setIsError(true);
            setIsLoading(false);
          });
      } else {
        setIsData([]);
        setIsError(true);
      }
    } else {
      setIsData([]);
      setIsError(true);
    }
  };
  const handleClose = () => {
    navigation("/", { state: { isCreate: false } });
    handleProviderClick(state);
  };
  console.log("gggg", handleClose, isFirstData);

  const { data = [], loading } = useFetch(
    `/api/v1/practices/PracticeSummary?practiceManagerId=${practiceManagerId}&isSQCNAdmin=${isAdmin}`
  );
  const [providerCount, setProviderCount] = useState(0);
  useEffect(() => {
    const practiceProviderCount = data?.practiceDetails && data?.practiceDetails?.find((prId) => prId?.practiceId === parseInt(selectedPracticeId))?.providerCount;
    setProviderCount(practiceProviderCount);
  }, [selectedPracticeId]);
  return (
    <> {isLoading || loading ? (
      <Spinner />
    ) : (
      <ProviderList
        providerList={isFirstData.length > 0 ? isFirstData : isData}
        selectedPracticeId={selectedPracticeId}
        primaryLocationPopover={primaryLocationPopover}
        stillAtPracticePopover={stillAtPracticePopover}
        locationListData={locationListData}
        practiceManagerId={practiceManagerId}
        isError={isError}
        providerListUpdate={providerListGetUpdate}
        PersonRole={PersonRole}
        providerCount={providerCount}
      />
    )
    }
    </>
  );
};

export default ProviderListPage;
