import React from "react";
import { useFetch } from "Components/Hooks/Fetch";
import EditBoardSpecialty from "Components/UI/Organisms/EditSpecialty";

function EditSpecialtyPage({
  specialtyDetailsByspecialtype,
  selectedSpecialtyIdfn,
  updatedSpltyName
}) {
  const { data } = useFetch("/api/v1/component/GetBoardSpecialities");
  return (
    <>
      <EditBoardSpecialty
        specialtydata={data}
        specialtyDetailsByspecialtype={specialtyDetailsByspecialtype}
        selectedSpecialtyIdfn={selectedSpecialtyIdfn}
        updatedSpltyName={updatedSpltyName}
      />
    </>
  );
}

export default EditSpecialtyPage;
