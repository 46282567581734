import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { listToRecord } from "utils";
import PracticePreview from "Components/UI/Molecules/PracticePreview";

function EditPracticeBanner({
  practiceListData,
  practiceNameLegal,
  editPractice,
  providerData,
}) {
  const selectedPracticeId = localStorage.getItem("selectedPracticeIdByAdmin");
  const practiceMap = useMemo(
    () => listToRecord(practiceListData?.practiceDetails, "practiceId"),
    [practiceListData]
  );
  const selectedPractice =
    practiceMap[selectedPracticeId] || practiceListData?.practiceDetails[0];

  const { practiceName } = selectedPractice;
  const [updatedLocationCount, setUpdatedLocationCount] = useState(0);
  let editedLocationCount = localStorage.getItem("locationCount");
  const [updatedProviderCount, setUpdatedProviderCount] = useState(0);
  let editedProviderCount = localStorage.getItem("providerCount");
  const providerListDataDetails = () => {
    if (providerData?.length > 0) {
      const dataCount =
        providerData?.filter((person) => {
          if (
            person?.practiceAssignment &&
            person?.practiceAssignment.length > 0
          ) {
            return person.practiceAssignment.some(
              (assignment) =>
                assignment?.roleTypeId === 2 && assignment?.termDate === null
            );
          }
          return false;
        }) || [];
      setUpdatedProviderCount(dataCount?.length);
      localStorage.setItem("providerCount", dataCount?.length);
    } else {
      setUpdatedProviderCount(0);
      localStorage.setItem("providerCount", 0);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      providerListDataDetails();
    };
    fetch();
  }, [selectedPracticeId]);
  useEffect(() => {
    setUpdatedLocationCount(editedLocationCount);
  }, [editedLocationCount]);
  useEffect(() => {
    setUpdatedProviderCount(editedProviderCount);
  }, [editedProviderCount]);
  return (
    <React.Fragment>
      <div className="manage-practice-banner">
        <Row>
          <Col md={10} xs={12}>
            <PracticePreview
              practiceTitle={practiceName}
              locationCount={updatedLocationCount}
              providerListCount={updatedProviderCount}
              practiceNameLegal={practiceNameLegal}
              editPractice={editPractice}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default EditPracticeBanner;
