import React, { useState } from "react";
import labels from "../../Static/Dictionary.json";
import { Block } from "../../UI/Atoms/Block";
import { Col, Row } from "react-bootstrap";
import Checkbox from "../../UI/Atoms/Checkbox";
import { ReviewSubmit } from "./ReviewSubmit";
import { changeStatus } from "utils";

export const LocationReview = ({
  locationReviewData,
  RequestTypeData,
  practiceInfoId,
  practiceReviewApproval,
  practiceReviewReject,
  fetchReviewPracticeList,
  setReviewMode
}) => {
  const [isCheck, setIsCheck] = useState([]);

  const handleClick = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  console.log("isc", isCheck);
  return (
    <>
      {locationReviewData?.length > 0 &&
        <div>
          {locationReviewData?.map((item) => (
            <div className="Approvalbox" key={item?.locationReplicaId}>
              <div className="location-name-box">
                <Row>
                  <Col md={10}>
                    {item?.approvalStatus === "Pending" &&
                      <div className="checkbox-text">
                        <Checkbox
                          type="checkbox"
                          id={item?.locationReplicaId}
                          handleClick={(e) => handleClick(e, item?.locationReplicaId)}
                          isChecked={isCheck.includes(item?.locationReplicaId)}
                        />
                      </div>
                    }
                    <h6>{item?.locationName}</h6>
                  </Col>
                  <Col md={2}>
                    {changeStatus(item?.approvalStatus)}
                  </Col>
                </Row>
              </div>
              <div className="mainblockbox">
                <Row>
                  <Block labelText={labels.Address} details={`${item?.street1} ${item?.street2} ${item?.street3}, 
            ${item?.city}, ${item?.state}, ${item?.zipCode}`} />
                  <Block
                    labelText={labels.Phone_Number_Main}
                    details={item?.main_ContactNumber}
                  />
                  <Block labelText={labels.Fax_Number} details={item?.fax_ContactNumber} />
                </Row>
                <Row className="mt-3">
                  <Block
                    labelText={labels.Scheduling_Number}
                    details={item?.directScheduling_ContactNumber}
                  />
                </Row>
              </div>
            </div>
          ))}
          <ReviewSubmit
            RequestTypeDataID={RequestTypeData?.requestTypeId}
            isCheck={isCheck}
            practiceInfoId={practiceInfoId}
            personReqId={0}
            practiceReviewApproval={practiceReviewApproval}
            practiceReviewReject={practiceReviewReject}
            fetchReviewPracticeList={fetchReviewPracticeList}
            setIsCheck={setIsCheck}
            setReviewMode={setReviewMode}
          />
        </div>
      }
    </>
  );
};
