import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import tooltipIcon from "./AddIcon.svg";

const popover2 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">To be approved by SQCN Admin</p>
    </Popover.Body>
  </Popover>
);

function ApproveIcon() {
  return (
    <React.Fragment>
      <OverlayTrigger
        trigger={["hover", "hover"]}
        placement="bottom"
        overlay={popover2}
      >
        <img src={tooltipIcon} alt="Tooltip Icon" className="tooltip-icon" />
      </OverlayTrigger>
    </React.Fragment>
  );
}

export default ApproveIcon;
