/* eslint-disable indent */
import React from "react";
import { Col, Row, OverlayTrigger } from "react-bootstrap";
import { findByPracticeRoleType } from "utils";
import labels from "Static/Dictionary.json";
import tooltipIcon from "./tooltipIcon.svg";
import ErrorServer from "Components/Hooks/Error404";
import EditContactInformation from "./EditContactInformation";
import("./_style.scss");

function ContactInformation({
  data,
  selectedPracticeId,
  practiceManagerId,
  contactInfoUpdate,
  // contactpracticeInfoUpdate,
  popover,
  popover1,
  popover2,
  popover3,
  popover4,
  isContactServerError,
  contactInfoGetUpdate,
  editMode,
  setEditMode,
  contactInfoValidation,
  setContactInfoValidation,
  setContactInfoError,
  setContactEditModeBox,
  contactEditModeBox,
  hiddenInnerSubmitFormRef,
  setPracticeEditMode,
  setPracticeContactInfoUpdate,
  PersonRole,
  handleEditClick
}) {
  // const [editMode, setEditMode] = useState(false);
  const [practicePhysicianChampion] = findByPracticeRoleType(
    data,
    "Practice Physician Champion"
  );
  const [practiceManager] = findByPracticeRoleType(data, "Practice Manager");
  const [director] = findByPracticeRoleType(data, "Director");
  const [AdditionalPoint] = findByPracticeRoleType(
    data,
    "Additional Practice Contact"
  );
  const [executive] = findByPracticeRoleType(
    data,
    "Executive/Leadership Contact"
  );
  const primarySqcnByRoleTypeId = practiceManager?.practiceAssignment.find(
    (item) => item.roleTypeId === 5
  );
  const addprimarySqcnByRoleTypeId = AdditionalPoint?.practiceAssignment.find(
    (item) => item.roleTypeId === 5
  );
  // const handleEditClick = () => {
  //   setEditMode(true);
  //   contactpracticeInfoUpdate();
  // };
  console.log("ad", AdditionalPoint);
  return (
    <React.Fragment>
      {isContactServerError && <ErrorServer />}
      {!isContactServerError && (
        <>
          {PersonRole === "SQCNAdmin" &&
            <Row className="mb-4">
              <Col md={10}></Col>
              <Col md={2}>
                <button
                  className="edit-contact-button"
                  onClick={handleEditClick}
                >
                   Edit
                </button>
              </Col>
            </Row>
          }
        <div className="contact-information">
          {editMode ? (
            <div>
              <EditContactInformation
                data={data}
                isContactServerError={isContactServerError}
                selectedPracticeId={selectedPracticeId}
                practiceManagerId={practiceManagerId}
                contactInfoUpdate={contactInfoUpdate}
                contactInfoGetUpdate={contactInfoGetUpdate}
                popover={popover}
                popover1={popover1}
                popover2={popover2}
                popover3={popover3}
                popover4={popover4}
                setEditModeBox={setEditMode}
                contactInfoValidation={contactInfoValidation}
                setContactInfoValidation={setContactInfoValidation}
                setContactInfoError={setContactInfoError}
                setContactEditModeBox={setContactEditModeBox}
                contactEditModeBox={contactEditModeBox}
                hiddenInnerSubmitFormRef={hiddenInnerSubmitFormRef}
                setPracticeEditMode={() => setPracticeEditMode(false)}
                setPracticeContactInfoUpdate={setPracticeContactInfoUpdate}
                PersonRole={PersonRole}
              />
            </div>
          ) : (
            <div>
              <Row>
                <Col md={10}></Col>
              </Row>
              <h6 className="contact-tilte">Practice Manager</h6>
              <div className="contact-section">
                <div>
                  <Row>
                    <Col md={4}>
                      <p className="details-label">
                        {labels.MDOFFICE_ID}{" "}
                        <OverlayTrigger
                          trigger={["hover", "hover"]}
                          placement="right"
                          overlay={popover}
                        >
                          <img
                            src={tooltipIcon}
                            alt="Tooltip Icon"
                            className="tooltip-icon"
                          />
                        </OverlayTrigger>
                      </p>
                      {practiceManager?.mdofficeId ? (
                        <p
                          className="contact-details"
                          data-testid="PracticeManagerMdoffice"
                        >
                          {practiceManager.mdofficeId}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noPracticeManagerMdoffice"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p className="details-label">{labels.NAME}</p>
                      {practiceManager?.firstName ? (
                        <p
                          className="contact-details"
                          data-testid="PracticeManagerName"
                        >
                          {practiceManager?.firstName}&nbsp;
                          {practiceManager?.lastName}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="NoPracticeManagerName"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      {practiceManager?.email[0]?.emailAddress ? (
                        <p
                          className="contact-details"
                          data-testid="PracticeManagerEmail"
                        >
                          {practiceManager?.email[0]?.emailAddress}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="NoPracticeManagerEmail"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      {practiceManager?.phoneNumber[0]?.number ? (
                        <p
                          className="contact-details"
                          data-testid="PracticeManagerPhone"
                        >
                          {practiceManager?.phoneNumber[0]?.number}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noPracticeManagerPhone"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      {practiceManager?.phoneNumber[0]?.extention ? (
                        <p
                          className="contact-details"
                          data-testid="PracticeManagerPhone"
                        >
                          {practiceManager?.phoneNumber[0]?.extention}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noPracticeManagerPhone"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p className="details-label">
                        {labels.PRIMARY_SQCN_CONTACT}{" "}
                      </p>
                      {primarySqcnByRoleTypeId?.roleTypeId === 5 ? (
                        <p
                          className="contact-details"
                          data-testid="PmPrimarySqcn"
                        >
                          Yes
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noPmPrimarySqcn"
                        >
                          No
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <h6 className="contact-tilte">
                Additional Point Of Contact
                <OverlayTrigger
                  trigger={["hover", "hover"]}
                  placement="right"
                  overlay={popover1}
                >
                  <img
                    src={tooltipIcon}
                    alt="Tooltip Icon"
                    className="tooltip-icon"
                  />
                </OverlayTrigger>
              </h6>
              <div className="contact-section">
                <div>
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      {AdditionalPoint?.mdofficeId && AdditionalPoint?.mdofficeId !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="AdditionalMdoffice"
                        >
                          {AdditionalPoint.mdofficeId}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="NoAdditionalMdoffice"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p className="details-label">{labels.NAME}</p>
                      {AdditionalPoint?.firstName && AdditionalPoint?.firstName !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="AdditionalName"
                        >
                          {AdditionalPoint.firstName}&nbsp;
                          {AdditionalPoint?.lastName}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noAdditionalName"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      {AdditionalPoint?.email[0]?.emailAddress ? (
                        <p
                          className="contact-details"
                          data-testid="AdditionalEmail"
                        >
                          {AdditionalPoint?.email[0]?.emailAddress}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noAdditionalEmail"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      {AdditionalPoint?.phoneNumber[0]?.number && AdditionalPoint?.phoneNumber[0]?.number !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="AdditionalPhoneData"
                        >
                          {AdditionalPoint?.phoneNumber[0]?.number}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noAdditionalPhoneData"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      {AdditionalPoint?.phoneNumber[0]?.extention && AdditionalPoint?.phoneNumber[0]?.extention !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="AdditionalPhoneData"
                        >
                          {AdditionalPoint?.phoneNumber[0]?.extention}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noAdditionalPhoneData"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p className="details-label">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      {addprimarySqcnByRoleTypeId?.roleTypeId === 5 ? (
                        <p
                          className="contact-details"
                          data-testid="AdditionalPrimarySqcn"
                        >
                          Yes
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="NoAdditionalPrimarySqcn"
                        >
                          No
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <h6 className="contact-tilte">
                Director{" "}
                <OverlayTrigger
                  trigger={["hover", "hover"]}
                  placement="right"
                  overlay={popover2}
                >
                  <img
                    src={tooltipIcon}
                    alt="Tooltip Icon"
                    className="tooltip-icon"
                  />
                </OverlayTrigger>
              </h6>
              <div className="contact-section">
                <div>
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      {director?.mdofficeId && director?.mdofficeId !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="DirectorMdOffice"
                        >
                          {director.mdofficeId}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noDirectorMdOffice"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p className="details-label">{labels.NAME}</p>
                      {director?.firstName && director?.firstName !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="DirectorName"
                        >
                          {director?.firstName}&nbsp;{director?.lastName}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noDirectorName"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      {director?.email[0]?.emailAddress ? (
                        <p
                          className="contact-details"
                          data-testid="DirectorEmail"
                        >
                          {director?.email[0]?.emailAddress}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noDirectorEmail"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      {director?.phoneNumber[0]?.number && director?.phoneNumber[0]?.number !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="DirectorPhone"
                        >
                          {director?.phoneNumber[0]?.number}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noDirectorPhone"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      {director?.phoneNumber[0]?.extention && director?.phoneNumber[0]?.extention !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="DirectorPhone"
                        >
                          {director?.phoneNumber[0]?.extention}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noDirectorPhone"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p className="details-label">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      {director?.practiceAssignment.find(
                        (item) => item.roleTypeId === 5) ? (
                        <p
                          className="contact-details"
                          data-testid="AdditionalPrimarySqcn"
                        >
                          Yes
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="NoAdditionalPrimarySqcn"
                        >
                          No
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <h6 className="contact-tilte">
                Executive/Leadership Point of Contact{" "}
                <OverlayTrigger
                  trigger={["hover", "hover"]}
                  placement="right"
                  overlay={popover3}
                >
                  <img
                    src={tooltipIcon}
                    alt="Tooltip Icon"
                    className="tooltip-icon"
                  />
                </OverlayTrigger>
              </h6>
              <div className="contact-section">
                <div>
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.NAME}</p>
                      {executive?.firstName ? (
                        <p
                          className="contact-details"
                          data-testid="ExecutiveName"
                        >
                          {executive?.firstName}&nbsp;
                          {executive?.lastName}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noExecutiveName"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      {executive?.email[0]?.emailAddress ? (
                        <p
                          className="contact-details"
                          data-testid="ExecutiveEmail"
                        >
                          {executive?.email[0]?.emailAddress}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noExecutiveEmail"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      {executive?.phoneNumber[0]?.number ? (
                        <p
                          className="contact-details"
                          data-testid="ExecutivePhone"
                        >
                          {executive?.phoneNumber[0]?.number}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noExecutivePhone"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      {executive?.phoneNumber[0]?.extention ? (
                        <p
                          className="contact-details"
                          data-testid="ExecutivePhone"
                        >
                          {executive?.phoneNumber[0]?.extention}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noExecutivePhone"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <h6 className="contact-tilte">Physician Champion</h6>
              <div className="contact-section">
                <div>
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      {practicePhysicianChampion?.mdofficeId && practicePhysicianChampion?.mdofficeId !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="DirectorMdOffice"
                        >
                          {practicePhysicianChampion?.mdofficeId}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noDirectorMdOffice"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p className="details-label">{labels.NAME}</p>
                      {practicePhysicianChampion?.firstName ? (
                        <p
                          className="contact-details"
                          data-testid="PhysicianName"
                        >
                          {practicePhysicianChampion?.firstName}&nbsp;
                          {practicePhysicianChampion?.lastName}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noPhysicianName"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      {practicePhysicianChampion?.email[0]?.emailAddress ? (
                        <p
                          className="contact-details"
                          data-testid="PhysicianEmail"
                        >
                          {practicePhysicianChampion?.email[0]?.emailAddress}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noPhysianEmail"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      {practicePhysicianChampion?.phoneNumber[0]?.number && practicePhysicianChampion?.phoneNumber[0]?.number !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="DirectorPhone"
                        >
                          {practicePhysicianChampion?.phoneNumber[0]?.number}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noDirectorPhone"
                        >
                          --
                        </p>
                      )}
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      {practicePhysicianChampion?.phoneNumber[0]?.extention && practicePhysicianChampion?.phoneNumber[0]?.extention !== " " ? (
                        <p
                          className="contact-details"
                          data-testid="DirectorPhone"
                        >
                          {practicePhysicianChampion?.phoneNumber[0]?.extention}
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="noDirectorPhone"
                        >
                          --
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p className="details-label">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      {practicePhysicianChampion?.practiceAssignment.find(
                        (item) => item.roleTypeId === 5) ? (
                        <p
                          className="contact-details"
                          data-testid="AdditionalPrimarySqcn"
                        >
                          Yes
                        </p>
                      ) : (
                        <p
                          className="contact-details"
                          data-testid="NoAdditionalPrimarySqcn"
                        >
                          No
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
        </div>
        </>
      )}
    </React.Fragment>
  );
}

export default ContactInformation;
