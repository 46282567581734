import React from "react";
import("./_style.scss");

export const Heading = (props) => {
  return (
    <>
      <div className="heading-text">{props?.text}</div>
    </>
  );
};
