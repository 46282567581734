import React from "react";
import { Form } from "react-bootstrap";

const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Select All
  //   </Tooltip>
  // );
  return (
    // <OverlayTrigger
    //   placement="bottom"
    //   delay={{ show: 250, hide: 400 }}
    //   overlay={renderTooltip}
    // >
    <Form.Check
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
    />
    // </OverlayTrigger>
  );
};

export default Checkbox;
