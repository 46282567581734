import React from "react";
import Paragraph from "Components/UI/Atoms/Paragraph/Paragraph";
import Title from "Components/UI/Atoms/Title/Title";

function PracticePreview({ practiceTitle, locationCount, providerListCount }) {
  return (
    <React.Fragment>
      <Title title={practiceTitle} />
      <Paragraph
        locationCount={locationCount}
        providerListCount={providerListCount}
      />
    </React.Fragment>
  );
}

export default PracticePreview;
