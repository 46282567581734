import React from "react";
// import { useFetch } from "Components/Hooks/Fetch";
import AddLocationModal from "../ProviderList/AddLocationModal";

function AddLocationModalPage({
  show,
  onHide,
  clickHandler,
  addLocationList
}) {
  // const { data } = useFetch(
  //   `/api/v1/providers/GetLocationByPracticeManagerId?practiceManagerId=${practiceManagerId}&personid=${id}`
  // );
  return (
    <>
      <AddLocationModal
        data={addLocationList}
        show={show}
        onHide={onHide}
        clickHandler={clickHandler}
      />
    </>
  );
}

export default AddLocationModalPage;
