import React from "react";
import LocationDropdownList from "Components/UI/Molecules/LocationDropdownList";

function LocationDropdown({
  locationListData,
  selectedLocationId,
  onItemSelected,
}) {
  return (
    <React.Fragment>
      <LocationDropdownList
        dataList={locationListData}
        onItemSelected={onItemSelected}
        selectedItemId={selectedLocationId}
      />
    </React.Fragment>
  );
}

export default LocationDropdown;
