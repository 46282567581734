import React from "react";
import { Alert } from "react-bootstrap";

function AlertWarning({
  ApprovalcloseIcon,
  ApprovalIcon,
  AlertText,
  ALertHandleClick
}) {
  return (
    <React.Fragment>
      <Alert variant="warning" data-testid="practiceInfoupdated">
        <img src={ApprovalIcon} alt="Updated Icon" className="updated-icon" />
        {AlertText}
        <button
          onClick={ALertHandleClick}
          className="close-icon-button1"
          data-testid="practiceInfoupdatedclose"
        >
          <img
            src={ApprovalcloseIcon}
            alt="Updated Icon"
            className="updated-icon"
          />
        </button>
      </Alert>
    </React.Fragment>
  );
}

export default AlertWarning;
