import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Table } from "react-bootstrap";
import Checkbox from "Components/UI/Atoms/Checkbox/Checkbox";

function AddLocationModal({ data, show, onHide, clickHandler }) {
  const [searchLocationName, setSearchLocationName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [locations, setLocations] = useState([]);
  const [isCheck, setIsCheck] = useState([]);

  console.log("dataL", data);

  const handleClick = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    if (data) {
      const locationData = data?.flatMap((assignment) => {
        return assignment;
      });
      setLocations(locationData);
    } else {
      setLocations([]);
    }
  }, [data]);

  const separatePracticeAssignments = () => {
    if (!data) {
      return [];
    }
    const selectedAssignments = data?.filter((assignment) => {
      if (assignment && assignment?.practice) {
        return isCheck.includes(assignment?.practice?.id);
      }
      return false;
    });
    return selectedAssignments;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onHide();
    const selectedAssignments = separatePracticeAssignments();
    clickHandler(selectedAssignments);
  };

  const onCancel = () => {
    onHide();
  };

  const handleSearchLocationNameChange = (e) => {
    setSearchLocationName(e.target.value);
  };

  const handleSearchAddressChange = (e) => {
    setSearchAddress(e.target.value);
  };

  const createSearchPattern = (input) => {
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };
  const filteredData = locations?.filter((item) => {
    const searchLocationNamePattern = createSearchPattern(searchLocationName);
    const searchAddressPattern = createSearchPattern(searchAddress);
    const locationMatches = item?.practice?.location?.some((location) => {
      const locationName = location?.locationName?.toLowerCase()?.replace(/\s/g, "");
      const address = `${location?.street1 || ""} ${location?.street2 || ""} ${location?.city || ""} ${location?.state || ""} ${location?.zipCode || ""}`.toLowerCase().replace(/\s/g, "");
      return (
        searchLocationNamePattern.test(locationName) &&
        searchAddressPattern.test(address)
      );
    });
    return locationMatches;
  });
  console.log(filteredData);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-provider-modal add"
      data-testid="modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Provider Location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="provider-location-table">
          <Table data-testid="providerLocationtable">
            <thead>
              <tr>
                <th>Location Number - Name</th>
                <th>Address</th>
              </tr>
              <tr>
                <td className="search_column new">
                  <Form.Group className="search-npi" controlId="formGroupLocationName">
                    <Form.Control
                      type="text"
                      name="locationName"
                      placeholder="Search Location Name"
                      autoComplete="off"
                      className="search-input new"
                      value={searchLocationName}
                      onChange={handleSearchLocationNameChange}
                      data-testid="LocationName"
                    />
                  </Form.Group>
                </td>
                <td className="search_column new1">
                  <Form.Group className="search-npi" controlId="formGroupAddress">
                    <Form.Control
                      type="text"
                      name="locationAddress"
                      placeholder="Search Address"
                      autoComplete="off"
                      className="search-input new"
                      value={searchAddress}
                      onChange={handleSearchAddressChange}
                      data-testid="LocationAddress"
                    />
                  </Form.Group>
                </td>
              </tr>
            </thead>
            <tbody data-testid="ProviderData" className="location-table-list">
              {filteredData?.map((practiceItem) => {
                return (
                  <React.Fragment key={practiceItem?.id}>
                    {practiceItem?.practice?.location.map((item) => (
                      <tr key={item?.id}>
                        <td style={{ width: "5%" }} className={item?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                          <Checkbox
                            type="checkbox"
                            id={practiceItem?.practiceId}
                            handleClick={(e) => handleClick(e, practiceItem?.practiceId)}
                            isChecked={isCheck.includes(practiceItem?.practiceId)}
                          />
                        </td>
                        <td style={{ width: "45%" }} className={item?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                          {item?.locationNumber}&nbsp;-&nbsp;{item?.locationName}
                        </td>
                        <td style={{ width: "50%" }} className={item?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                          {`${item?.street1} ${item?.street2 ? item?.street2 : ""}, ${item?.city}, ${item?.state} ${item?.zipCode}`}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button onClick={onCancel} className="white-btn" data-testid="close-button">
          Cancel
        </button>
        <button onClick={handleSubmit} className="blue-btn" data-testid="submit">
          Add Location
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddLocationModal;
