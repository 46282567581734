import React from "react";
import cookies from "js-cookie";
import { Button } from "../../UI/Atoms/Button";

export const ReviewSubmit = ({
  RequestTypeDataID,
  isCheck,
  practiceReviewApproval,
  practiceReviewReject,
  fetchReviewPracticeList,
  personReqId,
  practiceInfoId,
  fetchReviewProviderList,
  providerReviewApproval,
  providerReviewReject,
  setIsCheck,
  statusChange,
  statusChangeReject,
  setReviewMode,
  setChangeProviderMode,
  isProviderApproval,
}) => {
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;

  const handleClick = (statusCode) => {
    handleSubmit(statusCode);
  };

  const handleSubmit = async (statusCode) => {
    if(statusCode === "Cancel") {
      if(isProviderApproval) {
        setChangeProviderMode(false);
      } else {
        setReviewMode(false);
      }
    } else {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "access-control-allow-credentials": "true",
          "ocp-apim-subscription-key": subscriptionKey,
          "api-supported-versions": "1.0",
        },
        body: JSON.stringify(isCheck),
      };
  
      const response = await fetch(
        base_url +
        `/api/v1/providers/ApproveRejectReviewDetails?requestTypeId=${RequestTypeDataID}&statustoUpdate=${statusCode}&personId=${personReqId}&practiceId=${practiceInfoId}`,
        requestOptions
      );
  
      console.log("rep", response, response?.status);
      if (response?.status === 200 && personReqId === 0 && statusCode === "Approved") {
        practiceReviewApproval(true);
        fetchReviewPracticeList();
        setIsCheck([]);
      } else if (response?.status === 200 && personReqId === 0 && statusCode === "Rejected") {
        practiceReviewReject(true);
        fetchReviewPracticeList();
        setIsCheck([]);
      } else if(response?.status === 200 && personReqId !== 0 && statusCode === "Approved") {
        providerReviewApproval(true);
        fetchReviewProviderList();
        setIsCheck([]);
        statusChange();
      } else if(response?.status === 200 && personReqId !== 0 && statusCode === "Rejected") {
        providerReviewReject(true);
        fetchReviewProviderList();
        setIsCheck([]);
        statusChangeReject();
      }
    }
  };

  return (
    <div className="submit-box">
      <Button
        text="Cancel"
        className="Reject-button"
        disabled={!isCheck.length > 0}
        onClick={() => handleClick("Cancel")}
        type="submit"
      />
      <Button
        text="Reject"
        className="Reject-button"
        disabled={!isCheck.length > 0}
        onClick={() => handleClick("Rejected")}
        type="submit"
      />
      <Button
        text="Approve"
        className="Review-button"
        disabled={!isCheck.length > 0}
        onClick={() => handleClick("Approved")}
        type="submit"
      />
    </div>
  );
};
