import { useEffect, useState } from "react";
import cookies from "js-cookie";
import { useLocation } from "react-router-dom";

export const useFetch = (url) => {
  const [data, setData] = useState();

  const [error, setError] = useState();

  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  //console.log("se", search);
  const queryParam = new URLSearchParams(search);
  const tokenId = queryParam.get("token") || null;
  const token = cookies.get("access") || tokenId;
  const getToken = tokenId || token;

  useEffect(() => {
    const doFetch = async () => {
      try {
        let COMPLETE_URL;
        if (
          url.includes("ProviderDataByPracticeId") ||
          url.includes("providers") ||
          url.includes("Specialties") ||
          url.includes("GetAllTitleTypes") || 
          url.includes("GetBoardSpecialities")
        ) {
          COMPLETE_URL = process.env.REACT_APP_SERVER_URL1 + url;
        } else {
          COMPLETE_URL = process.env.REACT_APP_SERVER_URL + url;
        }
        const subscriptionKey = process.env.REACT_APP_TOKEN;
        console.log(COMPLETE_URL);
        const response = await fetch(COMPLETE_URL, {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer "+getToken,
            "Content-Type": "application/json",
            "ocp-apim-subscription-key":subscriptionKey
          }),
        });
        const result = await response.json();

        if (response?.status === 200) {
          setData(result);
          setError();
        } else {
          setError(true);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    doFetch();
  }, [url]);

  return { data, error, loading };

};
export const useFetchGet = (url) => {
  const [data, setData] = useState();
  
  const [error, setError] = useState();
  
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  //console.log("se", search);
  const queryParam = new URLSearchParams(search);
  const tokenId = queryParam.get("token") || null;
  const token = cookies.get("access") || tokenId;
  const getToken = tokenId || token;
  const doFetch = async () => {
    try {
      let COMPLETE_URL;
      if (
        url.includes("ProviderDataByPracticeId") ||
          url.includes("providers") ||
          url.includes("Specialties") ||
          url.includes("GetAllTitleTypes") || 
          url.includes("GetBoardSpecialities")
      ) {
        COMPLETE_URL = process.env.REACT_APP_SERVER_URL1 + url;
      } else {
        COMPLETE_URL = process.env.REACT_APP_SERVER_URL + url;
      }
      const subscriptionKey = process.env.REACT_APP_TOKEN;
      console.log(COMPLETE_URL);
      const response = await fetch(COMPLETE_URL, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer "+getToken,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key":subscriptionKey
        }),
      });
      const result = await response.json();

      if (response?.status === 200) {
        setData(result);
        setError();
      } else {
        setError(true);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const callGet = async () => {
    doFetch();
  }
  
  return { data, error, loading ,callGet};
};

export const useFetchPost = (url) => {
  const [data, setData] = useState();

  const [error, setError] = useState();

  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  //console.log("se", search);
  const queryParam = new URLSearchParams(search);
  const tokenId = queryParam.get("token") || null;
  const token = cookies.get("access") || tokenId;
  const getToken = tokenId || token;


  const doFetch = async (payload) => {
    try {
      let COMPLETE_URL;
      if (
        url.includes("ProviderDataByPracticeId") ||
        url.includes("providers") ||
        url.includes("Specialties") ||
        url.includes("GetAllTitleTypes") || 
        url.includes("GetBoardSpecialities")
      ) {
        COMPLETE_URL = process.env.REACT_APP_SERVER_URL1 + url;
      } else {
        COMPLETE_URL = process.env.REACT_APP_SERVER_URL + url;
      }
      const subscriptionKey = process.env.REACT_APP_TOKEN;
      console.log(COMPLETE_URL);

      const response = await fetch(COMPLETE_URL, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer "+getToken,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key":subscriptionKey
        }),
        body: JSON.stringify(payload),
      });
      const result = await response.json();


      console.log("result", result);

      if (response?.status === 200) {
        setData(result);
        setError();
      } else {
        setData(result);
        setError(true);
      }
    } catch (err) {

      console.log("err", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };



  const callPost = async (payload) => {
    doFetch(payload);
  }

  return { data, error, loading,callPost };
};

