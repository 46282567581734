import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import infoIcon from "./images/infoIcon.svg";
import { useFetch } from "Components/Hooks/Fetch";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function AddProviderModal(props) {
  const [curatedProvidersList, setCuratedProvidersList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [presentError, setPresentError] = useState(false);
  const [creatNpi, setCreateNpi] = useState("");
  const { data, loading } = useFetch("/api/v1/providers");
  if (loading) {
    return "";
  }
  const providerNpis = new Set(props?.providerList?.map(item => item?.npi));
  const filteredProviderList = data?.filter(item => !providerNpis?.has(item?.npi));
  const queryProvidersList = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const maxLength = 10;
    const sanitizedValue = value.replace(/\D/g, " ");
    const truncatedValue = sanitizedValue.slice(0, maxLength);
    if (truncatedValue !== value) {
      e.target.value = truncatedValue;
    }
    const curatedList = filteredProviderList?.filter(
      (f) => f && f?.npi && f?.npi?.includes(truncatedValue.toString())
    );
    if (curatedList?.length > 0) {
      setCreateNpi("");
      setCuratedProvidersList(curatedList);
    } else {
      setCuratedProvidersList([]);
      setCreateNpi(truncatedValue);
    }

    if (truncatedValue?.length === 0) {
      setCuratedProvidersList([]);
    }

    if (truncatedValue?.length >= 1 && curatedList?.length === 0 && providerNpis.has(truncatedValue)) {
      setPresentError(true);
      setErrorMessage(false);
    } else if (truncatedValue?.length >= 1 && curatedList?.length === 0) {
      setErrorMessage(true);
      setPresentError(false);
    } else {
      setErrorMessage(false);
      setPresentError(false);
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="add-provider-modal"
      data-testid="addprovidermodal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Provider
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="">Search Provider by NPI ID</p>
        <Form autoComplete="off">
          <Form.Group className="mb-3 search-npi" controlId="formGroupEmail">
            <Form.Label>NPI ID</Form.Label>
            <Form.Control
              type="number"
              placeholder="Search NPI ID"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              className="search-input"
              onChange={queryProvidersList}
              data-testid="SearchProviderNpi"
            />
          </Form.Group>
        </Form>
        <div className="providers-result" data-testid="providerresult">
          {curatedProvidersList?.map((item) => {
            return (
              <div
                key={item?.id}
                className="provider-search-list"
                data-testid="providersearchresult"
              >
                <Link key={item?.id} to={`/add-provider/${item?.id}`}>
                  <Row>
                    <Col md={4} xs={12} data-testid="providerName">
                      {item?.firstName} {item?.lastName}
                    </Col>
                    <Col md={2} xs={12} data-testid="providerTitle">
                      {item?.titleType[0]?.title
                        ? item?.titleType[0]?.title
                        : "--"}
                    </Col>
                    <Col
                      md={6}
                      xs={12}
                      className="provider-result-email"
                      data-testid="providerEmail"
                    >
                      {item?.email[0]?.emailAddress
                        ? item?.email[0]?.emailAddress
                        : "--"}
                    </Col>
                  </Row>
                </Link>
              </div>
            );
          })}
        </div>
        {presentError &&
          <p className="no-match" data-testid="providerNoMatchNpi">
            <img src={infoIcon} alt="Info Icon" />
            This provider NPI is already available in this practice.
          </p>
        }
        {errorMessage ? (
          <p className="no-match" data-testid="providerNoMatchNpi">
            <img src={infoIcon} alt="Info Icon" />
            No providers match this record. Are you sure you want to create a
            new record?
          </p>
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button
          onClick={() => {
            props.onHide();
            setErrorMessage(false);
            setPresentError(false);
            setCuratedProvidersList([]);
          }}
          className="white-btn"
          data-testid="close-button"
        >
          Cancel
        </button>
        {!errorMessage ? (
          <button
            onClick={props.onHide}
            // disabled={curatedProvidersList.length >= 1 ? false : true}
            disabled
            className="blue-btn"
            data-testid="AddproviderButton"
          >
            Add Provider
          </button>
        ) : (
          <Link
            className="edit-provider-button"
            state={{ data: creatNpi }}
            to={"/create-new-provider"}
          >
            <button className="blue-btn" data-testid="createnewproviderbutton">
              Create New Provider
            </button>
          </Link>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default AddProviderModal;
