/* eslint-disable indent */
import React, { useState, useMemo } from "react";
import ChangeLocation from "Components/UI/Organisms/ChangeLocation";
import { listToRecord } from "utils";
import labels from "Static/Dictionary.json";

const LocationList = ({
  locationListData,
  setLocationBox,
  manageStyle
}) => {
  if (!locationListData || locationListData?.length === 0) {
    return <div className="contact-details">No location data available.</div>;
  }
  const [selectedLocationId, setSelectedLocationID] = useState(
    locationListData?.find((loc)=>loc?.locationNumber === 1)?.id
  );

  const locationMap = useMemo(
    () => listToRecord(locationListData, "id"),
    [locationListData]
  );
  const clickHandler = (locationId) => {
    setSelectedLocationID(locationId);
  };
  const selectedLocationData = locationListData?.find((loc)=>loc?.locationNumber === 1) || locationListData[0];
  const selectedLocation =
    locationMap[selectedLocationId] || locationListData?.length > 0 && selectedLocationData;
  const { locationName, street1, street2, street3, city, state, zipCode, phoneNumber } =
    selectedLocation;
  return (
    <React.Fragment>
      <div>
        <ChangeLocation
          // locationListData={locationListData}
          locationListData={locationListData?.length > 0 && locationListData?.sort((a, b) => (a.locationNumber > b.locationNumber ? 1 : -1))}
          locationTitle={locationName}
          selectedLocationId={selectedLocationId}
          locationMap={locationMap}
          onItemSelected={clickHandler}
          setLocationBox={setLocationBox}
          manageStyle={manageStyle}
        />
        <div className="main-block">
          <div className="block">
            <div className="title">{labels.ADDRESS}</div>
            {street1 ? (
              <div className="info" data-testid="LocationData">
                {`${street1} ${street2 ? street2 : ""
                  } ${street3 ? street3 : ""
                  }, ${city} ${state}
            ${zipCode}`}
              </div>
            ) : (
              <div className="info" data-testid="NoLocationData">
                --
              </div>
            )}
          </div>
          <div className="block">
            <div className="title">{labels.PHONE_NUMBER}</div>
            {phoneNumber?.find((phone) => phone.phoneTypeId === 1)?.number ? (
              <div className="info" data-testid="PhoneNumber">
                {phoneNumber.find((phone) => phone.phoneTypeId === 1)?.number}
              </div>
            ) : (
              <div className="info" data-testid="NoPhoneNumber">
                --
              </div>
            )}
          </div>
          <div className="block">
            <div className="title">{labels.FAX_NUMBER}</div>
            {phoneNumber?.find((phone) => phone.phoneTypeId === 4)?.number ? (
              <div className="info" data-testid="FaxNumber">
                {phoneNumber.find((phone) => phone.phoneTypeId === 4)?.number}
              </div>
            ) : (
              <div className="info" data-testid="NoFaxNumber">
                --
              </div>
            )}
          </div>
        </div>
        <div className="main-block">
          <div className="block">
            <div className="title">{labels.SCHEDULING_NUMBER}</div>
            {phoneNumber?.find((phone) => phone.phoneTypeId === 5)?.number ? (
              <div className="info" data-testid="SchedulingNumber">
                {phoneNumber.find((phone) => phone.phoneTypeId === 5)?.number}
              </div>
            ) : (
              <div className="info" data-testid="NoSchedulingNumber">
                --
              </div>
            )}
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default LocationList;
