import React, { useEffect } from "react";
import cookies from "js-cookie";
import PracticePage from "./PracticePage";
import { useLocation } from "react-router-dom";
import ErrorServer from "Components/Hooks/Error404";

const Authentication = () => {
  const { search } = useLocation();
  console.log("se", search);
  const queryParam = new URLSearchParams(search);
  const tokenId = queryParam.get("token") || null;
  //const tokenId = queryParam.get("token") || process.env.REACT_APP_LOCAL_TOKEN;
  const error = queryParam.get("Error");
  // console.log("qq", tokenId);

  useEffect(() => {
    if (tokenId) {
      console.log("td", tokenId);
      cookies.set("access", tokenId);
    } else if (error) {
      return <ErrorServer />;
    }
  }, [tokenId]);

  const token = cookies.get("access") || null;
  const initialCall = tokenId || token ? false : true;
  const getToken = tokenId || token;
  console.log("itttt", initialCall, token, tokenId);
  return (
    <>
      <PracticePage
        initialCall={initialCall}
        token={getToken}
        tokenId={tokenId}
      />
    </>
  );
};

export default Authentication;
