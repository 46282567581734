import React from "react";
import Sidebar from "../Pages/Sidebar";
import Header from "../Pages/Header";
import NpiSearch from "./NpiSearch/NpiSearch";
import { useFetch } from "Components/Hooks/Fetch";

const NpiSearchPage = (props) => {
  const { data: providerList, loading: providerListLoading } = useFetch("/api/v1/providers");
  return (
    <div className="dashboard d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flow-root",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Header headerText="" SQCNName={props?.pmName} />
        <div style={{ height: "100%", background: "#f5f5f5" }}>
          <div
            className="dashboard-body"
            style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}
          >
            <div>
              <NpiSearch providerList={providerList} providerListLoading={providerListLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NpiSearchPage;
