/* eslint-disable indent */
import React from "react";
import { Modal } from "react-bootstrap";

function ChangePrimaryLocationModal(props) {
  const handleCloseModal = () => {
    if(props?.changePrimaryData === "true"){
      props?.setGotoListPage(true);
      props?.modalClose();
    } else {
      props?.modalClose();
    }
  }
  return (
    <React.Fragment>
      {props?.changePrimaryData === "true" &&
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Primary Location
          </Modal.Title>
        </Modal.Header>
      }
      <Modal.Body>
        {props?.changePrimaryData === "true" ? (
          <p>
            <b>{`${props?.providerSummary?.firstName} ${props?.providerSummary?.middleName || ""} ${props?.providerSummary?.lastName}`}</b>
            &nbsp;already has a primary location. Your request is being sent to SQCN Admin for further research and processing.
          </p>
        ) : (
          <p>
            There is an existing request to change the Primary Location for this provider{" "}
            <b>{`${props?.providerSummary?.firstName} ${props?.providerSummary?.middleName || ""} ${props?.providerSummary?.lastName}`}</b>.
            Please contact SQCN admin for further assistance at{" "}
            <a className="mail_text" href="mailto:SQCNPPD@sentara.com">SQCNPPD@sentara.com</a>.
          </p>
        )
        }
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button
          onClick={() => {
            props?.handlePrimaryCancel();
            props?.modalClose();
          }}
          className="white-btn"
          data-testid="close-button"
        >
          Cancel
        </button>
        {props?.changePrimaryData === "true" &&
          <button
            className="blue-btn"
            onClick={handleCloseModal}
            type="submit"
            data-testid="createnewproviderbutton"
          >
            Yes, Confirm
          </button>
        }
      </Modal.Footer>
    </React.Fragment>
  );
}

export default ChangePrimaryLocationModal;
