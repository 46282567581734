import React, { useState } from "react";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="">
      <div
        className={isOpen ? "accordian-header-2" : "accordian-header-1"}
        role="button"
        tabIndex={0}
        onClick={toggleAccordion}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            toggleAccordion();
          }
        }}
      >
        <div className="accordion-heading">
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ marginRight: "12px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 10.0016C3 9.22836 3.6268 8.60156 4.4 8.60156L15.6 8.60156C16.3732 8.60156 17 9.22836 17 10.0016C17 10.7748 16.3732 11.4016 15.6 11.4016L4.4 11.4016C3.6268 11.4016 3 10.7748 3 10.0016Z"
                fill="#004765"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ marginRight: "12px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3C10.7732 3 11.4 3.6268 11.4 4.4V8.6L15.6 8.6C16.3732 8.6 17 9.2268 17 10C17 10.7732 16.3732 11.4 15.6 11.4H11.4V15.6C11.4 16.3732 10.7732 17 10 17C9.2268 17 8.6 16.3732 8.6 15.6V11.4H4.4C3.6268 11.4 3 10.7732 3 10C3 9.2268 3.6268 8.6 4.4 8.6H8.6V4.4C8.6 3.6268 9.2268 3 10 3Z"
                fill="#004765"
              />
            </svg>
          )}
          <div className="">{title}</div>
        </div>
      </div>
      {isOpen && <div className="accordian-body">{children}</div>}
    </div>
  );
};

const Accordions = (props) => {
  return <Accordion title={props.title}>{props.content}</Accordion>;
};

export default Accordions;
