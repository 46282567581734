import React from "react";
import("./_style.scss");

export const Notification = (props) => {
  return (
    <>
      <p className="notification-text">
        <img
          src={props?.iconImage}
          alt="pending Approval Icon"
          className="pending-icon"
        ></img>
        {props?.text}
      </p>
    </>
  );
};
