import { BounceLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#0076A8",
};

export default function Spinner() {
  return (
    <BounceLoader
      loading
      color={"#0076A8"}
      cssOverride={override}
      size={35}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}
