import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

function SqcnApprovalIcon({ removeText, RemoveIcon }) {
  const popover2 = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p className="contact-tooltip-text">{removeText}</p>
      </Popover.Body>
    </Popover>
  );
  return (
    <React.Fragment>
      <OverlayTrigger
        trigger={["hover", "hover"]}
        placement="bottom"
        overlay={popover2}
      >
        <img src={RemoveIcon} alt="Tooltip Icon" className="tooltip-icon" />
      </OverlayTrigger>
    </React.Fragment>
  );
}

export default SqcnApprovalIcon;
