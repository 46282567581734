import React, { useState } from "react";
import { Popover } from "react-bootstrap";
import providerTooltip from "Static/provider.json";
import cookies from "js-cookie";
//import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import Spinner from "Components/Hooks/Spinner";
import ProviderListAdmin from "../ProviderList/ProviderListAdmin";

const primaryLocationPopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{providerTooltip.primaryLocation}</p>
    </Popover.Body>
  </Popover>
);

const stillAtPracticePopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{providerTooltip.StillAtLocation}</p>
    </Popover.Body>
  </Popover>
);
const ProviderListPageAdmin = ({
  selectedPracticeId,
  locationListData,
  practiceManagerId,
  // handleProviderClick,
  isAdmin,
  PersonRole,
  setUpdatedProviderVal,
  titleData,
  prefixData
}) => {
  const [isData, setIsData] = useState([]);
  //const [isFirstData, setIsFirstData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  //const { state } = useLocation();
  //const navigation = useNavigate();
  const token = cookies.get("access");
  // useEffect(() => {
  //   addNewProviderList(state?.isCreate);
  // }, []);
  const providerListGetUpdate = () => {
    const base_url = process.env.REACT_APP_SERVER_URL1;
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    fetch(
      base_url +
      `/api/v1/providers/ProviderDataByPracticeId?practiceId=${selectedPracticeId}&practicemanagerid=${practiceManagerId}&isSQCNAdmin=${isAdmin}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          data?.sort((a, b) => {
            const fullNameA = `${a?.lastName} ${a?.firstName}`;
            const fullNameB = `${b?.lastName} ${b?.firstName}`;
            return fullNameA.localeCompare(fullNameB);
          });
          setIsData(data);
          setRefreshData(false);
        }else {
          setIsData([]);
        }
      })
      .catch((error) => {
        if (error) {
          setIsError(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      providerListGetUpdate();
    };
    fetch();
  }, [selectedPracticeId, refreshData]);

  return (
    <> {isLoading ? (
      <Spinner />
    ) : (
      <ProviderListAdmin
        providerList={isData }
        selectedPracticeId={selectedPracticeId}
        primaryLocationPopover={primaryLocationPopover}
        stillAtPracticePopover={stillAtPracticePopover}
        locationListData={locationListData}
        practiceManagerId={practiceManagerId}
        isError={isError}
        providerListGetUpdate={providerListGetUpdate}
        PersonRole={PersonRole}
        setUpdatedProviderVal={setUpdatedProviderVal}
        setRefreshData={setRefreshData}   
        refreshData={refreshData}
        titleData={titleData}
        prefixData={prefixData}
      />
    )
    }
    </>
  );
};

export default ProviderListPageAdmin;
