import React, { useState, useEffect } from "react";
import sortIcon from "../../../../../../../images/sort_icon.svg";
import searchIcon from "../../../../../../../images/search.svg";
import downArrowIcon from "../../../../../../../images/down_arrow_icon.svg";
import upArrowIcon from "../../../../../../../images/up_arrow_icon.svg";
import deleteIcon from "../../../../../../../images/delete_icon.svg";
import datePickerIcon from "../../../../../../../images/datepicker_icon.svg";
import("../images/_style.scss");
import { Table, Button, Row, Col, Alert } from "react-bootstrap";
import EditLocation from "./EditLocation";
import Pagination2 from "../../../../UI/Atoms/Pagination2";
import cookies from "js-cookie";
import closeIcon from "../../../../../../../images/close-icon.svg";
import updatedIcon from "../../../../../../../images/updatedIcon.svg";
import addIcon from "../../../../../../../images/plus_icon.svg";

export default function AdminLocationList({
  locationListData,
  selectedPracticeIdByAdmin,
  setDeletedLocationId,
  setUpdatedLocationVal,
}) {
  let [updatedLocData, setUpdatedLocData] = useState(locationListData);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditView, setEditView] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [rowsPerPage] = useState(5);
  const [selectedLocationId, setSelectedLocationId] = useState();
  const [success, setSuccess] = useState(false);
  const [locAdded, setLocAdded] = useState(false);
  const [locDeleted, setLocDeleted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [dataObj, setDataObj] = useState([]);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const handleUpdatedLocationObject = (array) => {
    setDataObj(array);
  };
  useEffect(() => {
    localStorage.setItem("locationListData", JSON.stringify(locationListData));
  }, [locationListData]);
  useEffect(() => {
    setUpdatedLocData(updatedLocData);
  }, [setDeletedLocationId, setUpdatedLocationVal, locationListData]);
  useEffect(() => {
    setUpdatedLocData(locationListData);
  }, [locationListData]);
  const [showEditData, setEditData] = useState([]);
  const [numberSwap, setNumberSwap] = useState([]);
  const sortingData = () => {
    let sortedData = [...updatedLocData].sort((a, b) => {
      if (!sortConfig) return 0;
      const isAscending = sortConfig.direction === "ascending" ? 1 : -1;
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return -1 * isAscending;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return 1 * isAscending;
      }
      return 0;
    });
    setUpdatedLocData(sortedData);
  };

  useEffect(() => {
    sortingData();
  }, [sortConfig]);

  const [searchLocNum, setSearchLocNum] = useState("");
  const [searchLocName, setSearchLocName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchPhoneNum, setSearchPhoneNum] = useState("");
  const [searchFaxNum, setSearchFaxNum] = useState("");
  const [searchScheduleNum, setSearchScheduleNum] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");

  const handleSearchLocNumChange = (e) => {
    const input = e.target.value;
    setSearchLocNum(input);
  };

  const handleSearchLocNameChange = (e) => {
    const input = e.target.value;
    setSearchLocName(input);
  };

  const handleSearchAddressChange = (e) => {
    const input = e.target.value;
    setSearchAddress(input);
  };

  const handleSearchPhoneNumChange = (e) => {
    const input = e.target.value;
    setSearchPhoneNum(input);
  };

  const handleSearchFaxNumChange = (e) => {
    const input = e.target.value;
    setSearchFaxNum(input);
  };

  const handleSearchScheduleNumChange = (e) => {
    const input = e.target.value;
    setSearchScheduleNum(input);
  };

  const handleSearchStartDateChange = (e) => {
    const input = e.target.value;
    setSearchStartDate(input);
  };

  const handleSearchEndDateChange = (e) => {
    const input = e.target.value;
    setSearchEndDate(input);
  };
  const createSearchPattern = (input) => {
    // Remove spaces from the input and make it case-insensitive
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };

  const filterData = (item) => {
    const locationNumber = item?.locationNumber?.toString().replace(/\s/g, "");
    const locationName = item?.locationName?.toLowerCase().replace(/\s/g, "");
    const phoneNumber = item?.phoneNumber?.find((phone) => phone.phoneTypeId === 1)?.number.toString().replace(/\s/g, "");
    const faxNumber = item?.phoneNumber?.find((phone) => phone.phoneTypeId === 4)?.number.toString().replace(/\s/g, "");
    const schedulingNumber = item?.phoneNumber?.find((phone) => phone.phoneTypeId === 5)?.number.toString().replace(/\s/g, "");
    const address = (`${item?.street1 || ""} ${item?.street2 || ""} ${item?.city || ""} ${item?.state || ""} ${item?.zipCode || ""}`).toLowerCase().replace(/\s/g, "");
    const startDate = item?.startDate ? new Date(item.startDate).toLocaleDateString().replace(/\s/g, "") : "";
    const endDate = item?.termDate ? new Date(item.termDate).toLocaleDateString().replace(/\s/g, "") : "";
    const searchLocationNumPattern = createSearchPattern(searchLocNum);
    const searchLocationNamePattern = createSearchPattern(searchLocName);
    const searchAddressPattern = createSearchPattern(searchAddress);
    const searchPhoneNumberPattern = createSearchPattern(searchPhoneNum);
    const searchFaxNumberPattern = createSearchPattern(searchFaxNum);
    const searchSchedulingNumberPattern = createSearchPattern(searchScheduleNum);
    const searchStartDatePattern = createSearchPattern(searchStartDate);
    const searchEndDatePattern = createSearchPattern(searchEndDate);
    return (
      searchLocationNumPattern.test(locationNumber) &&
      searchLocationNamePattern.test(locationName) &&
      searchAddressPattern.test(address) &&
      searchPhoneNumberPattern.test(phoneNumber) &&
      searchFaxNumberPattern.test(faxNumber) &&
      searchSchedulingNumberPattern.test(schedulingNumber) &&
      searchStartDatePattern.test(startDate) &&
      searchEndDatePattern.test(endDate)
    );
  };
  useEffect(() => {
    const newFilteredData = locationListData?.filter(filterData);
    setUpdatedLocData(newFilteredData);
  }, [
    searchLocNum,
    searchLocName,
    searchAddress,
    searchPhoneNum,
    searchFaxNum,
    searchScheduleNum,
    searchStartDate,
    searchEndDate,
  ]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  useEffect(() => {
    if (
      currentPage > 1 &&
      updatedLocData.length <= (currentPage - 1) * rowsPerPage
    ) {
      setCurrentPage(1);
    }
  }, [updatedLocData, currentPage, rowsPerPage]);

  const handleAction = (id, action) => {
    const index = [...updatedLocData].findIndex((item) => item.id === id);
    if (action === "up") {
      if (index === -1 || index === 0) return;
      const updatedData = [...updatedLocData];
      [updatedData[index], updatedData[index - 1]] = [
        updatedData[index - 1],
        updatedData[index],
      ];
      // Update locationNumber for affected rows
      updatedData[index].locationNumber = index + 1;
      updatedData[index - 1].locationNumber = index;
      // setMoveData(updatedData);
      setNumberSwap(updatedData?.find((item) => item.id === id));
      setUpdatedLocData(updatedData);
    } else if (action === "down") {
      if (index === -1 || index === updatedLocData.length - 1) return;
      const updatedData = [...updatedLocData];
      [updatedData[index], updatedData[index + 1]] = [
        updatedData[index + 1],
        updatedData[index],
      ];
      // Update locationNumber for affected rows
      for (let i = 0; i < updatedData.length; i++) {
        updatedData[i].locationNumber = i + 1;
      }
      // setMoveData(updatedData);
      setNumberSwap(updatedData?.find((item) => item.id === id));
      setUpdatedLocData(updatedData);
    }
  };

  const handleEditView = (id) => {
    setEditView(true);
    setEditData(updatedLocData?.find((item) => item.id === id));
    setSelectedLocationId(id);
  };
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  useEffect(() => {
    const updatedData = updatedLocData.map(item => item.id === dataObj.id ? dataObj : item)
    setUpdatedLocData(updatedData);
  }, [dataObj]);
  const handleDelete = (id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(),
    };
    fetch(
      base_url +
        `/api/v1/practices/${selectedPracticeIdByAdmin}/location?LocationId=${id}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200) {
        setSuccess(true);
        setLocDeleted(true);
        localStorage.setItem("locationCount", updatedLocData.length - 1);
        setUpdatedLocData(updatedLocData.filter((item) => item.id !== id));
        setDeletedLocationId(id);
      }
    });
  };
  let currentRows = [...updatedLocData].slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(updatedLocData.length / rowsPerPage);
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleAddClick = () => {
    setIsSaving(true);
    const defaultLocationListData = JSON.parse(
      localStorage.getItem("locationListData")
    );
    let payLoadData = [];
    defaultLocationListData?.forEach((originalItem) => {
      // Find the corresponding object in secondArray
      updatedLocData.forEach((object) => {
        const correspondingObject = object.id === originalItem.id;
        // If correspondingObject is found, update thirdArray
        if (correspondingObject) {
          payLoadData.push({
            locationId: originalItem?.id,
            locationNumber: originalItem?.locationNumber,
            locationOrderId: object?.locationNumber, // Update locationOrderId from second array's locationNumber
          });
        }
      });
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(payLoadData),
    };
    fetch(
      base_url +
        `/api/v1/practices/${selectedPracticeIdByAdmin}/location/UpdateLocationNumber`,
      requestOptions
    ).then((response) => {
      console.log(
        "Saved locationInfo updatedLocationNumbers",
        response,
        response.status
      );
      if (response.status == 200) {
        setIsSaving(false);
        setSuccess(true);
        setUpdatedLocationVal(payLoadData);
        setSelectedLocationId(null);
      }
    });
  };
  const handleShowAddLocation = () => {
    setEditView(true);
    setEditData({});
    setSelectedLocationId(null);
  };
  return (
    <>
      <div className="practice-location-table">
        {success && (
          <div>
            <Alert variant="success" data-testid="providerProfileupdated">
              <img
                src={updatedIcon}
                alt="Updated Icon"
                className="updated-icon"
              />
              {locDeleted
                ? "Location deleted successfully"
                : locAdded
                  ? "Location added successfully"
                  : "Location updated successfully"}
              <button
                onClick={() => setSuccess(false)}
                className="close-icon-button"
                data-testid="providerProfileupdatedclose"
              >
                <img
                  src={closeIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
              </button>
            </Alert>
          </div>
        )}
        <Row className="mb-3 d-flex flex-row-reverse">
          <Col md={3} className="text-right">
            <Button
              className="rounded-pill save_btn"
              onClick={handleShowAddLocation}
            >
              <span>
                <img src={addIcon} alt="add icon" />
              </span>
              Add Location
            </Button>
          </Col>
        </Row>
        <Table data-testid="providerLocationtable">
          <thead>
            <tr>
              <th
                className="location_number search-table-font"
                style={{ width: "7%" }}
              >
                Location Number
              </th>
              <th className="location_name search-table-font">Location Name</th>
              <th className="location_name search-table-font">Address</th>
              <th
                className="phone-head other_number search-table-font"
                style={{ width: "12%" }}
              >
                Phone Number
              </th>
              <th
                className="phone-head1 other_number search-table-font"
                style={{ width: "12%" }}
              >
                Fax Number
              </th>
              <th
                className="phone-head2 other_number search-table-font"
                style={{ width: "12%" }}
              >
                Direct Scheduling Number
              </th>
              <th
                className="phone-head1 location_date search-table-font"
                style={{ width: "7%" }}
              >
                <span className="header-icons">
                  Start Date
                  <img src={datePickerIcon} alt="DatePicker Icon" />
                </span>
              </th>
              <th
                className="phone-head1 location_date search-table-font"
                style={{ width: "7%" }}
              >
                <span className="header-icons">
                  Term Date
                  <img src={datePickerIcon} alt="DatePicker Icon" />
                </span>
              </th>
              <th className="search-table-font">Notes Regarding Term Dates</th>
              
              <th className="search-table-font">Action</th>
            </tr>
            <tr className="search_row">
              <th>
                <div className="input-group search-container">
                  <input
                    type="text"
                    placeholder={"Search"}
                    value={searchLocNum}
                    onChange={handleSearchLocNumChange}
                    className="table_search"
                  />
                  <button type="submit" className="search-button left">
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                  <button
                    type="button"
                    className="search-button right"
                    onClick={() => handleSort("locationNumber")}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </div>
              </th>
              <th>
                <div className="input-group search-container">
                  <input
                    type="text"
                    placeholder={"Search"}
                    value={searchLocName}
                    onChange={handleSearchLocNameChange}
                    className="table_search"
                  />
                  <button type="submit" className="search-button left ">
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                  <button
                    type="button"
                    className="search-button right"
                    onClick={() => handleSort("locationName")}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </div>
              </th>
              <th>
                <div className="input-group search-container">
                  <input
                    type="text"
                    placeholder={"Search"}
                    value={searchAddress}
                    onChange={handleSearchAddressChange}
                    className="table_search"
                  />
                  <button type="submit" className="search-button left ">
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                  <button
                    type="button"
                    className="search-button right"
                    onClick={() => handleSort("street1")}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </div>
              </th>
              <th>
                <div className="input-group search-container">
                  <input
                    type="text"
                    onChange={handleSearchPhoneNumChange}
                    placeholder={"Search"}
                    value={searchPhoneNum}
                    className="table_search"
                  />
                  <button type="submit" className="search-button left ">
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                  <button
                    type="button"
                    className="search-button right"
                    onClick={() => handleSort("phoneNumber")}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </div>
              </th>
              <th>
                <div className="input-group search-container">
                  <input
                    type="text"
                    value={searchFaxNum}
                    onChange={handleSearchFaxNumChange}
                    placeholder={"Search"}
                    className="table_search"
                  />
                  <button type="submit" className="search-button left ">
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                  <button
                    type="button"
                    className="search-button right"
                    onClick={() => handleSort("faxNumber")}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </div>
              </th>
              <th>
                <div className="input-group search-container">
                  <input
                    type="text"
                    value={searchScheduleNum}
                    onChange={handleSearchScheduleNumChange}
                    placeholder={"Search"}
                    className="table_search"
                  />
                  <button type="submit" className="search-button left ">
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                  <button
                    type="button"
                    className="search-button right"
                    onClick={() => handleSort("schedulingNumber")}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </div>
              </th>
              <th>
                <div className="input-group search-container">
                  <input
                    type="text"
                    placeholder={"Search"}
                    value={searchStartDate}
                    onChange={handleSearchStartDateChange}
                    className="table_search"
                  />
                  <button type="submit" className="search-button left ">
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                  <button
                    type="button"
                    className="search-button right"
                    onClick={() => handleSort("startDate")}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </div>
              </th>
              <th>
                <div className="input-group search-container">
                  <input
                    type="text"
                    placeholder={"Search"}
                    value={searchEndDate}
                    onChange={handleSearchEndDateChange}
                    className="table_search"
                  />
                  <button type="submit" className="search-button left ">
                    <img src={searchIcon} alt="Search Icon" />
                  </button>
                  <button
                    type="button"
                    className="search-button right"
                    onClick={() => handleSort("termDate")}
                  >
                    <img src={sortIcon} alt="Sort Icon" />
                  </button>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody data-testid="LocationData">
            {currentRows?.length > 0 &&
              currentRows?.map((location, index) => (
                <tr
                  key={location?.id}
                  className={
                    selectedLocationId === location.id
                      ? "tr_border_color_grey"
                      : "row-background"
                  }
                >
                  <td
                    data-testid="locationNum"
                    className={
                      selectedLocationId === location.id
                        ? "locationNum tr_border_color_grey"
                        : "locationNum Secondary1"
                    }
                  >
                    {location?.locationNumber || "--"}
                  </td>
                  <td
                    className={
                      selectedLocationId === location.id
                        ? "tr_border_color_grey"
                        : "Secondary1"
                    }
                  >
                    <button
                      type="button"
                      onClick={() => handleEditView(location.id)}
                      className="td_button"
                    >
                      {location?.locationName || "--"}
                    </button>
                  </td>
                  <td
                    className={
                      selectedLocationId === location.id
                        ? "tr_border_color_grey"
                        : "Secondary1"
                    }
                  >
                    {location?.street1} {location?.street2 || ""}{" "}
                    {location?.street3 || ""}, {location?.city},{" "}
                    {location?.state}, {location?.zipCode}
                  </td>
                  <td
                    className={
                      selectedLocationId === location.id
                        ? "tr_border_color_grey"
                        : "Secondary1"
                    }
                  >
                    {location?.phoneNumber?.find(
                      (phone) => phone.phoneTypeId === 1
                    )?.number || "--"}
                  </td>
                  <td
                    className={
                      selectedLocationId === location.id
                        ? "tr_border_color_grey"
                        : "Secondary1"
                    }
                  >
                    {location?.phoneNumber?.find(
                      (phone) => phone.phoneTypeId === 4
                    )?.number || "--"}
                  </td>
                  <td
                    className={
                      selectedLocationId === location.id
                        ? "tr_border_color_grey"
                        : "Secondary1"
                    }
                  >
                    {location?.phoneNumber?.find(
                      (phone) => phone.phoneTypeId === 5
                    )?.number || "--"}
                  </td>
                  <td
                    className={
                      selectedLocationId === location.id
                        ? "tr_border_color_grey"
                        : "Secondary1"
                    }
                  >
                    {(location.startDate &&
                      new Date(location.startDate).toLocaleDateString()) ||
                      "--"}
                  </td>
                  <td
                    className={
                      selectedLocationId === location.id
                        ? "tr_border_color_grey"
                        : "Secondary1"
                    }
                  >
                    {(location.termDate &&
                      new Date(location.termDate).toLocaleDateString()) ||
                      "--"}
                  </td>


                  <td
                    className={
                      selectedLocationId === location.id
                        ? "tr_border_color_grey"
                        : "Secondary1"
                    }
                  >
                    {location?.notesRegardingTermdatesforLocation}
                  </td>
                  {currentRows?.length === 1 ? (
                    <td
                      className={
                        selectedLocationId === location.id
                          ? "tr_border_color_grey text-center"
                          : "Secondary1 text-center"
                      }
                    ></td>
                  ) : (
                    <td
                      className={
                        selectedLocationId === location.id
                          ? "tr_border_color_grey text-center header-icons"
                          : "Secondary1 text-center header-icons"
                      }
                    >
                      {index > 0 ? (
                        <>
                          <button
                            type="button"
                            className="icon_style"
                            onClick={() => handleDelete(location.id)}
                          >
                            <img src={deleteIcon} alt="Sort Icon" />
                          </button>
                          <button
                            type="button"
                            className="icon_style"
                            onClick={() => handleAction(location.id, "up")}
                            // onClick={() => moveItem1(index, index - 1)}
                          >
                            <img src={upArrowIcon} alt="Sort Icon" />
                          </button>
                        </>
                      ) : null}
                      {index < currentRows.length - 1 && (
                        <button
                          type="button"
                          className="icon_style"
                          onClick={() => handleAction(location.id, "down")}
                          // onClick={() => moveItem1(index, index + 1)}
                        >
                          <img src={downArrowIcon} alt="Sort Icon" />
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
        {currentRows?.length > 0 && (
          <>
            <Pagination2
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              paginate={paginate}
              data-testid="PaginationPage2"
            />
          </>
        )}
        {showEditView && (
          <EditLocation
            showEditData={showEditData}
            numberSwap={numberSwap}
            handleAddClick={handleAddClick}
            selectedPracticeIdByAdmin={selectedPracticeIdByAdmin}
            setEditView={setEditView}
            setSuccess={setSuccess}
            setLocAdded={setLocAdded}
            setLocDeleted={setLocDeleted}
            passArrayToParent={handleUpdatedLocationObject}
            updatedLocData={updatedLocData}
          />
        )}
        {!showEditView && (
          <Row className="mt-3 d-flex flex-row-reverse">
            <Col md={3} className="text-right">
              <Button
                className="rounded-pill save_btn"
                onClick={handleAddClick}
                disabled={isSaving}
              >
                {isSaving ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
