import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Table, Row, Col, Button } from "react-bootstrap";
import labels from "../../../Static/Dictionary.json";
import deleteIcon from "./images/delete.svg";
import cookies from "js-cookie";
import searchIcon from "./images/search.svg";
import sortIcon from "./images/Sort_icon.svg";
import Spinner from "Components/Hooks/Spinner";
import { BannerHeading } from "Components/Admin/Components/UI/Atoms/BannerHeading";
import("./_style.scss");
import plusIcon from "../../../../../../images/plus_icon.svg";
import Pagination2 from "Components/Admin/Components/UI/Atoms/Pagination2";
import jwt_decode from "jwt-decode";

export const PracticeList = () => {
  const [searchTerms, setSearchTerms] = useState({});
  const [practiceList, setPracticeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPracticeItem, setSelectedPracticeItem] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token1= jwt_decode(token);
  const base_url1 = process.env.REACT_APP_SERVER_URL1;
  const [updatedProviderCount, setUpdatedProviderCount] = useState();
  const [updatedLocationCount, setUpdatedLocationCount] = useState();
  const [countMessage, setCountMessage] = useState("The Practice has .. locations and .. provider");

  const fetchPracticeList = () => {
    fetch(base_url + "/api/v1/practices/GetAllPractices", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          data?.sort((a, b) => a?.supergroup?.localeCompare(b?.supergroup));
          setPracticeList(data);
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchPracticeList();
    };
    fetch();
  }, []);

  const handleEditClick = (practiceId) => {
    setSelectedRow(practiceId);
    navigate(`/edit-practice/${practiceId}`);
    localStorage.setItem("selectedPracticeIdByAdmin", practiceId);
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [rowsPerPage] = useState(10);
  const [prevSearchTerms, setPrevSearchTerms] = useState({});

  const handleSearchChange = (columnName, value) => {
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [columnName]: value,
    }));
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...practiceList].sort((a, b) => {
    if (!sortConfig) return 0;
    const isAscending = sortConfig.direction === "ascending" ? 1 : -1;
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return -1 * isAscending;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return 1 * isAscending;
    }
    return 0;
  });

  const filteredData = sortedData.filter((item) => {
    return Object.keys(searchTerms).every((columnName) => {
      const columnValue = item[columnName];
      const searchTerm = searchTerms[columnName];
      if (typeof columnValue === "string" && typeof searchTerm === "string") {
        return columnValue.toLowerCase().includes(searchTerm.toLowerCase());
      } else if (typeof columnValue === "number") {
        return columnValue.toString().includes(searchTerm.toString());
      } else {
        return false;
      }
    });
  });

  useEffect(() => {
    if (
      currentPage > 1 &&
      filteredData.length <= (currentPage - 1) * rowsPerPage
    ) {
      setCurrentPage(1);
    } else if (
      Object.keys(searchTerms).some(
        (key) => searchTerms[key] !== prevSearchTerms[key]
      )
    ) {
      setPrevSearchTerms(searchTerms);
    }
  }, [filteredData, currentPage, rowsPerPage, searchTerms, prevSearchTerms]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleDelete = (item) => {
    setUpdateModal(true);
    setSelectedPracticeItem(item);
  };

  const handleSubmit = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(),
    };
    fetch(
      base_url +
        `/api/v1/practices/RemovePractice?practiceId=${selectedPracticeItem?.practiceId}`,
      requestOptions
    ).then((response) => {
      if (response?.status == 200) {
        setUpdateModal(false);
        fetchPracticeList();
      }
    });
  };

  const handleAddClick = () => {
    navigate("/add-practice");
  };
  const providerListDataDetailsForCount = () => {
    fetch(
      base_url1 +
        `/api/v1/providers/ProviderDataByPracticeId?practiceId=${selectedPracticeItem?.practiceId}&practicemanagerid=${token1?.id}&isSQCNAdmin=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          const dataCount =
            data?.filter((person) => {
              if (
                person?.practiceAssignment &&
                person?.practiceAssignment.length > 0
              ) {
                return person.practiceAssignment.some(
                  (assignment) =>
                    assignment?.roleTypeId === 2 && assignment?.termDate === null
                );
              }
              return false;
            }) || [];
          setUpdatedProviderCount(dataCount?.length);
        } else {
          setUpdatedProviderCount(data?.length);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      providerListDataDetailsForCount();
    };
    fetch();
  }, [selectedPracticeItem?.practiceId]);
  const fetchLocationRecords = () => {
    fetch(
      base_url +
        `/api/v1/practices/${selectedPracticeItem?.practiceId}/location?includePending=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const locationWithoutTermDate = data?.filter(
            (location) => location?.isDeleted !== true
          );
          if (data && locationWithoutTermDate?.length > 0) {
            const sortedData = locationWithoutTermDate?.sort(
              (a, b) => a.locationNumber - b.locationNumber
            );
            setUpdatedLocationCount(sortedData.length);
          } else {
            setUpdatedLocationCount(data.length);
          }
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchLocationRecords();
    };
    fetch();
  }, [selectedPracticeItem?.practiceId]);
  useEffect(() => {
    setCountMessage("The Practice has .. locations and .. provider");
  }, [selectedPracticeItem?.practiceId]);
  useEffect(() => {
    setCountMessage(`The Practice has ${updatedLocationCount || 0} locations and ${updatedProviderCount || 0} providers`);
  }, [updatedLocationCount, updatedProviderCount]);

  return (
    <>
      <BannerHeading
        headingText="List of practices"
        subText={`${practiceList?.length} practices`}
      />

      {loading ? (
        <Spinner />
      ) : (
        <div className="manage-outlet">
          <Row className="mb-3">
            <Col md={9}></Col>
            <Col md={3} className="text-right">
              <Button
                variant="primary"
                className="rounded-pill add_new_practice"
                onClick={handleAddClick}
              >
                <img src={plusIcon} alt="Search Icon" />
                Add New Practice
              </Button>
            </Col>
          </Row>
          <div className="practice-location-table" style={{ minWidth: "800px" }} >
            <Table data-testid="providerLocationtable" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>
                    <div className="header-wrapper">
                      <span>{labels?.Super_group}</span>
                    </div>
                  </th>
                  <th style={{ width: "15%" }}>
                    <div className="header-wrapper">
                      <span>{labels?.Tin}</span>
                    </div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div className="header-wrapper">
                      <span>{labels?.Practice_Name_location1}</span>
                    </div>
                  </th>
                  <th style={{ width: "30%" }}>
                    <div className="header-wrapper">
                      <span>{labels?.Address}</span>
                    </div>
                  </th>
                  <th style={{ width: "5%" }}>{labels?.Action}</th>
                </tr>
                <tr className="search_row">
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        placeholder={"Search"}
                        value={searchTerms["supergroup"] || ""}
                        onChange={(e) =>
                          handleSearchChange("supergroup", e.target.value)
                        }
                        className="table_search"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        placeholder={"Search"}
                        value={searchTerms["tin"] || ""}
                        onChange={(e) =>
                          handleSearchChange("tin", e.target.value)
                        }
                        className="table_search"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("tin")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        placeholder={"Search"}
                        value={searchTerms["location"] || ""}
                        onChange={(e) =>
                          handleSearchChange("location", e.target.value)
                        }
                        className="table_search"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("location")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        onChange={(e) =>
                          handleSearchChange("address", e.target.value)
                        }
                        placeholder={"Search"}
                        value={searchTerms["address"] || ""}
                        className="table_search"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("address")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody data-testid="ProviderData">
                {currentRows?.length > 0 &&
                  currentRows?.map((item) => (
                    <tr
                      key={item?.practiceId}
                      className={`clickable-row ${
                        selectedRow === item?.practiceId ? "selected-row" : ""
                      }`}
                    >
                      <td className="Secondary1">
                        <button
                          type="button"
                          onClick={() => handleEditClick(item?.practiceId)}
                          className="td_button"
                        >
                          {item?.supergroup}
                        </button>
                      </td>
                      <td className="Secondary1">
                        <button
                          type="button"
                          onClick={() => handleEditClick(item?.practiceId)}
                          className="td_button"
                        >
                          {item?.tin}
                        </button>
                      </td>
                      <td className="Secondary1">
                        <button
                          type="button"
                          onClick={() => handleEditClick(item?.practiceId)}
                          className="td_button"
                        >
                          {item?.location}
                        </button>
                      </td>
                      <td className="Secondary1">
                        <button
                          type="button"
                          onClick={() => handleEditClick(item?.practiceId)}
                          className="td_button"
                        >
                          {item?.address}
                        </button>
                      </td>
                      <td className="Secondary1 text-center">
                        <button
                          type="button"
                          className="deletePbutton"
                          onClick={() => handleDelete(item)}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete-icon"
                            className="delete_icon"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {currentRows?.length > 0 && (
              <>
                <Pagination2
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  paginate={paginate}
                  data-testid="PaginationPage2"
                />
              </>
            )}
          </div>
        </div>
      )}

      <Modal
        show={updateModal}
        onHide={() => setUpdateModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="update-provider-modal"
        data-testid="bulkupdateprovidermodal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <h6 className="text-center">
              Are you sure you would like to proceed?
            </h6>
          </Row>
          <Row className="mt-3">
            <p>{selectedPracticeItem?.location}</p>
            <p>{selectedPracticeItem?.address}</p>
            <p>{countMessage}</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center modal-footer-transparent">
          <button
            onClick={() => setUpdateModal(false)}
            className="white-btn"
            data-testid="close-button"
          >
            Cancel
          </button>

          <button
            className="blue-btn"
            onClick={handleSubmit}
            type="submit"
            data-testid="createnewproviderbutton"
          >
            Yes, Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
