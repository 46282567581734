/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import labels from "Static/Dictionary.json";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import StateData from "Components/UI/Organisms/StateData";
import { formatDate } from "../../../../../../../Static/format.js";
import stateFieldData from "Static/state.json";
import datePickerIcon from "../../../../../../../images/datepicker_icon.svg";
import * as Yup from "yup";
import { phoneNumberAutoFormat } from "utils";
import LocationValidationModal from "../LocationValidation/LocationValidationModal.jsx";

const AdminAddEditLocationForm = ({
  locationEditData,
  setShowForm,
  setEditView,
  setEditLocationPayLoad,
}) => {
  const AddPracticeLocationSchema = Yup.object().shape({
    locationName: Yup.string().required("Location Name cannot be blank"),
    street1: Yup.string().required("Street1 cannot be blank"),
    city: Yup.string().required("City Name cannot be blank"),
    zipCode: Yup.string().required("Zip Code cannot be blank"),
    main_ContactNumber: Yup.string().required("Phone Number cannot be blank"),
    fax_ContactNumber: Yup.string().required("Fax Number cannot be blank"),
    startDate: Yup.string().notRequired(),
    endDate: Yup.string().notRequired(),
  });
  const [stateName, setStateName] = useState(null);
  const [newState, setNewState] = useState();
  const [isSavedData, setIsSavedData] = useState(false);
  const selectedStateIdfn = (id, name, data = []) => {
    const fName = data?.filter(
      (item) => item?.stateName?.toLowerCase() === name?.toLowerCase()
    );
    if (fName?.length > 0) {
      setNewState(false);
    } else {
      setNewState(true);
    }
    setStateName(name);
  };

  const stateCodeName = stateFieldData?.find(
    (item) => item?.stateName?.toLowerCase() === stateName?.toLowerCase()
  );
  
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setIsSavedData(true);
    const formatStartDate = new Date(values?.startDate);
    const formattedStartDate = formatDate(formatStartDate);
    const formatTermDate = new Date(values?.termDate);
    const formattedTermDate = formatDate(formatTermDate);
    const locationData = {
      locationReplicaId: 1,
      locationNumber: values?.locationNumber,
      locationName: values?.locationName,
      street1: values?.street1,
      street2: values?.street2,
      street3: values?.street3,
      city: values?.city,
      state: stateCodeName?.stateCode ? stateCodeName?.stateCode : values.state,
      zipCode: values?.zipCode,
      startDate: values?.startDate && formattedStartDate,
      termDate: values?.termDate && formattedTermDate,
      main_ContactNumber: values.main_ContactNumber,
      fax_ContactNumber: values.fax_ContactNumber,
      directScheduling_ContactNumber: values.schedulingNumber,
      notesRegardingTermdatesforLocation: values.notesRegardingTermdatesforLocation,

      sourceSystem: "SQCN",
      updateUser: "SYSTEM",
      status: "Approved",
    };
    setEditLocationPayLoad(locationData);
    // Prevent page reload
    setSubmitting(false); // Set submitting state to false
    setShowForm(false);
    // Clear the form data
    resetForm();


    setEditView(false);
  };
  // console.log("locationEditData==>", locationEditData);
  const [locationModalShow, setLocationModalShow] = useState(false);
  const [locationdetails, setLocationDetails] = useState("");
  const [locationDetails2, setLocationDetails2] = useState("");
  const [locationDetailsObj, setLocationDetailsObj] = useState({
    street_number: "",
    route: "",
    sublocality_level_2: "",
    sublocality_level_1: "",
    administrative_area_level_1: "",
    administrative_area_level_3: "",
    postalCode: ""
  });
  const [locDetails, setLocDetails] = useState(false);
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [stateA, setStateA] = useState("");
  const [zipcode, setZipcode] = useState("");
  const handleChangeGoogleApi = async () => {
    const street1Address = street1 ? `${street1.replace(/\s/g, "+")}` : "";
    const street2Address = street2 ? `${street2.replace(/\s/g, "+")}` : "";
    const cityAddress = city ?`${city.replace(/\s/g, "+")}` : "";
    const stateAAddress = stateA ? `${stateA.replace(/\s/g, "+")}` : "";
    const zipcodeAddress = zipcode ? `${zipcode.replace(/\s/g, "+")}` : "";
    const fullAddress = `${street1Address}+${street2Address}+${cityAddress}+${stateAAddress}+${zipcodeAddress}`;
    let status = "";
    const googleToken = process.env.REACT_APP_GOOGLE_TOKEN;
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=${googleToken}`
    )
      .then((response) => {
        status = response?.status;
        return response.json();
      }).then((res) => {
        if (status == 200) {
          if(res?.results.length > 0 && res && res.results && res.results[0] && res.results[0].address_components) {
            const street_number = res.results[0].address_components.find(component => 
              component.types.includes("street_number")
            )?.long_name;
            const route = res.results[0].address_components.find(component => 
              component.types.includes("route")
            )?.long_name;
            const sublocality_level_2 = res.results[0].address_components.find(component => 
              component.types.includes("sublocality_level_2")
            )?.long_name;
            const sublocality_level_1 = res.results[0].address_components.find(component => 
              component.types.includes("sublocality_level_1")
            )?.long_name;
            const locality = res.results[0].address_components.find(component => 
              component.types.includes("locality")
            )?.long_name;
            const administrative_area_level_1 = res.results[0].address_components.find(component => 
              component.types.includes("administrative_area_level_1")
            )?.long_name;
            const administrative_area_level_3 = res.results[0].address_components.find(component => 
              component.types.includes("administrative_area_level_3")
            )?.long_name;
            const postal_code = res.results[0].address_components.find(component => 
              component.types.includes("postal_code")
            )?.long_name;
            setLocationDetailsObj({
              street_number: street_number,
              route: route,
              locality: locality,
              sublocality_level_2: sublocality_level_2,
              sublocality_level_1: sublocality_level_1,
              administrative_area_level_3: administrative_area_level_3,
              administrative_area_level_1: administrative_area_level_1,
              postalCode: postal_code,
            });
            setLocationDetails(`${street_number || ""} ${route || ""} ${sublocality_level_2 || ""} ${sublocality_level_1 || ""} ${locality || ""}`);
            setLocationDetails2(`${administrative_area_level_1 || ""} ${postal_code || ""}`);
          }
        } else {
          setLocationDetails(initialValues?.street1);
          setLocationDetails2(`${initialValues.state} ${initialValues.zipCode}`);
        }
      });
  }
  useEffect(() => {
    const fetch = async () => {
      handleChangeGoogleApi();
    };
    fetch();
  }, [street1, street2, city, stateName, stateA, zipcode, locationModalShow]);
  useEffect(() => {
    if(locDetails === true) {
      setStreet1(`${locationDetailsObj?.street_number || ""} ${locationDetailsObj?.route || ""}`);
      setStreet2(`${locationDetailsObj?.sublocality_level_2 || ""} ${locationDetailsObj?.sublocality_level_1 || ""}`);
      setCity(locationDetailsObj?.locality ? locationDetailsObj?.locality : locationDetailsObj?.administrative_area_level_3 || "");
      setStateA(locationDetailsObj?.administrative_area_level_1 || "");
      setStateName(locationDetailsObj?.administrative_area_level_1 || "");
      setZipcode(locationDetailsObj?.postalCode || "");
    }
  }, [locDetails]);
  return (
    <>
      <Formik
        initialValues={locationEditData}
        enableReinitialize
        validationSchema={AddPracticeLocationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="contact-section">
              <Row>
                <Col md={2}>
                  <p className="details-label">{labels.LOCATION_NUMBER}</p>
                  <Form.Control
                    type="text"
                    id="practiceManagerMdId"
                    autoComplete="off"
                    className="nb-input readonly-input"
                    readOnly
                    value={values.locationNumber}
                  />
                </Col>
                <Col md={4}>
                  <p className="details-label">{labels.LOCATION_NAME}</p>
                  <Form.Control
                    type="text"
                    id="locationName"
                    autoComplete="off"
                    className="nb-input"
                    placeholder="--"
                    value={values.locationName}
                    onChange={handleChange}
                    isInvalid={!!errors.locationName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.locationName}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <p className="details-label">{labels.ADDRESS}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="street1"
                    autoComplete="off"
                    className="nb-input"
                    isInvalid={!!errors.street1}
                    value={street1}
                    name="street1"
                    onChange={(e) => {
                      setStreet1(e.target.value);
                      setFieldValue("street1", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.street1}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mt-4 d-flex flex-row-reverse">
                <Col md={6}>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="street2"
                    autoComplete="off"
                    className="nb-input"
                    value={street2}
                    name="street2"
                    onChange={(e) => {
                      setStreet2(e.target.value);
                      setFieldValue("street2", e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4 d-flex flex-row-reverse">
                <Col md={6}>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="street3"
                    autoComplete="off"
                    className="nb-input"
                    value={values.street3}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={3}>
                  <p className="details-label">{labels.CITY}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="city"
                    autoComplete="off"
                    className="nb-input"
                    isInvalid={!!errors.city}
                    value={city}
                    name="city"
                    onChange={(e) => {
                      setCity(e.target.value);
                      setFieldValue("city", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.STATE}</p>
                  <StateData
                    placeholder="--"
                    id="practiceManagerMdId"
                    autoComplete="off"
                    className="nb-input"
                    stateFieldData={stateFieldData}
                    selectedStateIdfn={selectedStateIdfn}
                    validationError={
                      (values?.locationName?.length > 0 &&
                        !stateName?.length > 0) ||
                      (errors.locationName && !stateName?.length > 0)
                    }
                    validationError1={
                      newState && stateName?.length > 1 && values.state === ""
                    }
                    isSavedData={isSavedData}
                    setIsSavedData={setIsSavedData}
                    formState={values.state}
                    stateA={stateA}
                    setStateName={setStateName}
                    setStateA={setStateA}
                  />
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.ZIP_CODE}</p>
                  <Form.Control
                    type="tel"
                    maxLength={10}
                    placeholder="--"
                    id="zipCode"
                    autoComplete="off"
                    className="nb-input"
                    isInvalid={!!errors.zipCode}
                    value={zipcode}
                    name="zipCode"
                    onChange={(e) => {
                      setZipcode(e.target.value);
                      setFieldValue("zipCode", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode}
                  </Form.Control.Feedback>
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.PHONE_NUMBER_MAIN}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="phoneNumber"
                    autoComplete="off"
                    className="nb-input"
                    name="main_ContactNumber"
                    value={values.main_ContactNumber}
                    onChange={(e)=>{


                     let formattedNumber = phoneNumberAutoFormat(e.target.value);

                    const formattedObject = {
                      target: {id:e.target.id, name: e.target.name, value: formattedNumber }
                    };
                    handleChange(formattedObject);

                    }}
                    isInvalid={!!errors.main_ContactNumber || !values.main_ContactNumber}
                    maxLength={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.main_ContactNumber}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={3}>
                  <p className="details-label">{labels.FAX_NUMBER}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    name="faxNumber"
                    id="faxNumber"
                    autoComplete="off"
                    className="nb-input"
                    value={values.fax_ContactNumber}
                    onChange={(e)=>{
                      let formattedNumber = phoneNumberAutoFormat(e.target.value);
                      const formattedObject = {
                        target: {id:e.target.id, name: e.target.name, value: formattedNumber }
                      };
                      handleChange(formattedObject);
                    }}
                    isInvalid={!!errors.fax_ContactNumber}
                    maxLength={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fax_ContactNumber}
                  </Form.Control.Feedback>
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.DIRECT_SCHEDULING_NUMBER}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="schedulingNumber"
                    autoComplete="off"
                    className="nb-input"
                    value={values.directScheduling_ContactNumber}
                    onChange={(e)=>{
                      let formattedNumber = phoneNumberAutoFormat(e.target.value);
                      const formattedObject = {
                        target: {id:e.target.id, name: e.target.name, value: formattedNumber }
                      };
                      handleChange(formattedObject);
                    }}
                    maxLength={12}
                  />
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.START_DATE}</p>
                  <div className="input-group search-container-date">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={values.startDate}
                      onChange={(date) =>
                        handleChange({
                          target: { name: "startDate", value: date },
                        })
                      }
                      placeholderText="--"
                      data-testid="startDate"
                      className="nb-input width-full"
                      value={values.startDate || "--"}
                      isInvalid={!!errors.startDate}
                    />
                    <button
                      type="button"
                      className="search-button right cursor-auto"
                    >
                      <img src={datePickerIcon} alt="DatePicker Icon" />
                    </button>
                  </div>
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.TERM_DATE}</p>
                  <div className="input-group search-container-date">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={values?.termDate}
                      onChange={(date) =>
                        handleChange({
                          target: { name: "termDate", value: date },
                        })
                      }
                      placeholderText="--"
                      data-testid="editproviderStartDate"
                      className="nb-input width-full"
                      value={values?.termDate || "--"}
                      isInvalid={!!errors.endDate}
                      minDate={values?.startDate}
                    />
                    <button
                      type="button"
                      className="search-button right cursor-auto"
                    >
                      <img src={datePickerIcon} alt="DatePicker Icon" />
                    </button>
                  </div>
                </Col>
                </Row>
                <Row className="mt-4">
                <Col md={3}>
                        <p className="details-label">  Notes Regarding Term Dates
                        </p>
                        <Form.Control
                          type="text"
                          placeholder="---"
                          id="notesRegardingTermdatesforLocation"
                          autoComplete="off"
                          className="nb-input"
                          value={values.notesRegardingTermdatesforLocation}
                          onChange={handleChange}

                          disabled={!values.termDate}
                         // isInvalid={!!errors.street1}
                        />
                      </Col>
              </Row>
            </div>
            <LocationValidationModal
              data-testid="locationValidationModal"
              show={locationModalShow}
              onHide={() => setLocationModalShow(false)}
              locationdetails={locationdetails}
              locationDetails2={locationDetails2}
              setLocDetails={setLocDetails}
            />
            <Row className="mt-3 d-flex justify-content-end">
              <Col md={2} className="text-right">
                <Button
                  type="submit"
                  className="rounded-pill save_btn blue-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setLocationModalShow(true);
                  }}
                  disabled
                >
                  Validate
                </Button>
              </Col>
              <Col md={3} className="text-right validate_btn">
                <Button
                  variant="primary"
                  className="rounded-pill save_btn"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AdminAddEditLocationForm;
