import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "Components/Templates/Home/Home";
import ProviderSummaryPage from "Components/Templates/DataPages/ProviderSummaryPage";
import EditProviderPage from "Components/Templates/DataPages/EditProviderPage";
import AddProviderPage from "Components/Templates/DataPages/AddProviderPage";
import CreateNewProvider from "Components/Templates/ProviderList/CreateNewProvider";
import Spinner from "Components/Hooks/Spinner";
import cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Header from "Components/UI/Organisms/Header/Header";
import { Dashboard } from "../Admin/Components/Templates/Dashboard";
import { Tables } from "Components/Admin/Components/Templates/Tables";
import { PracticeAccess } from "Components/Admin/Components/Templates/practiceAccess";
import AddPracticeList from "Components/Admin/Components/Templates/ManageSqcnPractice/PracticeInformation/AddPractice/AddPracticeList";
import { EditPracticeList } from "Components/Admin/Components/Templates/ManageSqcnPractice/PracticeInformation/EditPractice/EditPracticeList";
import ViewProvider from "Components/Admin/Components/Templates/ManageSqcnPractice/PracticeInformation/ProviderAccordion/ViewProvider";
import NpiSearch from "Components/Admin/Components/Templates/NpiSearchPage";
function PracticePage({ initialCall, token, tokenId }) {
  console.log("it", initialCall);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [practiceTitle, setPracticeTitle] = useState();
  const [practiceManagerId, setPracticeManagerId] = useState();
  const [pmName, SetPmName] = useState();
  const [personRoleName, setPersonRoleName] = useState();
  const [personRoleType, setPersonRoleType] = useState();
  const [isSqcnRole, setIsSqcnRole] = useState();
  const token1 = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    setSelectedId(selectedId);
  }, [selectedId]);

  const clickHandler = (practiceId) => {
    setSelectedId(practiceId);
  };

  const Login = () => {
    const baseURL = process.env.REACT_APP_SERVER_URL2;
    window.location.href = baseURL;
  };

  const titleHandler1 = (practiceName) => {
    setPracticeTitle(practiceName);
  };
  useEffect(() => {
    if (tokenId) {
      navigate("/");
    }
  }, [tokenId]);
  useEffect(() => {
    if (token) {
      const decodeToken = jwt_decode(token);
      console.log("dt", decodeToken);
      localStorage.setItem("createNewProviderId", decodeToken?.id)
      const base_url3 = process.env.REACT_APP_SERVER_URL1;
      const payLoad = {
        mdOfficeId: decodeToken?.mdofficeid,
      };
      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "ocp-apim-subscription-key": subscriptionKey,
          "access-control-allow-credentials": "true",
          "api-supported-versions": "1.0",
        },
        body: JSON.stringify(payLoad),
      };
      fetch(base_url3 + "/api/authentication/authenticate", request)
        .then((res) => res.text())
        .then((response) => {
          console.log("asp", response);
          const newToken = response;
          cookies.set("access", newToken);
        });
      const currentTime = Date.now() / 1000;
      if (decodeToken.exp < currentTime) {
        Login();
      } else if (token1) {
        const decodeToken1 = jwt_decode(token1);
        console.log("dt1", decodeToken1);
        setPracticeManagerId(decodeToken1?.id);
        SetPmName(`${decodeToken1?.firstname} ${decodeToken1?.lastname}`);
        setPersonRoleName(decodeToken1?.IsSQCNAdmin);
        setPersonRoleType(decodeToken1?.roleTypeIds);
        const isAdmin =
          decodeToken1?.IsSQCNAdmin === "SQCNAdmin" ? true : false;
        setIsSqcnRole(isAdmin);
        const data = fetch(
          base_url +
            `/api/v1/practices/PracticeSummary?practiceManagerId=${decodeToken1.id}&isSQCNAdmin=${isAdmin}`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: "Bearer " + token1,
              "Content-Type": "application/json",
              "ocp-apim-subscription-key": subscriptionKey,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data?.practiceDetails[0]?.practiceId);
            setSelectedId(
              localStorage.getItem("selectedPracticeId") ||
                data?.practiceDetails[0]?.practiceId
            );
            const practiceDefaultTitle = data?.practiceDetails?.filter(
              (prId) => prId?.practiceId === parseInt(selectedId)
            );
            setPracticeTitle(practiceDefaultTitle[0]?.practiceName);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Fetch error:", error);
          });
        console.log("dtt", data, loading);
      }
    }
  }, [token, token1]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <div>
      <Routes>
        <Route
          path="/"
          exact
          element={
            initialCall ? (
              <Login />
            ) : (
              selectedId && (
                <>
                  <Header
                    pmName={pmName}
                    data-testid="child"
                    PersonRole={personRoleName}
                    personRoleTypeId={personRoleType}
                  />
                  <Home
                    selectedPracticeId={selectedId}
                    practiceManagerId={practiceManagerId}
                    selectHandler={clickHandler}
                    titleHandler={titleHandler1}
                    pmName={pmName}
                    PersonRole={personRoleName}
                    isAdmin={isSqcnRole}
                  />
                </>
              )
            )
          }
        />
        <Route
          path="/provider-list/:id"
          exact
          element={
            <>
              <Header
                pmName={pmName}
                data-testid="child"
                PersonRole={personRoleName}
                personRoleTypeId={personRoleType}
              />
              <ProviderSummaryPage
                practiceTitle={practiceTitle}
                selectedPracticeId={selectedId}
                practiceManagerId={practiceManagerId}
                PersonRole={personRoleName}
              />
            </>
          }
        />
        <Route
          path="/edit-provider/:id"
          exact
          element={
            <>
              <Header
                pmName={pmName}
                data-testid="child"
                PersonRole={personRoleName}
                personRoleTypeId={personRoleType}
              />
              <EditProviderPage
                practiceTitle={practiceTitle}
                selectedPracticeId={selectedId}
                practiceManagerId={practiceManagerId}
              />
            </>
          }
        />
        <Route
          path="/add-provider/:id"
          exact
          element={
            <>
              <Header
                pmName={pmName}
                data-testid="child"
                PersonRole={personRoleName}
                personRoleTypeId={personRoleType}
              />
              <AddProviderPage
                practiceTitle={practiceTitle}
                selectedPracticeId={selectedId}
                practiceManagerId={practiceManagerId}
              />
            </>
          }
        />
        <Route
          path="/create-new-provider"
          exact
          element={
            <>
              <Header
                pmName={pmName}
                data-testid="child"
                PersonRole={personRoleName}
                personRoleTypeId={personRoleType}
              />
              <CreateNewProvider
                practiceTitle={practiceTitle}
                practiceManagerId={practiceManagerId}
                selectedPracticeId={selectedId}
              />
            </>
          }
        />
        {personRoleName === "SQCNAdmin" && (
          <Route
            path="/sqcn-admin"
            exact
            element={
              <>
                <Dashboard pmName={pmName} />
              </>
            }
          />
        )}
        {personRoleName === "SQCNAdmin" && (
          <Route
            path="/manage-ppd-access"
            exact
            element={
              <>
                <Tables pmName={pmName} />
              </>
            }
          />
        )}

        {personRoleName === "SQCNAdmin" && (
          <Route
            path="/manage-sqcn-practice"
            exact
            element={
              <>
                <PracticeAccess pmName={pmName} />
              </>
            }
          />
        )}
        {personRoleName === "SQCNAdmin" && (
          <Route
            path="/add-practice"
            exact
            element={
              <>
                <AddPracticeList
                  pmId={practiceManagerId}
                  isSqcnRole={isSqcnRole}
                  practiceTitle={practiceTitle}
                  practiceManagerId={practiceManagerId}
                  selectedPracticeId={selectedId}
                />
              </>
            }
          />
        )}
        {personRoleName === "SQCNAdmin" && (
          <Route
            path="/edit-practice/:id"
            exact
            element={
              <>
                <EditPracticeList
                  pmId={practiceManagerId}
                  selectedPracticeId={selectedId}
                  isSqcnRole={isSqcnRole}
                />
              </>
            }
          />
        )}

        {personRoleName === "SQCNAdmin" && (
          <Route
            path="/add-provider-by-admin/:id"
            exact
            element={
              <>
                <ViewProvider
                  practiceTitle={practiceTitle}
                  selectedPracticeId={selectedId}
                  practiceManagerId={practiceManagerId}
                />
              </>
            }
          />
        )}
        {personRoleName === "SQCNAdmin" && (
          <Route
            path="/npi-search"
            exact
            element={
              <>
                <NpiSearch pmName={pmName} />
              </>
            }
          />
        )}
      </Routes>
    </div>
  );
}

export default PracticePage;
