import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Table } from "react-bootstrap";
import Checkbox from "Components/UI/Atoms/Checkbox/Checkbox";
import Spinner from "Components/Hooks/Spinner";

function AddLocationModalPpd({ data, show, onHide, clickHandler, filteredAllData }) {
  const [searchLocationName, setSearchLocationName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [locations, setLocations] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAssignment, setIsCheckAssignment] = useState([]);
  const [isExisting, setIsExisting] = useState([]);

  const handleClick = (e, id,assignmentId) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);

    setIsCheckAssignment([...isCheckAssignment, assignmentId]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      setIsCheckAssignment(isCheckAssignment.filter((item) => item !== assignmentId));
    }
  };

  const removeObjectsByIds = (items, idsToRemove) => {
    const idsToRemoveArray = idsToRemove.map(item => item.practiceId);
    return items?.filter(item => !idsToRemoveArray.includes(item.practiceId));
  };
  useEffect(() => {
    if (filteredAllData) {
      let tempData = [];
      filteredAllData && filteredAllData?.map((item) => {
        if (item.practiceId) {
          tempData.push(item.practiceId);
        }
      })
      setIsCheckAssignment(tempData);
      setIsExisting(tempData);
      

      const updatedFilteredData = removeObjectsByIds(data.practiceDetails, filteredAllData);
      setLocations(updatedFilteredData);
    }
  }, [data, filteredAllData]);
  const separatePracticeAssignments = () => {
    if (!data) {
      return [];
    }
    const selectedAssignments = data?.practiceDetails?.filter((assignment) => {
      if (assignment) {
        if(isCheckAssignment.includes(assignment?.practiceId) && !isExisting.includes(assignment?.practiceId)){

          return assignment;
        }
      }
      return false;
    });
    return selectedAssignments;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onHide();
    const selectedAssignments = separatePracticeAssignments();
    clickHandler(selectedAssignments);
  };

  const onCancel = () => {
    onHide();
  };

  const handleSearchLocationNameChange = (e) => {
    setSearchLocationName(e.target.value);
  };

  const handleSearchAddressChange = (e) => {
    setSearchAddress(e.target.value);
  };

  const createSearchPattern = (input) => {
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };

  const filterData = (item) => {
    const searchLocationNamePattern = createSearchPattern(searchLocationName);
    const searchAddressPattern = createSearchPattern(searchAddress);
    const locationMatches = item.locationDetails.some((location) => {
      const locationName = location.locationName.toLowerCase().replace(/\s/g, "");
      const address = `${location.street1 || ""} ${location.street2 || ""} ${location.city || ""} ${location.state || ""} ${location.zipCode || ""}`.toLowerCase().replace(/\s/g, "");
   
      return (
        searchLocationNamePattern.test(locationName) &&
        searchAddressPattern.test(address)
      );
    });

    return locationMatches;
  };

  useEffect(() => {
    const updatedFilteredData = removeObjectsByIds(data.practiceDetails, filteredAllData);
    const newFilteredData = updatedFilteredData?.filter(filterData);
    setLocations(newFilteredData || []);
  }, [
    searchLocationName,searchAddress
  ]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-provider-modal add"
      data-testid="modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Provider Locations
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="provider-location-table">
          <Table data-testid="providerLocationtable">
            <thead>
              <tr>
                <th>Location Number - Name</th>
                <th>Address</th>
              </tr>
              <tr>
                <td className="search_column new">
                  <Form.Group
                    className="search-npi"
                    controlId="formGroupLocationName"
                  >
                    <Form.Control
                      type="text"
                      name="locationName"
                      placeholder="Search Location Name"
                      autoComplete="off"
                      className="search-input new"
                      value={searchLocationName}
                      onChange={handleSearchLocationNameChange}
                      data-testid="LocationName"
                    />
                  </Form.Group>
                </td>
                <td className="search_column new1">
                  <Form.Group
                    className="search-npi"
                    controlId="formGroupAddress"
                  >
                    <Form.Control
                      type="text"
                      name="locationAddress"
                      placeholder="Search Address"
                      autoComplete="off"
                      className="search-input new"
                      value={searchAddress}
                      onChange={handleSearchAddressChange}
                      data-testid="LocationAddress"
                    />
                  </Form.Group>
                </td>
              </tr>
            </thead>
            <tbody
              data-testid="AddPracticeAssignmentsInAdminProvider"
              className="location-table-list"
            >
              
              {data.length === 0 ?
                <Spinner /> :
                (locations?.map((item) => {
                  return  (item?.locationDetails?.map((location) => {
                    return (
                      <React.Fragment key={location?.locationId}>
                        <tr key={location?.locationId}>
                          <td
                            style={{ width: "5%" }}
                            className={location?.locationNumber === 1 ? "Primary1" : "Secondary1"}
                          >
                            <Checkbox
                              type="checkbox"
                              id={location?.locationId}
                              handleClick={(e) =>
                                handleClick(e, location?.locationId,item.practiceId)
                              }
                              isChecked={isCheckAssignment.includes(item.practiceId)}
                            />
                          </td>
                          <td
                            style={{ width: "45%" }}
                            className={location?.locationNumber === 1 ? "Primary1" : "Secondary1"}
                          >
                            {location?.locationNumber}&nbsp;-&nbsp;{location?.locationName}
                          </td>
                          <td
                            style={{ width: "50%" }}
                            className={location?.locationNumber === 1 ? "Primary1" : "Secondary1"}
                          >
                            {`${location?.street1} ${
                              location?.street2 ? location?.street2 : ""
                            }, ${location?.city}, ${location?.state} ${location?.zipCode}`}
                          </td>
                        </tr>
                      </React.Fragment>
                    );

                  }))}))}
               
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button
          onClick={onCancel}
          className="white-btn"
          data-testid="close-button"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="blue-btn"
          data-testid="submit"
        >
          Add Location
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddLocationModalPpd;
