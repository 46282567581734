import React from "react";
import avatar from "./Avatar.svg";
import { Container, Row, Col } from "react-bootstrap";
import "./_style.scss";

function Header(props) {
  const handleLogout = () => {
    const baseURL = process.env.REACT_APP_SERVER_URL2;
    window.location.href = baseURL;
  };
  return (
    <div data-testid="header" className="admin-header">
      <Container>
        <div className="content">
          <Row>
            <Col md={7} xs={6}>
              <h6 className="text-hide logo-text1" data-testid="logotext">
                {props?.headerText}
              </h6>
            </Col>
            <Col md={5} xs={6} className="order-lastalign">
              <div className="user-content1">
                <h6 className="user-name1">{props?.SQCNName}</h6>
                <p className="role-name1">SQCN Admin</p>
                <button className="user-signout1" onClick={handleLogout}>Sign Out</button>
              </div>
              <div className="user-image1">
                <img src={avatar} alt="User" className="_avatar" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Header;
