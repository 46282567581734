import jwt_decode from "jwt-decode";
import cookies from "js-cookie";
import EditPracticeNewProvider from "../ProviderList/EditPracticeNewProvider";

const EditPracticeNewProviderAdmin = ({
  practiceTitle,
  selectedPracticeId,
  practiceManagerId,
  providerId,
  personRoleName,
  providerListGetUpdate,
  updatedProviderList,
  setCreatNpi,
  addFlow,
  setViewProviderIdModal,
  setUpdatedProviderList,
  creatNpi,
  setRefreshData,
  refreshData,
  locationPayload,
  titleData,
  prefixData
}) => {
  const token = cookies.get("access");
  const decodeToken = jwt_decode(token);


  return (
    <>
      <EditPracticeNewProvider
        practiceManagerId={decodeToken?.id || practiceManagerId}
        providerSummary={{}}
        id={providerId}
        selectedPracticeId={selectedPracticeId}
        practiceTitle={practiceTitle}
        personRoleName={personRoleName}
        updatedProviderList={updatedProviderList}
        addFlow={addFlow}
        setUpdatedProviderList={setUpdatedProviderList}
        setCreatNpi={setCreatNpi}
        setViewProviderIdModal={setViewProviderIdModal}
        providerListGetUpdate={providerListGetUpdate}
        creatNpi={creatNpi}
        setRefreshData = {setRefreshData}
        refreshData={refreshData}
        locationPayload={locationPayload}
        titleData={titleData}
        prefixData={prefixData}
      />
    </>
  );
};

export default EditPracticeNewProviderAdmin;
