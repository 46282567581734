import React from "react";
import { Container, Row } from "react-bootstrap";
import HomePageTabs from "../Tabs/HomePageTabs";
import { useFetch } from "Components/Hooks/Fetch";
import Spinner from "Components/Hooks/Spinner";
import PracticeBannerPage from "../DataPages/PracticeBannerPage";

const Home = ({
  selectedPracticeId,
  practiceManagerId,
  selectHandler,
  titleHandler,
  isAdmin,
  PersonRole
}) => {
  const {
    data = [],
    loading,
    error = false,
  } = useFetch(`/api/v1/practices/${selectedPracticeId}/location`);
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <div className="mt-4">
        <Container>
          <Row>
            <PracticeBannerPage
              practiceManagerId={practiceManagerId}
              selectHandler={selectHandler}
              titleHandler={titleHandler}
              isAdmin={isAdmin}
            />
            <HomePageTabs
              locationListData={data}
              isLocationServerError={error}
              selectedPracticeId={selectedPracticeId}
              practiceManagerId={practiceManagerId}
              isAdmin={isAdmin}
              PersonRole={PersonRole}
            />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Home;
