import React from "react";
import Sidebar from "../Pages/Sidebar";
import "./Dashboard.css";
import Header from "../Pages/Header";
import HomePageTabs from "./Tabs/HomePageTabs";

export const Dashboard = (props) => {
  return (
    <div className="dashboard d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flow-root",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Header
          headerText="Home"
          SQCNName={props?.pmName}
        />
        <div style={{ height: "100%", background: "#f5f5f5" }}>
          <div
            className="dashboard-body"
            style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}
          >
            <HomePageTabs />
          </div>
        </div>
      </div>
    </div>
  );
};
