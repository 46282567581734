/* eslint-disable indent */
import React from "react";
import cookies from "js-cookie";
import { Col, Form, Modal, Row } from "react-bootstrap";
import labels from "Static/Dictionary.json";
import stateFieldData from "Static/state.json";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { phoneNumberAutoFormat } from "utils";    
import StateData from "Components/UI/Organisms/StateData";

function AddPracticeLocationModal(props) {
  const [updateModal, setUpdateModal] = useState(false);
  const [payLoadData, setPayLoadData] = useState({});
  const [sltdStateId, setSltdStateId] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [newState, setNewState] = useState();
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  console.log(sltdStateId);

  const selectedStateIdfn = (id, name, data = []) => {
    const fName = data?.filter(
      (item) => item?.stateName?.toLowerCase() === name?.toLowerCase()
    );
    if (fName?.length > 0) {
      setNewState(false);
    } else {
      setNewState(true);
    }
    setSltdStateId(id);
    setStateName(name);
  };
  const stateCodeName = stateFieldData?.find(
    (item) => item?.stateName?.toLowerCase() === stateName?.toLowerCase()
  );
  const initialValues = {
    locationName: "",
    street1: "",
    street2: "",
    street3: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    faxNumber: "",
    schedulingNumber: "",
  };
  const AddPracticeLocationSchema = Yup.object().shape({
    locationName: Yup.string().required("Location Name cannot be blank"),
    street1: Yup.string().required("Street1 cannot be blank"),
    city: Yup.string().required("City Name cannot be blank"),
    zipCode: Yup.string().required("Zip Code cannot be blank"),
    phoneNumber: Yup.string().required("Phone Number cannot be blank"),
    schedulingNumber: Yup.string().notRequired(),
  });
  const onSubmitFun = async (values) => {
    console.log(values);
    setPayLoadData(values);
    props.onHide();
    setUpdateModal(true);
  };
  const handleSubmit = async () => {
    const values = { ...payLoadData };
    const payLoad = {
      updateUser: props?.practiceManagerId,
      LocationName: values?.locationName,
      Street1: values?.street1,
      Street2: values?.street2,
      Street3: values?.street3,
      City: values?.city,
      State: stateCodeName?.stateCode,
      ZipCode: values?.zipCode,
      main_ContactNumber: values?.phoneNumber,
      fax_ContactNumber: values?.faxNumber,
      directScheduling_ContactNumber: values?.schedulingNumber,
    };
    console.log("pay", payLoad);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(payLoad),
    };
    fetch(
      base_url + `/api/v1/practices/${props.selectedPracticeId}/location`,
      requestOptions
    ).then((response) => {
      console.log("rep", response, response.status);
      if (response.status == 200) {
        setUpdateModal(false);
        props.fetchAddRecords();
        props.AddNewLocationInfo();
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-provider-modal"
        data-testid="addprovidermodal"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={AddPracticeLocationSchema}
          onSubmit={(values) => {
            onSubmitFun(values);
          }}
        >
          {({ errors, values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Add New Location
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={7} className="mb-4">
                    <p className="location-labelText">{labels.LOCATION_NAME}</p>
                    <Form.Control
                      type="text"
                      placeholder=""
                      id="locationName"
                      autoComplete="off"
                      value={values.locationName}
                      className="nb-input"
                      onChange={handleChange}
                      isInvalid={!!errors.locationName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.locationName}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <div className="practice-location">
                  <Row>
                    <Col md={12}>
                      <p className="location-label">{labels.ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="Street 1"
                        id="street1"
                        autoComplete="off"
                        value={values.street1}
                        className="nb-input street mb-3"
                        onChange={handleChange}
                        isInvalid={!!errors.street1}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.street1}
                      </Form.Control.Feedback>
                      <Form.Control
                        type="text"
                        placeholder="Street 2"
                        id="street2"
                        autoComplete="off"
                        value={values.street2}
                        className="nb-input street mb-3"
                        onChange={handleChange}
                      />
                      <Form.Control
                        type="text"
                        placeholder="Street 3"
                        id="street3"
                        autoComplete="off"
                        value={values.street3}
                        className="nb-input street"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="practice-location">
                  <Row>
                    <Col md={6}>
                      <p className="location-label">{labels.CITY}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="city"
                        autoComplete="off"
                        value={values.city}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={!!errors.city}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6}>
                      <p className="location-label">{labels.STATE}</p>
                      <StateData
                        stateFieldData={stateFieldData}
                        selectedStateIdfn={selectedStateIdfn}
                        validationError={
                          (values?.locationName?.length > 0 &&
                            !stateName?.length > 0) ||
                          (errors.locationName && !stateName?.length > 0)
                        }
                        validationError1={newState && stateName?.length > 1}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="practice-location">
                  <Row>
                    <Col md={6}>
                      <p className="location-label">{labels.ZIP_CODE}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="zipCode"
                        autoComplete="off"
                        value={values.zipCode}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={!!errors.zipCode}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.zipCode}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6}>
                      <p className="location-label">{labels.PHONE_NUMBER}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="phoneNumber"
                        autoComplete="off"
                        value={values.phoneNumber}
                        className="nb-input"
                        
    onChange={(e)=>{

      let formattedNumber = phoneNumberAutoFormat(e.target.value);

      const formattedObject = {
       target: {id:e.target.id, name: e.target.name, value: formattedNumber }
    };
    handleChange(formattedObject);

    }}
                        isInvalid={!!errors.phoneNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </div>
                <div className="practice-location">
                  <Row>
                    <Col md={6}>
                      <p className="location-label">{labels.FAX_NUMBER}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="faxNumber"
                        autoComplete="off"
                        value={values.faxNumber}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={!!errors.faxNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.faxNumber}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6}>
                      <p className="location-label">
                        {labels.SCHEDULING_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="schedulingNumber"
                        autoComplete="off"
                        value={values.schedulingNumber}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={!!errors.schedulingNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.schedulingNumber}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <button
                  onClick={props.onHide}
                  className="white-btn"
                  data-testid="close-button"
                >
                  Cancel
                </button>
                <button
                  className="blue-btn"
                  disabled={
                    !values.locationName ||
                    !values.street1 ||
                    !values.city ||
                    !stateName ||
                    !values.zipCode ||
                    !values.phoneNumber
                  }
                  type="submit"
                  value="submit"
                  data-testid="AddproviderButton"
                >
                  Add Location
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        show={updateModal}
        onHide={() => setUpdateModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="update-provider-modal"
        data-testid="bulkupdateprovidermodal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Add Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to add <b>&quot;{payLoadData?.locationName}&quot;</b>{" "}
            to your practice. This action can&#039;t be undone. Are you sure to
            proceed.
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={() => setUpdateModal(false)}
            className="white-btn"
            data-testid="close-button"
          >
            Cancel
          </button>

          <button
            className="blue-btn"
            value="submit"
            type="submit"
            onClick={handleSubmit}
            data-testid="createnewproviderbutton"
          >
            Yes, Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default AddPracticeLocationModal;
