import React from "react";
import Title from "Components/UI/Atoms/Title/Title";

function LocationPreview({ locationTitle }) {
  return (
    <React.Fragment>
      <Title title={locationTitle} />
    </React.Fragment>
  );
}

export default LocationPreview;
