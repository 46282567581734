import React, { useState, useEffect } from "react";
import TabContent from "./TabContent";
import TabNavItem from "./TabNavItem";
import "./styles.css";
import PracticeApprovalBoard from "../PracticeApprovalBoard/PracticeApprovalBoard";
import ProviderApprovalboard from "../ProviderApprovalBoard/ProviderApprovalboard";

const HomePageTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  useEffect(() => {
    const getData = localStorage.getItem("admin-tab");
    console.log("gd", getData);
    setActiveTab(getData || "tab1");
  }, []);

  return (
    <React.Fragment>
      <div className="Tabs">
        <ul className="nav">
          <TabNavItem
            title="Practice Approval Board"
            id="tab1"
            data-testid="practiceinformationtab"
            className="tab1"
            activeTab={activeTab}
            setActiveTab={() => {
              setActiveTab("tab1"), localStorage.setItem("admin-tab", "tab1");
            }}
          />
          <TabNavItem
            title="Provider Approval Board"
            id="tab2"
            data-testid="providerinformationtab"
            activeTab={activeTab}
            setActiveTab={() => {
              setActiveTab("tab2"), localStorage.setItem("admin-tab", "tab2");
            }}
          />
        </ul>
        <div className="outlet">
          <TabContent id="tab1" activeTab={activeTab}>
            <PracticeApprovalBoard />
          </TabContent>
          <TabContent id="tab2" activeTab={activeTab}>
            <ProviderApprovalboard />
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomePageTabs;
