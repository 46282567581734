/* eslint-disable indent */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import cookies from "js-cookie";

function RemovePpdAccess(props) {
    const [providePpdAccessError, setProvidePpdAccessError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const token = cookies.get("access");
    const base_url = process.env.REACT_APP_SERVER_URL;
    const base_url1 = process.env.REACT_APP_SERVER_URL1;
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    const handleSubmit = async () => {
        setIsLoading(true);
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "access-control-allow-credentials": "true",
                "ocp-apim-subscription-key": subscriptionKey,
                "api-supported-versions": "1.0",
            },
            body: JSON.stringify(),
        };
        fetch(
            base_url + `/api/v1/practices/RemoveAllPracticeAssignments?personid=${props?.personData[0]?.id}`,
            requestOptions
        ).then((response) => {
            console.log("rep", response, response.status);
            if (response.status == 200) {
                setProvidePpdAccessError(false);
                props?.emptyProvisionLocation();
                const payLoad = {
                    personId: props?.personData?.[0]?.id,
                    isAdminAccess: true
                };
                const requestOptions = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                        "access-control-allow-credentials": "true",
                        "ocp-apim-subscription-key": subscriptionKey,
                        "api-supported-versions": "1.0",
                    },
                    body: JSON.stringify(payLoad),
                };
                fetch(
                    base_url1 + "/api/v1/providers/ProvideSQCNAdminAccess",
                    requestOptions
                ).then((response) => {
                    console.log("rep", response, response.status);
                    if (response.status === 200) {
                        props?.updateModalClose();
                        props?.fetchMdofficeData();
                        props?.ppdUserRemoved();
                        setProvidePpdAccessError(false);
                        setIsLoading(false);
                    } else {
                        setProvidePpdAccessError(true);
                        setIsLoading(false);
                    }
                });
            } else {
                setProvidePpdAccessError(true);
                setIsLoading(false);
            }
        });
    };
    return (
        <React.Fragment>
            <Modal
                show={props?.updateModal}
                onHide={props?.updateModalClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="update-provider-modal"
                data-testid="bulkupdateprovidermodal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props?.headerText}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{props?.bodyText}</p>
                    <p>{props?.secondSentence}</p>
                </Modal.Body>
                {providePpdAccessError &&
                    <p className="error_ppd mb-1 text-center">Some thing went wrong</p>
                }
                <Modal.Footer className="justify-content-center">
                    <button
                        onClick={() => {
                            props?.updateModalClose();
                            setProvidePpdAccessError(false);
                        }}
                        className="white-btn"
                        data-testid="close-button"
                        disabled={isLoading}
                    >
                        Cancel
                    </button>
                    <button
                        className="blue-btn"
                        disabled={isLoading}
                        value="submit"
                        type="submit"
                        onClick={() => {
                            handleSubmit();
                            setProvidePpdAccessError(false);
                        }}
                        data-testid="createnewproviderbutton"
                    >
                        {isLoading ? "Saving..." : "Yes, Confirm"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default RemovePpdAccess;
