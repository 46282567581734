/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import labels from "../../Static/Dictionary.json";
import { Block } from "../../UI/Atoms/Block";
import { Col, Row, Table } from "react-bootstrap";
import { Heading } from "../../UI/Atoms/Heading";
import Checkbox from "../../UI//Atoms/Checkbox";
import { prefixName } from "utils";
import { ReviewProviderSubmit } from "./ReviewProviderSubmit";

export const AddExistingProvider = ({
    RequestTypeData,
    addProviderReviewData,
    practiceInfoId,
    providerReviewApproval,
    providerReviewReject,
    approvedStatusChange,
    statusChange,
    statusChangeReject,
    rejectStatusChange,
    updateUserId,
    approvedLocStatusChange,
    locStatusChange,
    rejectLocStatusChange,
    locRejectStatusChange,
    locationReviewApproval,
    locationReviewReject,
    setChangeProviderMode
}) => {
    const [isCheck, setIsCheck] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheckLoc, setIsCheckLoc] = useState([]);
    const [actionStatus, setActionStatus] = useState();

    const handleSelectAll = () => {
        if (!isCheckAll) {
            setIsCheckAll(!isCheckAll);
            setIsCheckLoc(primaryLocations?.map((item) => item?.locationId));
        } else {
            setIsCheckAll(!isCheckAll);
            setIsCheckLoc([]);
        }
    };
    const handleClickLoc = (e, id) => {
        const { checked } = e.target;
        setIsCheckLoc([...isCheckLoc, id]);
        if (!checked) {
            setIsCheckLoc(isCheckLoc.filter((item) => item !== id));
        }
    };
    const handleClick = (e, id) => {
        const { checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
        }
    };
    useEffect(() => {
        if (isCheck.length === 1 && isCheck[0] === 0 && isCheckLoc.length === 0 && primaryLocationsApproved?.length > 0) {
            setActionStatus("APAL");
        } else if (isCheck.length === 1 && isCheck[0] === 0 && isCheckLoc.length === 0) {
            setActionStatus("AP");
        } else if (isCheck.length === 1 && isCheck[0] === 0 && isCheckLoc.length > 0) {
            setActionStatus("APAL");
        } else if ((isCheck.length === 0 && isCheckLoc.length > 0)) {
            setActionStatus("AL");
        } else {
            setActionStatus("undefined");
        }
    }, [isCheck, isCheckLoc]);
    const providerLocationsData = addProviderReviewData?.providerLocations;
    const alternateLocations = providerLocationsData?.filter(item => item?.locationAssignmentType === "Alternate");
    const primaryLocations = providerLocationsData?.filter(item => item?.locationAssignmentType === "Primary");
    const primaryLocationsPending = providerLocationsData?.filter(item => item?.locationAssignmentType === "Primary" &&
        item?.approvalStatus === "Pending");
    const primaryLocationsApproved = providerLocationsData?.filter(item => (item?.locationAssignmentType === "Primary" &&
        item?.approvalStatus === "Approved" && item?.endDate !== null));

    const sortedData = primaryLocations?.sort((a, b) => {
        if (a?.approvalStatus === b?.approvalStatus) {
            return 0;
        }
        return a.approvalStatus === "Approved" ? -1 : 1;
    });
    console.log("isc", primaryLocationsPending, primaryLocationsApproved);
    return (
        <>
            <Heading text="Add Provider" />
            <div className="Approvalbox">
                <div className="location-name-box">
                    <Row>
                        <Col md={10}>
                            <div className="checkbox-text">
                                {((alternateLocations?.length > 0 && !approvedStatusChange && !rejectStatusChange) ||
                                    (primaryLocationsApproved?.length > 0 && !approvedStatusChange && !rejectStatusChange)) && (
                                        <Checkbox
                                            type="checkbox"
                                            id={0}
                                            handleClick={(e) => handleClick(e, 0)}
                                            isChecked={isCheck.includes(0)}
                                        />
                                    )}
                            </div>
                            <h6>{addProviderReviewData?.providerFirstName ? <>
                                {addProviderReviewData?.providerFirstName}&nbsp;
                                {addProviderReviewData?.providerMiddletName}&nbsp;
                                {addProviderReviewData?.providerLastName}</> : "--"}
                            </h6>
                        </Col>
                        <Col md={2}>
                            {!approvedStatusChange && !rejectStatusChange && (alternateLocations?.length > 0) &&
                                <p className="status-pending mt-1">Pending</p>
                            }
                            {approvedStatusChange && providerReviewApproval &&
                                <p className="status-approve mt-1">Approved</p>
                            }
                            {rejectStatusChange && providerReviewReject && (alternateLocations?.length > 0) &&
                                <p className="status-rejected mt-1">Rejected</p>
                            }
                        </Col>
                    </Row>
                </div>
                <div className="mainblockbox mb-2">
                    <Row>
                        <Block
                            labelText={labels.Provider_Prefix}
                            details={prefixName(`${addProviderReviewData?.providerPrefix}`)}
                        />
                        <Block
                            labelText={labels.Provider_Name}
                            details={addProviderReviewData?.providerFirstName ? `${addProviderReviewData?.providerFirstName} ${" "}
              ${addProviderReviewData?.providerMiddletName ? addProviderReviewData?.providerMiddletName : ""} ${" "} 
              ${addProviderReviewData?.providerLastName}` : "--"}
                        />
                        <Block labelText={labels.Title} details={addProviderReviewData?.title} />
                    </Row>
                </div>
                <div className="mainblockbox mb-2">
                    <Row>
                        <Block labelText={labels.NPI} details={addProviderReviewData?.npi} />
                        <Block
                            labelText={labels.Board_Specialty}
                            details={addProviderReviewData?.boardSpecialty}
                        />
                        <Block
                            labelText={labels.MDOFFICE_ID}
                            details={addProviderReviewData?.mdOfficeId}
                        />
                    </Row>
                </div>
                <div className="mainblockbox">
                    <Row>
                        <Block
                            labelText={labels.Provider_Preferred_Email}
                            details={addProviderReviewData?.providerPreferredEmail}
                        />
                        <Block
                            labelText={labels.Sentara_Email}
                            details={addProviderReviewData?.providerSentaraEmail}
                        />
                    </Row>
                </div>
                {providerLocationsData?.length > 0 &&
                    <div>
                        {alternateLocations?.length > 0 &&
                            <p className="location-text">{labels.Location_Information} </p>
                        }
                        {alternateLocations && alternateLocations?.map((item) => (
                            <div key={item?.locationId}>
                                <div className="mainblockbox mb-2" key={item?.locationId}>
                                    <Row>
                                        <Block
                                            labelText={labels.Provider_Location}
                                            details={item?.providerLocation}
                                        />
                                        <Block
                                            labelText={labels.Provider_at_Location}
                                            details={item?.providerAtLocation}
                                        />
                                        <Block
                                            labelText={labels.Location_Type}
                                            details={item?.locationAssignmentType}
                                        />
                                    </Row>
                                    <Row className="mt-3">
                                        <Block
                                            labelText={labels.Start_Date}
                                            details={
                                                item?.startDate
                                                    ? moment(item?.startDate).format("MM/DD/YYYY")
                                                    : "--"
                                            }
                                        />
                                        <Block
                                            labelText={labels.End_Date}
                                            details={
                                                item?.endDate
                                                    ? moment(item?.endDate).format("MM/DD/YYYY")
                                                    : "--"
                                            }
                                        />
                                    </Row>
                                </div>
                            </div>
                        ))}
                        {primaryLocationsApproved?.length > 0 &&
                            <div>
                                {primaryLocationsPending?.map((item) => (
                                    <div key={item?.locationId}>
                                        <div className="mainblockbox mb-2" key={item?.locationId}>
                                            <Row>
                                                <Block
                                                    labelText={labels.Provider_Location}
                                                    details={item?.providerLocation}
                                                />
                                                <Block
                                                    labelText={labels.Provider_at_Location}
                                                    details={item?.providerAtLocation}
                                                />
                                                <Block
                                                    labelText={labels.Location_Type}
                                                    details={item?.locationAssignmentType}
                                                />
                                            </Row>
                                            <Row className="mt-3">
                                                <Block
                                                    labelText={labels.Start_Date}
                                                    details={
                                                        item?.startDate
                                                            ? moment(item?.startDate).format("MM/DD/YYYY")
                                                            : "--"
                                                    }
                                                />
                                                <Block
                                                    labelText={labels.End_Date}
                                                    details={
                                                        item?.endDate
                                                            ? moment(item?.endDate).format("MM/DD/YYYY")
                                                            : "--"
                                                    }
                                                />
                                            </Row>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        {(((primaryLocations?.length > 0) && !rejectLocStatusChange && !rejectStatusChange &&
                            (primaryLocationsPending?.length > 0) && (primaryLocationsApproved?.length === 0))) &&
                            <div>
                                <p className="location-text">{labels?.Primary_Location_Information} </p>
                                <div className="manage-location-table exist mt-3">
                                    <Table data-testid="providerlisttable">
                                        <thead>
                                            <tr>
                                                <th className="checkhead">
                                                    {(!approvedLocStatusChange && ((isCheck?.length > 0) || (alternateLocations?.length === 0) || approvedStatusChange)) &&
                                                        <Checkbox
                                                            type="checkbox"
                                                            name="selectAll"
                                                            id="selectAll"
                                                            handleClick={handleSelectAll}
                                                            isChecked={isCheckAll}
                                                            className="dropbtn"
                                                        />
                                                    }
                                                </th>
                                                <th>{labels.Details}</th>
                                                <th>{labels.Provider_Location}</th>
                                                <th className="location_header">{labels.Provider_at_Location}</th>
                                                <th className="type_header">{labels.Location_Type}</th>
                                                <th className="start_header">{labels.Start_Date}</th>
                                                <th className="end_header">{labels.End_Date}</th>
                                                <th> {(!approvedLocStatusChange) &&
                                                    <p className="status-pending mt-1">Pending</p>}
                                                    {approvedLocStatusChange &&
                                                        <p className="status-approve mt-1">Approved</p>}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody data-testid="ProviderData">
                                            {sortedData?.map((item) => (
                                                <tr key={item?.locationId}>
                                                    <td className="checkhead">
                                                        {isCheck?.length > 2 &&
                                                            <Checkbox
                                                                key={item?.locationId}
                                                                type="checkbox"
                                                                id={item?.locationId}
                                                                handleClick={(e) => handleClickLoc(e, item?.locationId)}
                                                                isChecked={isCheckLoc?.includes(item?.locationId)}
                                                            />
                                                        }
                                                    </td>
                                                    <td>{item?.approvalStatus === "Approved" ?
                                                        "Old" : "New"}</td>
                                                    <td>{item?.providerLocation}</td>
                                                    <td>{item?.providerAtLocation} </td>
                                                    <td>{item?.locationAssignmentType}</td>
                                                    <td>{item?.startDate
                                                        ? moment(item?.startDate).format("MM/DD/YYYY")
                                                        : "--"}
                                                    </td>
                                                    <td className="text-center">{item?.endDate
                                                        ? moment(item?.endDate).format("MM/DD/YYYY")
                                                        : "--"}
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            <ReviewProviderSubmit
                RequestTypeDataID={RequestTypeData}
                isCheck={isCheck}
                personReqId={addProviderReviewData?.personId}
                practiceInfoId={practiceInfoId}
                providerReviewApproval={providerReviewApproval}
                providerReviewReject={providerReviewReject}
                setIsCheck={setIsCheck}
                setIsCheckLoc={setIsCheckLoc}
                statusChange={statusChange}
                statusChangeReject={statusChangeReject}
                updateUserId={updateUserId}
                updateAction={actionStatus}
                isCheckLoc={isCheckLoc}
                locStatusChange={locStatusChange}
                locRejectStatusChange={locRejectStatusChange}
                locationReviewApproval={locationReviewApproval}
                locationReviewReject={locationReviewReject}
                setChangeProviderMode={setChangeProviderMode}
            />
        </>
    );
};
