/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from "react";
import { Container, Col, Row, Breadcrumb, Form, Table, Modal, Button } from "react-bootstrap";
import { firstLetterOfTwo } from "utils";
import { Link } from "react-router-dom";
import practiceIcon from "./images/Icon-b-practice.svg";
import providerListIcon from "./images/Icon-b-provider-list.svg";
import userIcon from "./images/Icon-b-user.svg";
import sortIcon from "./images/Sort_arrow.svg";
import editIcon from "./images/Icon-b-edit.svg";
import dropIcon from "./images/dropdownIcon.svg";
import labels from "Static/Dictionary.json";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import { useFetch } from "Components/Hooks/Fetch";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import cookies from "js-cookie";
import { EMAIL_REGEX,ALPHABETS_AND_SPECIAL_CHARS_REGEX } from "utils";
import ApproveIcon from "Components/UI/Atoms/ApproveIcon";
import EditSpecialtyPage from "../DataPages/EditSpecialtyPage";
import ChangePrimaryLocationModal from "./ChangePrimaryLocatiomodal";
import { formatDateByTimeZone } from "Static/format";
import addIcon from "Components/Templates/ProviderList/add-icon.svg";
import AddLocationModalPpd from "./AddLocationModalPpd";

function convertLocalToUTCDate(date) {
  return date ? new Date(date) : null;
}

const EditProvider = ({
  practiceTitle,
  providerSummary,
  id,
  selectedPracticeId,
  practiceManagerId,
}) => {
  const [providerEdit, setProviderEdit] = useState({});
  const [providerPrefix, setProviderPrefix] = useState({ prefixId: providerSummary?.prefixId });
  const [changeFieldData, setChangeFieldData] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [sltdSpltyId, setSltdSpltyId] = useState(null);
  const [spltyName, setSpltyName] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [newSpecialty, setNewSpecialty] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [changePrimaryData, setChangePrimaryData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [primaryPracticeId, setPrimaryPracticeId] = useState(null);
  const [primaryLocData, setPrimayLocData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const { data = [] } = useFetch("/api/v1/providers/GetAllTitleTypes");
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const selectedPracticeId1 = localStorage.getItem("selectedPracticeId") || selectedPracticeId;
  const [modalShowLocationList, setModalShowLocationList] = useState(false);
  const [locationListData, setLocationListData] = useState([]);
  const [showLocButton, setShowLocButton] = useState(false);
  const navigation = useNavigate();
  const [gotoListPage, setGotoListPage] = useState(false);

  const fetchPracticeChangeRecords = () => {
    fetch(
      base_url +
      `/api/v1/providers/GetProviderUnderProcessById?providerId=${id}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setChangeFieldData(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchPracticeChangeRecords();
    };
    fetch();
  }, [id]);
  const fetchPrimaryLocChangeRecords = () => {
    fetch(
      base_url +
      `/api/v1/providers/GetSQCNApprovalFlag?providerId=${id}&practiceaManagerId=${practiceManagerId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("API request failed with status: " + response.status);
        }
      })
      .then((data) => {
        const trimmedData = data.toString();
        setChangePrimaryData(trimmedData);
        if (trimmedData.toLowerCase() === "false") {
          setModalShow(false);
          setPrimayLocData(true);
        } else if (trimmedData.toLowerCase() === "true") {
          setModalShow(true);
          setPrimayLocData(true);
        } else if (trimmedData.toLowerCase() === "pending") {
          setModalShow(true);
          setPrimayLocData(false);
        } else {
          setModalShow(false);
          setPrimayLocData(false);
        }
      })
      .catch((error) => {
        console.error("API request error:", error);
      });
  };
  console.log("cd", changeFieldData, spltyName, newSpecialty, primaryPracticeId, providerPrefix);
  const providerNpiData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "NPI"
    ) : "";
  const providerSpeialtyData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "boardspecialty"
    ) : "";
  const providerPrimaryLocationData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "PrimaryLocation"
    ) : "";

  useEffect(() => {
    // const primaryLocations = providerSummary?.practiceAssignment?.filter((item) => item?.practiceId === parseInt(selectedPracticeId1));
    const removedTermedProviders = providerSummary?.practiceAssignment?.filter(item => {
      return item.termDate === null;
    });
    const value = removedTermedProviders?.map((item) => ({
      ...item,
      isProviderTrue: item?.termDate === null ? true : false,
      isProviderFalse: item?.termDate === null ? false : true,
      isDefaultId: item?.assignmentTypeId === 1 ? true : false,
    }));
    const provider = { ...providerSummary, practiceAssignment: value };
    setProviderEdit(provider);
    providerSummary && setSelectedTitle(providerSummary?.titleType[0]?.title);
  }, [providerSummary]);
  const specialtyDetailsByspecialtype =
    providerSummary?.specialtySpecialtyType?.find(
      (item) => item?.specialtyType?.typeSpecialty === "Board Specialty1"
    );
  const prefferedEmailByemailtype = providerSummary?.email?.find(
    (item) => item?.emailType?.typeEmail === "Preferred"
  );
  const sentaraEmailByemailtype = providerSummary?.email?.find(
    (item) => item?.emailType?.typeEmail === "Sentara"
  );
  const firstLetters = firstLetterOfTwo(
    providerSummary?.firstName,
    providerSummary?.lastName
  );
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .max(25, "Too Long!")
     .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX,"Name cannot contain numbers ")
      .required("First Name is required"),
    middleName: yup
      .string()
      .nullable()
      .max(25, "Too Long!")
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Name cannot contain numbers"),
    lastName: yup
      .string()
      .max(25, "Too Long!")
      .matches(ALPHABETS_AND_SPECIAL_CHARS_REGEX, "Name cannot contain numbers ")
      .required("Last Name is required"),
    mdofficeId: yup.string().max(25, "Too Long!"),
    npi: yup
      .string()
      .required("NPI is required")
      .min(10, "NPI should be 10 digits")
      .max(10, "NPI should be 10 digits")
      .matches(/^[0-9]+$/, "Must be only digits"),
    preferredEmailAddress: yup
    .string()
    .required("Email required.")
    .matches(EMAIL_REGEX, "Invalid email format"),

    // sentaraEmailAddress: yup.string().email("Invalid email format"),
  });

  const validateFieldsFn = (prevData, currentData) => {
    const specialtyDetailsByCurrentData =
      currentData?.specialtySpecialtyType?.find(
        (item) => item?.specialtyTypeId?.toString() === "2"
      );
    console.log("prd", prevData, "crd", currentData);
    if ((prevData?.npi?.toString() === currentData?.npi?.toString()) &&
      (specialtyDetailsByspecialtype?.specialty
        ?.id?.toString() ===
        specialtyDetailsByCurrentData?.specialtyId?.toString())
    ) {
      return false;
    } else {
      return true;
    }
  }
  const formik = useFormik({
    initialValues: {
      firstName: providerSummary?.firstName,
      middleName: providerSummary?.middleName,
      lastName: providerSummary?.lastName,
      mdofficeId: providerSummary?.mdofficeId,
      npi: providerSummary?.npi,
      preferredEmailAddress: prefferedEmailByemailtype?.emailAddress,
      sentaraEmailAddress: sentaraEmailByemailtype?.emailAddress,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      console.log("ons", values);
      const payload = {
        prefixId: providerPrefix?.prefixId,
        firstName: values?.firstName,
        middleName: values?.middleName,
        lastName: values?.lastName,
        mdofficeId: values?.mdofficeId,
        npi: values?.npi,
        preferredEmailAddress: values?.preferredEmailAddress,
        sentaraEmailAddress: values?.sentaraEmailAddress,
        updateUser: practiceManagerId
      }
      const emailList = {
        email: [
          {
            emailTypeId: 1,
            emailAddress: values.preferredEmailAddress,
          },
          values.sentaraEmailAddress
            ? { emailTypeId: 2, emailAddress: values.sentaraEmailAddress }
            : { emailTypeId: 2, emailAddress: "" },
        ],
      };
      const reqBody = { ...providerEdit, ...payload, ...emailList };

      reqBody.practiceAssignment = [
        ...providerEdit.practiceAssignment,
      ];

      if (sltdSpltyId) {
        if (reqBody?.specialtySpecialtyType?.length > 0) {
          reqBody.specialtySpecialtyType = [
            ...providerEdit.specialtySpecialtyType.map((item) =>
              item.specialtyTypeId !== 2
                ? { ...item }
                : { specialtyId: sltdSpltyId, specialtyTypeId: 2 }
            ),
          ];
        } else {
          reqBody.specialtySpecialtyType = [
            {
              specialtyId: sltdSpltyId,
              specialtyTypeId: 2,
            },
          ];
        }
      }
      console.log("req", reqBody);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "access-control-allow-credentials": "true",
          "ocp-apim-subscription-key": subscriptionKey,
          "api-supported-versions": "1.0",
        },
        body: JSON.stringify(reqBody),
      };
      let status = "";
      fetch(base_url + `/api/v1/providers/${id}?primaryLocation=${primaryLocData}&issqcnqdmin=false`, requestOptions)
        .then((response) => {
          status = response?.status;
          return response.json()
        })
        .then((res) => {
          if (Number(status) === 200) {
            setErrorMessage();
            setIsLoading(false);
            const validFields = validateFieldsFn(providerSummary, reqBody);
            console.log("vf", validFields);
            if(gotoListPage) {
              navigation("/", {
                state: { isOtherProviderCreate: true },
              });
            } else {
              if (validFields) {
                navigation(`/provider-list/${id}`, { state: { isNEdit: true } });
              } else {
                navigation(`/provider-list/${id}`, { state: { isEdit: true } });
              }
            }
          } else {
            setErrorMessage(res?.message || res?.error);
            setIsLoading(false);
          }
        })
    }
  },
  );

  const handleCheckYes = (e, itemId) => {
    // const updatedData = providerEdit?.practiceAssignment?.map((item) => {
    //   if (item.id === itemId) {
    //     return {
    //       ...item,
    //       isProviderTrue: e.target.checked,
    //       isProviderFalse: !e.target.checked,
    //       termDate: null,
    //       // assignmentTypeId: (item?.isPrimarySelect === true) || (item?.isDefaultId === true) ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         // id: (item?.isPrimarySelect === true) || (item?.isDefaultId === true) ? 1 : 2,
    //       }
    //     };
    //   }
    //   return item;
    // });
    // const selectedPractice = providerEdit?.practiceAssignment?.find(item => item.id === itemId);
    const isProviderTrueSelected = e.target.checked;

    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item.id === itemId) {
        // Update the selected item
        return {
          ...item,
          isProviderTrue: isProviderTrueSelected,
          isProviderFalse: !isProviderTrueSelected,
          termDate: null,
          assignmentTypeId: isProviderTrueSelected ? 2 : 1,
          assignmentType: {
            ...item.assignmentType,
            id: isProviderTrueSelected ? 2 : 1,
          },
          // Ensure isPrimarySelect is false if toggling isProviderTrue
          isPrimarySelect: isProviderTrueSelected ? false : item.isPrimarySelect,
        };
      }

      // Ensure only one isPrimarySelect for isProviderTrue and isProviderFalse
      if (isProviderTrueSelected && item.isProviderTrue) {
        return {
          ...item,
          isPrimarySelect: false,
        };
      }

      if (!isProviderTrueSelected && item.isProviderFalse) {
        return {
          ...item,
          isPrimarySelect: false,
        };
      }

      return item;
    });
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };
  console.log("pedit", providerEdit)
  const handleCheckNo = (e, itemId) => {
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          isProviderTrue: !e.target.checked,
          isProviderFalse: e.target.checked,
          // assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            // id: 2,
          }
        };
      }
      return item;
    });
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };
  const handleRadioSelection = (pid, item) => {
    fetchPrimaryLocChangeRecords();
    console.log("up", selectedPracticeId1, item);

    const selectedPractice = providerEdit?.practiceAssignment?.find(item => item.practiceId === pid);
    const isProviderTrueSelected = selectedPractice?.isProviderTrue;
    const isProviderFalseSelected = selectedPractice?.isProviderFalse;

    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      // If the current item is the selected one, toggle its isPrimarySelect
      if (item.practiceId === pid) {
        setPrimayLocData(!item.isPrimarySelect);
        return {
          ...item,
          assignmentTypeId: item.isPrimarySelect ? 2 : 1,
          assignmentType: {
            ...item.assignmentType,
            id: item.isPrimarySelect ? 2 : 1,
          },
          isPrimarySelect: !item.isPrimarySelect,
          isDefaultId: false,
        };
      }

      // Ensure there can be only one isPrimarySelect: true for each isProviderTrue and isProviderFalse
      if (isProviderTrueSelected && item.isProviderTrue) {
        return {
          ...item,
          assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            id: 2,
          },
          isPrimarySelect: false,
          isDefaultId: false,
        };
      }

      if (isProviderFalseSelected && item.isProviderFalse) {
        return {
          ...item,
          assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            id: 2,
          },
          isPrimarySelect: false,
          isDefaultId: false,
        };
      }

      return item;
    });

    // Set the updated state
    setProviderEdit((prevState) => ({
      ...prevState,
      practiceAssignment: updatedData,
    }));
    
    // setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
    setPrimaryPracticeId(pid);
  };

  const handlePrimaryCancel = () => {
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item?.isDefaultId) {
        return {
          ...item,
          assignmentTypeId: item?.practiceId === primaryPracticeId ? 2 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.practiceId === primaryPracticeId ? 2 : 2
          }
        };
      }
      return {
        ...item,
        assignmentTypeId: item?.practiceId === primaryPracticeId ? 2 : 2,
        ["assignmentType.id"]: item?.practiceId === primaryPracticeId ? 2 : 2
      };
    });
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
    setPrimayLocData(false);
  }
  const startDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item.id === itemId) {
        console.log("item", item.id);
        return { ...item, startDate: isoDateString };
      }
      return item;
    });
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };
  const endDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item.id === itemId) {
        return { ...item, termDate: isoDateString };
      }
      return item;
    });
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };
  const newhandleSelectPrefix = (e) => {
    const value = { ...providerPrefix, prefixId: e.target.value };
    setProviderPrefix(value);
  };
  const selectedSpecialtyIdfn = (id, name, data = []) => {
    console.log("spltyId", id, name, data);
    const fName = data?.filter(
      (item) => item?.specialty?.specialtyName?.toLowerCase() === name?.toLowerCase()
    );
    if (fName.length > 0) {
      setNewSpecialty(false);
    } else {
      setNewSpecialty(true);
    }
    setSltdSpltyId(id);
    setSpltyName(name);
  };
  const handleClickStorage = () => {
    localStorage.removeItem("tab");
  };
  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };

  const handleSearchAddressChange = (e) => {
    const input = e.target.value;
    setSearchAddress(input);
  };

  const handleSearchStartDateChange = (e) => {
    const input = e.target.value;
    setSearchStartDate(input);
  };

  const handleSearchEndDateChange = (e) => {
    const input = e.target.value;
    setSearchEndDate(input);
  };

  const createSearchPattern = (input) => {
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };

  const filterData = (item) => {
    const searchLocationNamePattern = createSearchPattern(searchInput);
    const searchAddressPattern = createSearchPattern(searchAddress);
    const searchStartDatePattern = createSearchPattern(searchStartDate);
    const searchEndDatePattern = createSearchPattern(searchEndDate);

    const locationMatches = item?.practice?.location?.some((location) => {
      const locationName = location?.locationName?.toLowerCase().replace(/\s/g, "");
      const address = `${location?.street1 || ""} ${location?.street2 || ""} ${location?.city || ""} ${location?.state || ""} ${location?.zipCode || ""}`.toLowerCase().replace(/\s/g, "");
      const startDate = item?.startDate ? moment(item.startDate).format("MM/DD/YYYY")?.toLowerCase().replace(/\s/g, "") : "";
      const endDate = item?.termDate ? moment(item?.termDate).format("MM/DD/YYYY")?.toLowerCase().replace(/\s/g, "") : "";

      return (
        searchLocationNamePattern.test(locationName) &&
        searchAddressPattern.test(address) &&
        searchStartDatePattern.test(startDate) &&
        searchEndDatePattern.test(endDate)
      );
    });

    return locationMatches;
  };

  useEffect(() => {
    const newFilteredData = providerEdit?.practiceAssignment?.filter(filterData);
    setFilteredAllData(newFilteredData);
  }, [searchInput, searchAddress, searchStartDate, searchEndDate,providerEdit, providerEdit?.practiceAssignment]);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortDataByStartDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.startDate).diff(moment(b.startDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };

  const sortDataByEndDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.termDate).diff(moment(b.termDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };

  const clickHandler = (locations) => {
    //   setAddLocationFromModal(locations);
    const fData = locations?.map((item) => ({
      ...item,
      isProviderTrue: item?.termDate === null ? true : false,
      isProviderFalse: item?.termDate === null ? false : true,
      isDefaultId: item?.assignmentTypeId === 1 ? true : false,
      assignmentTypeId: 2,
      isNewLocation: true,
      personId: parseInt(id),
      practice: {
        id: item.practiceId,
        location: item.locationDetails.map((location) => ({
          ...location,
          id: location.locationId,
          status: "Approved",
          sourceSystem: "SQCN"
        }))
      },
      roleTypeId:2,
      status: "Approved",
      sourceSystem: "SQCN"
    }));

    let localPracticeEdit = { ...providerEdit };

     console.log("--------------localPracticeEdit-------------------------",localPracticeEdit);

    let updatedPracticeAssignment = [];

    if (localPracticeEdit?.practiceAssignment?.length > 0) {
     // updatedPracticeAssignment = [...localPracticeEdit?.practiceAssignment];

      updatedPracticeAssignment = [...localPracticeEdit?.practiceAssignment, ...fData];
    } else {
      updatedPracticeAssignment = fData;
    }
    updatedPracticeAssignment.forEach((item, index) => {
      item.id = index + 1;
    });

    localPracticeEdit.practiceAssignment = updatedPracticeAssignment;

    console.log("--------------localPracticeEdit----after---------------------",localPracticeEdit);

    setProviderEdit(localPracticeEdit);
  };
  const getLocations = () => {
    const base_url00 = process.env.REACT_APP_SERVER_URL;
    fetch(
      base_url00 +
        `/api/v1/practices/PracticeSummary?practiceManagerId=${practiceManagerId}&isSQCNAdmin=${false}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if(data){
          setLocationListData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      getLocations();
    };
    fetchData();
  }, [selectedPracticeId]);

  const primaryPending = filteredAllData?.filter(item => item?.status === "Pending" && item?.assignmentTypeId === 1);

  const removeObjectsByIds = (items, idsToRemove) => {
    const idsToRemoveArray = idsToRemove.map(item => item.practiceId);
    return items?.filter(item => !idsToRemoveArray.includes(item.practiceId));
  };
  useEffect(() => {
    if(locationListData){
      if (locationListData?.practiceDetails?.length > 0 && filteredAllData?.length > 0) {
        const updatedFilteredData = removeObjectsByIds(
          locationListData?.practiceDetails,
          filteredAllData
        );
        if (updatedFilteredData?.length === 0) {
          setShowLocButton(false);
        } else {
          setShowLocButton(true);
        }
      }
    } else if(filteredAllData?.length === 0) {
      setShowLocButton(true);
    }
  }, [locationListData, filteredAllData]);
  return (
    <div>
      <Container className="mt-40">
        <Breadcrumb>
          <Breadcrumb.Item data-testid="practiceName">
            <Link
              to={"/"}
              data-testid="practicetitlelink"
              onClick={handleClickStorage}
            >
              <img src={practiceIcon} alt="Practice Icon" /> {practiceTitle}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" data-testid="providerlistpage">
            <Link to={"/"} data-testid="providerlist">
              <img src={providerListIcon} alt="Practice Icon" /> Provider List
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item data-testid="providerNameDisplay">
            <Link to={`/provider-list/${id}`} data-testid="providerlist">
              <img src={userIcon} alt="Practice Icon" />{" "}
              {providerSummary?.firstName} {providerSummary?.lastName}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active data-testid="edit">
            <img src={editIcon} alt="Edit Icon" /> Edit
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="edit-provider">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={2} xs={12}>
                <div className="provider-profile">
                  <div
                    className="firstletter justify-content-center  d-flex align-items-center"
                    data-testid="providerNamefirstletters"
                  >
                    {firstLetters}
                  </div>
                </div>
              </Col>
              <Col md={10} xs={12}>
                <div className="main-block">
                  <div className="block">
                    <div className="title mt-2">{labels.PREFIX}</div>
                    <div className="title-dropdown">
                      <div className="title-change" data-testid="locationType">
                        {providerPrefix?.prefixId == 1 && <div>--</div>}
                        {providerPrefix?.prefixId == 2 && <div>Mr</div>}
                        {providerPrefix?.prefixId == 3 && <div>Ms</div>}
                        {providerPrefix?.prefixId == 4 && <div>Dr</div>}
                        <span className="locationtypeicon">
                          <img src={dropIcon} alt="dropdown icon" />
                        </span>
                      </div>
                      <div className="title-list-block">
                        <ul className="title-list">
                          <li className="titles" data-testid="Type">
                            <button
                              type="button"
                              name="prefixType"
                              className={providerPrefix.prefixId == 1 ? "selected-primary" : ""}
                              onClick={(e) => newhandleSelectPrefix(e)}
                              value="1"
                              data-testid="TypeMr"
                            >
                              --
                            </button>
                          </li>
                          <li className="titles" data-testid="Type">
                            <button
                              type="button"
                              name="prefixType"
                              className={providerPrefix.prefixId == 2 ? "selected-primary" : ""}
                              onClick={(e) => newhandleSelectPrefix(e)}
                              value="2"
                              data-testid="TypeMr"
                            >
                              Mr
                            </button>
                          </li>
                          <li className="titles" data-testid="Type">
                            <button
                              type="button"
                              name="prefixType"
                              className={providerPrefix.prefixId == 3 ? "selected-primary" : ""}
                              onClick={(e) => newhandleSelectPrefix(e)}
                              value="3"
                              data-testid="TypeMs"
                            >
                              Ms
                            </button>
                          </li>
                          <li className="titles" data-testid="Type">
                            <button
                              type="button"
                              name="prefixType"
                              className={providerPrefix.prefixId == 4 ? "selected-primary" : ""}
                              onClick={(e) => newhandleSelectPrefix(e)}
                              value="4"
                              data-testid="TypeDr"
                            >
                              Dr
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="block">
                    <Form.Label>{labels.FIRST_NAME}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      className={
                        formik.touched.firstName && formik.errors.firstName
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                      name="firstName"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerFirstName"
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div
                        className="form-error"
                        data-testid="providerFirstNameError"
                      >
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.MIDDLE_INTITIAL}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      name="middleName"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.middleName}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerMiddleName"
                      className={
                        formik.touched.middleName && formik.errors.middleName
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.middleName && formik.errors.middleName ? (
                      <div
                        className="form-error"
                        data-testid="providerMiddleNameError"
                      >
                        {formik.errors.middleName}
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.LAST_NAME}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      name="lastName"
                      className={
                        formik.touched.lastName && formik.errors.lastName
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerLastName"
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div
                        className="form-error"
                        data-testid="providerLastNameError"
                      >
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <Form.Label>{labels.TITLE}</Form.Label>
                    <div className="title-dropdown">
                      <div className="title-change" data-testid="titleName">
                        {selectedTitle}
                        <span>
                          <img src={dropIcon} alt="dropdown icon" />
                        </span>
                      </div>
                      <div className="title-list-block">
                        <ul className="title-list">
                          {data.map((title) => (
                            <li
                              className="titles"
                              key={title.id}
                              value={title.title}
                            >
                              <button
                                type="button"
                                data-testid={`title-button-${title.id}`}
                                onClick={() => {
                                  setSelectedTitle(title.title);
                                  setProviderEdit((prev) => {
                                    let prevState = prev;
                                    prevState.titleType[0].title = title.title;
                                    prevState.titleType[0].id = title.id;
                                    return prevState;
                                  });
                                }}
                              >
                                {title.title}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <Form.Label>{labels.BOARD_SPECIALITY}
                      {providerSpeialtyData?.status === "Pending" && (
                        <span>
                          <ApproveIcon />
                        </span>
                      )}
                    </Form.Label>
                    {providerSpeialtyData?.status === "Pending" ? (
                      <div className="info" data-testid="npidata">
                        {providerSpeialtyData?.newValue2 || providerSpeialtyData?.newValue}
                      </div>
                    ) :
                      <EditSpecialtyPage
                        specialtyDetailsByspecialtype={
                          specialtyDetailsByspecialtype
                        }
                        selectedSpecialtyIdfn={selectedSpecialtyIdfn}
                      />
                    }
                    {!spltyName && (
                      <p className="form-error">Board Specialty is required</p>
                    )}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.NPI}
                      {providerNpiData?.status === "Pending" && (
                        <span>
                          <ApproveIcon />
                        </span>
                      )}
                    </Form.Label>
                    {providerNpiData?.status === "Pending" ? (
                      <div className="info" data-testid="npidata">
                        {providerNpiData?.newValue}
                      </div>
                    ) :
                      < Form.Control
                        type="text"
                        placeholder="---"
                        name="npi"
                        className={
                          formik.touched.npi && formik.errors.npi
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.npi}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerNpi"
                      />
                    }
                    {formik.touched.npi && formik.errors.npi ? (
                      <div
                        className="form-error"
                        data-testid="providerNpiError"
                      >
                        {formik.errors.npi}
                      </div>
                    ) : null}

                  </div>
                  <div className="block">
                    <Form.Label>{labels.MDOFFICE_ID}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="---"
                      name="mdofficeId"
                      className={
                        formik.touched.mdofficeId && formik.errors.mdofficeId
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mdofficeId}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerMDofficeId"
                    />
                    {formik.touched.mdofficeId && formik.errors.mdofficeId ? (
                      <div
                        className="form-error"
                        data-testid="providerMDofficeIdError"
                      >
                        {formik.errors.mdofficeId}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <Form.Label>{labels.PREFERED_EMAIL_ADDRESS}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="---"
                      name="preferredEmailAddress"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.preferredEmailAddress}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerPreferredEmailAddress"
                      className={
                        formik.touched.preferredEmailAddress &&
                          formik.errors.preferredEmailAddress
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.preferredEmailAddress &&
                      formik.errors.preferredEmailAddress ? (
                      <div
                        className="form-error"
                        data-testid="providerPreferredEmailAddressError"
                      >
                        {formik.errors.preferredEmailAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.SENTARA_EMAIL_ADDRESS}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="---"
                      name="sentaraEmailAddress"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sentaraEmailAddress}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providersentaraEmailAddress"
                      className={
                        formik.touched.sentaraEmailAddress &&
                          formik.errors.sentaraEmailAddress
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.sentaraEmailAddress &&
                      formik.errors.sentaraEmailAddress ? (
                      <div
                        className="form-error"
                        data-testid="providersentaraEmailAddressError"
                      >
                        {formik.errors.sentaraEmailAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className="block"></div>
                  <div className="block"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12}>
                <div className="location-banner provider-loc" data-testid="locationBanner">
                    <Row>
                      <Col
                        md={8}
                        xs={12}
                        className="practice-loc-title"
                        data-testid="practicelocationTitle"
                      >
                        <h4>Provider Locations</h4>
                      </Col>
                      {showLocButton ?
                        <Col md={4} xs={12} className="ta-right">
                          <Button
                            variant="primary"
                            onClick={() => setModalShowLocationList(true)}
                            className="add-location"
                            data-testid="AddLocationbutton"
                          >
                            <span>
                              <img src={addIcon} alt="add icon" />
                            </span>
                            Add Provider Locations
                          </Button>
                          {modalShowLocationList && (
                            <AddLocationModalPpd
                              data-testid="LocationModel"
                              show={modalShowLocationList}
                              onHide={() => setModalShowLocationList(false)}
                              clickHandler={clickHandler}
                              practiceManagerId={practiceManagerId}
                              data={locationListData}
                              id="0"
                              filteredAllData={filteredAllData}
                            />
                          )}
                        </Col>
                        : null
                      }
                    </Row>
                </div>
                <div className="provider-location-table">
                  <Table data-testid="providerLocationtable">
                    <thead>
                      <tr>
                        <th>Location Number - Name</th>
                        <th>Address</th>
                        <th className="phone-head5">Provider at Location</th>
                        <th className="phone-head4">Is Primary?</th>
                        <th className="phone-head6"><span className="date_text">Start Date</span>
                          <button className="sort_icon_arrow" type="button" onClick={sortDataByStartDate}>
                            <img src={sortIcon} alt="Sort Icon" />
                          </button>
                        </th>
                        <th className="phone-head6"><span className="date_text">End Date </span>
                          <button className="sort_icon_arrow" type="button" onClick={sortDataByEndDate}>
                            <img src={sortIcon} alt="Sort Icon" />
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody data-testid="ProviderData">
                      <tr>
                        <td className="search_column">
                          <Form.Group className="search-npi" controlId="formGroupEmail">
                            <Form.Control
                              type="text"
                              name="location"
                              placeholder="Search"
                              autoComplete="off"
                              className="search-input new"
                              value={searchInput}
                              onChange={handleSearchChange}
                              data-testid="LocationName"
                            />
                          </Form.Group>
                        </td>
                        <td className="search_column">
                          <Form.Group className="search-npi" controlId="formGroupEmail">
                            <Form.Control
                              type="text"
                              name="location"
                              placeholder="Search"
                              autoComplete="off"
                              className="search-input new"
                              value={searchAddress}
                              onChange={handleSearchAddressChange}
                              data-testid="LocationName"
                            />
                          </Form.Group>
                        </td>
                        <td className="search_column"></td>
                        <td className="search_column"></td>
                        <td className="search_column">
                          <Form.Group className="search-npi" controlId="formGroupEmail">
                            <Form.Control
                              type="text"
                              name="location"
                              placeholder="Search"
                              autoComplete="off"
                              className="search-input new"
                              value={searchStartDate}
                              onChange={handleSearchStartDateChange}
                              data-testid="LocationName"
                            />
                          </Form.Group>
                        </td>
                        <td className="search_column1">
                          <Form.Group className="search-npi" controlId="formGroupEmail">
                            <Form.Control
                              type="text"
                              name="location"
                              placeholder="Search"
                              autoComplete="off"
                              className="search-input new"
                              value={searchEndDate}
                              onChange={handleSearchEndDateChange}
                              data-testid="LocationName"
                            />
                          </Form.Group>
                        </td>
                      </tr>
                      {filteredAllData?.map((item) => {
                        const formattedStartDate = item.startDate ? new Date(item.startDate) : null;
                        const formattedTermDate = item.termDate ? new Date(item.termDate) : null;
                        return (
                          <React.Fragment key={item?.id}>
                            {item?.practice?.location?.map((locationItem) => (
                              <tr key={locationItem.id}>
                                <td className={locationItem?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                  {locationItem?.locationNumber}&nbsp;-&nbsp;{locationItem?.locationName}
                                </td>
                                <td className={locationItem?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                  {`${locationItem?.street1} ${locationItem?.street2 ? locationItem?.street2 : ""}, ${locationItem?.city}, ${locationItem?.state} ${locationItem?.zipCode}`}
                                </td>
                                <td className={locationItem?.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                                  <div className="info">
                                    <Form.Check
                                      inline
                                      type="radio"
                                      disabled={locationItem?.locationNumber !== 1}
                                      label="Yes"
                                      data-testid="providerAtLocationYes"
                                      value={item?.isProviderTrue}
                                      checked={item?.isProviderTrue}
                                      onChange={(e) => handleCheckYes(e, item?.id)}
                                    />
                                    <Form.Check
                                      inline
                                      type="radio"
                                      disabled={locationItem?.locationNumber !== 1}
                                      label="No"
                                      data-testid="providerAtLocationNo"
                                      value={item?.isProviderFalse}
                                      checked={item?.isProviderFalse}
                                      onChange={(e) => handleCheckNo(e, item?.id)}
                                    />
                                  </div>
                                </td>
                                {locationItem?.locationNumber === 1 ? (
                                  <td className={locationItem?.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                                    {primaryPending?.length > 0 ? (
                                      item?.status === "Pending" && item?.assignmentTypeId === 1 ? (
                                        <>
                                          <ApproveIcon />&nbsp;&nbsp;Yes
                                        </>
                                      ) : (
                                        <div className="info">
                                          <Form.Check
                                            inline
                                            type="radio"
                                            data-testid="providerPrimary"
                                            value="yes"
                                            checked={item?.assignmentTypeId === 1}
                                            onChange={() => handleRadioSelection(item?.practiceId, item)}
                                          />
                                        </div>
                                      )
                                    ) : (
                                      providerPrimaryLocationData ? (
                                        providerPrimaryLocationData?.newValue?.toLowerCase() === locationItem?.locationName?.toLowerCase() ? (
                                          <>
                                            <ApproveIcon />&nbsp;&nbsp;Yes
                                          </>
                                        ) : <div className="info">
                                              <Form.Check
                                                inline
                                                type="radio"
                                                data-testid="providerPrimary"
                                                value="yes"
                                                checked={item?.assignmentTypeId === 1}
                                                onChange={() => handleRadioSelection(item?.practiceId, item)}
                                              />
                                            </div>
                                      ) : (
                                        <div className="info">
                                          <Form.Check
                                            inline
                                            type="radio"
                                            data-testid="providerPrimary"
                                            value="yes"
                                            checked={item?.assignmentTypeId === 1}
                                            onChange={() => handleRadioSelection(item?.practiceId, item)}
                                          />
                                        </div>
                                      )
                                    )}
                                  </td>
                                ) : (
                                  <td className={locationItem?.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                                  </td>
                                )}

                                {locationItem?.locationNumber === 1 ? (
                                  <td className={locationItem?.locationNumber === 1 ? "Primary1" : "Secondary1 text-center"}>
                                    <div className="info" data-testid="editstartDateInput">
                                      <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        selected={formattedStartDate}
                                        onChange={(date) => startDateFn(date, item?.id)}
                                        placeholderText="MM/DD/YYYY"
                                        data-testid="editproviderStartDate"
                                      />
                                    </div>
                                  </td>
                                ) : (
                                  <td className={locationItem?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                    {item?.startDate ? moment(item?.startDate).format("MM/DD/YYYY") : "--"}
                                  </td>
                                )}
                                {locationItem?.locationNumber === 1 ? (
                                  <td className={locationItem?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                    <div className="info" data-testid="editendDateInput">
                                      <DatePicker
                                        disabled={item?.isProviderTrue}
                                        dateFormat="MM/dd/yyyy"
                                        data-testid="editproviderEndDate"
                                        selected={formattedTermDate}
                                        placeholderText="MM/DD/YYYY"
                                        onChange={(date) => endDateFn(date, item?.id)}
                                        required
                                        minDate={formattedStartDate}
                                      />
                                    </div>
                                  </td>
                                ) : (
                                  <td className={locationItem?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                                    {item?.termDate ? moment(item?.termDate).format("MM/DD/YYYY") : "--"}
                                  </td>
                                )}
                              </tr>
                            ))}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <div className="add-location-footer">
                  {errorMessage && (
                    <p className="Error-message">
                      {errorMessage}
                    </p>
                  )}
                  <p data-testid="providerStartDateconfirmText">
                    Please confirm that all information is correct
                  </p>
                  <div className="add-location-buttons">
                    <button
                      className="white-btn"
                      data-testid="EditCancelButton"
                      onClick={() => {
                        navigation(`/provider-list/${id}`);
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="blue-btn"
                      type="submit"
                      disabled={!spltyName || isLoading}
                      data-testid="EditConfirmButton"
                    >
                      {isLoading ? "Saving..." : "Confirm and Save"}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container >
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="update-provider-modal"
        backdrop="static"
        keyboard={false}
        data-testid="bulkupdateprovidermodal"
      >
        <ChangePrimaryLocationModal
          providerSummary={providerSummary}
          handlePrimaryCancel={handlePrimaryCancel}
          modalClose={() => setModalShow(false)}
          setGotoListPage={setGotoListPage}
          changePrimaryData={changePrimaryData}
          />
      </Modal>
    </div >
  );
};

export default EditProvider;
