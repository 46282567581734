import React from "react";
// import { useFetch } from "Components/Hooks/Fetch";
import AddLocationModalAdmin from "../ProviderList/AddLocationModalAdmin";

function AddLocationModalPageAdmin({
  show,
  onHide,
  clickHandler,
  addLocationList,
  filteredAllData
}) {
  return (
    <>
      <AddLocationModalAdmin
        data={addLocationList}
        show={show}
        onHide={onHide}
        clickHandler={clickHandler}
        filteredAllData={filteredAllData}
      />
    </>
  );
}

export default AddLocationModalPageAdmin;
