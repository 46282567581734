import React from "react";
import { useIdleTimer } from "react-idle-timer";
import cookies from "js-cookie";
import { Link } from "react-router-dom";
import logo from "./logo.svg";
import avatar from "./Avatar.svg";
import { Container, Row, Col } from "react-bootstrap";
import "./_style.scss";

const handleLogout = () => {
  const baseURL = process.env.REACT_APP_SERVER_URL2;
  window.location.href = baseURL;
  cookies.remove("access");
  localStorage.clear();
};
function Header({ pmName, PersonRole, personRoleTypeId }) {
  const timeout = 28800000;
  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleLogout,
  });
  console.log("grt", getRemainingTime, PersonRole, personRoleTypeId);
  return (
    <div data-testid="header" className="header">
      <Container>
        <div className="content">
          <Row>
            <Col md={6} xs={6}>
              <Link to="/">
                <img src={logo} alt="Sentara Quality Care" className="_logo" />
              </Link>
              <h6 className="text-hide logo-text" data-testid="logotext">
                Integrated Directory
              </h6>
            </Col>
            <Col md={2} className="sqcn-ad order-lastalign">
              {PersonRole === "SQCNAdmin" &&
                <a
                  className="sqcn-admin-button"
                  data-testid="Editbuttonlink"
                  href="/sqcn-admin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SQCN Admin
                </a>
              }
            </Col>
            <Col md={4} xs={6} className="order-lastalign">
              <div className="user-content">
                <h6 className="user-name">{pmName}</h6>
                {/* <p className="role-name">
                  {PersonRole === "SQCNAdmin" ? "SQCN Admin" :
                    <>
                      {personRoleTypeId === "12" ? "PPD User" : "Practice Manager"}
                    </>
                  }
                </p> */}
                <button className="user-signout" onClick={handleLogout}>
                  Sign Out
                </button>
              </div>
              <div className="user-image">
                <img src={avatar} alt="User" className="_avatar" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Header;
