/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import useOutsideClick from "../../../Hooks/useOutsideClick";

function BoardSpecialty({
  specialtydata,
  specialtyDetailsByspecialtype,
  selectedSpecialtyIdfn
}) {
  const ref = useRef();
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [selectedSpecialtyId, setSelectedSpecialtyId] = useState();
  const [filterDropdown, setFilterDropdown] = useState(false);
  useOutsideClick(ref, () => {
    if (filterDropdown) {
      setFilterDropdown(false);
    }
  });
  useEffect(() => {
    if (specialtyDetailsByspecialtype) {
      setSelectedSpecialty(
        specialtyDetailsByspecialtype?.specialty?.specialtyName
      );
    } else {
      setSelectedSpecialty("");
    }
  }, [specialtyDetailsByspecialtype]);
  console.log("sty", specialtyDetailsByspecialtype?.specialty?.specialtyName);
  const onChange = (event) => {
    const evValue = event.target.value;
    setSelectedSpecialty(event.target.value);
    setFilterDropdown(true);
    const spl = specialtydata?.filter(
      (item) => item?.specialtyName?.toLowerCase() === evValue?.toLowerCase()
    );
    console.log("event", event.target.value, specialtydata, spl);
    if (spl.length > 0) {
      setSelectedSpecialty(spl[0].specialtyName);
      setSelectedSpecialtyId(spl[0].id);
    }
  };
  const onSearch = (searchTerm, id) => {
    console.log("onSearch called");
    const id1 = id;
    const searchTerm1 = searchTerm;
    setSelectedSpecialty(searchTerm1);
    setSelectedSpecialtyId(id1);
    console.log("on", searchTerm, id);
  };
  useEffect(() => {
    if (selectedSpecialtyId) {
      selectedSpecialtyIdfn(
        selectedSpecialtyId,
        selectedSpecialty.trim(),
        specialtydata
      );
    } else {
      selectedSpecialtyIdfn(null, selectedSpecialty.trim(), specialtydata);
    }
  }, [selectedSpecialty, selectedSpecialtyId, specialtydata]);
  console.log("spl", selectedSpecialty, selectedSpecialtyId);
  return (
    <>
      <Form.Control
        type="text"
        value={selectedSpecialty}
        onChange={onChange}
        onKeyDown={(e) =>
          [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "0",
            "!",
            "@",
            "#",
            "$",
            "%",
            "^",
            "&",
            "*",
            "(",
            ")",
            "-",
            "_",
            "+",
            "=",
            "Enter",
          ].includes(e.key) && e.preventDefault()
        }
        className="nb-input"
        data-testid="specialty-input"
      />
      {filterDropdown && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className="dropdown"
          data-testid="dropdown-row-1"
          onClick={() => setFilterDropdown(!filterDropdown)}
          ref={ref}
        >
          {specialtydata
            ?.filter((item) => {
              const searchTerm = selectedSpecialty?.toLowerCase();
              const spltyName = item?.specialtyName?.toLowerCase();
              return (
                (searchTerm && spltyName?.startsWith(searchTerm)) ||
                spltyName === searchTerm
              );
            })
            .slice(0, 10)
            .map((item) => (
              <button
                onClick={() => onSearch(item?.specialtyName, item?.id)}
                className="dropdown-row"
                key={item?.id}
                data-testid={`dropdown-row${item.id}`}
              >
                {item?.specialtyName}
              </button>
            ))}
        </div>
      )}
    </>
  );
}

export default BoardSpecialty;
