import React from "react";
import { useFetch } from "Components/Hooks/Fetch";
import BoardSpecialty from "Components/UI/Organisms/Specialty";

function SpecialtyPage({
  specialtyDetailsByspecialtype,
  selectedSpecialtyIdfn
}) {
  const { data } = useFetch("/api/v1/Specialties");
  return (
    <>
      <BoardSpecialty
        specialtydata={data}
        specialtyDetailsByspecialtype={specialtyDetailsByspecialtype}
        selectedSpecialtyIdfn={selectedSpecialtyIdfn}
      />
    </>
  );
}

export default SpecialtyPage;
